let functionList = {}
export const getFunctionList = val => {
  functionList = val
}
// 详情基本信息字段
export const detailBaseFields = [
    {
      key: 'code',
      name: '班次编号：'
    },
    {
      key: 'name',
      name: '班次名称：'
    },
    {
        key: 'startTime',
        name: '开始时间：'
      },
      {
        key: 'endTime',
        name: '结束时间：'
      },
      // {
      //   key: 'period',
      //   name: '周期设置：'
      // },
      {
        key: 'periodDate',
        name: '重复周期：'
      },
      {
        key: 'workdays',
        name: '工作日设置：'
      },
      {
        key: 'time',
        name: '工作时段：'
      },
    {
      key: 'remark',
      name: '备注：'
    }
  ]