var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "model-wrap" },
    [
      _c(
        "Split",
        {
          attrs: { offset: _vm.split.offset, height: _vm.splitHeight },
          on: {
            "update:offset": function($event) {
              return _vm.$set(_vm.split, "offset", $event)
            }
          }
        },
        [
          _c("template", { slot: "left" }, [
            _c("div", { staticClass: "group" }, [
              _c("div", { staticClass: "top" }, [
                _c(
                  "div",
                  {
                    staticClass: "title",
                    on: {
                      click: function($event) {
                        return _vm.onAndOrModify("RepositoryListModel")
                      }
                    }
                  },
                  [_vm._v("新建仓库")]
                )
              ]),
              _c(
                "div",
                { staticClass: "content" },
                _vm._l(_vm.getLeftList, function(item) {
                  return _c(
                    "div",
                    {
                      key: item.id,
                      staticClass: "Warehouse",
                      class: { active: item.id === _vm.currentId },
                      on: {
                        click: function($event) {
                          return _vm.onChangeRepository(item)
                        }
                      }
                    },
                    [
                      _c("span", { staticClass: "ellipsis" }, [
                        _vm._v(_vm._s(item.name))
                      ]),
                      item.id === _vm.currentId && item.isSystem !== 1
                        ? _c("div", [
                            _c("i", {
                              staticClass: "modify",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.onAndOrModify(
                                    "RepositoryListModel",
                                    item
                                  )
                                }
                              }
                            }),
                            _c("i", {
                              staticClass: "del",
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.onDeleteHouse(item)
                                }
                              }
                            })
                          ])
                        : _vm._e()
                    ]
                  )
                }),
                0
              )
            ])
          ]),
          _c("template", { slot: "right" }, [
            _c(
              "div",
              { staticClass: "model-wrap-right" },
              [
                _c(
                  "el-radio-group",
                  {
                    staticClass: "m-radio-group",
                    on: { change: _vm.radioChange },
                    model: {
                      value: _vm.params.radio,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "radio", $$v)
                      },
                      expression: "params.radio"
                    }
                  },
                  [
                    _c(
                      "el-radio-button",
                      { attrs: { label: "RepositoryModel" } },
                      [_vm._v("库位设置")]
                    ),
                    _c(
                      "el-radio-button",
                      { attrs: { label: "WarehouseSafeStock" } },
                      [_vm._v("安全库存")]
                    ),
                    _c(
                      "el-radio-button",
                      { attrs: { label: "WarehouseCustomer" } },
                      [_vm._v("适用客户")]
                    )
                  ],
                  1
                ),
                this.params.radio === "RepositoryModel" && _vm.parentInfo.id
                  ? _c(
                      "div",
                      { staticClass: "mtable-header" },
                      [
                        _c(
                          "div",
                          { staticClass: "top" },
                          [
                            _c("span", { staticClass: "desc" }, [
                              _vm._v("是否启用库位管理")
                            ]),
                            _c("el-switch", {
                              attrs: {
                                value: _vm.parentInfo.isLocation === 1,
                                "active-color": "#13ce66",
                                "inactive-color": "#eee"
                              },
                              on: { change: _vm.onChangeLocation }
                            })
                          ],
                          1
                        ),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              disabled: _vm.parentInfo.isLocation !== 1,
                              type: "primary",
                              size: "small"
                            },
                            on: { click: _vm.onAddKw }
                          },
                          [_vm._v("新增库位")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isStock
                  ? _c(
                      "div",
                      { staticClass: "mtable-header" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: { click: _vm.onAddKc }
                          },
                          [_vm._v("新增")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isCustomer
                  ? _c(
                      "div",
                      { staticClass: "mtable-header" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "small" },
                            on: {
                              click: function($event) {
                                return _vm.onAndOrModifyCustomer()
                              }
                            }
                          },
                          [_vm._v("添加客户")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                _c("MTable", {
                  ref: "mTable",
                  attrs: {
                    "default-expand-all": "",
                    "is-tree-data": true,
                    columns: _vm.columns[_vm.params.radio],
                    height: _vm.height,
                    "show-page": _vm.isStock,
                    "set-data-method": _vm.getTableData,
                    "columns-key": _vm.columnsKey,
                    "tree-data-parent-id": _vm.currentId,
                    "columns-setting": false
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "index",
                        fn: function(ref) {
                          var $index = ref.$index
                          return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                        }
                      },
                      {
                        key: "isEnabled",
                        fn: function(ref) {
                          var row = ref.row
                          return _c(
                            "div",
                            { staticClass: "isEnabled" },
                            [
                              _c("el-switch", {
                                attrs: {
                                  value: row.isEnabled === 1,
                                  "active-color": "#13ce66",
                                  "inactive-color": "#eee"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.onSwitchStatus(row)
                                  }
                                }
                              }),
                              _c("div", [
                                _vm._v(_vm._s(row.isEnabled ? "启用" : "停用"))
                              ])
                            ],
                            1
                          )
                        }
                      },
                      {
                        key: "lowerLimit",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _vm._v(" " + _vm._s(row.lowerLimit) + " ")
                          ])
                        }
                      },
                      {
                        key: "upperLimit",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _vm._v(" " + _vm._s(row.upperLimit) + " ")
                          ])
                        }
                      },
                      {
                        key: "action",
                        fn: function(ref) {
                          var row = ref.row
                          return _vm.isCustomer
                            ? _c(
                                "div",
                                {},
                                [
                                  _vm.permission("Del")
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.del(row)
                                            }
                                          }
                                        },
                                        [_vm._v("移除")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            : _c(
                                "div",
                                {},
                                [
                                  !_vm.isStock
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.onRowAppendChild(row)
                                            }
                                          }
                                        },
                                        [_vm._v("新建下级")]
                                      )
                                    : _vm._e(),
                                  _vm.permission("Status") && !_vm.isStock
                                    ? _c("el-divider", {
                                        attrs: { direction: "vertical" }
                                      })
                                    : _vm._e(),
                                  _vm.permission("Modify")
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.onAndOrModify(
                                                _vm.isStock
                                                  ? "WarehouseSafeStock"
                                                  : "RepositoryModel",
                                                row
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" 修改 ")]
                                      )
                                    : _vm._e(),
                                  _vm.permission("Modify")
                                    ? _c("el-divider", {
                                        attrs: { direction: "vertical" }
                                      })
                                    : _vm._e(),
                                  _vm.permission("Del")
                                    ? _c("DelPopover", {
                                        on: {
                                          onOk: function(callback) {
                                            return _vm.del(row, callback)
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                        }
                      }
                    ],
                    null,
                    true
                  )
                }),
                _c(
                  "MDialog",
                  {
                    attrs: { title: _vm.messages[_vm.currentType] },
                    on: { onOk: _vm.submitForm },
                    model: {
                      value: _vm.visible,
                      callback: function($$v) {
                        _vm.visible = $$v
                      },
                      expression: "visible"
                    }
                  },
                  [
                    _c("MFormBuilder", {
                      ref: "formBuild",
                      attrs: {
                        "form-data": _vm.formData,
                        "form-list": _vm.formList[_vm.params.dialogType]
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        2
      ),
      _c("select-materials", {
        ref: "selectMaterials",
        attrs: {
          visible: _vm.showMaterials,
          single: false,
          "is-add": true,
          "select-materials": _vm.selectMaterials
        },
        on: {
          "update:visible": function($event) {
            _vm.showMaterials = $event
          },
          submitForm: _vm.submitMaterials,
          addMaterial: _vm.addMaterial
        }
      }),
      _c("edit-materials", {
        ref: "editMaterials",
        on: { onSuccess: _vm.onSuccess }
      }),
      _c(
        "MDialog",
        {
          attrs: { width: "1200px", title: "新增安全库存" },
          on: { onOk: _vm.onSubmitKc },
          model: {
            value: _vm.showKc,
            callback: function($$v) {
              _vm.showKc = $$v
            },
            expression: "showKc"
          }
        },
        [
          _c(
            "el-button",
            {
              staticClass: "mtable-header",
              attrs: { type: "primary" },
              on: {
                click: function() {
                  return (this$1.showMaterials = true)
                }
              }
            },
            [_vm._v("选择物料")]
          ),
          _c("MTable", {
            ref: "kcTable",
            attrs: {
              height: 325,
              "show-page": false,
              data: _vm.materialList,
              columns: _vm.columns.WarehouseSafeStock,
              "columns-setting": false
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "lowerLimit",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _c("el-input-number", {
                        attrs: {
                          placeholder: "请输入",
                          min: 1,
                          "controls-position": "right",
                          step: 1
                        },
                        model: {
                          value: row.lowerLimit,
                          callback: function($$v) {
                            _vm.$set(row, "lowerLimit", $$v)
                          },
                          expression: "row.lowerLimit"
                        }
                      })
                    ],
                    1
                  )
                }
              },
              {
                key: "upperLimit",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _c("el-input-number", {
                        attrs: {
                          placeholder: "请输入",
                          min: 1,
                          "controls-position": "right",
                          step: 1
                        },
                        model: {
                          value: row.upperLimit,
                          callback: function($$v) {
                            _vm.$set(row, "upperLimit", $$v)
                          },
                          expression: "row.upperLimit"
                        }
                      })
                    ],
                    1
                  )
                }
              },
              {
                key: "action",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "li-btn",
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.onDelMaterial($index)
                            }
                          }
                        },
                        [_vm._v("移除")]
                      )
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }