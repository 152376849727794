var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "batch-number-wrap common-tab" },
    [
      _c(
        "el-radio-group",
        {
          staticClass: "m-radio-group",
          on: { change: _vm.radioChange },
          model: {
            value: _vm.params.radio,
            callback: function($$v) {
              _vm.$set(_vm.params, "radio", $$v)
            },
            expression: "params.radio"
          }
        },
        [
          _vm.permission("fastPage")
            ? _c("el-radio-button", { attrs: { label: "fast" } }, [
                _vm._v("快速生成")
              ])
            : _vm._e(),
          _vm.permission("historyPage")
            ? _c("el-radio-button", { attrs: { label: "history" } }, [
                _vm._v("历史生成")
              ])
            : _vm._e()
        ],
        1
      ),
      _vm.params.radio === "fast" && _vm.permission("fastPage")
        ? _c(
            "div",
            { staticClass: "top" },
            [
              _c(
                "SearchForm",
                {
                  ref: "searchForm",
                  attrs: {
                    "immediately-query": true,
                    "form-list-extend": _vm.searchFormList
                  }
                },
                [
                  _c("m-remote-select", {
                    staticClass: "input-prepend",
                    attrs: {
                      slot: "prepend",
                      placeholder: "请选择物料",
                      "interface-fun": _vm.materialsApi.getMaterialsListPage,
                      options: _vm.formOptions.materialsList,
                      condition: _vm.remoteSelectCondition,
                      clearable: false,
                      "on-change": _vm.remoteMaterialsSelectChange
                    },
                    on: {
                      "update:options": function($event) {
                        return _vm.$set(
                          _vm.formOptions,
                          "materialsList",
                          $event
                        )
                      }
                    },
                    slot: "prepend",
                    model: {
                      value: _vm.params.materialsId,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "materialsId", $$v)
                      },
                      expression: "params.materialsId"
                    }
                  }),
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-more" },
                    on: { click: _vm.selectMaterials },
                    slot: "append"
                  })
                ],
                1
              )
            ],
            1
          )
        : _c("div", { staticClass: "placeholder-div" }),
      _vm.permission(_vm.params.radio + "Page")
        ? _c(
            "div",
            { staticClass: "bottom" },
            [
              _c("FunctionHeader", {
                attrs: {
                  "search-title": "请输入批次码",
                  tags: _vm.functionHeader.tags,
                  "hidden-search": _vm.params.radio === "fast"
                },
                on: {
                  search: function($event) {
                    return _vm.$refs.mTable.setTableData(true)
                  }
                },
                model: {
                  value: _vm.functionHeader.searchVal,
                  callback: function($$v) {
                    _vm.$set(_vm.functionHeader, "searchVal", $$v)
                  },
                  expression: "functionHeader.searchVal"
                }
              }),
              _c("MTable", {
                ref: "mTable",
                attrs: {
                  data: _vm.dataSourceFront && _vm.batchNumberData,
                  columns: _vm.columns[_vm.params.radio],
                  height: _vm.height,
                  "columns-setting": false,
                  "row-class-name": _vm.tableRowClassName,
                  "set-data-method": _vm.getTableData
                },
                on: { "selection-change": _vm.selectionChange },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "index",
                      fn: function(ref) {
                        var row = ref.row
                        var $index = ref.$index
                        return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                      }
                    },
                    {
                      key: "materialsCode",
                      fn: function(ref) {
                        var row = ref.row
                        return _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function($event) {
                                return _vm.viewMaterialsDetail(row.materialsId)
                              }
                            }
                          },
                          [_vm._v(_vm._s(row.materialsCode))]
                        )
                      }
                    },
                    {
                      key: "batchNumber",
                      fn: function(ref) {
                        var row = ref.row
                        return _c(
                          "div",
                          { staticClass: "batch-number-columns" },
                          [
                            _c("span", [_vm._v(_vm._s(row.batchNumber))]),
                            _vm.currentBatchNumberList.indexOf(
                              row.batchNumber
                            ) !== -1
                              ? _c("img", {
                                  attrs: {
                                    src: require("../../../../assets/information/新@2x.png")
                                  }
                                })
                              : _vm._e()
                          ]
                        )
                      }
                    },
                    {
                      key: "action",
                      fn: function(ref) {
                        var row = ref.row
                        return _c(
                          "div",
                          {},
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.printCode(row)
                                  }
                                }
                              },
                              [_vm._v("打印")]
                            ),
                            _vm.permission("Del")
                              ? _c("el-divider", {
                                  attrs: { direction: "vertical" }
                                })
                              : _vm._e(),
                            _vm.permission("Del")
                              ? _c("DelPopover", {
                                  on: {
                                    onOk: function(callback) {
                                      return _vm.del(row, callback)
                                    }
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      }
                    }
                  ],
                  null,
                  false,
                  647200336
                )
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "MDialog",
        {
          attrs: { width: "400px", title: "生成批次码" },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "formBuild",
            attrs: { "label-position": "left", "form-list": _vm.formList }
          })
        ],
        1
      ),
      _c("SelectMaterials", {
        attrs: {
          visible: _vm.materialVisible,
          conditions: _vm.materialsConditions,
          single: true
        },
        on: {
          "update:visible": function($event) {
            _vm.materialVisible = $event
          },
          submitForm: _vm.setMaterialId
        }
      }),
      _vm.memberCode === "kiki"
        ? _c("PrintCodes", {
            ref: "printCode",
            attrs: { "common-id": _vm.commonId }
          })
        : _c("PrintCode", {
            ref: "printCode",
            attrs: { "common-id": _vm.commonId }
          }),
      _c("edit-materials", { ref: "editMaterials" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }