
import { status } from '@/config/options.config'
let functionList = {}
export const getFunctionList = val => {
  functionList = val
}

export const columns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    sortable: true,
    hiddenSearch: true,
    minWidth: 80
  },
  {
    prop: 'code',
    label: 'BOM编号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'code',
    minWidth: 150,
    form: { index: 0, tagName: 'el-input' }
  },
  {
    prop: 'name',
    label: 'BOM名称',
    sortable: false,
    hiddenSearch: true,
    minWidth: 180,
    form: { index: 1, tagName: 'el-input' }
  },
  {
    prop: 'version',
    label: 'BOM版本',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'materialsBomClassName',
    label: 'BOM类别',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 4,
      required: false,
      tagName: 'el-cascader',
      key: 'materialsClassId',
      props: {
        props: {
          label: 'name',
          value: 'id',
          checkStrictly: true
        },
        options: [],
        filterable: true
      }
    }
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    sortable: false,
    hiddenSearch: true,
    minWidth: 150,
    form: { index: 0, tagName: 'el-input' }
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true,
    minWidth: 150,
    form: { index: 1, tagName: 'el-input' }
  },
  {
    prop: 'materialsClassName',
    label: '物料分类',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 4,
      required: false,
      tagName: 'el-cascader',
      key: 'materialsClassId',
      props: {
        props: {
          label: 'name',
          value: 'id',
          checkStrictly: true
        },
        options: [],
        filterable: true
      }
    }
  },
  {
    prop: 'materialsTypeName',
    label: '物料类型',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 3,
      tagName: 'el-select',
      key: 'materialsTypeId',
      required: false
    }
  },
//   {
//     prop: 'materialsAttributeName',
//     label: '物料属性',
//     sortable: false,
//     hiddenSearch: true,
//     form: {
//       index: 5,
//       tagName: 'el-select',
//       key: 'materialsAttributeId',
//       required: false
//     }
//   },
  {
    prop: 'specifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true,
    form: { index: 2, tagName: 'el-input', required: false }
  },
  {
    prop: 'quantity',
    label: '数量',
    sortable: false,
    hiddenSearch: true,
    minWidth:100
  },
  {
    prop: 'unitName',
    label: '主单位',
    sortable: false,
    hiddenSearch: true,
    minWidth:100
  },
  {
    prop: 'isEnabled',
    label: '状态',
    sortable: false,
    slotName: 'isEnabled',
    hiddenSearch: true,
    minWidth:100,
    form: {
      index: 6,
      tagName: 'el-select',
      options: status,
      required: false,
      defaultValue: 1
    }
  },
//   {
//     prop: 'remark',
//     label: '备注',
//     sortable: false,
//     hiddenSearch: true,
//     form: { index: 7, tagName: 'el-input', required: false, props: { type: 'textarea' }}
//   },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 115
  }
]
