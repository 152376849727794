import { base } from './columns'
import _ from 'lodash'
import { transformColumnsToForm } from '@/utils'

const newColumns = _.cloneDeep(base)

export const baseFormList = transformColumnsToForm(newColumns)

export const versionFormList = [
  {
    label: '存储为：',
    key: 'name',
    tagName: 'el-input',
    colSpan: 24,
    required: true,
    attrs: {
      placeholder: '请输入版本名称'
    }
  },
  {
    hidden: true,
    label: '',
    key: 'isMain',
    tagName: 'el-checkbox',
    colSpan: 24,
    innerText: '将该版本设置为主版本',
    props: {
      trueLabel: 1,
      falseLabel: 0
    }
  }
]
