<template>
  <div v-loading="loading" class="inspection-setting-container">
    <template v-if="materialsInfo.processId && currentProcedure.id">
      <div v-if="permission(currentKey + 'Modify')" class="footer">
        <el-button v-if="!readOnly" type="default" size="small" @click="cancel">取消</el-button>
        <el-button type="primary" size="small" :loading="buttonLoading" @click="editInspection">{{ !readOnly ? '保存' : '编辑' }}</el-button>
      </div>
      <div class="left">
        <div class="title">{{ currentKey === 'inspectionProjectGroupProcess' ? '过程检' : '首检' }}</div>
        <div class="procedure-list">
          <div class="top-title">
            <span>序号 | 工序名称</span>
            <span>工序顺序</span>
          </div>
          <div class="procedure-li">
            <div v-for="(item, index) in procedureList" :key="index" class="procedure" :class="{ active: currentProcedure.uuid === item.procedureUuid }" @click="setCurrentProcedure(item)">
              <span class="ellipsis" style="max-width: 200px">{{ index + 1 > 9 ? index + 1 : `0${index + 1}` }} | {{ item.procedureName }}</span>
              <span>{{ item.procedureIndex > 9 ? item.procedureIndex : `0${item.procedureIndex}` }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="currentProcedure.id" class="right" :style="{ paddingBottom: permission(currentKey + 'Modify') ? '48px' : '0' }">
        <div style="background: #FFFFFF">
          <div class="flex-sbc" style="width: 100%">
            <div class="flex-sbc">
              <div style="padding-left: 40px;padding-right: 20px">
                <span>是否需要质检：</span>
                <el-radio-group v-model="qualityInspectionPlan[currentKey].isNeedInspect" :name="currentKey" :disabled="readOnly" @change="(val) => { isNeedQualityInspectionChange(val, currentKey)}">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </div>
              <div style="padding-left: 40px;padding-right: 20px">
                <span>质检完成才能出站：</span>
                <el-radio-group v-model="currentProcedure.isMustCheck" :name="currentKey" :disabled="readOnly">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </div>
              <div v-if="qualityInspectionPlan[currentKey].isNeedInspect === 1" style="padding-right: 20px">
                <span style="padding-left: 10px">检验类型：</span>
                <el-select v-model="currentProcedure.isFullInspection" :disabled="readOnly">
                  <el-option :label="'全检'" :value="1">全检</el-option>
                  <el-option :label="'抽检'" :value="0">抽检</el-option>
                </el-select>
              </div>
              <div v-if="qualityInspectionPlan[currentKey].isNeedInspect === 1">
                <span style="padding-left: 10px">质检方案：</span>
                <el-select
                  v-model="qualityInspectionPlan[currentKey].inspectId"
                  size="small"
                  disabled
                  filterable
                  style="width: 200px"
                  placeholder="请选择质检方案"
                  @change="(val) => qualityInspectionChange(val, currentKey)"
                >
                  <el-option
                    v-for="is in formOptions.inspectionPlanList"
                    :key="is.id"
                    :label="is.name"
                    :value="is.id"
                  />
                </el-select>
              </div>
            </div>
            <el-button v-if="permission(currentKey + 'ReDefault')" type="text" size="small" style="padding-right: 20px" @click="reDefault">恢复默认</el-button>
          </div>
          <quality-inspection-project
            v-if="qualityInspectionPlan[currentKey].isNeedInspect === 1 && qualityInspectionPlan[currentKey].inspectId"
            ref="qualityInspectionProject"
            :inspection-entry-map="qualityInspectionPlan[currentKey].inspectEntryMap"
            :attachments="qualityInspectionPlan[currentKey].inspectAttachments"
            :user-list="qualityInspectionPlan[currentKey].inspectorIdList"
            :read-only="readOnly"
            style="padding: 10px"
          />
        </div>
      </div>
    </template>
    <MBlank v-else-if="materialsInfo.processId" :title="currentKey === 'inspectionProjectGroupProcess' ? '物料所有工序均无需过程检' : '物料所有工序均无需首检'" />
    <MBlank v-else title="物料未绑定工艺" />
  </div>
</template>

<script>
import QualityInspectionProject from '@/views/quality/inspection-plan/components/quality-inspection-project'
import QIP from '@/api/quality/inspection-plan'
import maApi from '@/api/information/materials'
import processApi from '@/api/information/production/process'
import procedureApi from '@/api/information/production/procedure'
export default {
  name: 'InspectionSetting',
  components: { QualityInspectionProject },
  props: {
    currentKey: {
      type: String,
      default: 'inspectionProjectGroupProcess'
    },
    materialsInfo: {
      required: true,
      type: Object,
      default: () => ({
        materialsId: 0,
        processId: 0,
        versionId: 0
      })
    }
  },
  data() {
    return {
      qualityInspectionPlan: {
        inspectionProjectGroupFirst: {},
        inspectionProjectGroupProcess: {}
      },
      formOptions: {
        inspectionPlanList: []
      },
      currentProcedure: {},
      procedureList: [],
      readOnly: true,
      buttonLoading: false,
      loading: false,
      processFlowDetail: {}
    }
  },
  watch: {
    materialsInfo: {
      handler: function(val) {
        this.currentProcedure = {}
        this.readOnly = true
        this.procedureList = []
        this.qualityInspectionPlan = {
          inspectionProjectGroupFirst: {},
          inspectionProjectGroupProcess: {}
        }
        if (val.materialsId && val.processId) {
          this.getProcessDetail()
        }
      },
      immediate: true,
      deep: true
    },
    currentKey(val) {
      if (val && this.materialsInfo.materialsId && this.materialsInfo.processId) {
        this.currentProcedure = {}
        this.procedureList = []
        this.qualityInspectionPlan = {
          inspectionProjectGroupFirst: {},
          inspectionProjectGroupProcess: {}
        }
        this.getProcessDetail()
      }
    }
  },
  mounted() {
    this.getQualityInspectionPlanList()
  },
  methods: {
    async getQualityInspectionPlanList() {
      const res = await QIP.getInspectionPlanList(this.defaultSearch)
      if (res) {
        this.formOptions.inspectionPlanList = res
      }
    },
    async getProcessDetail() {
      const res = await processApi.getProcessDetail({ id: this.materialsInfo.processId, versionId: this.materialsInfo.versionId })
      if (res) {
        this.procedureList = res.processFlowVersion.procedureList
        this.processFlowDetail = res
        // 是否过滤工艺中存在过程检或者首检的工序
        // .filter(item => item[this.currentKey === 'inspectionProjectGroupProcess' ? 'isNeedProcessInspect' : 'isNeedFirstInspect'])
        const firstHasInspection = this.procedureList.find(item => item.isNeedFirstInspect || item.isNeedProcessInspect)
        if (!this.currentProcedure.id) {
          this.currentProcedure = {
            id: firstHasInspection ? firstHasInspection.procedureId : this.procedureList[0].procedureId,
            uuid: firstHasInspection ? firstHasInspection.procedureUuid : this.procedureList[0].procedureUuid,
            type: firstHasInspection ? firstHasInspection.procedureType : this.procedureList[0].procedureType,
            isFullInspection: firstHasInspection.isFullInspection,
            isMustCheck: firstHasInspection.isMustCheck
          }
          await this.getMaterialsInspectionPlanByIdAndProduceId()
        }
        if (this.currentProcedure.id) {
          await this.getMaterialsInspectionPlanByIdAndProduceId()
        }
      }
    },
    async getMaterialsInspectionPlanByIdAndProduceId() {
      this.loading = true
      const res = await maApi.getMaterialsInspectionSetting({
        materialsId: this.materialsInfo.materialsId,
        procedureId: this.currentProcedure.id,
        procedureUuid: this.currentProcedure.uuid
      })
      if (res) {
        this.currentProcedure.isFullInspection = res.isFullInspection
        this.currentProcedure.isMustCheck = res.isMustCheck
        this.qualityInspectionPlan = {
          inspectionProjectGroupFirst: {
            isNeedInspect: res.isNeedFirstInspect,
            inspectId: res.firstInspectId,
            inspectEntryMap: res.firstInspectEntryMap || {},
            inspectAttachments: res.firstInspectAttachments,
            inspectorIdList: res.firstInspectorIdList
          },
          inspectionProjectGroupProcess: {
            isNeedInspect: res.isNeedProcessInspect,
            inspectId: res.processInspectId,
            inspectEntryMap: res.processInspectEntryMap || {},
            inspectAttachments: res.processInspectAttachments,
            inspectorIdList: res.processInspectorIdList
          }
        }
      } else {
        this.qualityInspectionPlan = {
          inspectionProjectGroupFirst: {
            isNeedInspect: 0
          },
          inspectionProjectGroupProcess: {
            isNeedInspect: 0
          }
        }
      }
      this.loading = false
    },
    setCurrentProcedure(procedure) {
      this.currentProcedure = {
        id: procedure.procedureId,
        uuid: procedure.procedureUuid,
        type: procedure.procedureType
      }
      this.getMaterialsInspectionPlanByIdAndProduceId()
    },
    reDefault() {
      this.$confirm('您确定要恢复默认质检方案吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await maApi.reMaterialsInspectionSetting({
          materialsId: this.materialsInfo.materialsId,
          procedureId: this.currentProcedure.id,
          procedureUuid: this.currentProcedure.uuid,
          inspectionType: this.currentKey === 'inspectionProjectGroupProcess' ? 1 : 0
        })
        if (res) {
          this.$message.success('已恢复默认质检方案')
          await this.getMaterialsInspectionPlanByIdAndProduceId()
        }
      })
    },
    async qualityInspectionChange(id, key) {
      const res = await QIP.getInspectionPlanDetailById({ id })
      if (res) {
        this.qualityInspectionPlan[key] = {
          isNeedInspect: 1,
          inspectId: id,
          inspectEntryMap: res.inspectionEntryMap,
          inspectAttachments: res.attachments,
          inspectorIdList: res.userList
        }
      }
    },
    async isNeedQualityInspectionChange(val, key) {
      if (val === 0) {
        this.qualityInspectionPlan[key] = {
          isNeedInspect: val,
          inspectId: null,
          inspectEntryMap: {},
          inspectAttachments: JSON.stringify([]),
          inspectorIdList: []
        }
      } else {
        const current = this.procedureList.find(item => item.procedureId === this.currentProcedure.id)
        const typeKey = key === 'inspectionProjectGroupFirst' ? 'isNeedFirstInspect' : 'isNeedProcessInspect'
        if (current[typeKey]) {
          const {
            firstInspectId,
            firstInspectAttachments,
            firstInspectorIdList,
            firstInspectEntryMap,
            processInspectId,
            processInspectAttachments,
            processInspectorIdList,
            processInspectEntryMap
          } = current
          this.qualityInspectionPlan[key] = {
            isNeedInspect: val,
            inspectId: key === 'inspectionProjectGroupFirst' ? firstInspectId : processInspectId,
            inspectEntryMap: key === 'inspectionProjectGroupFirst' ? firstInspectEntryMap : processInspectEntryMap,
            inspectAttachments: key === 'inspectionProjectGroupFirst' ? firstInspectAttachments : processInspectAttachments,
            inspectorIdList: key === 'inspectionProjectGroupFirst' ? firstInspectorIdList : processInspectorIdList
          }
        } else {
          const res = await procedureApi.getProcedureDetail(this.currentProcedure.id)
          if (res && res.processInspectId) {
            const {
              firstInspectId,
              firstInspectAttachments,
              firstInspectorIdList,
              firstInspectEntryMap,
              processInspectId,
              processInspectAttachments,
              processInspectorIdList,
              processInspectEntryMap
            } = res
            this.qualityInspectionPlan[key] = {
              isNeedInspect: val,
              inspectId: key === 'inspectionProjectGroupFirst' ? firstInspectId : processInspectId,
              inspectEntryMap: key === 'inspectionProjectGroupFirst' ? firstInspectEntryMap : processInspectEntryMap,
              inspectAttachments: key === 'inspectionProjectGroupFirst' ? firstInspectAttachments : processInspectAttachments,
              inspectorIdList: key === 'inspectionProjectGroupFirst' ? firstInspectorIdList : processInspectorIdList
            }
          }
        }
      }
    },
    editInspection() {
      if (this.readOnly) {
        this.readOnly = false
      } else {
        this.saveMaterialsInspection()
      }
    },
    async saveMaterialsInspection() {
      this.buttonLoading = true
      const projectObject = this.$refs.qualityInspectionProject ? this.$refs.qualityInspectionProject.transferDataToStandard() : true
      if (this.currentKey === 'inspectionProjectGroupProcess' && this.qualityInspectionPlan.inspectionProjectGroupProcess.isNeedInspect) {
        if (!this.qualityInspectionPlan.inspectionProjectGroupProcess.inspectId) {
          this.buttonLoading = false
          return this.$message.info('请选择过程检质检方案')
        }
        if (!projectObject) {
          this.buttonLoading = false
          return false
        }
      }
      if (this.currentKey === 'inspectionProjectGroupFirst' && this.qualityInspectionPlan.inspectionProjectGroupFirst.isNeedInspect) {
        if (!this.qualityInspectionPlan.inspectionProjectGroupFirst.inspectId) {
          this.buttonLoading = false
          return this.$message.info('请选择首检质检方案')
        }
        if (!projectObject) {
          this.buttonLoading = false
          return false
        }
      }
      let body
      if (this.currentKey === 'inspectionProjectGroupProcess') {
        const isNeedProcessInspect = this.qualityInspectionPlan.inspectionProjectGroupProcess.isNeedInspect
        const projectProcessObject = isNeedProcessInspect ? projectObject : {}
        body = {
          isNeedProcessInspect,
          processInspectId: isNeedProcessInspect ? this.qualityInspectionPlan.inspectionProjectGroupProcess.inspectId : null,
          processInspectAttachments: isNeedProcessInspect ? projectProcessObject.attachments : JSON.stringify([]),
          processInspectorIdList: isNeedProcessInspect ? projectProcessObject.inspectorList : [],
          processInspectEntryMap: isNeedProcessInspect ? projectProcessObject.inspectionEntryMap : {}
        }
      } else {
        const isNeedFirstInspect = this.qualityInspectionPlan.inspectionProjectGroupFirst.isNeedInspect ? 1 : 0
        const projectFirstObject = isNeedFirstInspect ? projectObject : {}
        body = {
          isNeedFirstInspect,
          firstInspectId: isNeedFirstInspect ? this.qualityInspectionPlan.inspectionProjectGroupFirst.inspectId : null,
          firstInspectAttachments: isNeedFirstInspect ? projectFirstObject.attachments : JSON.stringify([]),
          firstInspectorIdList: isNeedFirstInspect ? projectFirstObject.inspectorList : [],
          firstInspectEntryMap: isNeedFirstInspect ? projectFirstObject.inspectionEntryMap : {}
        }
      }
      const res = await maApi.saveMaterialsInspectionSetting({
        materialsId: this.materialsInfo.materialsId,
        procedureId: this.currentProcedure.id,
        procedureUuid: this.currentProcedure.uuid,
        procedureType: this.currentProcedure.type,
        isFullInspection: this.currentProcedure.isFullInspection,
        isMustCheck: this.currentProcedure.isMustCheck,
        processFlowId: this.processFlowDetail.id,
        processFlowVersionId: this.processFlowDetail.processFlowVersion.id,
        ...body
      })
      if (res) {
        this.readOnly = true
        this.$message.success('保存成功')
        await this.getMaterialsInspectionPlanByIdAndProduceId()
      }
      this.buttonLoading = false
    },
    cancel() {
      this.readOnly = true
      this.getMaterialsInspectionPlanByIdAndProduceId()
    }
  }
}
</script>

<style scoped lang="scss">
.inspection-setting-container {
  width: 100%;
  height: calc(100% - 12px);
  display: inline-flex;
  justify-content: space-between;
  margin-top: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-size: 14px;
  color: #393D60;
  position: relative;
  .left {
    width: 242px;
    margin-right: 10px;
    border-radius: 4px;
    overflow: hidden;
    background: #F0F2F5;
    .title {
      padding: 10px;
      text-align: center;
      font-weight: 700;
      border: 1px solid #DDE3EB;
      border-radius: 4px 4px 0 0;
    }
    .procedure-list {
      height: calc(100% - 37px);
      background: #F0F2F5;
      .procedure-li {
        max-height: calc(100% - 35px);
        overflow-y: auto;
        background: #FFFFFF;
        border-left: 1px solid #DDE3EB;
        border-right: 1px solid #DDE3EB;
        border-bottom: 1px solid #DDE3EB;
        border-radius: 0 0 4px 4px;
        .procedure {
          cursor: pointer;
          color: #41456B;
          &:hover {
            background: #EDF0FF !important;
            color: #607FFF !important;
          }
        }
      }
      .top-title {
        padding: 10px;
        background: #FBFBFB;
        font-size: 12px;
        border-left: 1px solid #DDE3EB;
        border-right: 1px solid #DDE3EB;
        span {
          white-space: nowrap;
        }
      }
      .top-title, .procedure {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
      }
    }
  }
  .right {
    width: calc(100% - 242px);
    max-height: 100%;
    overflow-y: auto;
    background: #FFFFFF;
    position: relative;
    >div {
      padding: 15px 0;
    }
  }
  .footer {
    position: absolute;
    width: calc(100% - 262px - 10px);
    height: 48px;
    background: #FFFFFF;
    box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
    border-radius: 4px 4px 0 0;
    bottom: 0;
    right: 11px;
    display: inline-flex;
    align-items: center;
    justify-content: right;
    padding-right: 20px;
    z-index: 10;
  }
  .noSet {
    background: #F6F7F9;
    color: #9597AE !important;
  }
  .active {
    background: #EDF0FF !important;
    color: #607FFF !important;
  }
}
</style>
