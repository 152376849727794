import { render, staticRenderFns } from "./inspection-setting2.vue?vue&type=template&id=3e23adc2&scoped=true&"
import script from "./inspection-setting2.vue?vue&type=script&lang=js&"
export * from "./inspection-setting2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e23adc2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/mubai-mom-frontend-dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3e23adc2')) {
      api.createRecord('3e23adc2', component.options)
    } else {
      api.reload('3e23adc2', component.options)
    }
    module.hot.accept("./inspection-setting2.vue?vue&type=template&id=3e23adc2&scoped=true&", function () {
      api.rerender('3e23adc2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/information/materials-setting/components/inspection-setting2.vue"
export default component.exports