var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "materials-list-wrapper",
      style: { width: _vm.open ? "359px" : 0 }
    },
    [
      _c("div", { staticClass: "operate" }, [
        _c("div", { staticClass: "top" }, [
          _c("div", { staticClass: "title" }, [_vm._v("未绑定物料清单")]),
          _c(
            "div",
            { staticClass: "page" },
            [
              _c("el-button", {
                attrs: {
                  type: "text",
                  icon: "el-icon-arrow-left",
                  disabled: _vm.page.current === 1
                },
                on: {
                  click: function($event) {
                    return _vm.pageChange("-")
                  }
                }
              }),
              _c("div", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.page.current,
                      expression: "page.current",
                      modifiers: { number: true }
                    }
                  ],
                  staticClass: "input-c",
                  domProps: { value: _vm.page.current },
                  on: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.pageChange()
                    },
                    blur: [
                      function($event) {
                        return _vm.pageChange()
                      },
                      function($event) {
                        return _vm.$forceUpdate()
                      }
                    ],
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.page, "current", _vm._n($event.target.value))
                    }
                  }
                }),
                _vm._v(" / " + _vm._s(_vm.page.total) + " ")
              ]),
              _c("el-button", {
                attrs: {
                  type: "text",
                  icon: "el-icon-arrow-right",
                  disabled: _vm.page.current === _vm.page.total
                },
                on: {
                  click: function($event) {
                    return _vm.pageChange("+")
                  }
                }
              })
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "search flex-sbc" },
          [
            _c("el-input", {
              attrs: {
                size: "small",
                placeholder: "请输入物料名称/编码/规格",
                clearable: ""
              },
              on: { clear: _vm.getMaterialsList },
              model: {
                value: _vm.searchVal,
                callback: function($$v) {
                  _vm.searchVal = $$v
                },
                expression: "searchVal"
              }
            }),
            _c(
              "el-button",
              {
                staticClass: "search-btn",
                attrs: {
                  size: "small",
                  type: "primary",
                  icon: "el-icon-search",
                  clearable: ""
                },
                on: { click: _vm.getMaterialsList }
              },
              [_vm._v("搜索")]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "list"
        },
        _vm._l(_vm.materialsList, function(item) {
          return _c(
            "div",
            {
              key: item.id,
              staticClass: "card-wrapper",
              class: { active: item.id === _vm.currentId },
              on: {
                click: function($event) {
                  return _vm.clickCard(item)
                }
              }
            },
            [
              _c("div", { staticClass: "top" }, [
                _c("span", [_vm._v("物料名称：")]),
                _c("span", [_vm._v(_vm._s(item.name))])
              ]),
              _c("div", { staticClass: "bottom" }, [
                _c("div", { staticClass: "label-value" }, [
                  _c("span", [_vm._v("物料编码：")]),
                  _c("span", [_vm._v(_vm._s(item.code))])
                ]),
                _c("div", { staticClass: "label-value" }, [
                  _c("span", [_vm._v("规格：")]),
                  _c("span", [_vm._v(_vm._s(item.specifications))])
                ])
              ])
            ]
          )
        }),
        0
      ),
      _c("img", {
        staticClass: "open-img",
        staticStyle: { width: "19px" },
        attrs: { src: _vm.open ? _vm.closeImg : _vm.openImg, alt: "" },
        on: {
          click: function($event) {
            _vm.open = !_vm.open
          }
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }