var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderAdd" },
    [
      _c("div", { staticClass: "procedure-detail-header" }, [
        _c(
          "div",
          {
            staticClass: "left",
            on: {
              click: function($event) {
                return _vm.$router.push({ name: "materialsSettingList" })
              }
            }
          },
          [
            _c("img", {
              attrs: {
                src: require("@/assets/information/procedure/左滑@2x.png")
              }
            }),
            _c("span", [_vm._v("编辑BOM")])
          ]
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("物料名称：" + _vm._s(_vm.detailData.name))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("物料编码：" + _vm._s(_vm.detailData.code))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("规格：" + _vm._s(_vm.detailData.specifications))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v(
                  "物料属性：" + _vm._s(_vm.detailData.materialsAttributeName)
                )
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c(
                "span",
                { staticClass: "bot-ti" },
                [
                  _vm._v("用量分母："),
                  _c("el-input-number", {
                    staticStyle: { width: "150px" },
                    attrs: { step: 1, min: 0, "controls-position": "right" },
                    model: {
                      value: _vm.detailData.quantityDenominator,
                      callback: function($$v) {
                        _vm.$set(_vm.detailData, "quantityDenominator", $$v)
                      },
                      expression: "detailData.quantityDenominator"
                    }
                  })
                ],
                1
              )
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("物料类型：" + _vm._s(_vm.detailData.materialsTypeName))
              ])
            ]),
            _c("el-col", { attrs: { span: 6 } }, [
              _c("span", { staticClass: "bot-ti" }, [
                _vm._v("单位：" + _vm._s(_vm.detailData.mainUnitName))
              ])
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "detail-card" }, [
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            _c(
              "el-button",
              {
                staticClass: "e-btn",
                attrs: { type: "primary" },
                on: { click: _vm.selectadd }
              },
              [_vm._v("选择添加物料")]
            ),
            _c(
              "MTable",
              {
                ref: "mUnitTable",
                attrs: {
                  height: 200,
                  "show-page": false,
                  data: _vm.orderList,
                  columns: _vm.columnsOrder,
                  "columns-setting": false
                },
                scopedSlots: _vm._u([
                  {
                    key: "quantityNumerator",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          _c("el-input-number", {
                            attrs: {
                              min: 0,
                              "controls-position": "right",
                              step: 1
                            },
                            model: {
                              value: row.quantityNumerator,
                              callback: function($$v) {
                                _vm.$set(row, "quantityNumerator", $$v)
                              },
                              expression: "row.quantityNumerator"
                            }
                          })
                        ],
                        1
                      )
                    }
                  },
                  {
                    key: "action",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return _c(
                        "div",
                        {},
                        [
                          _c(
                            "el-button",
                            {
                              staticClass: "li-btn",
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.delPlan($index)
                                }
                              }
                            },
                            [_vm._v("删除")]
                          )
                        ],
                        1
                      )
                    }
                  }
                ])
              },
              [
                _c("div", { attrs: { slot: "bomLevel" }, slot: "bomLevel" }, [
                  _vm._v("1")
                ])
              ]
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "order-footer" }, [
        _c("div", { staticClass: "footer-le" }),
        _c(
          "div",
          { staticClass: "footer-ri" },
          [
            _c("el-button", { on: { click: _vm.returnList } }, [
              _vm._v("取消")
            ]),
            _c(
              "el-button",
              {
                attrs: { type: "primary", loading: _vm.loading },
                on: { click: _vm.submitForms }
              },
              [_vm._v("确认")]
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "lines" }),
      _c("select-materials", {
        attrs: { visible: _vm.showMaterials },
        on: {
          "update:visible": function($event) {
            _vm.showMaterials = $event
          },
          submitForm: _vm.submitMaterials
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }