<template>
     <div class="procedure-detail-wrapper">
    <div class="procedure-detail-body">
      <div class="detail-card">
        <div class="card-tit">基本信息</div>
        <div class="card-bot">
          <el-row class="view-list" :gutter="24">
            <el-col
              v-for="(item, index) in fixData(columnss.bases)"
              :key="item.prop"
              :span="6"
              :style="{marginBottom: index !== 4 ? '20px' : 0 }"
            >
              <span class="bot-tis">{{ item.label }}：</span>
              <span class="bot-tis">{{ getValue(item) }}</span>
            </el-col>
            <el-col :span="24" :style="{margin: '20px 0'}">
              <div class="flex-mode">
                <div class="tit">默认下发方式：</div>
                <el-radio-group v-model="selectParams.issueMode" name="assingGroups" disabled>
                  <el-radio v-for="items in issList" :key="items.value" :label="items.value">{{ items.name }}</el-radio>
                </el-radio-group>
              </div>
            </el-col>
            <el-col :span="24">
              <div class="flex-mode">
                <div class="tit">默认指派方式：</div>
                <el-radio-group v-model="selectParams.assignMode" name="assingGroup" disabled>
                  <el-radio v-for="items in assList" :key="items.value" :label="items.value">{{ items.name }}</el-radio>
                </el-radio-group>
              </div>
            </el-col>
            <el-col :span="24">
              <div class="flex-modes">
                <div class="tit">
                  <el-tooltip class="item" effect="dark" content="工单仅一个工序时公差控制报工数量生效，多于一个工序时不生效" placement="top">
                    <span class="el-icon-question" />
                  </el-tooltip>
                  工序报工公差设置：
                </div>
                <div class="mode-ri">
                  <el-select v-model="differenceType" size="small" placeholder="请选择" class="ri-select" disabled>
                    <el-option
                      v-for="item in procedureDifferenceTypes"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                  <div class="ri-top">
                    <div class="top-tit">上公差：</div>
                    <div class="top-tit">{{ differencePlus }}</div>
                  </div>
                  <div class="ri-top">
                    <div class="top-tit">下公差：</div>
                    <div class="top-tit">{{ differenceMinus }}</div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <div class="detail-card">
        <div class="card-tit">可执行工位</div>
        <div class="card-bot">
          <MTable ref="mTable" :show-page="false" :height="300" :columns="columnss.station" :data="stationList">
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <div slot="action" slot-scope="{ $index }">
              <el-button type="text" :disabled="!saveButton" @click="delFrontData('stationList', $index)">删除</el-button>
            </div>
          </MTable>
        </div>
      </div>

      <div class="detail-card">
        <div class="card-tit">工序操作描述</div>
        <div class="card-bot">
          <el-input
            v-model="operationDesc"
            type="textarea"
            placeholder="请输入工序操作描述"
            maxlength="500"
            :disabled="!saveButton"
            show-word-limit
          />
        </div>
      </div>

      <div class="detail-card">
        <div class="card-tit">工序图纸及附件</div>
        <div class="card-bot">
          <div class="b20 flex-sbc">
            <img src="@/assets/information/procedure/图纸@2x.png" style="width: 18px">
            <span class="l10" style="position: relative;top: 2px">图纸</span>
          </div>
          <br>
          <el-upload
            v-if="saveButton"
            class="b20"
            type="primary"
            v-bind="uploadProps"
          >
          </el-upload>
          <MTable ref="mTable" :show-page="false" :height="300" :columns="columnss.drawings" :data="drawingsList">
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <div slot="fileName" slot-scope="{ row }">
              <a :href="row.url" :download="row.name" target="_blank" style="color: #607FFF">{{ row.name }}</a>
              <img src="@/assets/information/procedure/附件@2x.png" style="width: 11px;margin-left: 5px">
            </div>
            <div slot="action" slot-scope="{ $index }">
              <el-button type="text" :disabled="!saveButton" @click="delFrontData('drawingsList', $index)">删除</el-button>
            </div>
          </MTable>

          <div class="bt20 flex-sbc">
            <img src="@/assets/information/procedure/其他附件@2x.png" style="width: 18px">
            <span class="l10" style="position: relative;top: 2px" @click="uploadKey = 'filesList'">其他附件</span>
          </div>
          <br>
          <MTable ref="mTable" :show-page="false" :height="300" :columns="columnss.files" :data="filesList">
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <div slot="fileName" slot-scope="{ row }">
              <a :href="row.url" :download="row.name" target="_blank" style="color: #607FFF">{{ row.name }}</a>
              <img src="@/assets/information/procedure/附件@2x.png" style="width: 11px;margin-left: 5px">
            </div>
            <div slot="action" slot-scope="{ $index }">
              <el-button type="text" :disabled="!saveButton" @click="delFrontData('filesList', $index)">删除</el-button>
            </div>
          </MTable>
        </div>
      </div>

      <div class="detail-card">
        <div class="card-tit">工序上传参数</div>
        <div class="card-bot">
          <ProcedureParams ref="procedureParams" :read-only="!saveButton" :group-list="groupList" />
        </div>
      </div>

      <template v-if="!['bbelc'].includes(memberCode)">
        <div v-if="procedureType === 1" class="detail-card">
          <div class="card-tit">外协工序时间设置</div>
          <div class="card-bot">
            <span style="padding-left: 10px">外协默认周期：</span>
            <el-input-number v-model="outsourceTime" size="small" controls-position="right" :min="0" :step="1" :disabled="!saveButton" style="width: 200px" />
            <span style="padding-left: 10px">天</span>
          </div>
        </div>
      </template>
    </div>
  </div>
  </template>
  
  <script>
  import { base, station, fileAndDrawings } from '../columns'
  import ProcedureParams from '@/components/ProcedureParams/ProcedureParams'
  import stationApi from '@/api/information/production/station'
  import QIP from '@/api/quality/inspection-plan'
  import { Encrypt } from '@/utils/sercet'
  import { getUUid, setTreeData } from '@/utils'
  import { status } from '@/config/options.config'
  import procedureApi from '@/api/information/production/procedure'
  import baseSettingApi from '@/api/sets/base-setting'
  import CURDSelect from '@/components/CURDSelect/CURDSelect'
  import { getToken } from '@/utils/auth'
  import QualityInspectionProject from '@/views/quality/inspection-plan/components/quality-inspection-project'
  import api from '@/api/information/production/procedure'
  
  export default {
    name: 'procedureDetail',
    components: { QualityInspectionProject, CURDSelect, ProcedureParams },
    data() {
      return {
        selectedData: [],
        value: [],
        shareScopeEnd: [],
        onlyProcess: [],
        onlyProcessList: [],
        tongRadio: 1,
        processVisible: false,
        activeName: 'inspectionProjectGroupProcess',
        tabs: [
          {
            label: '过程检',
            ref: 'qualityInspectionProjectProcess',
            key: 'inspectionProjectGroupProcess'
          }
          // {
          //   label: '首检',
          //   ref: 'qualityInspectionProjectFirst',
          //   key: 'inspectionProjectGroupFirst'
          // }
        ],
        isFirst: false,
        maxData: undefined,
        differenceType: undefined,
        differenceMinus: undefined,
        differencePlus: undefined,
        selectParams: {
          issueMode: 0,
          assignMode: 0
        },
        procedureDifferenceTypes: [{
          id: 0,
          name: '数值'
        },
        {
          id: 1,
          name: '百分比'
        }],
        issList: [{
          value: 0,
          name: '工序任务无需下发，拥有权限的工位均可操作'
        },
        {
          value: 1,
          name: '工序任务必须下发至拥有权限的工位才可进行操作'
        }
        ],
        assList: [{
          value: 0,
          name: '工序任务无需指派，拥有权限人员均可操作'
        },
        {
          value: 1,
          name: '工序任务必须指派至拥有权限的用户/用户组才可进行操作'
        }
        ],
        params: {},
        baseFormData: {},
        saveButton: false,
        buttonLoading: false,
        columnss: {
          bases: base,
          station,
          drawings: fileAndDrawings,
          files: fileAndDrawings
        },
        uploadProps: {
          action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
          headers: { token: getToken() },
          showFileList: false,
          onSuccess: this.fileUploadChange,
          onError: this.fileUploadChange,
          beforeUpload: this.beforeUpload
        },
        operationDesc: '',
        qualityInspectionPlan: {
          inspectionProjectGroupFirst: { isNeedInspect: 0, isFullInspection: 1, isMustCheck: 0 },
          inspectionProjectGroupProcess: { isNeedInspect: 0, isFullInspection: 1, isMustCheck: 0 }
        },
        stationList: [],
        drawingsList: [],
        filesList: [],
        uploadKey: 'drawingsList',
        formOptions: {
          stationOptions: [],
          inspectionPlanList: []
        },
        visible: false,
        formData: {},
        formList: [{
          key: 'stationIds',
          tagName: 'el-select',
          colSpan: 24,
          props: {
            placeholder: '请选择工位',
            multiple: true
          },
          children: [
            {
              tagName: 'el-option',
              props: {
                label: 'name',
                value: 'id'
              },
              options: status
            }
          ]
        }],
        defaultSearch: {
          searchVal: Encrypt(JSON.stringify(
            [{
              id: getUUid(),
              fieldName: 'isEnabled',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq'
            }]
          ))
        },
        groupList: [],
        messages: {
          add: '新增成功',
          modify: '修改成功',
          copy: '复制成功',
          viewO: '工序详情',
          addO: '新增工序',
          modifyO: '修改工序',
          copyO: '复制工序'
        },
        inputVisible: false,
        procedureGroups: 'common',
        procedureGroupList: [],
        currentIdx: '',
        inputValue: '',
        procedureGroupId: '',
        procedureGroupName: '',
        typeTitle: new Map([
          ['add', '新增类型'],
          ['modify', '编辑类型'],
          ['del', '删除']
        ]),
        outsourceTime: null,
        procedureType: 0,
        procedureClassList: []
      }
    },
    computed: {
      memberCode() {
        return sessionStorage.getItem('memberCode')
      }
    },
    watch: {
      differenceType(val) {
        if (!this.isFirst) {
          this.isFirst = true
          return false
        }
        if (val === 1) {
          this.maxData = 100
        } else {
          this.maxData = undefined
        }
        this.differenceMinus = undefined
        this.differencePlus = undefined
      }
    },
    mounted() {
      this.params = this.$route.query
      this.getStationList()
      this.getProcedureClassList()
      if (!['bbelc', 'jinshi'].includes(this.memberCode)) {
        this.getQualityInspectionPlanList()
      }
      if (this.params.type !== 'add') {
        // this.getProcedureDetailById(this.params.id)
        // this.getProcedureIdProcess()
      }
      if (this.params.type === 'add') {
        this.getIssueConfig()
        this.createProcedureCode()
      }
    },
    methods: {
      // 获取工序分类
      async getProcedureClassList() {
        const res = await api.getProcedureClassList(this.defaultSearch)
        if (res) {
          this.procedureClassList = res
          // this.baseFormList[2].props.options = setTreeData(res)
          this.columnss.bases[2].form.options = res
        }
      },
      procedureClassChange(val) {
        const name = this.procedureClassList.filter(item => item.id === +this._.last(val))[0].name
        this.$refs.baseForm.setForm({
          procedureClassName: name,
          procedureClassId: this._.last(val)
        })
      },
      async createProcedureCode() {
        if (sessionStorage.getItem('memberCode') !== 'mubai') return false
        const res = await procedureApi.createCode({
          count: 1
        })
        if (res) {
          this.$refs.baseForm.setForm({
            code: res[0]
          })
        }
      },
      async getProcedureIdProcess() {
        const res = await procedureApi.getProcedureIdProcess(this.params.id)
        if (res) {
          this.onlyProcessList = res.map(item => {
            return {
              ...item,
              version: item.name
            }
          })
        }
      },
      async submitProcessForm(done) {
        let versionIds = ''
        if (this.tongRadio === 2) {
          const list = this.onlyProcess.map(item => {
            return item[1]
          })
          versionIds = list.join('^')
        }
        const res = await procedureApi.synchronousToProcess(this.params.id, versionIds)
        if (res) {
          this.$message.success('同步成功')
          this.processVisible = false
        }
        done()
      },
      async getQualityInspectionPlanList() {
        const res = await QIP.getInspectionPlanList(this.defaultSearch)
        if (res) {
          this.formOptions.inspectionPlanList = res
        }
      },
      procedureTypeChange(val) {
        this.procedureType = val
        if (val === 1) {
          this.qualityInspectionPlan.inspectionProjectGroupFirst = {
            isFullInspection: 1,
            isNeedInspect: 0,
            isMustCheck: 0,
            inspectId: null,
            inspectEntryMap: {},
            inspectAttachments: JSON.stringify([]),
            inspectorIdList: []
          }
        } else {
          this.outsourceTime = 0
        }
      },
      tabChange(tab) {},
      async qualityInspectionChange(id, key) {
        const res = await QIP.getInspectionPlanDetailById({ id })
        if (res) {
          this.qualityInspectionPlan[key] = {
            isFullInspection: this.qualityInspectionPlan[key].isFullInspection,
            isMustCheck: this.qualityInspectionPlan[key].isMustCheck,
            isNeedInspect: 1,
            inspectId: id,
            inspectEntryMap: res.inspectionEntryMap,
            inspectAttachments: res.attachments,
            inspectorIdList: res.userList
          }
        }
      },
      isNeedQualityInspectionChange(val, key) {
        if (val === 0) {
          this.qualityInspectionPlan[key] = {
            isFullInspection: 1,
            isMustCheck: 0,
            isNeedInspect: val,
            inspectId: null,
            inspectEntryMap: {},
            inspectAttachments: JSON.stringify([]),
            inspectorIdList: []
          }
        }
      },
      selectName(item) {
        this.$refs.baseForm.setForm({
          groupId: item.id,
          groupName: item.name
        })
      },
      beforeUpload(file) {
        const isLt20M = file.size / 1024 / 1024 < 20
        if (!isLt20M) {
          this.$message.error('上传文件大小不能超过 20M!')
        }
        return isLt20M
      },
      fixData(data) {
        return data.filter((item, index) => index < 5)
      },
      assingChanges(type) {
        if (type === 1) {
          this.$refs.baseForm.setForm({
            issueMode: this.selectParams.issueMode
          })
        }
        if (type === 2) {
          this.$refs.baseForm.setForm({
            assignMode: this.selectParams.assignMode
          })
        }
      },
      // 获取默认下发和指派方式
      async getIssueConfig() {
        const search = {
          searchVal: Encrypt(JSON.stringify(
            [{
              id: getUUid(),
              fieldName: 'code',
              fieldType: 'string',
              fieldValue: 'assign_mode^issue_mode',
              operator: 'in'
            }]
          ))
        }
        const res = await baseSettingApi.getDetail(search)
        if (res) {
          this.selectParams = {
            issueMode: parseInt(res[0].value),
            assignMode: parseInt(res[1].value)
          }
          this.$refs.baseForm.setForm({
            issueMode: parseInt(res[0].value),
            assignMode: parseInt(res[1].value)
          })
        }
      },
      async getStationList() {
        const res = await stationApi.getStationList(this.defaultSearch)
        if (res) {
          this.formOptions.stationOptions = res
          this.formList[0].children[0].options = res
        }
      },
      async getProcedureDetailById(id) {
        const res = await procedureApi.getProcedureDetail(id)
        if (res) {
          this.baseFormData = res
          this.procedureType = res.type
          this.outsourceTime = res.outsourceTime
          this.stationList = res.stationList
          this.operationDesc = res.operationDesc
          this.drawingsList = res.drawing ? JSON.parse(res.drawing) : []
          this.qualityInspectionPlan = {
            inspectionProjectGroupFirst: {
              isFullInspection: res.isFullInspection,
              isMustCheck: res.isMustCheck,
              isNeedInspect: res.isNeedFirstInspect,
              inspectId: res.firstInspectId,
              inspectEntryMap: res.firstInspectEntryMap || {},
              inspectAttachments: res.firstInspectAttachments,
              inspectorIdList: res.firstInspectorIdList
            },
            inspectionProjectGroupProcess: {
              isFullInspection: res.isFullInspection,
              isMustCheck: res.isMustCheck,
              isNeedInspect: res.isNeedProcessInspect,
              inspectId: res.processInspectId,
              inspectEntryMap: res.processInspectEntryMap || {},
              inspectAttachments: res.processInspectAttachments,
              inspectorIdList: res.processInspectorIdList
            }
          }
          this.filesList = res.attachment ? JSON.parse(res.attachment) : []
          this.groupList = res.params ? JSON.parse(res.params) : []
          this.selectParams = {
            issueMode: res.issueMode,
            assignMode: res.assignMode
          }
          this.differenceMinus = res.differenceMinus
          this.differencePlus = res.differencePlus
          this.differenceType = res.differenceType === -1 ? '' : res.differenceType
        }
        if (this.params.type === 'copy') await this.createProcedureCode()
      },
      switchOrSubmit() {
        if (this.saveButton) {
          this.submitForm()
        } else {
          this.params.type = 'modify'
          this.saveButton = true
        }
      },
      async synchronousToProcess() {
        this.onlyProcess = []
        this.tongRadio = 1
        this.processVisible = true
      },
      getValue(row) {
        const result = this.baseFormData[row.prop]
        if (row.form && row.form.options) {
          const current = row.form.options.find(item => item.id === result)
          return current && current.name
        }
        return result
      },
      // 添加工位
      addStation() {
        this.formData.stationIds = this.stationList.map(item => item.id)
        this.visible = true
      },
      // 上传图纸/附件
      fileUploadChange(res, file) {
        if (res.errorCode === 0) {
          this.$message.success('上传成功')
          this[this.uploadKey].push({
            id: file.uid,
            name: file.name,
            url: res.result
          })
        } else {
          this.$message.error(res.result)
        }
      },
      delFrontData(key, index) {
        this[key].splice(index, 1)
        this.$message.success('删除成功')
      },
      submitStationForm(callback) {
        const { form } = this.$refs.formBuild
        this.$refs.formBuild.formRefs().validate(async(valid) => {
          if (valid) {
            this.stationList = this.formOptions.stationOptions.filter(item => form.stationIds.indexOf(item.id) !== -1)
            this.$message.success('添加成功')
            this.visible = false
            callback(true)
          } else {
            callback()
          }
        })
      },
      submitForm() {
        this.buttonLoading = true
        this.$refs.baseForm.setForm({
          differenceMinus: this.differenceMinus,
          differencePlus: this.differencePlus,
          differenceType: this.differenceType
        })
        this.$refs.baseForm.formRefs().validate(async(valid) => {
          if (valid) {
            if (this.qualityInspectionPlan.inspectionProjectGroupProcess.isNeedInspect) {
              if (!this.qualityInspectionPlan.inspectionProjectGroupProcess.inspectId) {
                this.buttonLoading = false
                return this.$message.info('请选择过程检质检方案')
              }
              const projectProcessObject = this.$refs.qualityInspectionProjectProcess[0].transferDataToStandard()
              if (!projectProcessObject) {
                this.buttonLoading = false
                return false
              }
            }
            if (this.qualityInspectionPlan.inspectionProjectGroupFirst.isNeedInspect && this.procedureType !== 1) {
              if (!this.qualityInspectionPlan.inspectionProjectGroupFirst.inspectId) {
                this.buttonLoading = false
                return this.$message.info('请选择首检质检方案')
              }
              const projectFirstObject = this.$refs.qualityInspectionProjectFirst[0].transferDataToStandard()
              if (!projectFirstObject) {
                this.buttonLoading = false
                return false
              }
            }
            await this.addOrModify()
          } else {
            this.buttonLoading = false
          }
        })
      },
      async addOrModify() {
        let res
        const { form } = this.$refs.baseForm
        if (['add', 'copy'].indexOf(this.params.type) !== -1) {
          res = await procedureApi.addProcedure(this.createSetData(form))
        }
        if (this.params.type === 'modify') {
          res = await procedureApi.modifyProcedure(this.createResetData(form))
        }
        if (res) {
          this.saveButton = false
          this.$message.success(this.messages[this.params.type])
          if (this.params.type !== 'modify') {
            this.$router.push({ query: { type: 'view', id: res }})
            this.params = { type: 'view', id: res }
          }
          await this.getProcedureDetailById(this.params.id)
        }
        this.buttonLoading = false
      },
      createSetData(raw) {
        const groupList = this.$refs.procedureParams.paramsGroupList.map(item => ({
          name: item.name,
          formList: item.originFormList
        }))
        const isNeedFirstInspect = this.qualityInspectionPlan.inspectionProjectGroupFirst.isNeedInspect && this.procedureType !== 1 ? 1 : 0
        const isNeedProcessInspect = this.qualityInspectionPlan.inspectionProjectGroupProcess.isNeedInspect
        const isFullInspection = this.qualityInspectionPlan.inspectionProjectGroupProcess.isFullInspection
        const isMustCheck = this.qualityInspectionPlan.inspectionProjectGroupProcess.isMustCheck
        const projectProcessObject = isNeedProcessInspect ? this.$refs.qualityInspectionProjectProcess[0].transferDataToStandard() : {}
        const projectFirstObject = isNeedFirstInspect ? this.$refs.qualityInspectionProjectFirst[0].transferDataToStandard() : {}
        return {
          code: raw.code,
          name: raw.name,
          groupId: raw.groupId,
          groupName: raw.groupName,
          procedureClassId: raw.procedureClassId,
          procedureClassName: raw.procedureClassName,
          drawing: JSON.stringify(this.drawingsList || []),
          attachment: JSON.stringify(this.filesList || []),
          type: raw.type,
          outsourceTime: raw.type === 1 ? this.outsourceTime : 0,
          params: JSON.stringify(groupList),
          operationDesc: this.operationDesc,
          stationIdList: this.stationList.map(item => item.id),
          isEnabled: raw.isEnabled,
          remark: raw.remark,
          issueMode: raw.issueMode,
          assignMode: raw.assignMode,
          differenceMinus: raw.differenceMinus,
          differencePlus: raw.differencePlus,
          differenceType: raw.differenceType,
          isNeedFirstInspect,
          firstInspectId: isNeedFirstInspect ? this.qualityInspectionPlan.inspectionProjectGroupFirst.inspectId : null,
          firstInspectAttachments: isNeedFirstInspect ? projectFirstObject.attachments : JSON.stringify([]),
          firstInspectorIdList: isNeedFirstInspect ? projectFirstObject.inspectorList : [],
          firstInspectEntryMap: isNeedFirstInspect ? projectFirstObject.inspectionEntryMap : {},
          isNeedProcessInspect,
          isFullInspection,
          isMustCheck,
          processInspectId: isNeedProcessInspect ? this.qualityInspectionPlan.inspectionProjectGroupProcess.inspectId : null,
          processInspectAttachments: isNeedProcessInspect ? projectProcessObject.attachments : JSON.stringify([]),
          processInspectorIdList: isNeedProcessInspect ? projectProcessObject.inspectorList : [],
          processInspectEntryMap: isNeedProcessInspect ? projectProcessObject.inspectionEntryMap : {}
        }
      },
      createResetData(raw) {
        const obj = {
          id: raw.id
        }
        return Object.assign(this.createSetData(raw), obj)
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  ::v-deep {
    .el-select-dropdown.el-popper.is-multiple.multi-cascader-style .li-style .li-label-style {
      color: #607FFF;
    }
    .el-select-dropdown.el-popper.is-multiple.multi-cascader-style .li-style.active-li {
      background-color: rgba(96, 127, 255, .1);
      color: #607FFF;
    }
  }
  .procedure-detail-wrapper {
    height: 100%;
  
    .procedure-detail-header {
      width: 100%;
      padding: 10px 0;
    }
  
    .procedure-detail-body {
      height: calc(100% - 60px);
      overflow-y: auto;
  
      .detail-card {
        margin-bottom: 20px;
      }
    }
  }
  .flex-mode {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    .tit {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393D60;
      margin-right: 20px;
    }
    ::v-deep .el-radio-group {
        vertical-align: initial;
      }
    ::v-deep .el-radio {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #41456B;
    }
  }
  .flex-modes {
    display: inline-flex;
    justify-content: flex-start;
    margin-top: 20px;
    .tit {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393D60;
      margin-right: 20px;
    }
    .mode-ri {
      .ri-top {
        display: flex;
        justify-content: flex-start;
        margin: 10px 0;
        .top-tit {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #393D60;
          line-height: 36px;
        }
        .ri-num {
          width: 130px;
          margin: 0 20px;
          background: #FFFFFF;
          border-radius: 4px;
          border: 1px solid #DDE3EB;
        }
      }
    }
  }
  .bot-tis{
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393D60;
    display:inline-block;
  }
  </style>
  