<template>
    <div class="scrap-wrap">
      <div class="bottom">
        <FunctionHeader
          ref="functionHeader"
          v-model="functionHeader.searchVal"
          search-title="请输入日历编号/名称"
          :tags="!exportSelectionIds.length ?  functionHeader.tags :[]"
          :export-name="functionHeader.exportName"
          :export-params="functionHeader.exportParams"
          :show-export="!exportSelectionIds.length ? true : false"
           @search="$refs.mTable.setTableData(true)"
        >
        <div slot="all" v-show="exportSelectionIds.length">
        <el-button v-if="permission('Export')" size="small" type="primary"  @click="allMore('batchExport')">批量导出</el-button>
        <el-button v-if="permission('Status')" size="small" type="primary"  @click="batchEnables">批量启用</el-button>
        <el-button v-if="permission('Status')" size="small" type="primary"  @click="batchDisables">批量停用</el-button>
        <el-button v-if="permission('Del')" size="small" type="primary"  @click="allMore('batchDel')">批量删除</el-button>
        <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选 
          <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
          / {{ total }}项
        </span>
          <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
          </div>
        </FunctionHeader>
        <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData" @selection-change="selectionChange">
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <NewPageOpen slot="projectNumber" slot-scope="{ row }" :path="getDetailPath(row, 'view')" :text="row.code" />
          <div slot="defaultCalendar" slot-scope="{ row }">
          <div>{{ row.defaultCalendar ? '是' : '否' }}</div>
        </div>   
          <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
        <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}" />
        <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
      </div>
          <div slot="action" slot-scope="{ row }">
            <el-button
                  v-if="permission('Modify')"
                  type="text"
                  @click="modify(row)"
                  >编辑</el-button
                >
                <el-divider v-if="permission('Modify')" direction="vertical" />
                <el-button v-if="permission('Status')" type="text" @click="switchStatus(row, params.radio)">{{ row.isEnabled ? '停用' : '启用' }}</el-button>
        <el-divider v-if="permission('Status')" direction="vertical" />
                <el-button
                  v-if="permission('Del')"
                  type="text"
                  @click="del(row)"
                  >删除</el-button
                >
              </div>
        </MTable>
      </div>
    </div>
  </template>
  
  <script>
  import { columns } from './columns'
  import conventionMixin from '@/mixin/conventional-page'
  import batchExportFront from '@/mixin/batch-export-front'
  import FunctionHeader from '@/components/FunctionHeader/index'
  import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
  import api from '@/api/information/production/calendar'
  
  export default {
    name: 'CalendarList',
    components: { NewPageOpen, FunctionHeader },
    mixins: [conventionMixin, batchExportFront],
    data() {
      return {
        api,
        params: {
          radio: 'Calendar'
        },
        columns,
        searchFormData: {},
        searchFormatData: [],
        functionHeader: {
          searchVal: '',
          tags: [
          {
            hidden: !this.permission('Add'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.add
            }
          }
          ],
          exportName: 'exportCalendar',
          exportParams: {}
        },
        formData: {},
        isPageLoaded:false,
        total:0,
        searchKeyword:true
      }
    },
    beforeRouteLeave(to, from, next) {
      if (to.name !== 'refresh') {
        this.$store.dispatch('tagsView/addCachedView', from)
      }
      next()
    },
    computed: {
      height() {
        return this.$store.state.app.contentHeight - 235 - 10 + 35
      },
      getSelectionDataLength() {
        const selectionDataNoDisabled = this.exportSelectionList.filter((item) => !item.selectable)
        return selectionDataNoDisabled.length
    }
    },
    watch: {
    },
    created() {
    },
    activated(){
    if(!this.isPageLoaded){
      this.$refs.mTable.setTableData()
    }
    this.isPageLoaded = false
    },
    mounted() {
      this.isPageLoaded = true
    },
    methods: {
    resetSelectData() {
      this.exportSelectionList.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.exportSelectionList = this.exportSelectionList.filter((item) => item.selectable)
    },
    async batchEnables(){
        const res = await api.batchEnable({ids:this.exportSelectionIds})
        if(res){
          this.$message.success('启用成功')
          this.$refs.mTable.setTableData()
        }
      },
      async batchDisables(){
        const res = await api.batchDisable({ids:this.exportSelectionIds})
        if(res){
          this.$message.success('停用成功')
          this.$refs.mTable.setTableData()
        }
      },
      getDetailPath(row, command) {
        return { name: 'CalendarDetail', query: { id: row.id,type: 'view'}}
      },
       add() {
          this.$router.push({ name: 'CalendarDetail', query: { type: 'add'} })
    },
     modify(row){
      this.$router.push({ name: 'CalendarDetail', query: { id: row.id,type: 'modify'} })
    }
    }
  }
  </script>
  
  <style scoped lang="scss">
  .function-header-wrap {
    padding-top: 0;
  }
  
  .scrap-wrap {
    width: 100%;
    padding-top: 10px;
    .bottom {
      padding: 10px;
    }
    .m-radio-group {
      margin-left: 10px;
    }
  }
  .role-tags {
    margin: 5px;
    border-color: #dde3eb;
    color: #9597ae;
  }
  ::v-deep {
    .e-divider {
      margin: 0px 0 0 0;
    }
    .el-form-item__content {
      height:32px;
    }
  }
  </style>