var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "unit-wrap" }, [
    _c(
      "div",
      { staticStyle: { display: "flex", "justify-content": "space-between" } },
      [
        _c(
          "div",
          { style: { width: _vm.type === 0 ? "100%" : "50%" } },
          [
            _c(
              "FunctionHeader",
              {
                attrs: {
                  tags: _vm.functionHeader.tags,
                  "search-title": _vm.searchTitle,
                  "columns-key": _vm.columnsKey,
                  "import-name": _vm.functionHeader.importName,
                  "export-name": _vm.functionHeader.exportName,
                  "export-params": _vm.functionHeader.exportParams
                },
                on: {
                  search: function($event) {
                    return _vm.$refs.mTable.setTableData(true)
                  }
                },
                model: {
                  value: _vm.functionHeader.searchVal,
                  callback: function($$v) {
                    _vm.$set(_vm.functionHeader, "searchVal", $$v)
                  },
                  expression: "functionHeader.searchVal"
                }
              },
              [
                _c(
                  "div",
                  { attrs: { slot: "all" }, slot: "all" },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "margin-left": "15px",
                          "font-size": "14px",
                          color: "#606266",
                          "font-family":
                            "Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif"
                        }
                      },
                      [_vm._v(" 需求范围 ： ")]
                    ),
                    _c(
                      "el-select",
                      {
                        staticStyle: { "margin-left": "5px" },
                        attrs: { placeholder: "请选择需求范围" },
                        on: { change: _vm.allSrc },
                        model: {
                          value: _vm.allFlag,
                          callback: function($$v) {
                            _vm.allFlag = $$v
                          },
                          expression: "allFlag"
                        }
                      },
                      _vm._l(_vm.allOptions, function(item) {
                        return _c("el-option", {
                          key: item.id,
                          attrs: { label: item.name, value: item.id }
                        })
                      }),
                      1
                    ),
                    _c("el-date-picker", {
                      staticStyle: { "margin-left": "15px" },
                      attrs: {
                        align: "right",
                        type: "date",
                        "value-format": "yyyy-MM-dd",
                        format: "yyyy-MM-dd",
                        placeholder: "选择日期",
                        "picker-options": _vm.pickerOptions
                      },
                      on: { change: _vm.allSrc },
                      model: {
                        value: _vm.allTime,
                        callback: function($$v) {
                          _vm.allTime = $$v
                        },
                        expression: "allTime"
                      }
                    })
                  ],
                  1
                )
              ]
            ),
            _c(
              "MTable",
              {
                ref: "mTable",
                attrs: {
                  columns: _vm.getColumns,
                  height: _vm.heightAll,
                  "columns-key": _vm.columnsKey,
                  "set-data-method": _vm.getTableData,
                  "row-style": _vm.getRowStyle
                },
                on: { "row-click": _vm.handleRowClick },
                scopedSlots: _vm._u([
                  {
                    key: "index",
                    fn: function(ref) {
                      var $index = ref.$index
                      return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                    }
                  },
                  {
                    key: "materialsTypeName",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        _c("div", [_vm._v(_vm._s(row.materialsTypeName))])
                      ])
                    }
                  },
                  {
                    key: "missMaterialsRate",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        _c("div", [_vm._v(_vm._s(row.missMaterialsRate) + "%")])
                      ])
                    }
                  },
                  {
                    key: "sitMissMaterialsRate",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        _c("div", [
                          _vm._v(_vm._s(row.sitMissMaterialsRate) + "%")
                        ])
                      ])
                    }
                  },
                  {
                    key: "isKeyMaterials",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        _c("div", [
                          _vm._v(_vm._s(row.isKeyMaterials === 1 ? "是" : "否"))
                        ])
                      ])
                    }
                  },
                  {
                    key: "completeRate",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        _c("div", [_vm._v(_vm._s(row.completeRate) + "%")])
                      ])
                    }
                  }
                ])
              },
              [
                _c(
                  "div",
                  { attrs: { slot: "headerSlot" }, slot: "headerSlot" },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "justify-content": "center",
                          color: "#000"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-right": "15px",
                              cursor: "pointer"
                            },
                            attrs: { title: "物料需求" },
                            on: {
                              click: function($event) {
                                return _vm.viewDetail(1)
                              }
                            }
                          },
                          [_c("i", { staticClass: "el-icon-d-arrow-right" })]
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              "margin-right": "15px",
                              cursor: "pointer"
                            },
                            attrs: { title: "物料供应" },
                            on: {
                              click: function($event) {
                                return _vm.viewDetail(2)
                              }
                            }
                          },
                          [_c("i", { staticClass: "el-icon-d-arrow-left" })]
                        )
                      ]
                    )
                  ]
                )
              ]
            )
          ],
          1
        ),
        _vm.type === 1
          ? _c(
              "div",
              {
                staticStyle: { cursor: "pointer", margin: "53px 8px 0" },
                attrs: { title: "物料总览" }
              },
              [
                _c("i", {
                  staticClass: "el-icon-d-arrow-left",
                  on: {
                    click: function($event) {
                      return _vm.viewDetail(0)
                    }
                  }
                })
              ]
            )
          : _vm._e(),
        _vm.type === 1
          ? _c(
              "div",
              { staticStyle: { width: "48%", "margin-top": "53px" } },
              [
                _c("MTable", {
                  ref: "mTables",
                  attrs: {
                    columns: _vm.columnsRequirement,
                    "show-page": false,
                    "columns-setting": false,
                    height: _vm.height,
                    "columns-key": _vm.columnsKey,
                    data: _vm.requireList,
                    "is-tree-data": true,
                    "default-expand-all": _vm.isExpand
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "index",
                        fn: function(ref) {
                          var $index = ref.$index
                          return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                        }
                      },
                      {
                        key: "missMaterialsRate",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _c("div", [
                              _vm._v(_vm._s(row.missMaterialsRate) + "%")
                            ])
                          ])
                        }
                      }
                    ],
                    null,
                    false,
                    14497025
                  )
                })
              ],
              1
            )
          : _vm._e(),
        _vm.type === 2
          ? _c(
              "div",
              {
                staticStyle: { margin: "53px 8px 0", cursor: "pointer" },
                attrs: { title: "物料总览" }
              },
              [
                _c("i", {
                  staticClass: "el-icon-d-arrow-right",
                  on: {
                    click: function($event) {
                      return _vm.viewDetail(0)
                    }
                  }
                })
              ]
            )
          : _vm._e(),
        _vm.type === 2
          ? _c(
              "div",
              { staticStyle: { width: "48%", "margin-top": "53px" } },
              [
                _c("MTable", {
                  ref: "mTabless",
                  attrs: {
                    "is-tree-data": true,
                    "tree-props": { children: "children" },
                    "show-page": false,
                    columns: _vm.columnsSupply,
                    data: _vm.supplyList,
                    "columns-setting": false,
                    "default-expand-all": "",
                    "row-style": _vm.getRowDelayStyle
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "index",
                        fn: function(ref) {
                          var $index = ref.$index
                          return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                        }
                      }
                    ],
                    null,
                    false,
                    442309118
                  )
                })
              ],
              1
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }