<template>
  <div class="package-code-wrap common-tab">
    <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange">
      <el-radio-button v-if="permission('fastPage')" label="fast">快速生成</el-radio-button>
      <el-radio-button v-if="permission('historyPage')" label="history">历史生成</el-radio-button>
    </el-radio-group>
    <div v-if="params.radio === 'fast' && permission('fastPage')" class="top">
      <SearchForm ref="searchForm" :immediately-query="true" :form-list-extend="searchFormList" />
    </div>
    <div v-else class="placeholder-div" />
    <div v-if="permission(params.radio + 'Page')" class="bottom">
      <FunctionHeader
        v-model="functionHeader.searchVal"
        search-title="请输入包装码"
        :tags="functionHeader.tags"
        :hidden-search="params.radio === 'fast'"
        @search="$refs.mTable.setTableData(true)"
      />
      <MTable
        ref="mTable"
        :data="dataSourceFront && packageCodeList"
        :columns="columns[params.radio]"
        :height="height"
        :columns-setting="false"
        :row-class-name="tableRowClassName"
        :set-data-method="getTableData"
        @selection-change="selectionChange"
      >
        <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
        <div slot="code" slot-scope="{ row }" class="batch-number-columns">
          <span>{{ row.code }}</span>
          <img v-if="currentPackageCodeList.indexOf(row.code) !== -1" src="../../../../assets/information/新@2x.png">
        </div>
        <div slot="action" slot-scope="{ row }">
          <el-button type="text" @click="printCode(row)">打印</el-button>
        </div>
      </MTable>
    </div>

    <PrintCode ref="printCode" />
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import DelPopover from '@/components/DelPopover/index.vue'
import { fastColumns, historyColumns } from './columns'
import { searchFormList } from './form-list'
import SearchForm from '@/components/SearchForm/SearchForm'
import api from '@/api/information/print-label/package-code'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import { getFunctionList } from './form-list'
import PrintCode from '@/components/PrintCode/printPackage'

export default {
  name: 'PackageCode',
  components: { PrintCode, SearchForm, FunctionHeader, DelPopover },
  data() {
    return {
      select: '',
      params: {
        radio: 'fast',
        materialsId: null
      },
      columns: {
        fast: fastColumns,
        history: historyColumns
      },
      functionHeader: {
        searchVal: '',
        tags: [
          {
            tagName: 'el-button',
            props: { type: 'primary', size: 'small', disabled: true },
            style: { width: 'auto' },
            innerText: '批量打印',
            on: {
              click: this.batchPrint
            }
          }
        ]
      },
      searchFormList,
      currentPackageCodeList: [],
      packageCodeList: [],
      printCodes: [],
      dataSourceFront: true
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 65
    }
  },
  beforeMount() {
    getFunctionList({
      generatePackageCode: this.generatePackageCode
    })
  },
  mounted() {},
  methods: {
    // 获取物料数据
    async getTableData(condition, callback) {
      const { page, search, order } = condition
      // 在快速生成下未选择物料不获取批次码
      if (this.params.radio === 'fast') {
        callback({
          total: 0,
          content: []
        })
        callback()
        return false
      }
      if (this.functionHeader.searchVal) {
        search.push({
          id: getUUid(),
          fieldName: 'code',
          fieldType: 'string',
          fieldValue: this.functionHeader.searchVal,
          operator: 'like'
        })
      }
      const res = await api.getPackageCodeListPage({
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        searchVal: Encrypt(JSON.stringify(search))
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    tableRowClassName({ row }) {
      if (this.currentPackageCodeList.indexOf(row.code) !== -1 && this.params.radio === 'history') {
        return 'new-columns'
      }
      return ''
    },
    radioChange(radio) {
      this.functionHeader.searchVal = ''
      this.dataSourceFront = radio === 'fast'
      this.$refs.mTable.setTableData(true)
    },
    batchPrint() {
      this.$refs.printCode.batchPrint(this.printCodes)
    },
    printCode(row) {
      console.log('890')
      this.$refs.printCode.print(row.code)
    },
    async del(row) {
      const res = await api.delPackageCode({ id: row.id })
      if (res) {
        this.$message.success('删除成功')
        this.$refs.mTable.setTableData()
      }
    },
    selectionChange(data) {
      this.printCodes = data.map(item => item.code)
      this.functionHeader.tags[0].props.disabled = !this.printCodes.length
    },
    async generatePackageCode() {
      const { form } = this.$refs.searchForm
      if (!form.quantity) return this.$message.info('请输入数量')
      const res = await api.batchAddPackageCode({
        generateCount: form.quantity
      })
      if (res) {
        this.visible = false
        this.currentPackageCodeList.push(...res.map(item => item.code))
        this.packageCodeList.push(...res)
        this.$message.success('生成成功')
        this.$refs.mTable.setTableData()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}

.m-radio-group {
  margin: 10px 10px 0 10px;
}

.package-code-wrap {
  width: 100%;

  .top {
    ::v-deep {
      .search-form-wrap {
        form {
          .el-row {
            .el-col:last-child {
              .el-form-item__content {
                margin-left: 0 !important;
              }
            }
          }
        }
      }
    }
  }

  .bottom {
    padding: 10px;
  }
}

.placeholder-div {
  height: 57px;
  padding: 10px 10px 0 10px;
  border-bottom: 1px solid #DDE3EB;
}

::v-deep input[classname=select-materials] {
  display: none;
}

::v-deep .el-input-group--prepend {
  width: 100%;
  height: 40px;
}

::v-deep .el-input-group__prepend {
  background: #FFFFFF;
  width: calc(100% - 56px);

  > div {
    width: calc(100% + 40px);
  }

  .input-prepend {
    width: 100%;
    color: #495060;
  }
}

::v-deep .el-input-group__append {
  width: 56px;
}
::v-deep {
  .el-table .new-columns {
    background: #ECFAFF;
  }
}
.batch-number-columns {
  display: inline-flex;
  align-items: center;
  img {
    height: 11px;
    padding-left: 5px;
  }
}
</style>
