<template>
  <div class="scrap-wrap common-tab">
    <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange">
      <el-radio-button label="FacilitySpotCheckPlan">点检计划</el-radio-button>
      <el-radio-button label="PlanSpotCheckTemplate">计划模板</el-radio-button>
    </el-radio-group>
    <div class="top">
      <SearchForm
        ref="searchForm"
        :form-list-extend="searchFormList[params.radio]"
        @search="searchTable"
      />
    </div>
    <div class="bottom">
      <FunctionHeader
        v-model="functionHeader.searchVal"
        :search-title="functionHeader.searchTitle"
        :tags="!selectionData.length ? functionHeader.tags : []"
        :radio="params.radio"
        @search="$refs.mTable.setTableData(true)"
      >
        <div v-if="params.radio === 'FacilitySpotCheckPlan' && selectionData.length" slot="all">
          <el-button v-if="permission(params.radio + 'Del')" type="primary" size="small" @click="allMore('allDel')">批量删除</el-button>
        <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选
          <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
          / {{ total }}项
        </span>
          <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
        </div>
      </FunctionHeader>
      <MTable ref="mTable" :selectShowFalse="params.radio === 'FacilitySpotCheckPlan'" :columns="columns[params.radio]" :height="height" :columns-key="columnsKey" :set-data-method="getTableData" @selection-change="selectionChange">
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <el-button slot="code" slot-scope="{ row }" style="white-space: pre-wrap;" type="text" @click="viewDetail(row)">{{ row.code }}</el-button>
        <el-button slot="name" slot-scope="{ row }" style="white-space: pre-wrap;" type="text" @click="viewDetails(row)">{{ row.name }}</el-button>
        <div slot="customerSiteIdList" slot-scope="{ row }">
          {{ row.customerName }}
        </div>
        <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
          <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}" />
          <div>{{ row.isEnabled ? '启用' : '不启用' }}</div>
        </div>
        <div slot="toleranceRange" slot-scope="{ row }">
          {{ row.toleranceRange ? "±" +row.toleranceRange : '' }}
        </div>
        <div slot="cycleType" slot-scope="{ row }">
          {{ row.cycleType || row.cycleType === 0 ? maintenanceCycleType[row.cycleType].name : '-' }}
        </div>
        <div slot="lifeCycle" slot-scope="{ row }">
          {{ row.lifeCycle || row.lifeCycle === 0 ? row.lifeCycle : '-' }}
        </div>
        <div slot="action" slot-scope="{ row }">
          <template v-if="params.radio === 'FacilitySpotCheckPlan'">
            <el-button v-if="permission('Copy')" type="text" @click="copys(row)">复制</el-button>
            <el-divider v-if="permission('Copy')" direction="vertical" />
            <el-button v-if="permission(params.radio + 'Modify')" type="text" @click="modifys(row, 'modify')">修改</el-button>
            <el-divider v-if="permission(params.radio + 'Modify')" direction="vertical" />
            <el-button v-if="permission(params.radio + 'Del')" type="text" @click="desPlan(row)">删除</el-button>
          </template>
          <template v-else>
            <el-button v-if="permission('User')" type="text" @click="userPlan(row)">使用</el-button>
            <el-divider v-if="permission('User')" direction="vertical" />
            <el-button v-if="permission(params.radio + 'Modify')" type="text" @click="modifyInfo(row, 'modify')">修改</el-button>
            <el-divider v-if="permission(params.radio + 'Modify')" direction="vertical" />
            <el-button v-if="permission(params.radio + 'Del')" type="text" @click="del(row)">删除</el-button>
          </template>
        </div>
      </MTable>
    </div>
    <MDialog v-model="delVisible" :append-to-body="true" title="删除计划" width="500px" @onOk="submitFormDel">
      <div>
        <div style="margin-bottom: 10px">是否同步删除相关联的待执行任务工单？</div>
        <el-radio-group v-model="isDel">
          <el-radio :label="1">删除</el-radio>
          <el-radio :label="0">保留</el-radio>
        </el-radio-group>
      </div>
    </MDialog>
  </div>
</template>

<script>
import conventionMixin from '@/mixin/conventional-page'
import DelPopover from '@/components/DelPopover/index'
import SearchForm from '@/components/SearchForm/SearchForm'
import FunctionHeader from '@/components/FunctionHeader/index'
import api from '@/api/xiewei/facility/point'
import columns from './columns'
import { searchFormList } from './form-list'
import TableDialog from '@/components/TableDialog/TableDialog'
import { maintenanceCycleType } from '@/config/options.sales'
import { getUUid } from '@/utils'

export default {
  name: 'FacilityPointList',
  components: { TableDialog, DelPopover, SearchForm, FunctionHeader },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      maintenanceCycleType,
      params: {
        radio: 'FacilitySpotCheckPlan'
      },
      columns,
      searchFormList,
      searchFormatData: [],
      functionHeader: {
        searchVal: '',
        searchTitle: '点检计划编号/名称',
        tags: [
          {
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.addAll
            }
          }
        ]
      },
      formData: {},
      messages: {
        add: '新增',
        modify: '修改',
        copy: '复制'
      },
      currentType: 'add',
      visible: false,
      formOptions: {
        equipmentClass: []
      },
      getTableDataParams: {},
      searchKeyword: true,
      selectionData: [],
      delVisible: false,
      isDel: 0,
      selectId: '',
      isMul: false,
      extraParams: [],
      total:0,
      selectionDataList:[]
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 65
    },
    columnsKey() {
      return this.$route.name + this.params.radio
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectionDataList.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
  },
  mounted() {

  },
  methods: {
    radioChange(label) {
      this.functionHeader.searchTitle = label === 'FacilitySpotCheckPlan' ? '点检计划编号/名称' : '模板名称'
      this.extraParams = label === 'FacilitySpotCheckPlan' ? [] : [
        {
          id: getUUid(),
          fieldName: 'planTemplateSign',
          fieldType: 'number',
          fieldValue: 1,
          operator: 'eq'
        }
      ]
      this.searchFormatData = []
      this.getTableDataParams = {}
      if (this.$refs.searchForm) this.$refs.searchForm.reset()
      else this.$refs.mTable.setTableData(true)
    },
    allMore(command) {
      const functions = {
        allDel: () => { this.allDel() }
      }
      functions[command]()
    },
    selectionChange(data) {
      this.selectionData = data.map(item => item.id)
      this.selectionDataList = data
    },
    resetSelectData() {
      this.selectionDataList.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectionDataList = this.selectionDataList.filter((item) => item.selectable)
    },
    // 批量删除
    async allDel() {
      // this.delVisible = true
      this.isDel = 1
      this.isMul = true
      this.submitFormDel()
    },
    // 查看详情
    viewDetail(row) {
      this.$router.push({
        name: 'facilityPointDetail',
        query: { id: row.id }
      })
    },
    viewDetails(row) {
      this.$router.push({
        name: 'facilityPointTemplateDetail',
        query: { id: row.id }
      })
    },
    modifyInfo(row) {
      this.$router.push({ name: 'addFacilityPointTemplate', query: { id: row.id, type: 'modify' }})
    },
    userPlan(row) {
      this.$router.push({ name: 'addFacilityPoint', query: { type: 'add', templateId: row.id }})
    },
    addAll() {
      if (this.params.radio === 'FacilitySpotCheckPlan') {
        this.$router.push({ name: 'addFacilityPoint', query: { type: 'add' }})
      } else {
        this.$router.push({ name: 'addFacilityPointTemplate', query: { type: 'add' }})
      }
    },
    searchTable(data) {
      this.searchFormatData = data
      this.$refs.mTable.setTableData(true)
    },
    modifys(row) {
      this.$router.push({ name: 'addFacilityPoint', query: { type: 'modify', id: row.id }})
    },
    copys(row) {
      this.$router.push({ name: 'addFacilityPoint', query: { type: 'copy', id: row.id }})
    },
    desPlan(node) {
      // this.delVisible = true
      // this.isDel = 1
      this.isMul = false
      this.selectId = node.id
      this.submitFormDel()
    },
    async submitFormDel() {
      if (this.isMul) {
        const res = await api.delBatchFacilitySpotCheckPlan({
          ids: this.selectionData.join('^'),
          isDelete: 0
        })
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.tableRefs().clearSelection()
          this.$refs.mTable.setTableData()
        }
        return false
      }
      this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await this.api[`delFacilitySpotCheckPlan`]({ id: this.selectId, isDelete: 0 })
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.setTableData()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}

.scrap-wrap {
  width: 100%;
  padding-top: 10px;
  .bottom {
    padding: 10px;
  }
  .m-radio-group {
    margin-left: 10px;
  }
}
.role-tags {
  margin: 5px;
  border-color: #DDE3EB;
  color: #9597AE;
}
</style>
