<template>
    <div class="model-wrap">
      <FunctionHeader
        v-model="functionHeader.searchVal"
        search-title="请输入工时原因编码/原因"
        :tags="functionHeader.tags"
        @search="$refs.mTable.setTableData(true)"
      />
      <MTable
        ref="mTable"
        :columns="columns"
        :height="height"
        :set-data-method="getTableData"
      >
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <div slot="type" slot-scope="{ row }">{{ !row.type ? '停工':'返工' }}</div>
        <div slot="action" slot-scope="{ row }">

          <el-button v-if="permission('Modify')" type="text" @click="modifyOrCopy(row, 'modify')">修改</el-button>
          <el-divider v-if="permission('Modify')" direction="vertical" />
  
          <el-button v-if="permission('Del')" type="text" @click="del(row)">删除</el-button>
        </div>
      </MTable>
  
      <MDialog v-model="visible" :title="messages[currentType]" @onOk="submitForm">
        <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList" />
      </MDialog>
  
    </div>
  </template>
  
  <script>
  import FunctionHeader from '@/components/FunctionHeader/index'
  import conventionMixin from '@/mixin/conventional-page'
  import api from '@/api/information/production/workhours-reason'
  import { columns } from './columns'
  import formList from './form-list'
  
  export default {
    name: 'WorkHoursReason',
    components: { FunctionHeader },
    mixins: [conventionMixin],
    data() {
      return {
        api,
        params: {
          radio: 'WorkhoursReason'
        },
        functionHeader: {
          searchVal: '',
          tags: [
            {
              hidden: !this.permission('Add'),
              tagName: 'el-button',
              props: { type: 'primary', size: 'small' },
              style: { width: 'auto', background: '#607FFF' },
              innerText: '新增',
              on: {
                click: this.add
              }
            }
          ]
        },
        columns,
        visible: false,
        formList: formList,
        formData: {},
        currentType: '新增',
        formOptions: {},
        searchKeyword: true
      }
    },
    computed: {
      height() {
        return this.$store.state.app.contentHeight - 192
      }
    },
    mounted() {
    },
    methods: {
      createSetData(raw) {
        return {
          code: raw.code,
          name: raw.name,
          type: raw.type,
          remark: raw.remark
        }
      },
      createResetData(raw) {
        const obj = {
          id: raw.id
        }
        return Object.assign(this.createSetData(raw), obj)
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  .model-wrap {
    padding: 10px;
    .function-header-wrap {
      padding-top: 0;
    }
  }
  </style>
  