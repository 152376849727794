var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-materials-wrapper" },
    [
      _c(
        "MDrawer",
        {
          attrs: { title: _vm.materialsMessage[_vm.currentType] },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "MFormBuilder",
            {
              ref: "formBuild",
              attrs: {
                "col-span": 6,
                "form-data": _vm.formData,
                "form-list": _vm.formList,
                "custom-rules": _vm.rules,
                disabled: _vm.formDisabled
              }
            },
            [
              _c(
                "div",
                { attrs: { slot: "unit" }, slot: "unit" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "label-title",
                              staticStyle: { "padding-right": "10px" }
                            },
                            [_vm._v("单位管理")]
                          ),
                          _c(
                            "el-checkbox",
                            {
                              on: { change: _vm.showEnableUnit },
                              model: {
                                value: _vm.dialogForm.showEnableUnit,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.dialogForm,
                                    "showEnableUnit",
                                    $$v
                                  )
                                },
                                expression: "dialogForm.showEnableUnit"
                              }
                            },
                            [_vm._v("只显示启用单位")]
                          )
                        ],
                        1
                      ),
                      !_vm.formDisabled
                        ? _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.addDialogFormInfo("unit")
                                    }
                                  }
                                },
                                [_vm._v("新增辅助单位")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "padding-top": "10px" },
                          attrs: { span: 24 }
                        },
                        [
                          _c("MTable", {
                            ref: "mUnitTable",
                            attrs: {
                              height: 300,
                              "show-page": false,
                              data: _vm.form.materialsUnitList,
                              columns: _vm.dialogTColumns.unit
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "type",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return _c(
                                      "div",
                                      {
                                        class:
                                          row.type === "main"
                                            ? "required-div red"
                                            : ""
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              row.type === "main"
                                                ? "主单位"
                                                : "辅助单位"
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  }
                                },
                                {
                                  key: "name",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return _c(
                                      "div",
                                      {},
                                      [
                                        row.type === "main"
                                          ? _c(
                                              "el-form-item",
                                              { attrs: { prop: "mainUnitId" } },
                                              [
                                                _c(
                                                  "el-select",
                                                  {
                                                    staticStyle: {
                                                      width: "100%"
                                                    },
                                                    attrs: {
                                                      placeholder: "请选择",
                                                      disabled:
                                                        _vm.currentType ===
                                                        "modify"
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.setMainUnitName
                                                    },
                                                    model: {
                                                      value: row.mainUnitId,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          row,
                                                          "mainUnitId",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "row.mainUnitId"
                                                    }
                                                  },
                                                  _vm._l(
                                                    _vm.formOptions
                                                      .materialsUnitList,
                                                    function(item) {
                                                      return _c("el-option", {
                                                        key: item.id,
                                                        attrs: {
                                                          label: item.name,
                                                          value: item.id
                                                        }
                                                      })
                                                    }
                                                  ),
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          : _c("div", [
                                              _vm._v(_vm._s(row.name))
                                            ])
                                      ],
                                      1
                                    )
                                  }
                                },
                                {
                                  key: "isMappingEnable",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return _c("div", {}, [
                                      _vm._v(
                                        _vm._s(
                                          row.isMappingEnable ? "启用" : "停用"
                                        )
                                      )
                                    ])
                                  }
                                },
                                {
                                  key: "calc",
                                  fn: function(ref) {
                                    var row = ref.row
                                    return row.type !== "main"
                                      ? _c("div", {}, [
                                          _vm._v(
                                            "1" +
                                              _vm._s(row.name) +
                                              " = " +
                                              _vm._s(row.conversionFactor) +
                                              _vm._s(_vm.form.mainUnitName) +
                                              " "
                                          )
                                        ])
                                      : _vm._e()
                                  }
                                },
                                {
                                  key: "action",
                                  fn: function(ref) {
                                    var row = ref.row
                                    var $index = ref.$index
                                    return row.type !== "main"
                                      ? _c(
                                          "div",
                                          {},
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.switchDialogStatus(
                                                      row,
                                                      $index
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    row.isMappingEnable
                                                      ? "停用"
                                                      : "启用"
                                                  ) + " "
                                                )
                                              ]
                                            ),
                                            _c("el-divider", {
                                              attrs: { direction: "vertical" }
                                            }),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.modifyDialog(
                                                      row,
                                                      "unit",
                                                      $index
                                                    )
                                                  }
                                                }
                                              },
                                              [_vm._v("修改")]
                                            ),
                                            _c("el-divider", {
                                              attrs: { direction: "vertical" }
                                            }),
                                            _c(
                                              "el-button",
                                              {
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.delDialog($index)
                                                  }
                                                }
                                              },
                                              [_vm._v("删除")]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "weightRelative" }, slot: "weightRelative" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c("el-col", { attrs: { span: 2 } }, [_vm._v("1")]),
                      _c(
                        "el-col",
                        { staticClass: "unit-name", attrs: { span: 4 } },
                        [_vm._v(_vm._s(_vm.form.mainUnitName))]
                      ),
                      _c("el-col", { attrs: { span: 2 } }, [_vm._v("=")]),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              "controls-position": "right",
                              disabled: !_vm.weightRelative
                            },
                            on: {
                              change: function(val) {
                                return _vm.setForm(
                                  "weightConversionFactor",
                                  val
                                )
                              }
                            },
                            model: {
                              value: _vm.form.weightConversionFactor,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "weightConversionFactor",
                                  $$v
                                )
                              },
                              expression: "form.weightConversionFactor"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                disabled: !_vm.weightRelative,
                                placeholder: "请选择"
                              },
                              on: {
                                change: function(val) {
                                  return _vm.setForm("weightUnit", val)
                                }
                              },
                              model: {
                                value: _vm.form.weightUnit,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "weightUnit", $$v)
                                },
                                expression: "form.weightUnit"
                              }
                            },
                            [
                              _c("el-option", {
                                attrs: { label: "毫克(mg)", value: "毫克(mg)" }
                              }),
                              _c("el-option", {
                                attrs: { label: "克(g)", value: "克(g)" }
                              }),
                              _c("el-option", {
                                attrs: { label: "千克(kg)", value: "千克(kg)" }
                              }),
                              _c("el-option", {
                                attrs: { label: "吨(t)", value: "吨(t)" }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "package" }, slot: "package" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "label-title",
                              staticStyle: { "padding-right": "10px" }
                            },
                            [_vm._v("包装信息")]
                          ),
                          _c(
                            "el-checkbox",
                            {
                              on: { change: _vm.showEnablePackage },
                              model: {
                                value: _vm.dialogForm.showEnablePackage,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.dialogForm,
                                    "showEnablePackage",
                                    $$v
                                  )
                                },
                                expression: "dialogForm.showEnablePackage"
                              }
                            },
                            [_vm._v("只显示启用包装")]
                          )
                        ],
                        1
                      ),
                      !_vm.formDisabled
                        ? _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function($event) {
                                      return _vm.addDialogFormInfo("package")
                                    }
                                  }
                                },
                                [_vm._v("新增包装信息")]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "el-col",
                        {
                          staticStyle: { "padding-top": "10px" },
                          attrs: { span: 24 }
                        },
                        [
                          _c("MTable", {
                            ref: "mPackageTable",
                            attrs: {
                              height: 300,
                              "show-page": false,
                              data: _vm.form.containerClassList,
                              columns: _vm.dialogTColumns.package
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "isMappingEnable",
                                fn: function(ref) {
                                  var row = ref.row
                                  return _c("div", {}, [
                                    _vm._v(
                                      _vm._s(
                                        row.isMappingEnable ? "启用" : "停用"
                                      )
                                    )
                                  ])
                                }
                              },
                              {
                                key: "calc",
                                fn: function(ref) {
                                  var row = ref.row
                                  return _c("div", {}, [
                                    _vm._v(
                                      "1" +
                                        _vm._s(row.containerUnitName) +
                                        " = " +
                                        _vm._s(row.conversionFactor) +
                                        _vm._s(_vm.form.mainUnitName) +
                                        " "
                                    )
                                  ])
                                }
                              },
                              {
                                key: "action",
                                fn: function(ref) {
                                  var row = ref.row
                                  var $index = ref.$index
                                  return _c(
                                    "div",
                                    {},
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.switchDialogStatus(
                                                row,
                                                $index
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              row.isMappingEnable
                                                ? "停用"
                                                : "启用"
                                            ) + " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.modifyDialog(
                                                row,
                                                "package",
                                                $index
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("修改")]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.delDialog($index)
                                            }
                                          }
                                        },
                                        [_vm._v("删除")]
                                      )
                                    ],
                                    1
                                  )
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "process" }, slot: "process" },
                [
                  _vm.currentTab === "process"
                    ? _c("StaticInfo", {
                        attrs: { type: "process", data: _vm.formData }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "drawAndFile" }, slot: "drawAndFile" },
                [
                  _c("StaticInfo", {
                    attrs: { type: "drawAndFile", data: _vm.formData }
                  })
                ],
                1
              ),
              _c(
                "div",
                { attrs: { slot: "bom" }, slot: "bom" },
                [
                  _c(
                    "MTable",
                    {
                      ref: "mTable",
                      attrs: {
                        "default-expand-all": "",
                        "is-tree-data": true,
                        columns: _vm.columnsAll,
                        height: 300,
                        "show-page": false,
                        data: _vm.list,
                        "columns-setting": false,
                        "tree-props": { children: "childBomList" }
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "index",
                          fn: function(ref) {
                            var $index = ref.$index
                            return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                          }
                        },
                        {
                          key: "quantityStandard",
                          fn: function(ref) {
                            var row = ref.row
                            return _c("div", {}, [
                              _vm._v(_vm._s(_vm.fixData(row.quantityStandard)))
                            ])
                          }
                        }
                      ])
                    },
                    [_vm._v(" > ")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: {
            title:
              _vm.dialogMessage[_vm.currentDialogType] +
              _vm.dialogMessage[_vm.dialogType],
            "append-to-body": true
          },
          on: { onOk: _vm.dialogSubmit },
          model: {
            value: _vm.dialogVisible,
            callback: function($$v) {
              _vm.dialogVisible = $$v
            },
            expression: "dialogVisible"
          }
        },
        [
          _c(
            "MFormBuilder",
            {
              ref: "dialogFormBuild",
              attrs: {
                "form-data": _vm.dialogFormData,
                "form-list": _vm.dialogFormList[_vm.dialogType]
              }
            },
            [
              _c(
                "div",
                { attrs: { slot: "unitConversion" }, slot: "unitConversion" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 10 } },
                    [
                      _c("el-col", { attrs: { span: 1 } }, [_vm._v("1")]),
                      _c(
                        "el-col",
                        { staticClass: "unit-name", attrs: { span: 4 } },
                        [_vm._v(_vm._s(_vm.dialogForm.unitName))]
                      ),
                      _c("el-col", { attrs: { span: 1 } }, [_vm._v("=")]),
                      _c(
                        "el-col",
                        { attrs: { span: 10 } },
                        [
                          _c("el-input-number", {
                            staticStyle: { width: "100%" },
                            attrs: { "controls-position": "right", min: 0 },
                            on: {
                              change: function(val) {
                                return _vm.setDialogForm(
                                  "conversionFactor",
                                  val
                                )
                              }
                            },
                            model: {
                              value: _vm.dialogForm.conversionFactor,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.dialogForm,
                                  "conversionFactor",
                                  $$v
                                )
                              },
                              expression: "dialogForm.conversionFactor"
                            }
                          })
                        ],
                        1
                      ),
                      _c("el-col", { attrs: { span: 8 } }, [
                        _vm._v(_vm._s(_vm.form.mainUnitName) + "(主单位)")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }