<template>
  <div class="scrap-wrap common-tab">
    <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange">
      <el-radio-button v-if="permission('ProcedureList')" label="Procedure">工序</el-radio-button>
      <el-radio-button v-if="permission('ProcedureGroupList')" label="ProcedureGroup">工序段</el-radio-button>
    </el-radio-group>
    <div v-if="params.radio ==='Procedure'" class="bottom">
      <Split :offset.sync="split.offset" :height="splitHeight">
        <template slot="left">
          <ProcedureClass @nodeClick="setProcedureClassId" />
        </template>
        <template slot="right">
          <div class="top">
            <SearchForm
              v-if="params.radio === 'Procedure'"
              ref="searchForm"
              :form-list-extend="searchFormList[params.radio]"
              :form-data="searchFormData"
              @search="searchTable"
            />
            <div v-else style="height: 55px;border-bottom: 1px solid #DDE3EB" />
          </div>
          <div class="bottom">
            <FunctionHeader
              ref="functionHeader"
              v-model="functionHeader.searchVal"
              :search-title="'请输入工序编码/名称'"
              :tags="!exportSelectionIds.length ? functionHeader.tags :[]"
              :radio="params.radio"
              :columns-key="columnsKey"
              :export-name="functionHeader.exportName"
              :export-params="functionHeader.exportParams"
              :import-name="!exportSelectionIds.length ? functionHeader.importName :''"
              :show-export="!exportSelectionIds.length ? true : false"
              @search="$refs.mTable.setTableData(true)"
            >
              <div slot="all" v-show="exportSelectionIds.length">
              <el-button v-if="permission(params.radio + 'Export')" size="small" type="primary"  @click="allMore('batchExport')">批量导出</el-button>
              <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
                已选 
                <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
                / {{ total }}项
              </span>
                <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
              </div>
            </FunctionHeader>
            <MTable v-if="permission(params.radio + 'List')" ref="mTable" :columns="getColumns" :height="height" :set-data-method="getTableData" :columns-key="columnsKey" @selection-change="selectionChange">
              <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
              <div slot="type" slot-scope="{ row }">{{ typeName[row.type] }}</div>
              <NewPageOpen slot="code" slot-scope="{ row }" :path="{ name: 'procedureDetail', query: { id: row.id, type: 'view' }}" :text="row.code" />
              <el-button slot="station" slot-scope="{ row }" type="text" @click="viewStation(row)">查看
              </el-button>
              <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
                <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}" />
                <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
              </div>
              <div slot="action" slot-scope="{ row }">
                <NewPageOpen v-if="params.radio === 'Procedure' && permission(params.radio + 'Copy')" :path="{ name: 'procedureDetail', query: { id: row.id, type: 'copy' }}" text="复制" />
                <el-button v-else-if="permission(params.radio + 'Copy')" type="text" @click="modifyOrCopyF(row, 'copy')">复制</el-button>
                <el-divider v-if="permission(params.radio + 'Copy')" direction="vertical" />
                <el-button v-if="permission(params.radio + 'Status')" type="text" @click="switchStatus(row, params.radio)">{{ row.isEnabled ? '停用' : '启用' }}</el-button>
                <el-divider v-if="permission(params.radio + 'Status')" direction="vertical" />
                <el-dropdown @command="(command) => { more(row, command) }">
                  <span class="el-dropdown-link">
                    更多<i class="el-icon-arrow-down el-icon--right" />
                  </span>
                  <el-dropdown-menu slot="dropdown" class="td-dropdown-menu">
                    <el-dropdown-item v-if="permission(params.radio + 'Modify')" command="modify">修改</el-dropdown-item>
                    <el-dropdown-item v-if="memberCode === 'reagold' && params.radio === 'ProcedureGroup'" command="board">看板</el-dropdown-item>
                    <el-dropdown-item v-if="permission(params.radio + 'Del')" command="del">删除</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </MTable>
          </div>
        </template>
      </Split>
    </div>
    <div v-if="params.radio ==='ProcedureGroup'" class="bottom">
      <FunctionHeader
        ref="functionHeader"
        v-model="functionHeader.searchVal"
        :search-title="'请输入工序段名称'"
        :tags="!exportSelectionIds.length ? functionHeader.tags : []"
        :radio="params.radio"
        :columns-key="columnsKey"
        :export-name="functionHeader.exportName"
        :export-params="functionHeader.exportParams"
        :import-name="!exportSelectionIds.length ? functionHeader.importName :''"
        :show-export="!exportSelectionIds.length ? true : false"
        @search="$refs.mTable.setTableData(true)"
      >
        <div slot="all"  v-show="exportSelectionIds.length">
          <el-button v-if="permission(params.radio + 'Export')" size="small" type="primary"  @click="allMore('batchExport')">批量导出</el-button>
              <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
                已选 
                <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
                / {{ total }}项
              </span>
              <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
        </div>
      </FunctionHeader>
      <MTable v-if="permission(params.radio + 'List')" ref="mTable" :columns="getColumns" :height="heights" :set-data-method="getTableData" :columns-key="columnsKey" @selection-change="selectionChange">
        <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
        <div slot="type" slot-scope="{ row }">{{ typeName[row.type] }}</div>
        <NewPageOpen slot="code" slot-scope="{ row }" :path="{ name: 'procedureDetail', query: { id: row.id, type: 'view' }}" :text="row.code" />
        <el-button slot="station" slot-scope="{ row }" type="text" @click="viewStation(row)">查看
        </el-button>
        <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
          <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}" />
          <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
        </div>
        <div slot="action" slot-scope="{ row }">
          <NewPageOpen v-if="params.radio === 'Procedure' && permission(params.radio + 'Copy')" :path="{ name: 'procedureDetail', query: { id: row.id, type: 'copy' }}" text="复制" />
          <el-button v-else-if="permission(params.radio + 'Copy')" type="text" @click="modifyOrCopyF(row, 'copy')">复制</el-button>
          <el-divider v-if="permission(params.radio + 'Copy')" direction="vertical" />
          <el-button v-if="permission(params.radio + 'Status')" type="text" @click="switchStatus(row, params.radio)">{{ row.isEnabled ? '停用' : '启用' }}</el-button>
          <el-divider v-if="permission(params.radio + 'Status')" direction="vertical" />
          <el-dropdown @command="(command) => { more(row, command) }">
            <span class="el-dropdown-link">
              更多<i class="el-icon-arrow-down el-icon--right" />
            </span>
            <el-dropdown-menu slot="dropdown" class="td-dropdown-menu">
              <el-dropdown-item v-if="permission(params.radio + 'Modify')" command="modify">修改</el-dropdown-item>
              <el-dropdown-item v-if="memberCode === 'reagold' && params.radio === 'ProcedureGroup'" command="board">看板</el-dropdown-item>
              <el-dropdown-item v-if="permission(params.radio + 'Del')" command="del">删除</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </MTable>
    </div>

    <MDialog v-model="visible" :title="messages[currentType]" @onOk="submitForm">
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList[params.radio]" />
    </MDialog>

    <TableDialog title="查看工位" :show.sync="stationVisible" :columns="stationColumns" :table-data="stationList" />
  </div>
</template>

<script>
import { columns, stationColumns, groupColumns } from './columns'
import { formList, searchFormList, groupFormList } from './form-list'
import api from '@/api/information/production/procedure'
import stationApi from '@/api/information/production/station'
import conventionMixin from '@/mixin/conventional-page'
import batchExportFront from '@/mixin/batch-export-front'
import TableDialog from '@/components/TableDialog/TableDialog'
import FunctionHeader from '@/components/FunctionHeader/index'
import SearchForm from '@/components/SearchForm/SearchForm'
import { procedureType } from '@/config/options.config'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import Split from '@/components/Split/Split'
import ProcedureClass from '@/components/ProcedureClass/ProcedureClass'
import { getUUid } from '@/utils'
import { Encrypt } from '@/utils/sercet'

export default {
  name: 'ProcedureList',
  components: { ProcedureClass, Split, NewPageOpen, SearchForm, TableDialog, FunctionHeader },
  mixins: [conventionMixin, batchExportFront],
  data() {
    return {
      api,
      params: {
        radio: 'Procedure'
      },
      columns: {
        Procedure: columns,
        ProcedureGroup: groupColumns
      },
      searchFormList: {
        Procedure: searchFormList,
        ProcedureGroup: []
      },
      searchFormData: {},
      searchFormatData: [],
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('ProcedureAdd'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.addF
            }
          }
        ],
        exportName: 'exportProcedure',
        exportParams: {},
        importName: 'procedure'
      },
      formList: {
        Procedure: formList,
        ProcedureGroup: groupFormList
      },
      formData: {},
      messages: {
        add: '新增',
        modify: '修改',
        copy: '复制'
      },
      currentType: 'add',
      visible: false,
      stationVisible: false,
      stationColumns,
      stationList: [],
      searchKeyword: true,
      typeName: {},
      procedureClassId: '',
      split: {
        offset: 15
      },
      extraParams: [],
      total:0
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 95
    },
    heights() {
      return this.$store.state.app.contentHeight - 235 - 10
    },
    splitHeight() {
      return this.$store.state.app.contentHeight - 235 + 24
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.exportSelectionList.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    },
    getColumns() {
      if(this.params.radio === 'ProcedureGroup'){
        return this.columns[this.params.radio]
      }else{
        if (this.memberCode != 'mom') {
        return this.columns[this.params.radio]
      } else {
        return this.columns[this.params.radio].filter(item => {
          return item.prop !== 'station'
        })
      }
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'refresh') {
      this.$store.dispatch('tagsView/addCachedView', from)
    }
    next()
  },
  mounted() {
    procedureType.forEach(item => {
      this.typeName[item.id] = item.name
    })
  },
  methods: {
    resetSelectData() {
      this.exportSelectionList.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.exportSelectionList = this.exportSelectionList.filter((item) => item.selectable)
    },
    setProcedureClassId(id) {
      this.procedureClassId = id
      if (id) {
        this.extraParams = [
          {
            id: getUUid(),
            fieldName: 'procedureClassId',
            fieldType: 'number',
            fieldValue: id,
            operator: 'eq'
          }
        ]
      } else {
        this.extraParams = []
      }
      this.$refs.mTable.setTableData(true)
    },
    radioChange(label) {
      this.extraParams = []
      const flag = label === 'Procedure'
      this.exportSelectionIds = []
      this.functionHeader.tags[0].hidden = !this.permission(label + 'Add')
      Object.assign(this.functionHeader, {
        importName: flag ? 'procedure' : 'procedureGroup',
        exportName: flag ? 'exportProcedure' : 'exportProcedureGroup'
      })
      this.searchKeyword = flag
      if (this.params.radio === 'Procedure') {
        this.$refs.searchForm.reset()
      } else {
        this.searchFormatData = []
        this.$refs.mTable.setTableData(true)
      }
    },
    addProcedure() {
      this.$router.push({ name: 'procedureDetail', query: { type: 'add' }})
    },
    addF() {
      if (this.params.radio === 'Procedure') {
        this.addProcedure()
      } else {
        this.add()
      }
    },
    modifyOrCopyF(row, command) {
      if (this.params.radio === 'Procedure') {
        this.$router.push({ name: 'procedureDetail', query: { id: row.id, type: command }})
      } else {
        this.modifyOrCopy(row, command)
      }
    },
    // 更多操作
    more(row, command) {
      const functions = {
        copy: () => { this.modifyOrCopyF(row, command) },
        modify: () => { this.modifyOrCopyF(row, command) },
        view: () => { this.modifyOrCopyF(row, command) },
        board: () => { this.$router.push('/dashboard/reagold/' + row.id) },
        del: () => { this.dels(row) }
      }
      functions[command]()
    },
    async dels(row) {
      this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.delProcedure({ id: row.id })
        if (res.errorCode === 5) {
          this.jumps(row)
        } else {
          this.$message.success('删除成功')
          this.$refs.mTable.setTableData()
        }
      })
    },
    jumps(row) {
      this.$confirm('已有工艺路线使用了该工序，不允许删除', '提示', {
        confirmButtonText: '查看',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$router.push({ name: 'process', query: { procedureId: row.id }})
      })
    },
    async viewStation(row) {
      const res = await stationApi.getStationListByProcedureId(row.id)
      if (res) {
        if (!res.length) return this.$message.info('暂无可执行工位')
        this.stationList = res
        this.stationVisible = true
      }
    },
    searchTable(data) {
      this.searchFormatData = data
      this.$refs.mTable.setTableData(true)
    },
    createSetData(raw) {
      return {
        name: raw.name,
        remark: raw.remark
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    }
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}

.scrap-wrap {
  width: 100%;
  padding-top: 10px;
  .bottom {
    padding: 10px;
  }
  .m-radio-group {
    margin-left: 10px;
  }
}
</style>
