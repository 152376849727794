<template>
    <div class="orderAdd">
      <div class="detail-card">
        <div class="card-tit">基本信息</div>
        <div class="card-bot">
          <el-form
            v-if="['modify','addOrder','copy'].includes($route.query.type)"
            ref="ruleForm"
            label-position="right"
            :model="ruleForm"
            :rules="rules"
            label-width="85px"
            class="demo-form-inline"
          >
            <el-row>
              <el-col :span="6">
                <el-form-item label="班次编号" prop="code">
                  <el-input
                    v-model="ruleForm.code"
                    placeholder="请输入"
                    :disabled="type === 'modify'"
                  />
                </el-form-item>
              </el-col>
  
              <el-col :span="6">
                <el-form-item label="班次名称" prop="name">
                    <el-input
                    v-model="ruleForm.name"
                    placeholder="请输入"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="开始时间" prop="startTime">
                  <el-date-picker
                    v-model="ruleForm.startTime"
                    type="date"
                    value-format="yyyy-MM-dd"
                    format="yyyy-MM-dd"
                    style="width: 100%"
                    placeholder="开始时间"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="结束时间" prop="endTime">
                    <el-date-picker
                    v-model="ruleForm.endTime"
                    type="date"
                    value-format="yyyy-MM-dd"
                    format="yyyy-MM-dd"
                    style="width: 100%"
                    placeholder="结束时间"
                    />
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="周期设置" prop="period">
                  <!-- <el-select v-model="ruleForm.period" clearable placeholder="请选择" @change="changeperiod">
                    <el-option
                      v-for="item in cycleSettingList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select> -->
                  <el-select clearable v-show="ruleForm.period" v-model="ruleForm.periodDate" placeholder="请选择">
                    <el-option
                      v-for="item in cycleList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6" v-show="ruleForm.periodDate === 4">
                <el-form-item label="工作日设置" prop="workdays">
                  <div style="display:flex;justify-content:space-between;height:33px;" >
                    <div v-for="(item,index) in weekday" :key="item.id" @click="changeIndex(index)" :style="{background:item.isShowBg ? '#607FFF':'',color:item.isShowBg ? '#FFF':''}" style="border-radius: 100%;width: 30px;height: 30px;text-align: center;cursor: pointer;flex-shrink: 0;">{{ item.name }}</div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="工作时段" prop="time">
                    <el-time-picker
        v-model="ruleForm.time"
        is-range
        range-separator="至"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        placeholder="选择时间范围"
        value-format="HH:mm"
        format="HH:mm"
        :clearable="false"
        style="width:100%"
      />
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="备注" >
                  <el-input
                    v-model="ruleForm.remark"
                    placeholder=""
                  />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <el-form
            v-else
            ref="ruleForm"
            label-position="right"
            :model="ruleForm"
            :rules="rules"
            label-width="96px"
            class="demo-form-inline"
          >
            <!-- 详情 -->
            <el-row type="flex" justify="start" style="flex-flow:row wrap">
              <el-col v-for="item in getDetailBaseFields" :key="item.key" :span="item.key != 'workdays' ? 6 : 7">
                <el-form-item :label="item.name">
                  <div v-if="item.key != 'workdays'">{{ getDetailVal(item.key) }}</div>
                  <div v-else>
                    <div style="display:flex;justify-content:space-between;height:33px;" >
                    <div v-for="items in weekday" :key="items.id" :style="{background:items.isShowBg ? '#607FFF':'',color:items.isShowBg ? '#FFF':''}" style="border-radius: 100%;width: 30px;height: 30px;text-align: center;flex-shrink: 0;">{{ items.name }}</div>
                  </div>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>
      <div class="order-footer">
        <div />
        <div class="footer-ri">
          <el-button @click="returnList">{{ this.type!=='view'?'取 消':'返 回' }}</el-button>
          <el-button v-if="this.type!=='view'" type="primary" @click="save" :loading="loading">保 存</el-button>
        </div>
      </div>
      <div class="lines" />
    </div>
  </template>
  <script>
  import { detailBaseFields } from './columns'
  import api from '@/api/sets/shift'
  export default {
    name: 'ShiftsDetail',
    data() {
      return {
        api,
        ruleForm: {
          name: '',
          remark:'',
          period:1,
          workdays:[],
          periodDate:0
        },
        rules: {
            code: [
            { required: true, message: '请输入班次编号', trigger: 'blur' }
          ],
          name: [
            { required: true, message: '请输入班次名称', trigger: 'blur' }
          ],
          startTime: [
            { required: true, message: '请选择开始时间', trigger: 'blur' }
          ],
          endTime: [
            { required: true, message: '请选择结束时间', trigger: 'blur' }
          ],
          period: [
            { required: true, message: '请选择周期', trigger: 'change' }
          ],
          time:[
           { required: true, message: '请选择工作时段', trigger: 'blur' }
        ],
        // workdays:[
        //    { required: true, message: '请选择工作日', trigger: 'blur' }
        // ],
        },
        baseFormData: {},
        detailBaseFields,
        materialsList:[],
        functionHeader: {
        searchVal: '',
        importName: '',
        exportName: 'exportApplyMaterialsList',
        exportParams: {},
        tags: []
      },
      expandedRows:[],
      selectMaterials:[],
      searchId:undefined,
      planNodetitles: {
        add: "新增计划节点",
        modify: "修改计划节点"
      },
      loading:false,
      cycleSettingList:[
        {id:0,name:'不重复'},
        {id:1,name:'重复'},
      ],
      cycleList:[
      {id:0,name:'每天'},
        {id:1,name:'每周'},
        // {id:2,name:'每月'},
        // {id:3,name:'每年'},
        {id:4,name:'每工作日'},
      ],
      weekday:[
      {id:2,name:'一',isShowBg:false},
        {id:3,name:'二',isShowBg:false},
        {id:4,name:'三',isShowBg:false},
        {id:5,name:'四',isShowBg:false},
        {id:6,name:'五',isShowBg:false},
        {id:7,name:'六',isShowBg:false},
      {id:1,name:'日',isShowBg:false}
      ]
      }
    },
    async created() {
      this.type = this.$route.query.type
      switch (this.type) {
        case 'modify':
        case 'view':
        case 'copy':
          await this.getDetail()
          break
        case 'addOrder':
          break
        default:
          break
      }
    },
    beforeMount() {
  },
  computed: {
      height() {
        return this.$store.state.app.contentHeight - 235 - 88 - 60
      },
     getDetailBaseFields(){
        const newList = this._.cloneDeep(this.detailBaseFields)
        if(!this.ruleForm.period){
            return newList.filter(item => item.key !== 'periodDate' && item.key !== 'workdays')
        }else if(this.ruleForm.period && this.ruleForm.periodDate !=4){
            return newList.filter(item => item.key !== 'workdays')
        }
        return newList
     }

    },
    mounted(){
    },
    methods: {
      validateProcess(rule, value, callback) {
        if (value === 0 || (value === 1 && (this.ruleForm.periodDate || this.ruleForm.periodDate===0))) {
          callback()
        } else {
          callback(new Error('请选择重复周期'))
        }
    },
        changeperiod(val){
           if(!val){
            this.ruleForm.periodDate = ''
            this.weekday.forEach(day => {
              day.isShowBg = false;
            })
            this.ruleForm.workdays = []
           }
        },
        changeIndex(index){
            this.weekday[index].isShowBg = this.weekday[index].isShowBg ? false : true
            if(this.weekday[index].isShowBg){
                this.ruleForm.workdays.push(this.weekday[index].id)
            }else{
                const idIndex = this.ruleForm.workdays.indexOf(this.weekday[index].id);
                if (idIndex !== -1) {
                    this.ruleForm.workdays.splice(idIndex, 1)
                }
            }
        },
    // 获取班次基本信息值
      getDetailVal(key) {
        const result = this.ruleForm[key] === 0 || this.ruleForm[key] ? this.ruleForm[key]:'-'
        if (key === 'period') {
          const current = result ? '重复':'不重复'
          return current
        }
        if(key === 'periodDate'){
            const current = this.cycleList.find(item => item.id === result)
            return current ? current && current.name : '-'
        }
        if (key === 'time') {
          const current = Array.isArray(result) ? result.join('~') :'-'
          return current
        }
      return result
      },
      // 获取详情
      async getDetail() {
        const res = await api.getShiftDetail({id:this.$route.query.id})
        if (res) {
        this.ruleForm = res
        // this.ruleForm.time = []
        this.$set(this.ruleForm, 'time', [this.ruleForm.workHoursStartTime, this.ruleForm.workHoursEndTime])
        this.ruleForm.workdays = this.ruleForm.workdays ? this.ruleForm.workdays : []
        this.weekday.forEach(item => {
            if (this.ruleForm.workdays.includes(item.id)) {
                item.isShowBg = true
            }
        })
        }
      },
      save() {
        this.rules.period = [
            { required: true, message: '请选择周期', trigger: 'change' },
            { validator: this.validateProcess, trigger: 'change' }
          ]
        this.$refs.ruleForm.validate(async(valid) => {
          if (valid) {
            this.loading = true
            let obj
            if(this.type === 'modify'){
              obj = {
                id:this.$route.query.id,
               ...this.ruleForm,
               workdays:this.ruleForm.periodDate === 4 ? this.ruleForm.workdays : [],
                workHoursStartTime:this.ruleForm.time ? this.ruleForm.time[0] : '',
                workHoursEndTime:this.ruleForm.time ? this.ruleForm.time[1] : ''
            }
            }else{
                    obj = {
                    ...this.ruleForm,
                    workdays:this.ruleForm.periodDate === 4 ? this.ruleForm.workdays: [],
                    workHoursStartTime:this.ruleForm.time ? this.ruleForm.time[0] : '',
                    workHoursEndTime:this.ruleForm.time ? this.ruleForm.time[1] : ''
            }
            }
            let papi
            papi = this.type === 'modify' ? api.modifyShift : api.addShift
            const res = await papi(obj)
            if (res) {
              this.loading = false
              this.$router.push({ name: 'ShiftsList'})
            }
            this.loading = false
          } else {
            return false
          }
        })
      },
      returnList() {
        this.$router.go(-1)
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  ::v-deep .my-col {
    .el-form-item {
      margin-bottom: 2px;
      .el-form-item__label {
        padding-right: 0;
      }
    }
  }
  ::v-deep {
    .el-tag {
      margin-right: 10px;
      margin-bottom: 5px;
    }
    .el-form-item {
      margin-bottom: 20px;
    }
    .el-date-editor .el-range-separator,
    .el-date-editor .el-range__icon {
      line-height: 26px;
    }
    .el-input-number {
      height: 32px;
      line-height: 32px;
      width: 100%;
    }
    .el-input__inner {
      height: 32px;
      line-height: 32px;
    }
    .el-input-number.is-controls-right .el-input-number__increase,
    .el-input-number.is-controls-right .el-input-number__decrease {
      line-height: 15px;
    }
    .module-content >div{
      height:34px;
            }
  }
  .orderAdd {
    width: 100%;
    height: 100%;
    .num-img {
        width: 14px;
        height: 14px;
        position:absolute;
        right:10px;
        cursor: pointer;
      }
    .input-new-tag {
      width: 130px;
    }
    .button-new-tag {
      height: 32px;
    }
    .detail-card {
      margin-bottom: 20px;
    }
    .lines {
      width: 100%;
      height: 20px;
      background: #f0f2f5;
    }
    ::v-deep .el-select {
      width: 100%;
    }
    .e-btn {
      margin-bottom: 10px;
    }
    .order-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 60px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 100;
      background: #fff;
      border-top: 1px solid #dde3eb;
      .footer-le {
        padding-left: 30px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #393d60;
        span {
          margin-right: 45px;
          display: inline-block;
        }
      }
      .footer-ri {
        margin-right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  </style>
  