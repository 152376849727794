import { status } from '@/config/options.config'
import _ from 'lodash'
import { columns } from './columns'
import { transformColumnsToForm } from '@/utils'
const newColumns = _.cloneDeep(columns)

export const formList = transformColumnsToForm(newColumns)

export const searchFormList = [
  {
    key: 'areaId',
    tagName: 'el-select',
    colSpan: 5,
    props: {
      placeholder: '请选择产线'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 5,
    key: 'isEnabled',
    tagName: 'el-select',
    defaultValue: 1,
    props: {
      placeholder: '请选择工位状态'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: status
      }
    ]
  },
  {
    colSpan: 5,
    tagName: 'el-cascader',
    key: 'userId',
    props: {
      placeholder: '请选择人员',
      props: {
        label: 'name',
        value: 'key'
      },
      options: [],
      filterable: true
    }
  },
  // {
  //   colSpan: 5,
  //   tagName: 'el-cascader',
  //   key: 'equipmentId',
  //   props: {
  //     placeholder: '请选择设备',
  //     props: {
  //       label: 'name',
  //       value: 'id'
  //     },
  //     options: [],
  //     filterable: true
  //   }
  // }
]

