const columns = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'index',
    width: 80
  },
  {
    prop: 'code',
    label: '线边仓编码',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input', props: { disabled: true }}
  },
  {
    prop: 'name',
    label: '线边仓名称',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input' }
  },
  {
    prop: 'areaCode',
    label: '产线编码',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input', props: { disabled: true }}
  },
  {
    prop: 'areaName',
    label: '产线名称',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input', props: { disabled: true }}
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 50
  }
]

export default columns
