<template>
    <div class="orderAdd">
      <div class="detail-card">
        <div class="card-tit">工作日历</div>
        <div class="card-bot" style="display:flex;margin-bottom: 60px;">
            <div class="form" style="width: 260px;flex-shrink: 0;" 
                >
                <el-form ref="ruleForm" :rules="rules" :model="ruleForm" label-width="80px" v-if="['modify','add','copy'].includes($route.query.type)">
                <el-form-item label="日历编号" prop="code">
                    <el-input v-model="ruleForm.code" placeholder="请输入" :disabled="type === 'modify'"></el-input>
                </el-form-item>
                <el-form-item label="日历名称" prop="name">
                    <el-input v-model="ruleForm.name" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="班次列表" prop="shiftIds">
                    <el-select v-model="ruleForm.shiftIds" placeholder="请选择" multiple @change="changeShifts">
                    <el-option
                                v-for="item in shiftList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            />
                </el-select>
              </el-form-item>
              <el-form-item label="默认日历" prop="defaultCalendar">
                <el-radio-group v-model="ruleForm.defaultCalendar">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
              </el-form-item>
              <el-form-item label="备注">
                <el-input type="textarea" v-model="ruleForm.remark"></el-input>
              </el-form-item>
            </el-form>
        <el-form
            v-else
            ref="ruleForm"
            label-position="right"
            :model="ruleForm"
            label-width="85px"
          >
                <el-form-item v-for="item in detailBaseFields" :key="item.key" :label="item.name">
                  <div>{{ getDetailVal(item.key) }}</div>
                </el-form-item>
          </el-form>
            </div>
            <div style="padding-left:10px;">
                <ElCalendar @changeTime="changeTime">
                    <template slot="dateCell" slot-scope="{ data }">
                        <div :class="{ 'isTodayC': data.day === getToday()}" style="height: 32px;line-height: 32px;margin-bottom: 4px;">
                        {{  parseInt(data.day.split("-").slice(2).join("-")) }}日
                    </div>
                      <div v-for="(item,index) in shiftDetailList[data.day]" :key="item.id" :style="{background:index % 2 === 0 ? '#D9E3F1':'#DBD9F1'}" style="border-radius: 4px;margin-bottom:6px;" 
                    >
                        <el-popover placement="right" trigger="hover" popper-class="pover-tips">
                            <div slot="reference" class="noplan">{{ item.frequencyName }}</div>
                            <div>
                                <div class="title">{{ item.frequencyName }}</div>
                                <div class="content">开始时间：{{ item.workHoursStartTime }}</div>
                                <div class="content">结束时间：{{ item.workHoursEndTime }}</div>
                                <div class="content">重复：{{ findperiodDate(item.periodDate) }}</div>
                            </div>
                        </el-popover>
                    </div>
                    </template>
                </ElCalendar>
            </div>
        </div>
      </div>
      <div class="order-footer">
        <div />
        <div class="footer-ri">
          <el-button @click="returnList">{{ this.type !== 'view'?'取 消':'返 回' }}</el-button>
          <el-button v-if="this.type!=='view'" type="primary" @click="save" :loading="loading">保 存</el-button>
        </div>
      </div>
      <div class="lines" />
    </div>
    
  </template>
  <script>
  import ElCalendar from '../components/calendar/main'
  import sapi from '@/api/sets/shift'
  import api from '@/api/information/production/calendar'
  
  export default {
    name: 'CalendarDetail',
    components: { ElCalendar },
    data() {
      return {
        api,
        logVisible: false,
        visible:false,
        value: new Date(),
        ruleForm: {
          code:'',
          name: '',
          remark:'',
          shiftIds:[],
          defaultCalendar:0
        },
        shiftList:[],
        rules: {
            code: [
            { required: true, message: '请输入日历编号', trigger: 'blur' }
          ],
          name: [
            { required: true, message: '请输入日历名称', trigger: 'blur' }
          ],
          shiftIds: [
            { required: true, message: '请选择班次', trigger: 'change' }
          ],
          defaultCalendar: [
            { required: true, message: '请选择默认日历', trigger: 'change' }
          ]
        },
      expandedRows:[],
      selectMaterials:[],
      searchId:undefined,
      loading:false,
      cycleList:[
        {id:0,name:'每天'},
        {id:1,name:'每周'},
        {id:2,name:'每月'},
        {id:3,name:'每年'},
        {id:4,name:'每工作日'},
      ],
      detailBaseFields : [
          {
          key: 'code',
          name: '日历编号：'
          },
          {
          key: 'name',
          name: '日历名称：'
          },
          {
            key: 'shiftIds',
            name: '班次列表：'
          },
          {
            key: 'defaultCalendar',
            name: '默认日历：'
          },
          {
          key: 'remark',
          name: '备注：'
          }
      ],
      shiftDetailList:[],
      calendarTime:[],
      shiftData:[]
      }
    },
    async created() {
      this.type = this.$route.query.type
      this.getShiftList()
      switch (this.type) {
        case 'modify':
        case 'view':
        case 'copy':
          await this.getDetail()
          break
        case 'add':
          break
        default:
          break
      }
    },
  computed: {
      height() {
        return this.$store.state.app.contentHeight - 235 - 88 - 60
      }
    },
    mounted(){

    },
    methods: {
      changeTime(val){
        this.calendarTime = val
        if(this.type === 'view'){
          this.getDetailsByCalendarId()
        }else if(this.ruleForm.shiftIds.length && this.type != 'view'){
          this.getDetailsByShiftId()
        }
      },
      getToday(){
        const today = new Date();
        const todayDate = today.getDate();
        const todayMonth = today.getMonth() + 1; // 月份是从0开始计数的，所以要加1
        const todayYear = today.getFullYear();
        const formattedToday = `${todayYear}-${todayMonth < 10 ? '0' + todayMonth : todayMonth}-${todayDate < 10 ? '0' + todayDate : todayDate}`;
        return formattedToday;
      },
      async getDetailsByShiftId(){
        const res = await sapi.getFrequencyMapByFrequencyIds({frequencyIds:this.ruleForm.shiftIds,calendarStartTime:this.calendarTime[0],calendarEndTime:this.calendarTime[1]})
        if (res) {
          this.shiftDetailList = res
        }
      },
      changeShifts(val){
        if(!val.length){
          this.getDetailsByShiftId()
        }
      },
      // 获取班次详情
      getShiftDetailList(val){
        this.shiftDetailList = this.shiftList.filter(item => val.includes(item.id))
      },
      findperiodDate(result){
        const current = this.cycleList.find(item => item.id === result)
        return current ? current && current.name : '-'
      },
        shouldShowItem(item, day) {
          if (item.periodDate) {
            return this.isDateInRange(item.startTime, item.endTime, day) && this.isWeeklyDate(day, item.startTime,item.periodDate,item.workdays);
          } else {
            return !item.periodDate && this.isDateInRange(item.startTime, item.endTime, day);
          }
       },
      //  是否显示班次数据
        isDateInRange(startTime, endTime, targetDate) {
            const targetTimestamp = new Date(targetDate).getTime()
            const startTimestamp = new Date(startTime).getTime()
            const endTimestamp = new Date(endTime).getTime()
            return targetTimestamp >= startTimestamp && targetTimestamp <= endTimestamp
        },
       isWeeklyDate(day, startTime,periodDate,workdays) {
        const start = new Date(startTime)
        const diffTime = Math.abs(new Date(day).getTime() - start.getTime())
        // 算日期时间差
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
        if (periodDate === 2) {
          return startTime.substring(8) == day.substring(8)
          // return parseInt(startTime.split("-").slice(2).join("-")) === parseInt(day.split("-").slice(2).join("-")) 
            } else if (periodDate === 3) {
              return startTime.substring(5) == day.substring(5)
            } else if (periodDate === 1) { // 按周循环
                return diffDays % 7 === 0
            }else{
                return workdays.includes(new Date(day).getDay())
            }
    },
       async getShiftList(){
          const res = await sapi.getShiftList()
            if(res){
                this.shiftList = res
            }
        },
    // 获取日历基本信息值
      getDetailVal(key) {
        const result = this.ruleForm[key] === 0 || this.ruleForm[key] ? this.ruleForm[key]:'-'
        if (key === 'shiftIds') {
          const current = this.shiftData.length ? this.shiftData.map(item=>item.name).join('、') :'-'
          return current
        }
        if (key === 'defaultCalendar') {
          const current = this.ruleForm.defaultCalendar ? '是' : '否'
          return current
        }
      return result
      },
      // 获取详情
      async getDetail() {
        const res = await api.getCalendarDetail({id:this.$route.query.id})
        if (res) {
            const {name,code,remark,defaultCalendar} = res
          this.ruleForm.name = name
          this.ruleForm.code = code
          this.ruleForm.remark = remark
          this.ruleForm.shiftIds = res.shiftList.map(item=>item.id)
          this.ruleForm.defaultCalendar = defaultCalendar
          this.shiftData = res.shiftList
        }
      },
      async getDetailsByCalendarId(){
        const res = await sapi.getFrequencyDetailMapByCalendarId({calendarId:this.$route.query.id,calendarStartTime:this.calendarTime[0],calendarEndTime:this.calendarTime[1]})
        if (res) {
          this.shiftDetailList = res
        }
      },
      save() {
        this.$refs.ruleForm.validate(async(valid) => {
          if (valid) {
            if(this.ruleForm.defaultCalendar){
              this.checkExistDefaultCalendar()
            }else{
              this.saveCalendar()
            }
          } else {
            return false
          }
        })
      },
      async checkExistDefaultCalendar(){
        const res = await api.checkExistDefaultCalendar()
        if(res){
          this.$confirm('已有生产日历 ，是否保存?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(async() => {
              this.saveCalendar()
            })
        }else{
           this.saveCalendar()
        }
      },
      async saveCalendar(){
      this.loading = true
            let obj
            if(this.type === 'modify'){
              obj = {
                id:this.$route.query.id,
               ...this.ruleForm
            }
            }else{
                    obj = {
                    ...this.ruleForm
            }
            }
            let papi
            papi = this.type === 'modify' ? api.modifyCalendar : api.addCalendar
            const res = await papi(obj)
            if (res) {
              this.loading = false
              this.$router.push({ name: 'CalendarList'})
            }
            this.loading = false
      },
      returnList() {
        this.$router.go(-1)
      }
    }
  }
  </script>
  <style lang="scss" >
  .povers {
    position: relative;
  }
  .pover-tips{
    width: 357px;
    padding-bottom: 5px;
    // right: 50%;
    background: rgba(0, 0, 0, 0.7)!important;
  }
  .pover-tips[x-placement^=right] .popper__arrow{
    /*el-popover上边三角下层的颜色---也就是视觉上的边框*/
    border-right-color: rgba(0, 0, 0, 0.7)!important;
  }
  .pover-tips[x-placement^=right] .popper__arrow::after{
    /*el-popover上边三角表层的颜色---也就是视觉上的角*/
    border-right-color: rgba(0, 0, 0, 0.7)!important;
  }
  .pover-tips[x-placement^=left] .popper__arrow{
    /*el-popover上边三角下层的颜色---也就是视觉上的边框*/
    border-left-color: rgba(0, 0, 0, 0.7)!important;
  }
  .pover-tips[x-placement^=left] .popper__arrow::after{
    /*el-popover上边三角表层的颜色---也就是视觉上的角*/
    border-left-color: rgba(0, 0, 0, 0.7)!important;
  }
  </style>
  <style scoped lang="scss">
  ::v-deep {
    .el-calendar-table td.is-today{
        .isTodayC{
            width: 32px;
            background: #607FFF;
            border-radius: 100%;
            color:#FFFFFF;
            display: flex;
                justify-content: center; /* 水平居中 */
                align-items: center; /* 垂直居中 */
        }
    }
    .el-calendar-day{
        min-height: 85px;
        height: auto;
    }
}
  ::v-deep .my-col {
    .el-form-item {
      margin-bottom: 2px;
      .el-form-item__label {
        padding-right: 0;
      }
    }
  }
  ::v-deep {
    .el-tag {
      margin-right: 10px;
      margin-bottom: 5px;
    }
    .el-form-item {
      margin-bottom: 20px;
    }
    .el-date-editor .el-range-separator,
    .el-date-editor .el-range__icon {
      line-height: 26px;
    }
    .el-input-number {
      height: 32px;
      line-height: 32px;
      width: 100%;
    }
    .el-input__inner {
      height: 32px;
      line-height: 32px;
    }
    .el-input-number.is-controls-right .el-input-number__increase,
    .el-input-number.is-controls-right .el-input-number__decrease {
      line-height: 15px;
    }
    .module-content >div{
      height:34px;
            }
  }
  
  .title {
        height: 22px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        font-size: 16px;
        color: #FFFFFF;
        line-height: 22px;
        text-align: left;
        font-style: normal;
   }
   .content {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 24px;
        text-align: left;
        font-style: normal;
  }
  .orderAdd {
    width: 100%;
    height: 100%;
    .num-img {
        width: 14px;
        height: 14px;
        position:absolute;
        right:10px;
        cursor: pointer;
      }
    .input-new-tag {
      width: 130px;
    }
    .button-new-tag {
      height: 32px;
    }
    .detail-card {
      margin-bottom: 20px;
    }
    ::v-deep .el-select {
      width: 100%;
    }
    .e-btn {
      margin-bottom: 10px;
    }
    .order-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 60px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 100;
      background: #fff;
      border-top: 1px solid #dde3eb;
      .footer-le {
        padding-left: 30px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #393d60;
        span {
          margin-right: 45px;
          display: inline-block;
        }
      }
      .footer-ri {
        margin-right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .noplan {
    font-family: PingFangSC, PingFang SC;
    color: #393D60;
    line-height: 22px;
    padding-left: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
 }
  </style>
  