<template>
  <div class="inspection-plan-detail-wrapper">
    <div class="inspection-plan-detail-header flex-sbc">
      <div class="left" @click="$router.push({ name: 'packageInfo' })">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px;cursor: pointer">容器详情</span>
      </div>
    </div>
    <div class="inspection-plan-detail-body">
      <div class="detail-card">
        <div class="card-tit">基本信息</div>
        <div class="card-bot">
          <el-row class="view-list" :gutter="24">
            <!--            暂时这么处理距离问题-->
            <template v-for="(item, index) in getColumns">
              <el-col
                v-if="item.label"
                :key="item.prop"
                :span="6"
                :style="{marginBottom: index !== 4 ? '20px' : 0 }"
              >
                <span style="color: #9597AE;">{{ item.label }}：</span>
                <span class="bot-tis">{{ getValue(item) }}</span>
              </el-col>
            </template>
          </el-row>
        </div>
      </div>
      <div class="detail-card">
        <div class="card-tit">物料</div>
        <div class="card-bot">
          <el-button size="small" type="primary" style="margin-bottom: 10px" @click="addCheckItem()">添加物料</el-button>
          <MTable
            ref="pointCheckTable"
            :height="300"
            :columns="matertialColumns"
            :columns-setting="false"
            :show-page="false"
            :data="selectCheckCheckItem"
            :only-key="'uuid'"
          >
            <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
            <!-- <div slot="batchNumber" slot-scope="{ row }">{{ row.batchNumber || '-'}}</div> -->
            <div slot="nominalCapacity" slot-scope="{ row }">
              <span v-if="!row.isEdit">{{ row.nominalCapacity }}</span>
              <template v-else>
                <el-input-number v-model="row.nominalCapacity" controls-position="right" :step="1" style="width: 100%;" />
              </template>
            </div>
            <div slot="action" slot-scope="{ row, $index }">
              <template v-if="!row.isEdit">
                <el-button type="text" @click="modifyOrCopy(row, $index)">修改</el-button>
                <el-button type="text" @click="del(row)">删除</el-button>
              </template>
              <template v-else>
                <el-button type="text" @click="cancel(row, $index)">取消</el-button>
                <el-button type="text" @click="save(row)">保存</el-button>
              </template>
            </div>
          </MTable>
        </div>
      </div>
    </div>
    <select-materials
      ref="selectMaterials"
      :visible.sync="showMaterials"
      :title="'添加物料'"
      :container-class-id="+params.id"
      @submitForm="submitMaterials"
    />
  </div>
</template>

<script>
import SelectMaterials from '@/components/SelectMaterials/SelectMaterials.vue'
import conventionMixin from '@/mixin/conventional-page'
import api from '@/api/information/warehouse/package'
import FunctionHeader from '@/components/FunctionHeader/index'
import { getUUid, setTreeData } from '@/utils'
import { columns, matertialColumns } from '../columns'
import { Encrypt } from '@/utils/sercet'
import dayjs from 'dayjs'

export default {
  name: 'PackageDetail',
  components: { SelectMaterials, FunctionHeader },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      columns,
      matertialColumns,
      showMaterials: false,
      fullScreen: false,
      params: {},
      baseFormData: {},
      buttonLoading: false,
      messages: {
        add: '新增成功',
        modify: '修改成功',
        copy: '复制成功',
        viewO: '查看',
        addO: '新增',
        modifyO: '修改'
      },
      selectCheckCheckItem: [],
      editData: {}
    }
  },
  computed: {
    getColumns() {
      const num = this._.cloneDeep(this.columns)
      num.pop()
      num.shift()
      return num
    }
  },
  mounted() {
    this.params = this.$route.query
    this.getContainerClassDeatil(this.params.id)
  },
  methods: {
    modifyOrCopy(row, index) {
      this.editData = this._.cloneDeep(row)
      this.$set(this.selectCheckCheckItem[index], 'isEdit', !row.isEdit)
    },
    cancel(row, index) {
      if (row.isAdd) {
        this.selectCheckCheckItem.splice(index, 1)
        return false
      }
      this.$set(this.selectCheckCheckItem[index], 'isEdit', !row.isEdit)
      this.$set(this.selectCheckCheckItem[index], 'nominalCapacity', this.editData.nominalCapacity)
    },
    async save(row) {
      const data = {
        id: row.id,
        containerClassId: this.params.id,
        materialsId: row.materialsId,
        nominalCapacity: row.nominalCapacity
      }
      let res
      if (row.isAdd) {
        res = await api.addContainerClassMaterialsMapping(data)
      } else {
        res = await api.modifyContainerClassMaterialsMapping(data)
      }
      if (res) {
        this.$message.success(row.isAdd ? '添加成功' : '修改成功')
        this.getContainerClassDeatil(this.params.id)
      }
    },
    async del(row) {
      this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.delContainerClassMaterialsMapping({ id: row.id })
        if (res) {
          this.$message.success('删除成功')
          this.getContainerClassDeatil(this.params.id)
        }
      })
    },
    addCheckItem() {
      this.showMaterials = true
    },
    submitMaterials(val) {
      const data = val.map(item => {
        return {
          containerClassId: this.params.id,
          materialsId: item.id,
          materialsCode: item.code,
          materialsName: item.name,
          materialsClassName: item.materialsClassName,
          materialsTypeName: item.materialsTypeName,
          specifications: item.specifications,
          materialsMainUnitName: item.mainUnitName,
          nominalCapacity: undefined,
          isEdit: true,
          isAdd: true
        }
      })
      this.selectCheckCheckItem = [...this.selectCheckCheckItem, ...data]
      this.showMaterials = false
      // this.addContainerClassMaterialsMapping(data)
    },
    async addContainerClassMaterialsMapping(data) {
      const res = await api.addContainerClassMaterialsMappingBatch(data)
      if (res) {
        this.$message.success('添加成功')
        this.getContainerClassDeatil(this.params.id)
        this.showMaterials = false
      }
    },
    async getContainerClassDeatil(id) {
      const res = await api.getContainerClassDeatil({ id })
      if (res) {
        this.baseFormData = res
        this.selectCheckCheckItem = res.containerClassMaterialsMappingList.map(item => {
          return {
            ...item,
            nominalCapacity: item.nominalCapacity || item.nominalCapacity === 0 ? item.nominalCapacity : undefined,
            isEdit: false
          }
        })
      }
    },
    getValue(row) {
      const result = this.baseFormData[row.prop]
      if (row.form && row.form.options) {
        const current = row.form.options.find(item => item.id === result)
        return current && current.name
      }
      if (row.options) {
        const current = row.options.find(item => item.id === result)
        return current && current.name
      }
      return result
    }
  }
}
</script>

<style scoped lang="scss">
.inspection-plan-detail-wrapper {
  height: 100%;

  .inspection-plan-detail-header {
    width: 100%;
    padding: 10px 0;
  }

  .inspection-plan-detail-body {
    height: calc(100% - 60px);
    overflow-y: auto;

    .detail-card {
      margin-bottom: 20px;
    }
  }
  ::v-deep {
    .el-col.el-col-6 >.el-form-item {
      min-height: 91px;
    }
    .el-col.el-col-12 >.el-form-item {
      min-height: 91px;
    }
    .date-number {
      width: 100%;
      display: flex;

      .el-form-item:first-child {
        width: 138% !important;

        input {
          border-radius: 4px 0 0 4px;
        }
      }

      .el-form-item:last-child {
        width: 62% !important;

        input {
          border-radius: 0 4px 4px 0;
          border-left: 0;
        }
      }
    }
  }
  .function-header-wrap {
    padding-top: 0;
  }
  ::v-deep {
    .table-row-img {
      >img {
        width: 50px;
      }
    }
  }
}
</style>
