<template>
  <div class="tray-wrap">
    <FunctionHeader
      v-model="functionHeader.searchVal"
      search-title="请输入托盘编码"
      :tags="functionHeader.tags"
      :export-name="functionHeader.exportName"
      :export-params="functionHeader.exportParams"
      @search="$refs.mTable.setTableData(true)"
    />
    <MTable
      ref="mTable"
      :columns="columns"
      :height="height"
      :set-data-method="getTableData"
    >
      <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
      <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
        <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}" />
        <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
      </div>
      <div slot="status" slot-scope="{ row }">{{ row.status ? '已绑定' : '未绑定' }}</div>
      <div slot="action" slot-scope="{ row }">
        <el-button v-if="permission('PrintCode')" type="text" @click="printCode(row)">打印托盘编码</el-button>
        <el-divider v-if="permission('PrintCode')" direction="vertical" />
        <el-button v-if="permission('Status')" type="text" @click="switchStatus(row, params.radio)">{{ row.isEnabled ? '停用' : '启用' }}</el-button>
        <el-divider v-if="permission('Status')" direction="vertical" />
        <DelPopover v-if="permission('Del')" @onOk="(callback) => del(row, callback)" />
      </div>
    </MTable>

    <MDialog v-model="visible" title="新增" @onOk="submitForm">
      <el-row :gutter="20" type="flex" align="middle" style="padding-bottom: 10px">
        <el-col :span="5" style="font-size: 16px;font-weight: 700">输入数量生成托盘:</el-col>
        <el-col :span="6">
          <el-input-number
            v-model="form.number"
            controls-position="right"
            :min="1"
            style="width: 100%"
          />
        </el-col>
        <el-col :span="6">
          <el-input
            v-model="form.unit"
            controls-position="right"
            style="width: 100%"
          />
        </el-col>
        <el-col :span="6">
          <el-button type="primary" @click="createTray">生成托盘</el-button>
        </el-col>
      </el-row>
      <MTable
        :data="trayAddData"
        :show-page="false"
        :columns="addColumns"
        :height="addHeight"
      >
        <div slot="status">未绑定</div>
        <div slot="index" slot-scope="{ $index }">{{ $index }}</div>
        <div slot="code" slot-scope="{ row }">{{ row }}</div>
        <div slot="isEnabled" class="is-enabled">
          <div style="background: #24CAD7" />
          <div>启用</div>
        </div>
      </MTable>
    </MDialog>

    <PrintCode ref="printCode" />
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import PrintCode from '@/components/PrintCode/printPackage'
import DelPopover from '@/components/DelPopover/index'
import api from '@/api/information/warehouse/tray'
import { columns, addColumns } from './columns'
import conventionMixin from '@/mixin/conventional-page'

export default {
  name: 'TrayInfo',
  components: { FunctionHeader, DelPopover, PrintCode },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      params: {
        radio: 'Pallet'
      },
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('Add'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.add
            }
          }
        ],
        exportName: 'exportTray',
        exportParams: {}
      },
      columns,
      visible: false,
      addColumns,
      form: {
        unit: '个',
        number: 1
      },
      trayAddData: [],
      searchFieldName: 'code'
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 192
    },
    addHeight() {
      return this.$store.state.app.contentHeight - 100 - 60 - 54 - 70 - 100
    }
  },
  methods: {
    add() {
      this.form = { unit: '个', number: 1 }
      this.trayAddData = []
      this.currentType = 'add'
      this.visible = true
    },
    // 生成托盘
    async createTray() {
      if (!this.form.number) {
        this.$message.info('请输入数量')
        return
      }
      const res = await api.batchCreatePallet(this.form.number)
      if (res) {
        this.trayAddData = res
      }
    },
    printCode(row) {
      this.$refs.printCode.print(row.code)
    },
    async submitForm(callback) {
      if (this.trayAddData.length === 0) {
        this.$message.info('请生成托盘')
        return
      }
      const res = await api.batchAddPallet(this.trayAddData.map(item => ({
        code: item,
        name: '',
        status: 0,
        unit: this.form.unit,
        isEnabled: 1
      })))
      if (res) {
        this.visible = false
        this.$refs.mTable.setTableData()
        callback(false)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.tray-wrap {
  padding: 10px;
.function-header-wrap {
  padding-top: 0;
}
}
</style>
