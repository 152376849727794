import { render, staticRenderFns } from "./package.vue?vue&type=template&id=31773f38&scoped=true&"
import script from "./package.vue?vue&type=script&lang=js&"
export * from "./package.vue?vue&type=script&lang=js&"
import style0 from "./package.vue?vue&type=style&index=0&id=31773f38&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31773f38",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/mubai-mom-frontend-dev/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('31773f38')) {
      api.createRecord('31773f38', component.options)
    } else {
      api.reload('31773f38', component.options)
    }
    module.hot.accept("./package.vue?vue&type=template&id=31773f38&scoped=true&", function () {
      api.rerender('31773f38', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/information/warehouse/package/package.vue"
export default component.exports