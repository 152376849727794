var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "unit-wrap common-tab" },
    [
      _c(
        "el-radio-group",
        {
          staticClass: "m-radio-group",
          on: { change: _vm.radioChange },
          model: {
            value: _vm.params.radio,
            callback: function($$v) {
              _vm.$set(_vm.params, "radio", $$v)
            },
            expression: "params.radio"
          }
        },
        [
          _vm.permission("CustomerList")
            ? _c("el-radio-button", { attrs: { label: "Customer" } }, [
                _vm._v("客户")
              ])
            : _vm._e(),
          _vm.permission("CustomerSiteList")
            ? _c("el-radio-button", { attrs: { label: "CustomerSite" } }, [
                _vm._v("现场")
              ])
            : _vm._e()
        ],
        1
      ),
      _vm.params.radio === "Customer"
        ? _c(
            "div",
            [
              _c("FunctionHeader", {
                attrs: {
                  tags: _vm.functionHeader.tags,
                  "search-title": _vm.searchTitle,
                  radio: _vm.params.radio,
                  "columns-key": _vm.columnsKey,
                  "import-name": _vm.functionHeader.importName,
                  "export-name": _vm.functionHeader.exportName,
                  "export-params": _vm.functionHeader.exportParams
                },
                on: {
                  search: function($event) {
                    return _vm.$refs.mTable.setTableData(true)
                  }
                },
                model: {
                  value: _vm.functionHeader.searchVal,
                  callback: function($$v) {
                    _vm.$set(_vm.functionHeader, "searchVal", $$v)
                  },
                  expression: "functionHeader.searchVal"
                }
              }),
              _vm.permission(_vm.params.radio + "List")
                ? _c("MTable", {
                    ref: "mTable",
                    attrs: {
                      columns: _vm.Customer,
                      height: _vm.height,
                      "columns-key": _vm.columnsKey,
                      "set-data-method": _vm.getTableData
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "index",
                          fn: function(ref) {
                            var $index = ref.$index
                            return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                          }
                        },
                        {
                          key: "isEnabled",
                          fn: function(ref) {
                            var row = ref.row
                            return _c("div", { staticClass: "is-enabled" }, [
                              _c("div", {
                                style: {
                                  background: row.isEnabled
                                    ? "#24CAD7"
                                    : "#FFCC78"
                                }
                              }),
                              _c("div", [
                                _vm._v(_vm._s(row.isEnabled ? "启用" : "停用"))
                              ])
                            ])
                          }
                        },
                        {
                          key: "action",
                          fn: function(ref) {
                            var row = ref.row
                            return _c(
                              "div",
                              {},
                              [
                                _vm.permission(_vm.params.radio + "Status")
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function($event) {
                                            return _vm.switchStatus(
                                              row,
                                              _vm.params.radio
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              row.isEnabled ? "停用" : "启用"
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.permission(_vm.params.radio + "Status")
                                  ? _c("el-divider", {
                                      attrs: { direction: "vertical" }
                                    })
                                  : _vm._e(),
                                _vm.permission(_vm.params.radio + "Modify")
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function($event) {
                                            return _vm.modifyOrCopy(
                                              row,
                                              "modify"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v("修改")]
                                    )
                                  : _vm._e(),
                                _vm.permission(_vm.params.radio + "Modify")
                                  ? _c("el-divider", {
                                      attrs: { direction: "vertical" }
                                    })
                                  : _vm._e(),
                                _vm.permission(_vm.params.radio + "Del")
                                  ? _c("DelPopover", {
                                      on: {
                                        onOk: function(callback) {
                                          return _vm.del(row, callback)
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          }
                        }
                      ],
                      null,
                      false,
                      524392618
                    )
                  })
                : _vm._e()
            ],
            1
          )
        : _c(
            "Split",
            {
              staticStyle: { "margin-top": "10px" },
              attrs: { offset: _vm.split.offset, height: _vm.splitHeight },
              on: {
                "update:offset": function($event) {
                  return _vm.$set(_vm.split, "offset", $event)
                }
              }
            },
            [
              _c("template", { slot: "left" }, [
                _c(
                  "div",
                  { staticClass: "group" },
                  [
                    _c("el-input", {
                      attrs: { placeholder: "请输入客户名称" },
                      model: {
                        value: _vm.filterText,
                        callback: function($$v) {
                          _vm.filterText = $$v
                        },
                        expression: "filterText"
                      }
                    }),
                    _c(
                      "div",
                      { staticClass: "content" },
                      _vm._l(_vm.customerList, function(item) {
                        return _c(
                          "div",
                          {
                            key: item.id,
                            staticClass: "Warehouse",
                            class: { active: item.id === _vm.currentId },
                            on: {
                              click: function($event) {
                                return _vm.onChangeCustomer(item)
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "ellipsis" }, [
                              _vm._v(_vm._s(item.name))
                            ])
                          ]
                        )
                      }),
                      0
                    )
                  ],
                  1
                )
              ]),
              _c("template", { slot: "right" }, [
                _c(
                  "div",
                  { staticClass: "model-wrap-right" },
                  [
                    _c("FunctionHeader", {
                      attrs: {
                        tags: _vm.functionHeaders.tags,
                        "search-title": _vm.searchTitle,
                        radio: _vm.params.radio,
                        "columns-key": _vm.columnsKey
                      },
                      on: { search: _vm.searchTable },
                      model: {
                        value: _vm.functionHeaders.searchVal,
                        callback: function($$v) {
                          _vm.$set(_vm.functionHeaders, "searchVal", $$v)
                        },
                        expression: "functionHeaders.searchVal"
                      }
                    }),
                    _c("MTable", {
                      ref: "mTable",
                      attrs: {
                        "default-expand-all": "",
                        "is-tree-data": true,
                        columns: _vm.CustomerSite,
                        height: _vm.height,
                        "set-data-method": _vm.getTableData,
                        "columns-key": _vm.columnsKey,
                        "show-page": false,
                        "immediate-get-data": false
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "index",
                          fn: function(ref) {
                            var $index = ref.$index
                            return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                          }
                        },
                        {
                          key: "action",
                          fn: function(ref) {
                            var row = ref.row
                            return _c(
                              "div",
                              {},
                              [
                                _vm.permission(_vm.params.radio + "Modify")
                                  ? _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function($event) {
                                            return _vm.modifys(row)
                                          }
                                        }
                                      },
                                      [_vm._v("修改")]
                                    )
                                  : _vm._e(),
                                _vm.permission(_vm.params.radio + "Modify")
                                  ? _c("el-divider", {
                                      attrs: { direction: "vertical" }
                                    })
                                  : _vm._e(),
                                _vm.permission(_vm.params.radio + "Del")
                                  ? _c("DelPopover", {
                                      on: {
                                        onOk: function(callback) {
                                          return _vm.del(row, callback)
                                        }
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ])
            ],
            2
          ),
      _c(
        "MDialog",
        {
          attrs: { title: _vm.messages[_vm.currentType] },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "formBuild",
            attrs: {
              "form-data": _vm.formData,
              "form-list": _vm.formList[_vm.params.radio]
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }