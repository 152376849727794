/** write by luomingzhi */

export const fastColumns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left'
  },
  {
    prop: 'index',
    label: '序号',
    width: 150,
    sortable: true,
    hiddenSearch: true,
    slotName: 'index'
  },
  {
    prop: 'code',
    label: '包装码',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 50
  }
]

export const historyColumns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left'
  },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true
  },
  {
    prop: 'code',
    label: '包装码',
    slotName: 'code',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'creator',
    label: '操作人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'createTime',
    label: '操作时间',
    sortable: 'custom',
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 50
  }
]
