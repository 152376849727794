import { docType } from '@/config/options.config'
import _ from 'lodash'
import { columns } from './columns'
import { transformColumnsToForm } from '@/utils'
const newColumns = _.cloneDeep(columns)

export const formList = transformColumnsToForm(newColumns)
let functionList = {}
export const getFunctionList = val => {
  functionList = val
}

export const searchFormList = [
  {
    colSpan: 5,
    key: 'mode',
    tagName: 'el-select',
    props: {
      placeholder: '批量领料方式'
    },
    on: { change: (val) => { functionList.applyMethodsChange(val) } },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: [
          {
          id:0,
          name:'按工序领料'
        },
        // {
        //   id:1,
        //   name:'按模组领料'
        // },
        {
          id:2,
          name:'按物料领料'
        },
      ]
      }
    ]
  },
      {
        colSpan: 5,
        key: 'modeSearch',
        tagName: 'el-input',
        attrs: {
          placeholder: '领料方式搜索'
        }
      },
  {
    colSpan: 5,
    key: 'receiptType',
    tagName: 'el-select',
    props: {
      placeholder: '请选择单据类型'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: docType
      }
    ]
  }
]

