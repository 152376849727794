/** write by luomingzhi */
import { processStatus, procedureClass, status, reportType } from '@/config/options.config'
const memberType = localStorage.getItem('memberType')
let functionList = {}
export const getFunctionList = val => {
  functionList = val
}
const columns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    hiddenSearch: true,
    minWidth: 80,
    slotName: 'index'
  },
  {
    prop: 'code',
    label: '工艺路线编码',
    sortable: false,
    hiddenSearch: true,
    slotName: 'code',
    minWidth: 160,
    form: {
      props: { disabled: true },
      tagName: 'el-input'
    }
  },
  {
    prop: 'name',
    label: '工艺路线名称',
    sortable: false,
    hiddenSearch: true,
    minWidth: 160,
    form: {
      tagName: 'el-input'
    }
  },
  // {
  //   prop: 'materialsFullName',
  //   label: '成品物料',
  //   sortable: false,
  //   hiddenSearch: true,
  //   minWidth: 160,
  //   directives: [
  //     {
  //       name: 'selectScrollEnd',
  //       value: () => {
  //         console.log('滚动触底了，可以请求下一页了')
  //       },
  //     }
  //   ],
  //   form: {
  //     tagName: 'el-select',
  //     key: 'materialsId',
  //     options: [],
  //     optionsProps:{
  //       label:'fullLabel',
  //       value:'id'
  //     },
  //     props: {
  //       remote: true,
  //       remoteMethod: (val) => {
  //         console.log('远程搜', val)
  //       },
  //     }
  //   },
  // },
  {
    prop: 'materialsFullName',
    label: '成品物料',
    sortable: false,
    hiddenSearch: true,
    minWidth: 160,
    directives: [
      {
        name: 'selectScrollEnd',
        value: () => {
          console.log('滚动触底了，可以请求下一页了')
        }
      }
    ],
    form: {
      tagName: 'el-select',
      key: 'materialsId',
      options: [],
      optionsProps: {
        label: 'fullLabel',
        value: 'id'
      },
      props: {
        remote: true,
        remoteMethod: (val) => {
          console.log('远程搜', val)
        }
      },
      on: {
        change: (val) => {
          functionList.searchChange(val)
        }
      }
    }
  },
  {
    prop: 'areas',
    label: '适用产线',
    sortable: false,
    hiddenSearch: true,
    minWidth: 150,
    form: {
      tagName: 'el-select',required:false,key: 'areaIdList', props: { multiple: false, collapseTags: true }, options: []
    }
  },
  {
    prop: 'type',
    label: '工艺路线属性',
    sortable: false,
    hiddenSearch: true,
    slotName: 'type',
    minWidth: 150,
    form: {
      tagName: 'el-select',
      options: procedureClass
    }
  },
  {
    prop: 'processFlowClassName',
    label: '工艺路线类型',
    sortable: false,
    hiddenSearch: true,
    minWidth: 150,
    form: {
      tagName: 'div',
      customSlots: ['processFlowClassName']
    }
  },
  {
    prop: 'processFlowClassTy',
    label: '物料分类',
    sortable: false,
    hiddenSearch: true,
    minWidth: 150
  },
  {
    prop: 'isEnabled',
    label: '工艺路线状态',
    sortable: false,
    slotName: 'isEnabled',
    hiddenSearch: true,
    minWidth: 150,
    form: {
      tagName: 'el-select',
      options: status,
      defaultValue: 1
    }
  },
  {
    prop: 'creator',
    label: '创建人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'updater',
    label: '更新人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'createTime',
    label: '创建时间',
    sortable: 'custom',
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'lastUpdateTime',
    label: '最近更新时间',
    sortable: 'custom',
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 5,
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' }
    }
  },
  {
    prop: 'isPartialOutbound',
    label: '分批出站',
    sortable: false,
    slotName: 'isPartialOutbound',
    hiddenSearch: true,
    minWidth: 150,
    form: {
      tagName: 'el-select',
      options: processStatus,
      defaultValue: 0
    }
  },
  {
    prop: 'overProductionType',
    label: '超产报工',
    sortable: false,
    slotName: 'overProductionType',
    hiddenSearch: true,
    minWidth: 150,
    form: {
      tagName: 'el-select',
      options: reportType,
      defaultValue: 0
    }
  },
  {
    prop: 'processFile',
    label: '工艺文件',
    sortable: false,
    slotName: 'processFile',
    hiddenSearch: true,
    minWidth: 150,
    form: {
      colSpan: 24,
      required: false,
      index: 6,
      tagName: 'div',
      customSlots: ['processFile']
    }
  },
  {
    prop: 'processFlowClassTy',
    label: '主版本',
    sortable: false,
    hiddenSearch: true,
    minWidth: 150
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 165
  }
]

// const memberCode = sessionStorage.getItem('memberCode')

// if (['reagold', 'bbelc'].includes(memberCode)) {
//   columns.splice(columns.length - 6, 0, {
//     prop: 'erpWarehouseName',
//     label: '工艺结束后产出物料对接ERP仓库',
//     sortable: false,
//     hiddenSearch: true,
//     form: {
//       tagName: 'el-select',
//       key: 'erpWarehouseId',
//       required: false,
//       options: [],
//       colSpan: 12
//     }
//   })
// }

export {
  columns
}
