
export const columns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left'
  },
  {
    prop: 'cycleCheckTaskNumber',
    label: '巡检单号',
    sortable: 'custom',
    hiddenSearch: true,
    slotName: 'cycleCheckTaskNumber',
    minWidth: 180
  },
  {
    prop: 'taskCheckType',
    label: '巡检类别',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'equipmentCode',
    label: '设备编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'result',
    label: '巡检结论',
    sortable: false,
    hiddenSearch: true,
    slotName: 'result'
  },
  {
    prop: 'equipmentName',
    label: '设备名称',
    sortable: false,
    hiddenSearch: true,
    minWidth: 160
  },
  {
    prop: 'equipmentClassName',
    label: '设备类',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'checkPlanNumber',
    label: '计划单号',
    sortable: false,
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'checkPlanTime',
    label: '计划日期',
    sortable: 'custom',
    hiddenSearch: true,
    minWidth: 160
  },
  {
    prop: 'checkStartTime',
    label: '实际开始',
    sortable: 'custom',
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'checkEndTime',
    label: '实际结束',
    sortable: 'custom',
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'checkSpendTime',
    label: '消耗时间（min）',
    sortable: false,
    hiddenSearch: true,
    slotName: 'checkSpendTime',
    minWidth: 160
  },
  {
    prop: 'checker',
    label: '执行人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'organizationName',
    label: '业务部门',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'status',
    label: '状态',
    sortable: false,
    hiddenSearch: true,
    slotName: 'status'
  },
  {
    prop: 'pointCheckItem',
    label: '巡检项',
    sortable: false,
    hiddenSearch: true,
    slotName: 'pointCheckItem'
  },
  {
    prop: 'createTime',
    label: '创建时间',
    sortable: 'custom',
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'creator',
    label: '创建人',
    sortable: false,
    hiddenSearch: true
  }
]
