import { Customer, CustomerSite } from './columns'
import { transformColumnsToForm } from '@/utils'
const news = [...transformColumnsToForm(CustomerSite)]
news.splice(1, 0, {
  index: 1,
  label: '上级',
  key: 'parentId',
  tagName: 'el-select',
  required: false,
  props: {
    clearable: true
  },
  children: [
    {
      tagName: 'el-option',
      props: {
        label: 'name',
        value: 'id'
      }
    }
  ]
})
const formList = {
  CustomerSite: news,
  Customer: transformColumnsToForm(Customer)
}
export default formList
