<template>
  <div class="materials-wrap common-tab">
    <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange" style="display:none;">
      <el-radio-button v-if="permission('MaterialsUnitList')" label="MaterialsUnit">单位定义</el-radio-button>
      <el-radio-button v-if="permission('MaterialsTypeList')" label="MaterialsType">物料类型</el-radio-button>
      <el-radio-button v-if="permission('MaterialsQualityList')" label="MaterialsQuality">物料材质</el-radio-button>
      <el-radio-button v-if="permission('MaterialsAttributeList')" label="MaterialsAttribute">物料属性</el-radio-button>
    </el-radio-group>
    <FunctionHeader
      v-model="functionHeader.searchVal"
      :tags="functionHeader.tags"
      :search-title="searchTitle"
      :radio="params.radio"
      :columns-key="columnsKey"
      :import-name="functionHeader.importName"
      :export-name="functionHeader.exportName"
      :export-params="functionHeader.exportParams"
      @search="$refs.mTable.setTableData(true)"
    />
    <MTable
      v-if="permission(params.radio + 'List')"
      ref="mTable"
      :columns="columns[params.radio]"
      :height="height"
      :columns-key="columnsKey"
      :set-data-method="getTableData"
    >
      <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
      <div slot="isSystem" slot-scope="{ row }">{{ row.isSystem ? '内置' : '自建' }}</div>
      <template v-for="(key, index) in attributeSlotKeys" :slot="key" slot-scope="{ row }">
        <el-switch
          :key="key"
          v-model="row[key]"
          active-color="#44D7B6"
          inactive-color="#DCDFE6"
          :active-value="1"
          :inactive-value="0"
          @change="(val) => { attributeChange(row, key, val) }"
        />
        <span :key="index" class="l10" :style="{ color: row[key] ? '#393D60' : '#DDE3EB' }">{{ row[key] ? '启用' : '停用' }}</span>
      </template>
      <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
        <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}" />
        <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
      </div>
      <div slot="action" slot-scope="{ row }">
        <el-button v-if="permission(params.radio + 'Status')" type="text" @click="switchStatus(row, params.radio)">{{ row.isEnabled ? '停用' : '启用' }}</el-button>
        <el-divider v-if="permission(params.radio + 'Status') && !row.isSystem" direction="vertical" />
        <el-button v-if="permission(params.radio + 'Modify') && !row.isSystem" type="text" @click="modifyOrCopy(row, 'modify')">修改</el-button>
        <el-divider v-if="permission(params.radio + 'Modify') && !row.isSystem" direction="vertical" />
        <DelPopover v-if="permission(params.radio + 'Del') && !row.isSystem" @onOk="(callback) => del(row, callback)" />
      </div>
    </MTable>

    <MDialog v-model="visible" :title="messages[currentType]" @onOk="submitForm">
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList[params.radio]" />
    </MDialog>
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import DelPopover from '@/components/DelPopover/index'
import columns from './columns'
import formList from './form-list'
import api from '@/api/information/materials-attribute'
import conventionMixin from '@/mixin/conventional-page'

export default {
  name: 'MaterialsAttribute',
  components: { FunctionHeader, DelPopover },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      params: {
        radio: 'MaterialsUnit'
      },
      functionHeader: {
        searchVal: '',
        tags: [],
        importName: 'materialsUnit',
        exportName: 'exportMaterialsUnit',
        exportParams: {}
      },
      tags: [
        {
          hidden: !this.permission('MaterialsUnitAdd'),
          tagName: 'el-button',
          props: { type: 'primary', size: 'small' },
          style: { width: 'auto', background: '#607FFF' },
          innerText: '新增',
          on: {
            click: this.add
          }
        }
      ],
      columns,
      visible: false,
      formList: formList,
      formData: {},
      currentType: 'add',
      searchTitle: '请输入单位名称',
      attributeSlotKeys: ['isPurchasable', 'isProducible', 'isMarketable']
    }
  },
  created(){
    this.params.radio = this.$route.meta.nameFlage ? this.$route.meta.nameFlage : 'MaterialsUnit'
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 10 + 40
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    }
  },
  mounted() {
    this.functionHeader.tags = this.tags
    if(this.params.radio === 'MaterialsAttribute'){
      this.functionHeader.tags = []
      this.functionHeader.exportName = ''
      this.functionHeader.importName = ''
    }
    if(this.params.radio === 'MaterialsQuality' || this.params.radio === 'MaterialsType'){
      this.functionHeader.exportName = ''
      this.functionHeader.importName = ''
    }
  },
  methods: {
    radioChange(label) {
      const flag = label === 'MaterialsUnit'
      Object.assign(this.functionHeader, {
        importName: flag ? 'materialsUnit' : '',
        exportName: flag ? 'exportMaterialsUnit' : ''
      })
      this.functionHeader.tags = this.tags
      switch (this.params.radio) {
        case 'MaterialsUnit':
          this.searchTitle = '请输入单位名称'
          break
        case 'MaterialsType':
          this.searchTitle = '请输入类型名称'
          break
        case 'MaterialsQuality':
          this.searchTitle = '请输入材质名称'
          break
        case 'MaterialsAttribute':
          this.searchTitle = '请输入属性名称'
          this.functionHeader.tags = []
          break
        default:
          break
      }
      if (this.functionHeader.tags[0]) {
        this.functionHeader.tags[0].hidden = !this.permission(label + 'Add')
      }
      this.$refs.mTable.setTableData(true)
    },
    // 物料属性switch切换状态
    async attributeChange(row, key, val) {
      const res = await api.modifyMaterialsAttribute({
        id: row.id,
        [key]: val
      })
      if (res) {
        this.$message.success('状态切换成功')
        this.$refs.mTable.setTableData()
      }
    },
    createSetData(raw) {
      let returnObj = {}
      switch (this.params.radio) {
        case 'MaterialsAttribute':
          returnObj = {
            name: raw.name,
            isMarketable: raw.isMarketable || 0,
            isProducible: raw.isProducible || 0,
            isPurchasable: raw.isPurchasable || 0,
            isEnabled: 1,
            remark: raw.remark
          }
          break
        default:
          returnObj = {
            name: raw.name,
            isEnabled: raw.isEnabled,
            isSystem: raw.isSystem || 0,
            remark: raw.remark
          }
          break
      }
      return returnObj
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    }
  }
}
</script>

<style scoped lang="scss">
.materials-wrap {
  padding: 10px;
}
</style>
