<template>
  <div class="scrap-wrap common-tab">
    <el-radio-group v-model="changeParam" class="m-radio-group" @change="radioChange">
      <el-radio-button v-if="permission('CommonList')" label="Common">普通</el-radio-button>
      <el-radio-button v-if="permission('ReturnFixList') && memberType === 'line'" label="ReturnFix">返修</el-radio-button>
    </el-radio-group>
    <div class="top">
      <SearchForm
        ref="searchForm"
        :form-list-extend="searchFormList"
        :form-data="searchFormData"
        :default-form="defaultSearchFormData"
        @search="searchTable"
      />
    </div>
    <div class="bottom">
      <FunctionHeader
        ref="functionHeader"
        v-model="functionHeader.searchVal"
        search-title="请输入工艺名称/编码"
        :tags="!exportSelectionIds.length ? functionHeader.tags : []"
        :radio="changeParam"
        :export-name="functionHeader.exportName"
        :columns-key="columnsKey"
        :export-params="functionHeader.exportParams"
        :show-export="!exportSelectionIds.length ? true : false"
        @search="$refs.mTable.setTableData(true)"
      >
        <div
          slot="all"
          v-show="exportSelectionIds.length"
        >
          <el-button v-if="permission('CommongylxImport')" size="small" type="primary"
                     @click="showImport = true">工时导入
          </el-button>
          <el-button v-if="permission('CommongylxExport')" type="primary" size="small"
                     @click="downloadTemplate">
            工时导出
          </el-button>

          <el-button v-if="permission(changeParam + 'Export')" size="small" type="primary"
                     @click="allMore('batchExport')">批量导出
          </el-button>
          <el-button v-if="permission(changeParam + 'Del')" type="primary" size="small" @click="allMore('batchDel')">
            批量删除
          </el-button>
          <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
          已选
          <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
          / {{ total }}项
        </span>
          <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
        </div>
      </FunctionHeader>
      <MTable
        v-if="permission(changeParam + 'List')"
        ref="mTable"
        :columns="getColumns"
        :height="height"
        :columns-key="columnsKey"
        :set-data-method="getTableData"
        @selection-change="selectionChange"
      >
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <!-- <div slot="type" slot-scope="{ row }">{{ typeName[row.type] }}</div> -->
        <NewPageOpen
          slot="code"
          slot-scope="{ row }"
          :path="getDetailPath(row, 'view')"
          :text="row.code"
        />
        <div v-if="row.areaList" slot="areaIdList" slot-scope="{ row }">
          <el-tag
            v-for="item in row.areaList"
            :key="item.id"
            color="#F6F7F9"
            class="role-tags"
          >{{ item.name }}
          </el-tag>
        </div>
        <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
          <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}"/>
          <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
        </div>
        <div slot="isPartialOutbound" slot-scope="{ row }" class="is-enabled">
          <div :style="{background: row.isPartialOutbound ? '#24CAD7' : '#FFCC78'}"/>
          <div>{{ row.isPartialOutbound ? '启用' : '禁用' }}</div>
        </div>
        <div slot="overProductionType" slot-scope="{ row }" class="is-enabled">
          <div :style="{background: row.overProductionType ? '#24CAD7' : '#FFCC78'}"/>
          <div>{{ +row.overProductionType === 1 ? '启用' : '禁用' }}</div>
        </div>
        <div slot="type" slot-scope="{ row }" class="is-enabled">
          <span>{{ procedureClass[row.type].name }}</span>
        </div>
        <div slot="action" slot-scope="{ row }">
          <NewPageOpen
            v-if="permission(changeParam + 'Copy')"
            :path="getDetailPath(row, 'copy')"
            text="复制"
          />
          <el-divider v-if="permission(changeParam + 'Copy')" direction="vertical"/>
          <el-button
            v-if="permission(changeParam + 'Status')"
            type="text"
            @click="switchStatus(row, params.radio)"
          >{{ row.isEnabled ? '停用' : '启用' }}
          </el-button>
          <el-divider v-if="permission(changeParam + 'Status')" direction="vertical"/>
          <el-dropdown @command="(command) => { more(row, command) }">
            <span class="el-dropdown-link">
              更多<i class="el-icon-arrow-down el-icon--right"/>
            </span>
            <el-dropdown-menu slot="dropdown" class="td-dropdown-menu">
              <el-dropdown-item
                v-if="permission(changeParam + 'Modify')"
                command="modify"
              >修改
              </el-dropdown-item>
              <el-dropdown-item
                v-if="permission(changeParam + 'Del')"
                command="del"
              >删除
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </MTable>

      <MDialog v-model="visible" :title="messages[currentType]" @onOk="submitForm">
        <MFormBuilder ref="formBuild" :form-data="formData" :form-list="getFormList">
          <div slot="processFlowClassName">
            <CURDSelect
              :default-value="formData.processFlowClassId"
              :default-value-index="0"
              api-name="ProcessType"
              @change="selectName"
            />
          </div>
          <div slot="processFile">
            <ProcessFile ref="ProcessFile" :base-list="formData.processFiles" :read-only="false"/>
          </div>
        </MFormBuilder>
      </MDialog>

      <BatchImport
        :visible.sync="showImport"
        import-name="工时导入"
        :show-cover="false"
      />
    </div>
  </div>
</template>

<script>
import { columns, getFunctionList } from './columns'
import { formList, searchFormList } from './form-list'
import api from '@/api/information/production/process'
import stationApi from '@/api/information/production/station'
import conventionMixin from '@/mixin/conventional-page'
import batchExportFront from '@/mixin/batch-export-front'
import FunctionHeader from '@/components/FunctionHeader/index'
import SearchForm from '@/components/SearchForm/SearchForm'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import { procedureClass } from '@/config/options.config'
import CURDSelect from '@/components/CURDSelect/CURDSelect'
import processApi from '@/api/information/production/process'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import produceApi from '@/api/information/production/procedure'
import ProcessFile from './detail/ProcessFile.vue'
import BatchImport from '@/components/BatchImport/indexFSD.vue'
import requestForAsync from '@/utils/requestForAsync'
import dayjs from 'dayjs'

export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'processList',
  components: { NewPageOpen, CURDSelect, SearchForm, FunctionHeader, ProcessFile,BatchImport },
  mixins: [conventionMixin, batchExportFront],
  data() {
    return {
      api,
      showImport: false,
      isProcess: false,
      procedureClass,
      params: {
        radio: 'Process'
      },
      changeParam: 'Common',
      columns,
      searchFormList,
      searchFormData: {
        procedureId: ''
      },
      defaultSearchFormData: {
        procedureId: ''
      },
      searchFormatData: [],
      functionHeader: {
        searchVal: '',
        tags: [
          {

            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增工艺路线',
            on: {
              click: this.add
            }
          },
          {
            hidden: !this.permission('CommongylxImport'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '工时导入',
            on: {
              click: () => {this.showImport = true}
            }
          },
          {
            hidden: !this.permission('CommongylxExport'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '工时导出',
            on: {
              click: this.downloadTemplate
            }
          },
        ],
        exportName: 'exportProcess',
        exportParams: {}
      },
      formList,
      formData: {},
      messages: {
        add: '新增',
        modify: '修改',
        copy: '复制'
      },
      currentType: 'add',
      visible: false,
      stationVisible: false,
      stationList: [],
      getTableDataParams: { isGetAreaList: 1 },
      formOptions: {
        areaOptions: []
        // erpWarehouseOptions: []
      },
      extraParams: [{
        id: getUUid(),
        fieldName: 'type',
        fieldType: 'number',
        fieldValue: 0,
        operator: 'eq'
      }],
      searchKeyword: true,
      defaultSearch: {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      },
      procedureId: undefined,
      commonSelect: [],
      returnFixSelect: [],
      commonProcedureId: '',
      commonProcedureIds: '',
      materialParams: { status: 0, page: 1, size: 20, search: '', materialsId: '' },
      total: 0
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'refresh') {
      this.$store.dispatch('tagsView/addCachedView', from)
    }
    next()
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 65
    },
    columnsKey() {
      return this.$route.name + this.changeParam
    },
    getSelectionDataLength() {
      const selectionDataNoDisabled = this.exportSelectionList.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    },
    memberType(){
      return localStorage.getItem('memberType')
    },
    getColumns() {
      if (localStorage.getItem('memberType') === 'line') {
        return this.columns
      } else {
        return this.columns.filter(item => {
          return item.prop !== 'isPartialOutbound' && item.prop !== 'overProductionType'
        })
      }
    },
    getFormList(){
      if (localStorage.getItem('memberType') === 'line') {
        this.formList[4].children[0].options = this.procedureClass
        return this.formList
      } else {
        this.formList[4].children[0].options = [
          {
            id: 0,
            name: '普通'
          }
        ]
        return this.formList.filter(item => {
          return item.key !== 'isPartialOutbound' && item.key !== 'overProductionType'
        })
      }
    }
    // needErp () {
    //   return ['reagold', 'bbelc'].includes(sessionStorage.getItem('memberCode'))
    // },
  },
  watch: {
    'materialParams.search'(val) {
      this.formList[2].children[0].options.length = 0
      this.searchFormList[3].children[0].options.length = 0
      this.materialParams.page = 1
      this.$nextTick(() => {
        this.getFinishedMaterials(this.materialParams)
      })
    },
    visible(val) {
      this.formList[2].children[0].options.length = 0
      this.searchFormList[3].children[0].options.length = 0
      !val ? this.materialParams = { status: 0, page: 1, size: 20, search: '', materialsId: '' }
        : this.materialParams = {
          status: 1,
          page: 1,
          size: 20,
          search: '',
          materialsId: this.materialParams.materialsId
        }
      if (!this.materialParams.materialsId) this.getFinishedMaterials(this.materialParams)
    }
  },
  beforeMount() {
    getFunctionList({
      searchChange: this.searchChange
    })
  },
  created() {
    // 绑定成品物料下拉加载指令
    this.searchFormList[3].directives[0].value = this.finishedMaterialLoadMore
    // 绑定成品物料远程搜索
    this.searchFormList[3].props.remoteMethod = (val) => {
      this.materialParams.search = val
    }
    this.getFinishedMaterials(this.materialParams)

    // 绑定弹窗物料选择下拉加载指令
    this.formList[2].directives[0].value = this.finishedMaterialLoadMore
    // 绑定弹窗物料成品物料远程搜索
    this.formList[2].props.remoteMethod = (val) => this.materialParams.search = val

    this.getProcedureList()
    this.procedureId = this.$route.query.procedureId ? +this.$route.query.procedureId : undefined
    this.defaultSearchFormData.procedureId = this.procedureId
    this.searchFormData.procedureId = this.procedureId
    this.commonProcedureIds = this.procedureId
  },
  mounted() {
    this.getTableDataParams = {
      isGetAreaList: 1,
      procedureId: this.procedureId
    }
    this.getAreaList()
    this.getProcedureTypeList()
    // if (this.needErp) {
    //   this.getErpWarehouseList()
    // }
  },
  methods: {
    async downloadTemplate() {
      const arr = this.exportSelectionIds || []
      const res = await requestForAsync.exportPost("api/web/v1/basicData/production/private/procedureDetails/exportProcedureDetails",arr)
      if (res && res.data.byteLength) {
        const urlP = window.URL.createObjectURL(
          new Blob([res.data], {
            type: 'application/octet-stream;charset=ISO8859-1'
          })
        )
        const fileName = `工时导出${dayjs().format('YYYYMMDDHHmmss')}.xls`
        this.downloadFile(urlP, fileName)
      }
    },
    downloadFile(urlP, name) {
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = urlP
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    resetSelectData() {
      this.exportSelectionList.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.exportSelectionList = this.exportSelectionList.filter((item) => item.selectable)
    },
    searchChange(val) {
      if (!val) {
        this.materialParams.search = ''
        this.materialParams.materialsId = ''
        this.formList[2].children[0].options = []
        this.getFinishedMaterials(this.materialParams)
      }
    },
    // 成品物料下拉触底分页加载
    finishedMaterialLoadMore() {
      if (!this.visible) {
        if (this.searchFormList[3].children[0].options.length === this.materialsTotal) return
        if (this.searchFormList[3].children[0].options.length > this.materialsTotal) return [...this.searchFormList[3].children[0].options = new Set(this.searchFormList[3].children[0].options)]
      } else {
        if (this.formList[2].children[0].options.length === this.materialsTotal) return
        if (this.formList[2].children[0].options.length > this.materialsTotal) return [...this.formList[2].children[0].options = new Set(this.formList[2].children[0].options)]
      }
      this.materialParams.page++
      this.getFinishedMaterials(this.materialParams)
    },
    // 获取成品物料
    async getFinishedMaterials(params) {
      const res = await processApi.getFinishedMaterials(params)
      if (res) {
        this.materialsTotal = res.total
        !this.visible
          ? this.searchFormList[3].children[0].options.push(...res.records || [])
          : this.formList[2].children[0].options.push(...res.records || [])
      }
    },

    // 获取工序
    async getProcedureList() {
      const res = await produceApi.getProcedureList(this.defaultSearch)
      if (res) {
        this.searchFormList[2].children[0].options = res
      }
    },
    // 获取工艺路线类型
    async getProcedureTypeList() {
      const res = await api.getProcedureTypeList()
      if (res) {
        this.searchFormList[1].children[0].options = res
      }
    },
    // 获取ERP仓库
    // async getErpWarehouseList () {
    //   const res = await processApi.getErpWarehouseList()
    //   if (res) {
    //     this.formOptions.erpWarehouseOptions = res
    //     this.formList[this.formList.length - 3].children[0].options = res
    //   }
    // },
    selectName(item) {
      this.$refs.formBuild.setForm({
        processFlowClassId: item.id,
        processFlowClassName: item.name
      })
    },
    radioChange() {
      if (this.changeParam === 'Common') {
        this.extraParams = [{
          id: getUUid(),
          fieldName: 'type',
          fieldType: 'number',
          fieldValue: 0,
          operator: 'eq'
        }]
        this.searchFormatData = this.commonSelect
        this.procedureId = this.commonProcedureId
      }
      if (this.changeParam === 'ReturnFix') {
        this.extraParams = [{
          id: getUUid(),
          fieldName: 'type',
          fieldType: 'number',
          fieldValue: 1,
          operator: 'eq'
        }]
        this.searchFormatData = this.returnFixSelect
        this.procedureId = this.commonProcedureIds
      }
      const list = this.searchFormatData.length > 0 ? this.searchFormatData.filter(item => item.fieldName === 'isEnabled') : []
      const list1 = this.searchFormatData.length > 0 ? this.searchFormatData.filter(item => item.fieldName === 'processFlowClassId') : []
      this.$refs.searchForm.form = {
        procedureId: this.procedureId,
        isEnabled: list.length > 0 ? list[0].fieldValue : '',
        processFlowClassId: list1.length > 0 ? list1[0].fieldValue : ''
      }
      this.$refs.searchForm.isProcess = !this.procedureId
      this.functionHeader.searchData = {
        searchVal: Encrypt(JSON.stringify([
            ...this.extraParams
          ])
        )
      }
      this.getTableDataParams = {
        isGetAreaList: 1,
        procedureId: this.procedureId
      }

      this.exportSelectionIds = []
      this.$refs.mTable.getCheckedColumns()
      this.$refs.mTable.setTableData(true)
      this.$refs.mTable.tableRefs().clearSelection()
      // this.$refs.searchForm.reset()
    },
    // 获取产线
    async getAreaList() {
      const res = await api.getAreaList({
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }, {
            id: getUUid(),
            fieldName: 'type',
            fieldType: 'number',
            fieldValue: 2,
            operator: 'eq'
          }]
        ))
      })
      if (res) {
        this.formOptions.areaOptions = res
        this.formList[3].children[0].options = res
      }
    },
    add() {
      const isType = this.changeParam === 'Common' ? 0 : 1
      this.$router.push({ name: 'processDetail', query: { type: 'add', isType: isType, addType: 'normal' } })
    },
    getDetailPath(row, command) {
      const isType = this.changeParam === 'Common' ? 0 : 1
      return { name: 'processDetail', query: { id: row.id, type: command, isType: isType, addType: 'normal' } }
    },
    modifyOrCopy(row, command) {
      const isType = this.changeParam === 'Common' ? 0 : 1
      this.$router.push({ name: 'processDetail', query: { id: row.id, type: command, isType: isType } })
    },
    modify(row, command) {
      row.processFiles = row.processFiles || []
      if (row.areaIdList && row.areaIdList.length > 0) {
        row.areaIdList = row.areaIdList[0]
      }
      this.formData = row
      this.currentType = command
      this.materialParams = { status: 1, page: 1, size: 20, search: '', materialsId: row.materialsId }
      this.getFinishedMaterials(this.materialParams)
      this.visible = true
    },
    // 更多操作
    more(row, command) {
      const functions = {
        copy: () => {
          this.modifyOrCopy(row, command)
        },
        modify: () => {
          this.modify(row, command)
        },
        view: () => {
          this.modifyOrCopy(row, command)
        },
        del: () => {
          this.del(row)
        }
      }
      functions[command]()
    },
    async viewStation(row) {
      const res = await stationApi.getStationListByProcedureId(row.id)
      if (res) {
        if (!res.length) return this.$message.info('暂无可执行工位')
        this.stationList = res
        this.stationVisible = true
      }
    },
    searchTable(data, val) {
      if (val === 1) {
        this.procedureId = ''
        this.searchFormatData = []
        this.$refs.searchForm.isProcess = true
        this.$refs.searchForm.form = {
          procedureId: '',
          isEnabled: '',
          processFlowClassId: ''
        }
      } else {
        if (data.length > 0) {
          const datas = data.filter(item => item.fieldName === 'procedureId')
          this.procedureId = datas.length > 0 ? datas[0].fieldValue : ''
          this.searchFormatData = data.filter(item => item.fieldName !== 'procedureId')
        } else {
          this.procedureId = ''
          this.searchFormatData = []
        }
      }
      this.getTableDataParams = {
        isGetAreaList: 1,
        procedureId: this.procedureId
      }
      if (this.changeParam === 'Common') {
        this.commonSelect = this.searchFormatData
        this.commonProcedureId = this.procedureId
      } else {
        this.returnFixSelect = this.searchFormatData
        this.commonProcedureIds = this.procedureId
      }
      this.$refs.mTable.setTableData(true)
    },
    createResetData(raw) {
      // let currentErp = {}
      // if (this.needErp && raw.erpWarehouseId && raw.erpWarehouseId !== -1) {
      //   currentErp = this.formOptions.erpWarehouseOptions.find(item => item.id === raw.erpWarehouseId)
      // }
      return {
        id: raw.id,
        name: raw.name,
        code: raw.code,
        isEnabled: raw.isEnabled,
        areaIdList: raw.areaIdList ? (Array.isArray(raw.areaIdList) ? raw.areaIdList : [raw.areaIdList]) : [],
        erpWarehouseId: -1,
        // erpWarehouseCode: currentErp.code || '',
        // erpWarehouseName: currentErp.name || '',
        type: raw.type,
        processFlowClassId: raw.processFlowClassId,
        processFlowClassName: raw.processFlowClassName,
        remark: raw.remark,
        isPartialOutbound: raw.isPartialOutbound,
        materialsId: raw.materialsId,
        overProductionType: raw.overProductionType
      }
    }
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}

.scrap-wrap {
  width: 100%;
  padding-top: 10px;

  .bottom {
    padding: 10px;
  }

  .m-radio-group {
    margin-left: 10px;
  }
}

.role-tags {
  margin: 5px;
  border-color: #dde3eb;
  color: #9597ae;
}

::v-deep {
  .e-divider {
    margin: 0px 0 0 0;
  }
}
</style>
