<template>
    <div class="materials-wrap">
        <div class="materials-detail-header">
      <div class="left" @click="back()">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px;cursor: pointer">BOM详情</span>
      </div>
    </div>
      <div class="bottom">
        <Split :offset.sync="split.offset" :height="splitHeight" >
          <template slot="left">
            <div v-show="isOpenOne" style="height:calc(100% - 40px)">
              <BOMLevel :mySelfShowFlag="false" @nodeClick="setMaterialsClassId" @changeWidth="changeWidths" :materialsBomId="materialsBomId" :type="'bom'"/>
            </div>
            <div class="divFixed" v-show="isOpenOne" @click="changeWidths(false)" title="点击收起" style="cursor: pointer;"><i class="el-icon-d-arrow-left"></i></div>
            <div class="divFixed" v-show="!isOpenOne" @click="changeWidths(true)" title="点击展开" style="cursor: pointer;"><i class="el-icon-d-arrow-right"></i></div>
          </template>
          <template slot="right">
            <div class="materials-info">
              <FunctionHeader
                ref="functionHeader"
                v-model="functionHeader.searchVal"
                search-title="请输入名称/编号/规格"
                :tags="functionHeader.tags"
                :import-name="functionHeader.importName"
                :export-name="functionHeader.exportName"
                :export-params="functionHeader.exportParams"
                @search="$refs.mTable.setTableData(true)"
              >
              </FunctionHeader>
              <MTable ref="mTable" :columns="columnsDetail" :height="height" :set-data-method="getTableData" @selection-change="selectionChange">
                <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
                <div slot="drawing" slot-scope="{ row }">
                  <el-image
                    v-if="row.picture"
                    :src="row.picture"
                    class="drawing-img"
                    :preview-src-list="[row.picture]"
                  />
                </div>
                <NewPageOpen v-if="isShowChild" slot="code" slot-scope="{ row }" :path="{ name: 'bomDetail', query: { id: row.id, type: 'view' }}" :text="row.code" />
                <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
                  <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}" />
                  <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
                </div>
                <div slot="action" slot-scope="{ row }">
                  <NewPageOpen v-if="permission('Modify')" :path="{ name: 'materialsDetail', query: { id: row.id, type: 'modify' }}" text="编辑" />
                </div>
              </MTable>
            </div>
          </template>
        </Split>
      </div>
    </div>
  </template>

  <script>
  import FunctionHeader from '@/components/FunctionHeader'
  import SearchForm from '@/components/SearchForm/SearchForm'
  import { columnsDetail } from './columns'
  import maApi from '@/api/information/materials-attribute'
  import api from '@/api/information/bom'
  import { getUUid } from '@/utils'
  import { Encrypt } from '@/utils/sercet'
  import Split from '@/components/Split/Split'
  import BOMLevel from '@/components/BOMClass/BOMLevel'
  import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
  import batchExportFront from '@/mixin/batch-export-front'
  import fixCommonPage from '@/mixin/fix-common-page'

  export default {
    name: 'bomDetail',
    components: { NewPageOpen, BOMLevel, Split, FunctionHeader, SearchForm },
    mixins: [batchExportFront, fixCommonPage],
    data() {
      return {
        isOpenOne:true,
        functionHeader: {
          searchVal: '',
          tags: [],
          // importName: 'materials',
          importName: '',
          exportName: 'exportBomLevelDownMList',
          exportParams: {}
        },
        columnsDetail,
        searchFormData: {},
        searchFormatData: [],
        split: {
          offset: 28
        },
        materialsClassId: '',
        defaultSearch: {
          searchVal: Encrypt(JSON.stringify(
            [{
              id: getUUid(),
              fieldName: 'isEnabled',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq'
            }]
          ))
        },
        materialsBomId:+this.$route.query.id,
      }
    },
    computed: {
      height() {
        return this.$store.state.app.contentHeight - 235 - 39
      },
      splitHeight() {
        return this.$store.state.app.contentHeight - 235 + 70 - 39
      }
    },
    beforeRouteLeave(to, from, next) {
      if (to.name !== 'refresh') {
        this.$store.dispatch('tagsView/addCachedView', from)
      }
      next()
    },
    mounted() {
    },
    methods: {
    changeWidths(val){
        this.split.offset = val ? 28 : 3
      this.isOpenOne =  val ? true : false
    },
      back() {
        // this.$router.back()
        this.$router.push({ name: 'bomList'})
      },
      setMaterialsClassId(id) {
        this.materialsClassId = id
        this.$refs.mTable.setTableData(true)
      },
      // 获取物料类型
      async getMaterialsTypeList() {
        const res = await maApi.getMaterialsTypeList(this.defaultSearch)
        if (res) {
          this.searchFormList[1].children[0].options = res
        }
      },
    //   // 获取物料属性
    //   async getMaterialsAttributeList() {
    //     const res = await maApi.getMaterialsAttributeList()
    //     if (res) {
    //       this.searchFormList[2].children[0].options = res
    //     }
    //   },
      // 获取物料数据
      async getTableData(condition, callback) {
        if(this.materialsClassId){
          const { page, search, order } = condition
        const searchCondition = {
          materialsBomLevelId: this.materialsClassId,
          search: this.functionHeader.searchVal
        }
        this.functionHeader.exportParams = searchCondition
        const res = await api.getBomLevelDownList({
          page: page.currentPage,
          size: page.pageSize,
          materialsBomLevelId:this.materialsClassId,
          ...searchCondition
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
        }
        callback()
        }else{
          callback()
        }

      },
      // 查询物料
      searchTable(data) {
        this.searchFormatData = data
        this.$refs.mTable.setTableData(true)
      },
      add() {
        this.$router.push({ name: 'materialsDetail', query: { type: 'add' }})
      },
      // 更多操作
      more(row, command) {
        const functions = {
          modify: () => {
            this.modifyOrCopy(row, command)
          },
          copy: () => {
            this.modifyOrCopy(row, command)
          },
          del: () => {
            this.del(row)
          },
          switch: () => {
            this.switch(row)
          }
        }
        functions[command]()
      },
      modifyOrCopy(row, command) {
        this.$router.push({ name: 'materialsDetail', query: { id: row.id, type: command }})
      },
      async batchDel() {
        const res = await api.delBatchMaterials(this.exportSelectionIds)
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.tableRefs().clearSelection()
          this.$refs.mTable.setTableData(true)
        }
      },
      // 删除物料
      del(row) {
        this.currentType = 'del'
        this.$confirm('您确定要删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          const res = await api.delMaterials({ id: row.id })
          if (res) {
            this.$message.success('删除成功')
            this.$refs.mTable.setTableData()
          }
        })
      },
      // 更改物料状态
      async switch(row) {
        const res = await api.switchStatus(row.isEnabled ? 'disable' : 'enable', 'materials', row.id)
        if (res) {
          this.$message.success(`${row.name}${row.isEnabled ? '停用' : '启用'}成功`)
          this.$refs.mTable.setTableData()
        }
      },
      viewDetail(row) {
        this.$message.info('敬请期待！')
      },
      onSuccess() {
        this.$refs.mTable.setTableData()
      }
    }
  }
  </script>

  <style scoped lang="scss">
  .function-header-wrap {
    padding-top: 0;
  }

  .divFixed {
    position: absolute;
    bottom: 0;
    text-align: end;
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding-right: 10px;
    background: #d3deea;
    box-sizing: border-box;
  }

  .materials-wrap {
    width: 100%;
    .materials-detail-header {
    width: 100%;
    padding: 10px 0;
  }
    .bottom {
      width: 100%;
      height: 100%;
      padding: 10px;
      background: #fff;
      .materials-info {
        padding: 10px;
      }
    }
  }
  .drawing-img {
    width: 50px;
    height: 48px;
    display: block;
    cursor: pointer;
    color: #fff;
  }
  ::v-deep {
    .params-card {
      .body {
        padding: 0 !important;
      }
      .params {
        width: 100% !important;
      }
      .el-form-item__label {
        padding: 0 12px 0 0 !important;
      }
    }
  }
  </style>
