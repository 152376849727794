<template>
  <div class="detail-container">
    <div class="container-left">
      <div class="orderAdd">
        <div class="detail-card" style="margin-bottom:20px">
          <div class="card-tit">基本信息</div>
          <div class="card-bot">
            <el-form ref="detail" label-position="right" label-width="90px" class="demo-form-inline">
              <!-- 详情 -->
              <el-row>
                <el-col v-for="item in detailBaseFields" :key="item.key" :span="8">
                  <el-form-item :label="item.name">
                    <div>{{ getDetailVal(item.key) }}</div>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
        <div v-if="expTaskNeedHandleMsg.id" class="detail-card" style="margin-bottom:20px">
          <div class="card-tit">异常判定</div>
          <div class="card-bot">
            <el-form ref="detail" label-position="right" label-width="90px" class="demo-form-inline">
              <el-row>
                <el-col :span="8">
                  <el-form-item
                    :label="expTaskNeedHandleMsg.mastHaveValue.includes('异常原因') ? '*异常原因' :'异常原因'">
                    <el-select v-if="expTaskNeedHandleMsg.editFieldList.includes('异常原因')
                    && !expTaskNeedHandleMsg.showFlag"
                               v-model="expReasonId" placeholder="请选择异常原因">
                      <el-option
                        v-for="item in expReasonList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                      </el-option>
                    </el-select>
                    <el-input v-else :disabled="true"
                              v-model="detail.abnormalReasonName"
                              clearable>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item
                    :label="expTaskNeedHandleMsg.mastHaveValue.includes('原因分析') ? '*原因分析' :'原因分析'">
                    <el-input
                      :disabled="!expTaskNeedHandleMsg.editFieldList.includes('原因分析') || expTaskNeedHandleMsg.showFlag"
                      :placeholder="expTaskNeedHandleMsg.editFieldList.includes('原因分析') ? '请输入原因分析' : '-'"
                      v-model="detail.reason"
                      clearable>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
        <div v-if="expTaskNeedHandleMsg.id" class="detail-card" style="margin-bottom:20px">
          <div class="card-tit">异常处理</div>
          <div class="card-bot">
            <el-form ref="detail" label-position="right" label-width="90px" class="demo-form-inline">
              <el-row>
                <el-col :span="8">
                  <el-form-item
                    :label="expTaskNeedHandleMsg.mastHaveValue.includes('处理措施') ? '*处理措施' :'处理措施'">
                    <el-input
                      :disabled="!expTaskNeedHandleMsg.editFieldList.includes('处理措施') || expTaskNeedHandleMsg.showFlag"
                      :placeholder="expTaskNeedHandleMsg.editFieldList.includes('处理措施') ? '请输入处理措施' : '-'"
                      v-model="detail.tempPolicy"
                      clearable>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
        <div v-if="expTaskNeedHandleMsg.id"
             class="detail-card" style="margin-bottom:20px">
          <div class="card-tit">{{
              expTaskNeedHandleMsg.mastHaveValue.includes('拍照上传') ? '*拍照上传' : '拍照上传'
            }}
          </div>
          <div class="card-bot">
            <el-form ref="detail" label-position="right" label-width="90px" class="demo-form-inline">
              <el-row v-if="expTaskNeedHandleMsg.editFieldList.includes('拍照上传')">
                <el-col :span="8">
                  <el-upload
                    class="b20"
                    type="primary"
                    v-bind="uploadProps"
                  >
                    <el-button
                      size="small"
                      type="primary"
                      @click="uploadKey = 'drawingsList'"
                    >上传图片
                    </el-button
                    >
                  </el-upload>
                </el-col>
              </el-row>
              <el-row style="margin-top: 3px">
                <el-col :span="2" v-for="(yp,index) in drawingsList" style="position: relative;margin-right: 20px">
                  <div v-if="expTaskNeedHandleMsg.editFieldList.includes('拍照上传')" class="deleteA"
                       @click="deleteImg(index)">×
                  </div>
                  <img :src="yp.url"
                       style="width: 80px;height: 80px;cursor: pointer"
                       @click="windowOpenImg(yp.url)"/>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
        <el-tabs
          v-model="activeName"
          class="detail-card detail-tabs"
          type="border-card"
          @tab-click="tabClick"
        >
          <el-tab-pane label="详细信息" name="1">
            <TimeLine :base-data="moreMsgDetail"/>
          </el-tab-pane>
          <el-tab-pane label="涉及项目" name="2">
            <MTable ref="mTable" :columns="detailColumn_3" :height="500" :data="detail.projectList" :show-page="false">
              <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
              <div slot="projectName" slot-scope="{row}">{{ row.projectName.split('/')[1] }}</div>
            </MTable>
          </el-tab-pane>
          <el-tab-pane label="涉及物料" name="3">
            <MTable
              ref="mTable"
              :columns="detailColumn_2"
              :height="500"
              :data="detail.materialsList"
              :show-page="false"
            >
              <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
            </MTable>
          </el-tab-pane>
        </el-tabs>
        <div class="order-footer">
          <div/>
          <div class="footer-ri">
            <el-button @click="returnList">返 回</el-button>
            <template v-if="expTaskNeedHandleMsg.id && !expTaskNeedHandleMsg.showFlag"
                      v-for="btn in expTaskNeedHandleMsg.buttonNameList">
              <el-button type="primary" @click="onChangeStatus(0)">
                {{ btn }}
              </el-button>
            </template>
            <el-button :loading="buttonLoading" v-if="expTaskNeedHandleMsg.id && !expTaskNeedHandleMsg.showFlag"
                       type="primary" @click="onChangeStatus(1)">通 过
            </el-button>
            <el-button :loading="buttonLoading" v-if="expTaskNeedHandleMsg.id && expTaskNeedHandleMsg.showFlag"
                       type="primary" @click="callTaskCancel">撤 回
            </el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="container-right">
      <div style="height: 100vh;width: 94%;box-sizing: border-box;margin-left: 6%;background: #FFFFFF;padding: 20px">
        <h3>流程日志</h3>
        <div style="width: 100%;max-height: 80vh;overflow-y: scroll">
          <el-steps direction="vertical" :active="50" space="140px">
            <el-step :title="itemP.endTime" v-for="(itemP,index) in processList"
                     :icon="itemP.endTime ? 'el-icon-success' : 'el-icon-error'">
              <div slot="description">
                <div class="description"
                     :style="{paddingRight:index === processList.length - 1 ? '10%' : '0'}">
                  <div style="border: none;font-weight: bold;color: #000000">{{ itemP.taskName }}</div>
                  <div>
                    {{ itemP.assignee }}
                    <span v-if="itemP.comment"> : {{ itemP.comment }}</span>
                  </div>
                </div>
              </div>
            </el-step>
          </el-steps>
        </div>
      </div>
    </div>

    <el-dialog title="评论" :visible.sync="dialogFormVisible">
      <el-form>
        <el-form-item label-width="300">
          <el-input v-model="comment" autocomplete="off" placeholder="请输入评论"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmFin">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import api from '@/api/call/search'
import expApi from '@/api/quality/expection-task'
import {
  detailBaseFields,
  detailColumn_1,
  detailColumn_2,
  detailColumn_3,
  emergencyDegreeMap,
  handleStatusMap,
  finalResultMap
} from './data'
import TimeLine from './comp/TimeLine.vue'
import { getToken } from '@/utils/auth'

export default {
  name: 'ExceptionDetail',
  components: { TimeLine },
  data() {
    return {
      dialogFormVisible: false,
      detailColumn_1,
      detailColumn_2,
      detailColumn_3,
      activeName: '1',
      finishedMaterials: [],
      materialParams: { status: 0, page: 1, size: 20, search: '' },
      areaList: [],
      detailBaseFields,
      detail: {
        baseData: [],
        workOrderList: [],
        materialsList: [],
        projectList: []
      },
      buttonLoading: false,
      detailOriginal: {},
      moreMsgDetail: [],
      processList: [],
      expTaskNeedHandleMsg: {},
      expReasonList: [],
      expReasonId: '',//异常原因id
      comment: '',//评论
      uploadProps: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        showFileList: false,
        onSuccess: this.fileUploadChange,
        onError: this.fileUploadChange,
        beforeUpload: this.beforeUpload
      },
      uploadKey: 'drawingsList',
      drawingsList: [],
      btnName: ''
    }
  },
  computed: {},
  async created() {
    //如果有needInfo字段 说明是走异常任务列表跳转过来的
    if (this.$route.query.needInfo) {
      this.expTaskNeedHandleMsg = JSON.parse(decodeURIComponent(this.$route.query.needInfo))
      await this.getReasonArr(this.expTaskNeedHandleMsg.abnormalTypeId)
      consoleSelf.info('原始数据', this.expTaskNeedHandleMsg)
    }
    this.getDetail()
  },
  methods: {
    deleteImg(index) {
      this[this.uploadKey].splice(index, 1)
    },
    windowOpenImg(url) {
      window.open(url)
    },
    // 上传图纸/附件
    fileUploadChange(res, file) {
      if (res.errorCode === 0) {
        this.$message.success('上传成功')
        this[this.uploadKey].push({
          id: file.uid,
          name: file.name,
          url: res.result
        })
      } else {
        this.$message.error(res.result)
      }
    },
    beforeUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isLt20M) {
        this.$message.error('上传文件大小不能超过 20M!')
      }
      return isLt20M
    },
    async getReasonArr(abnormalTypeId) {
      const res = await expApi.getAbnormalReasonArr(abnormalTypeId)
      this.expReasonList = res || []
    },
    tabClick() {

    },
    showInfo() {
      console.log(this.drawingsList)
    },
    callTaskCancel() {
      this.$confirm('确定要撤回吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
         return expApi.callTaskCancel(this.expTaskNeedHandleMsg.taskId)
      }).then(res => {
        if(res){
          this.$message.success('撤回成功')
          this.$router.push({ name: 'ExceptionTaskList' })
        }
      })
    },
    onChangeStatus(status) {
      // 判断必填数据是否有值 ————————Start————————
      {
        if (this.expTaskNeedHandleMsg.mastHaveValue.includes('异常原因')) {
          if (!this.expReasonId) {
            this.$message.warning('请选择异常原因')
            return false
          }
        }
        if (this.expTaskNeedHandleMsg.mastHaveValue.includes('原因分析')) {
          if (!this.detail.reason) {
            this.$message.warning('请输入原因分析')
            return false
          }
        }
        if (this.expTaskNeedHandleMsg.mastHaveValue.includes('处理措施')) {
          if (!this.detail.tempPolicy) {
            this.$message.warning('请输入处理措施')
            return false
          }
        }
        if (this.expTaskNeedHandleMsg.mastHaveValue.includes('拍照上传')) {
          if (!this[this.uploadKey].length) {
            this.$message.warning('请上传图片')
            return false
          }
        }
      }
      // 判断必填数据是否有值 ————————End————————
      const that = this
      status == 1 ? this.btnName = '通过' : this.btnName = '驳回'
      that.dialogFormVisible = true
    },
    async confirmFin() {
      const that = this
      if (this.btnName === '驳回') {
        if (!this.comment) {
          this.$message.warning('请输入评论')
          return false
        }
      }
      that.buttonLoading = true
      const resUpdate = await this.updateMsg()
      if (resUpdate) {
        // 流程中的通过/驳回
        const resBtn = await expApi.btnProcess({
          taskId: that.expTaskNeedHandleMsg.taskId,
          buttonName: that.btnName,
          comment: that.comment
        })
        that.buttonLoading = false
        if (resBtn) {
          this.$message.success('操作成功')
          this.$router.push({ name: 'ExceptionTaskList' })
        } else {
          return false
        }
      } else {
        that.buttonLoading = false
        return false
      }
    },
    // 先更新数据
    async updateMsg() {
      // 处理更新 ————————Start————————
      {
        const updateMsg = {
          ...this.detailOriginal
        }
        //物料和项目
        let materialsIdsArr = this.detail.influenceScopeList.filter(k => k.key === 'materials')
        let projectIdsArr = this.detail.influenceScopeList.filter(k => k.key === 'project')
        let materialsIds = materialsIdsArr.map(item => {
          return item.content.map(h => h.id)
        })
        let projectIds = projectIdsArr.map(item => {
          return item.content.map(h => h.projectId)
        })
        updateMsg.materialsIds = materialsIds[0] || []
        updateMsg.projectIds = projectIds[0] || []
        //异常原因
        if (this.expReasonId) {
          const p = this.expReasonList.filter(item => item.id == this.expReasonId)
          updateMsg.abnormalReasonId = p[0].id
          updateMsg.abnormalReasonCode = p[0].code
          updateMsg.abnormalReasonName = p[0].name
        }
        updateMsg.reason = this.detail.reason
        updateMsg.tempPolicy = this.detail.tempPolicy

        let imgUrl = ''
        const that = this
        this.drawingsList.forEach((k, i) => {
          if (i === that.drawingsList.length - 1) {
            imgUrl += k.url
          } else {
            imgUrl += k.url + ','
          }
        })
        updateMsg.judgePictures = imgUrl
        const res = await expApi.updateCall(updateMsg)
        return res
      }
      // 处理更新 ————————End————————
    },
    getDetailVal(key) {
      if (key === 'emergencyDegree') return emergencyDegreeMap[this.detail[key]]
      if (key === 'status') return handleStatusMap[this.detail[key]]
      if (key === 'finalResult') return finalResultMap[this.detail[key]]
      return this.detail[key] || '-'
    },

    // 获取详情
    async getDetail() {
      let res = {}
      if (this.expTaskNeedHandleMsg.id) {
        res = await api.detailApp({ id: this.$route.query.id })
      } else {
        res = await api.detail({ id: this.$route.query.id })
      }
      if (res) {
        this.detail = res
        if (this.detail.abnormalReasonId) {
          this.expReasonId = res.abnormalReasonId
        }
        if (this.detail.judgePictures) {
          this.drawingsList = res.judgePictures.split(',').map(s => {
            return {
              url: s
            }
          })
        }
        this.detailOriginal = JSON.parse(JSON.stringify(res))
        this.processList = res.flowableHistoryTaskList || []
        this.processList.forEach(item => {
          if (!item.endTime) {
            item.endTime = ''
          }
        })
        // 处理详细信息 ————————Start————————
        const abnormalInitiate = {
          'key': 'abnormalInitiate',
          'label': '异常发起',
          'content': [
            {
              'abnormalRemark': res.abnormalRemark,
              'pictures': res.callPictures || ''
            }
          ]
        }

        const abnormalJudge = {
          'key': 'abnormalJudge',
          'label': '异常判定',
          'content': [
            {
              'abnormalReasonName': res.abnormalReasonName || '',
              'reason': res.reason
            }
          ]
        }

        const handleProcess = {
          'key': 'handleProcess',
          'label': '处理过程',
          'content': [
            {
              'tempPolicy': res.tempPolicy,
              'pictures': res.judgePictures || ''
            }
          ]
        }
        // 处理详细信息 ————————End————————
        this.moreMsgDetail.push(abnormalInitiate, abnormalJudge, handleProcess)
      }
    },

    returnList() {
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
.detail-container {
  width: 100%;
  height: 100%;
  display: flex;
  box-sizing: border-box;

  .container-left {
    width: 75%;
    height: 100%;
    box-sizing: border-box;
  }

  .container-right {
    width: 25%;
    height: 100%;
    box-sizing: border-box;

    .description {
      margin-top: 8px;

      div {
        line-height: 40px;
        background: #f5f7f9;
        padding-left: 10px;
        color: #67687d;
        border-bottom: 1px solid #e4e6e8;
        font-size: 14px;
      }
    }
  }
}

::v-deep {
  .el-tag {
    margin-right: 10px;
    margin-bottom: 5px;
  }

  .el-form-item {
    margin-bottom: 20px;

    .el-form-item__label {
      // color: rgb(149, 151, 174);
    }
  }

  .el-date-editor .el-range-separator,
  .el-date-editor .el-range__icon {
    line-height: 26px;
  }

  .el-input-number {
    height: 32px;
    line-height: 32px;
    width: 100%;
  }

  .el-input__inner {
    height: 32px;
    line-height: 32px;
  }

  .el-input-number.is-controls-right .el-input-number__increase,
  .el-input-number.is-controls-right .el-input-number__decrease {
    line-height: 15px;
  }

  .el-tag .el-icon-close {
    position: absolute;
    cursor: pointer;
    font-size: 12px;
    height: 16px;
    width: 16px;
    line-height: 16px;
    vertical-align: middle;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
  }
}

.el-tags {
  padding: 0 20px 0 10px;
  position: relative;
}

.orderAdd {
  width: 100%;
  height: 100%;
  //  position:relative;
  .input-new-tag {
    width: 130px;
  }

  .button-new-tag {
    height: 32px;
  }

  .detail-card {
    margin-bottom: 20px;
  }

  .e-btn {
    margin-bottom: 10px;
  }

  .order-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    background: #fff;
    border-top: 1px solid #dde3eb;

    .footer-le {
      padding-left: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;

      span {
        margin-right: 45px;
        display: inline-block;
      }
    }

    .footer-ri {
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .deleteA {
    text-align: end;
    font-size: 30px;
    cursor: pointer;
    color: #4f77d9;
    font-weight: bold;
    transition: 0.2s;
    position: absolute;
    right: 0;
    top: -20px;
  }

  .deleteA:hover {
    transform: scale(1.2);
  }
}
</style>
