<template>
  <div>
    <!--        工艺路线-->
    <div v-if="type ==='process'">
      <div v-if="data.processFlowVersionId">
        <div class="process-info">
          工艺路线：{{ baseFormData.name }}
          <el-tag size="small">{{ processFlowVersion.version }}</el-tag>
        </div>

        <div class="process-detail-body">
          <div class="detail-card">
            <div class="card-tit">基本信息</div>
            <div class="card-bot">
              <el-row class="view-list" :gutter="24">
                <!--            暂时这么处理距离问题-->
                <el-col
                  v-for="(item, index) in columns.base"
                  :key="item.prop"
                  class="ellipsis"
                  :title="getValue(item)"
                  :span="6"
                  :style="{marginBottom: index !== 4 ? '20px' : 0 }"
                >
                  <span>{{ item.label }}：</span>
                  <span>{{ getValue(item) }}</span>
                </el-col>
              </el-row>
            </div>
          </div>

          <div class="detail-card" style="margin-top: 10px">
            <div class="card-tit">工艺路线图</div>
            <div class="card-bot process-card">
              <ProcessFlow ref="processFlow" :read-only="true" :concise-process-flow="true" :process-flow-detail="processFlowVersion" />
            </div>
          </div>
        </div>
      </div>
      <div v-else style="padding-top: 50px">
        <MBlank title="请前往物料配置配置工艺！" />
      </div>
    </div>
    <!--        图纸及附件-->
    <div v-if="type ==='drawAndFile'">
      <div v-if="isShowDrawAndFile" class="detail-card">
        <div class="card-bot">
          <div class="b20 flex-sbc">
            <img src="@/assets/information/procedure/图纸@2x.png" style="width: 18px">
            <span class="l10" style="position: relative;top: 2px">图纸</span>
          </div>
          <br>
          <MTable
            ref="mTable"
            :show-page="false"
            :height="300"
            :columns="columns.files"
            :data="JSON.parse(data.drawing)"
          >
            <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
            <div slot="fileName" slot-scope="{ row }">
              <a :href="row.url" :download="row.name" target="_blank" style="color: #607FFF">{{ row.name }}</a>
              <img src="@/assets/information/procedure/附件@2x.png" style="width: 11px;margin-left: 5px">
            </div>
          </MTable>

          <div class="bt20 flex-sbc">
            <img src="@/assets/information/procedure/其他附件@2x.png" style="width: 18px">
            <span class="l10" style="position: relative;top: 2px">其他附件</span>
          </div>
          <br>
          <MTable
            ref="mTable"
            :show-page="false"
            :height="300"
            :columns="columns.files"
            :data="JSON.parse(data.attachment)"
          >
            <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
            <div slot="fileName" slot-scope="{ row }">
              <a :href="row.url" :download="row.name" target="_blank" style="color: #607FFF">{{ row.name }}</a>
              <img src="@/assets/information/procedure/附件@2x.png" style="width: 11px;margin-left: 5px">
            </div>
          </MTable>
        </div>
      </div>
      <div v-else style="padding-top: 50px">
        <MBlank title="请前往物料配置配置图纸及附件！" />
      </div>
    </div>
  </div>
</template>

<script>
import { base } from '@/views/information/production/process/detail/columns'
import ProcessFlow from '@/components/ProcessFlow/ProcessFlow'
import processApi from '@/api/information/production/process'
export default {
  name: 'StaticInfo',
  components: { ProcessFlow },
  props: {
    type: {
      type: String,
      required: true,
      default: ''
    },
    data: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data() {
    return {
      baseFormData: {},
      processFlowVersion: {},
      columns: {
        base,
        files: [{
          prop: 'index',
          label: '序号',
          slotName: 'index',
          width: 80,
          hiddenSearch: true
        },
        {
          prop: 'name',
          label: '名称',
          slotName: 'fileName',
          sortable: false,
          hiddenSearch: true
        }]
      }
    }
  },
  computed: {
    isShowDrawAndFile() {
      const drawFlag = this.data.drawing && JSON.parse(this.data.drawing).length
      const attachmentFlag = this.data.attachment && JSON.parse(this.data.attachment).length
      return drawFlag || attachmentFlag
    }
  },
  mounted() {
    if (this.type === 'process' && this.data.processFlowVersionId) {
      this.getProcessVersionDetail()
    }
  },
  methods: {
    async getProcessVersionDetail() {
      const res = await processApi.getProcessDetail({
        id: this.data.processFlowId,
        versionId: this.data.processFlowVersionId
      })
      if (res) {
        this.baseFormData = res
        this.processFlowVersion = res.processFlowVersion
      }
    },
    getValue(row) {
      const result = this.baseFormData[row.prop]
      if (row.form && row.form.options && row.prop !== 'areas') {
        const current = row.form.options.find(item => item.id === result)
        return current && current.name
      }
      return result
    }
  }
}
</script>

<style scoped lang="scss">
.card-bot {
  padding: 20px 0 0 0;
}
.process-info {
  display: flex;
  align-items: center;

  .el-tag {
    border-color: #DDE3EB;
    background: #F6F7F9;
    color: #9597AE;
    margin: 0 20px;
  }

  button {
    img {
      width: 16px;
      position: relative;
      top: 1px;
    }
  }
}
</style>
