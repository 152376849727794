var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "materials-detail-wrapper" }, [
    _c("div", { staticClass: "materials-detail-header flex-sbc" }, [
      _c(
        "div",
        {
          staticClass: "left",
          on: {
            click: function($event) {
              return _vm.back()
            }
          }
        },
        [
          _c("img", {
            staticStyle: { width: "8px" },
            attrs: {
              src: require("@/assets/information/procedure/左滑@2x.png")
            }
          }),
          _c(
            "span",
            { staticStyle: { "padding-left": "10px", cursor: "pointer" } },
            [_vm._v(_vm._s(_vm.title))]
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "right" },
        [
          _vm.permission("materialsListModify", false)
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.buttonLoading },
                  on: { click: _vm.switchOrSubmit }
                },
                [_vm._v(" " + _vm._s(_vm.saveButton ? "保存" : "编辑") + " ")]
              )
            : _vm._e()
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "materials-detail-body" },
      [
        _c("div", { staticClass: "detail-card" }, [
          _c("div", { staticClass: "card-tit" }, [_vm._v("物料基本信息")]),
          _c(
            "div",
            { staticClass: "card-bot", staticStyle: { padding: "15px" } },
            [
              _vm.saveButton
                ? _c(
                    "MFormBuilder",
                    {
                      ref: "baseForm",
                      attrs: {
                        "form-list": _vm.baseFormList,
                        "form-data": _vm.baseFormData
                      }
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "picture" }, slot: "picture" },
                        [
                          _c(
                            "el-upload",
                            _vm._b(
                              {
                                staticClass: "avatar-uploader",
                                attrs: {
                                  accept: ".png,.jpg",
                                  "before-upload": _vm.beforeAvatarUpload,
                                  "on-success": _vm.handleAvatarSuccess
                                }
                              },
                              "el-upload",
                              _vm.options,
                              false
                            ),
                            [
                              _vm.materialsImageUrl
                                ? _c("img", {
                                    staticClass: "avatar",
                                    attrs: { src: _vm.materialsImageUrl }
                                  })
                                : _c("i", {
                                    staticClass:
                                      "el-icon-plus avatar-uploader-icon"
                                  })
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _c(
                    "div",
                    {
                      staticStyle: {
                        display: "inline-flex",
                        "justify-content": "space-between",
                        width: "100%"
                      }
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { width: "200px" } },
                        [
                          !_vm.baseFormData.name
                            ? _c("div")
                            : _c("el-image", {
                                staticStyle: { "border-radius": "4px" },
                                attrs: {
                                  fit: "contain",
                                  src: _vm.baseFormData.picture || _vm.empty,
                                  "preview-src-list": [
                                    _vm.baseFormData.picture || _vm.empty
                                  ]
                                }
                              })
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        {
                          staticClass: "view-list",
                          staticStyle: { padding: "10px 15px", flex: "1" },
                          attrs: { gutter: 24 }
                        },
                        _vm._l(_vm.columns, function(item, index) {
                          return _c(
                            "el-col",
                            {
                              key: item.prop,
                              style: { marginBottom: index !== 5 ? "20px" : 0 },
                              attrs: { span: 6 }
                            },
                            [
                              _c("span", { staticClass: "bot-tis" }, [
                                _vm._v(_vm._s(item.label) + "：")
                              ]),
                              _c("span", { staticClass: "bot-tis" }, [
                                _vm._v(_vm._s(_vm.getValue(item)))
                              ])
                            ]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          )
        ]),
        _c("EditMaterialsCopy", { ref: "editMaterialsCopy" })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }