import { docType, materialsRequisitionStatus,receiptStatus } from '@/config/options.config'
export const searchFormList = [
  {
    colSpan: 5,
    key: 'receiptNumber',
    tagName: 'el-input',
    attrs: {
      placeholder: '工单编号'
    }
  },
  {
    colSpan: 5,
    key: 'receiptName',
    tagName: 'el-input',
    attrs: {
      placeholder: '工单名称'
    }
  },
  // {
  //   colSpan: 5,
  //   key: 'receiptType',
  //   tagName: 'el-select',
  //   props: {
  //     placeholder: '请选择单据类型'
  //   },
  //   children: [
  //     {
  //       tagName: 'el-option',
  //       props: {
  //         label: 'name',
  //         value: 'id'
  //       },
  //       options: docType
  //     }
  //   ]
  // },
  {
    colSpan: 5,
    key: 'directionType',
    tagName: 'el-select',
    props: {
      placeholder: '方向',
      clearable: false
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: receiptStatus
      }
    ]
  },
  {
    colSpan: 4,
    key: 'receiptStatus',
    tagName: 'el-select',
    props: {
      placeholder: '状态'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: materialsRequisitionStatus
      }
    ]
  },
  {
    colSpan: 5,
    key: 'projectNumber',
    tagName: 'el-input',
    attrs: {
      placeholder: '项目编号'
    }
  },
  {
    colSpan: 5,
    key: 'projectName',
    tagName: 'el-input',
    attrs: {
      placeholder: '项目名称'
    }
  }
]
