var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "orderAdd" }, [
    _c("div", { staticClass: "detail-card" }, [
      _c("div", { staticClass: "card-tit" }, [_vm._v("工作日历")]),
      _c(
        "div",
        {
          staticClass: "card-bot",
          staticStyle: { display: "flex", "margin-bottom": "60px" }
        },
        [
          _c(
            "div",
            {
              staticClass: "form",
              staticStyle: { width: "260px", "flex-shrink": "0" }
            },
            [
              ["modify", "add", "copy"].includes(_vm.$route.query.type)
                ? _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      attrs: {
                        rules: _vm.rules,
                        model: _vm.ruleForm,
                        "label-width": "80px"
                      }
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "日历编号", prop: "code" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入",
                              disabled: _vm.type === "modify"
                            },
                            model: {
                              value: _vm.ruleForm.code,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleForm, "code", $$v)
                              },
                              expression: "ruleForm.code"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "日历名称", prop: "name" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入" },
                            model: {
                              value: _vm.ruleForm.name,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleForm, "name", $$v)
                              },
                              expression: "ruleForm.name"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "班次列表", prop: "shiftIds" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择", multiple: "" },
                              on: { change: _vm.changeShifts },
                              model: {
                                value: _vm.ruleForm.shiftIds,
                                callback: function($$v) {
                                  _vm.$set(_vm.ruleForm, "shiftIds", $$v)
                                },
                                expression: "ruleForm.shiftIds"
                              }
                            },
                            _vm._l(_vm.shiftList, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "默认日历", prop: "defaultCalendar" }
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.ruleForm.defaultCalendar,
                                callback: function($$v) {
                                  _vm.$set(_vm.ruleForm, "defaultCalendar", $$v)
                                },
                                expression: "ruleForm.defaultCalendar"
                              }
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("是")
                              ]),
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("否")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "备注" } },
                        [
                          _c("el-input", {
                            attrs: { type: "textarea" },
                            model: {
                              value: _vm.ruleForm.remark,
                              callback: function($$v) {
                                _vm.$set(_vm.ruleForm, "remark", $$v)
                              },
                              expression: "ruleForm.remark"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      attrs: {
                        "label-position": "right",
                        model: _vm.ruleForm,
                        "label-width": "85px"
                      }
                    },
                    _vm._l(_vm.detailBaseFields, function(item) {
                      return _c(
                        "el-form-item",
                        { key: item.key, attrs: { label: item.name } },
                        [
                          _c("div", [
                            _vm._v(_vm._s(_vm.getDetailVal(item.key)))
                          ])
                        ]
                      )
                    }),
                    1
                  )
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "padding-left": "10px" } },
            [
              _c("ElCalendar", {
                on: { changeTime: _vm.changeTime },
                scopedSlots: _vm._u([
                  {
                    key: "dateCell",
                    fn: function(ref) {
                      var data = ref.data
                      return [
                        _c(
                          "div",
                          {
                            class: { isTodayC: data.day === _vm.getToday() },
                            staticStyle: {
                              height: "32px",
                              "line-height": "32px",
                              "margin-bottom": "4px"
                            }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  parseInt(
                                    data.day
                                      .split("-")
                                      .slice(2)
                                      .join("-")
                                  )
                                ) +
                                "日 "
                            )
                          ]
                        ),
                        _vm._l(_vm.shiftDetailList[data.day], function(
                          item,
                          index
                        ) {
                          return _c(
                            "div",
                            {
                              key: item.id,
                              staticStyle: {
                                "border-radius": "4px",
                                "margin-bottom": "6px"
                              },
                              style: {
                                background:
                                  index % 2 === 0 ? "#D9E3F1" : "#DBD9F1"
                              }
                            },
                            [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "right",
                                    trigger: "hover",
                                    "popper-class": "pover-tips"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "noplan",
                                      attrs: { slot: "reference" },
                                      slot: "reference"
                                    },
                                    [_vm._v(_vm._s(item.frequencyName))]
                                  ),
                                  _c("div", [
                                    _c("div", { staticClass: "title" }, [
                                      _vm._v(_vm._s(item.frequencyName))
                                    ]),
                                    _c("div", { staticClass: "content" }, [
                                      _vm._v(
                                        "开始时间：" +
                                          _vm._s(item.workHoursStartTime)
                                      )
                                    ]),
                                    _c("div", { staticClass: "content" }, [
                                      _vm._v(
                                        "结束时间：" +
                                          _vm._s(item.workHoursEndTime)
                                      )
                                    ]),
                                    _c("div", { staticClass: "content" }, [
                                      _vm._v(
                                        "重复：" +
                                          _vm._s(
                                            _vm.findperiodDate(item.periodDate)
                                          )
                                      )
                                    ])
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ]
      )
    ]),
    _c("div", { staticClass: "order-footer" }, [
      _c("div"),
      _c(
        "div",
        { staticClass: "footer-ri" },
        [
          _c("el-button", { on: { click: _vm.returnList } }, [
            _vm._v(_vm._s(this.type !== "view" ? "取 消" : "返 回"))
          ]),
          this.type !== "view"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.save }
                },
                [_vm._v("保 存")]
              )
            : _vm._e()
        ],
        1
      )
    ]),
    _c("div", { staticClass: "lines" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }