var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "orderAdd" }, [
    _c("div", { staticClass: "detail-card" }, [
      _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
      _c(
        "div",
        { staticClass: "card-bot" },
        [
          ["modify", "addOrder", "copy"].includes(_vm.$route.query.type)
            ? _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-form-inline",
                  attrs: {
                    "label-position": "right",
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "85px"
                  }
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "班次编号", prop: "code" } },
                            [
                              _c("el-input", {
                                attrs: {
                                  placeholder: "请输入",
                                  disabled: _vm.type === "modify"
                                },
                                model: {
                                  value: _vm.ruleForm.code,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "code", $$v)
                                  },
                                  expression: "ruleForm.code"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "班次名称", prop: "name" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请输入" },
                                model: {
                                  value: _vm.ruleForm.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "name", $$v)
                                  },
                                  expression: "ruleForm.name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "开始时间", prop: "startTime" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  format: "yyyy-MM-dd",
                                  placeholder: "开始时间"
                                },
                                model: {
                                  value: _vm.ruleForm.startTime,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "startTime", $$v)
                                  },
                                  expression: "ruleForm.startTime"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "结束时间", prop: "endTime" } },
                            [
                              _c("el-date-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "date",
                                  "value-format": "yyyy-MM-dd",
                                  format: "yyyy-MM-dd",
                                  placeholder: "结束时间"
                                },
                                model: {
                                  value: _vm.ruleForm.endTime,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "endTime", $$v)
                                  },
                                  expression: "ruleForm.endTime"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 6 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "周期设置", prop: "period" } },
                            [
                              _c(
                                "el-select",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.ruleForm.period,
                                      expression: "ruleForm.period"
                                    }
                                  ],
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择"
                                  },
                                  model: {
                                    value: _vm.ruleForm.periodDate,
                                    callback: function($$v) {
                                      _vm.$set(_vm.ruleForm, "periodDate", $$v)
                                    },
                                    expression: "ruleForm.periodDate"
                                  }
                                },
                                _vm._l(_vm.cycleList, function(item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.ruleForm.periodDate === 4,
                              expression: "ruleForm.periodDate === 4"
                            }
                          ],
                          attrs: { span: 6 }
                        },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "工作日设置", prop: "workdays" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    display: "flex",
                                    "justify-content": "space-between",
                                    height: "33px"
                                  }
                                },
                                _vm._l(_vm.weekday, function(item, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: item.id,
                                      staticStyle: {
                                        "border-radius": "100%",
                                        width: "30px",
                                        height: "30px",
                                        "text-align": "center",
                                        cursor: "pointer",
                                        "flex-shrink": "0"
                                      },
                                      style: {
                                        background: item.isShowBg
                                          ? "#607FFF"
                                          : "",
                                        color: item.isShowBg ? "#FFF" : ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.changeIndex(index)
                                        }
                                      }
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  )
                                }),
                                0
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "工作时段", prop: "time" } },
                            [
                              _c("el-time-picker", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "is-range": "",
                                  "range-separator": "至",
                                  "start-placeholder": "开始时间",
                                  "end-placeholder": "结束时间",
                                  placeholder: "选择时间范围",
                                  "value-format": "HH:mm",
                                  format: "HH:mm",
                                  clearable: false
                                },
                                model: {
                                  value: _vm.ruleForm.time,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "time", $$v)
                                  },
                                  expression: "ruleForm.time"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "备注" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "" },
                                model: {
                                  value: _vm.ruleForm.remark,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "remark", $$v)
                                  },
                                  expression: "ruleForm.remark"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-form-inline",
                  attrs: {
                    "label-position": "right",
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-width": "96px"
                  }
                },
                [
                  _c(
                    "el-row",
                    {
                      staticStyle: { "flex-flow": "row wrap" },
                      attrs: { type: "flex", justify: "start" }
                    },
                    _vm._l(_vm.getDetailBaseFields, function(item) {
                      return _c(
                        "el-col",
                        {
                          key: item.key,
                          attrs: { span: item.key != "workdays" ? 6 : 7 }
                        },
                        [
                          _c("el-form-item", { attrs: { label: item.name } }, [
                            item.key != "workdays"
                              ? _c("div", [
                                  _vm._v(_vm._s(_vm.getDetailVal(item.key)))
                                ])
                              : _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "space-between",
                                        height: "33px"
                                      }
                                    },
                                    _vm._l(_vm.weekday, function(items) {
                                      return _c(
                                        "div",
                                        {
                                          key: items.id,
                                          staticStyle: {
                                            "border-radius": "100%",
                                            width: "30px",
                                            height: "30px",
                                            "text-align": "center",
                                            "flex-shrink": "0"
                                          },
                                          style: {
                                            background: items.isShowBg
                                              ? "#607FFF"
                                              : "",
                                            color: items.isShowBg ? "#FFF" : ""
                                          }
                                        },
                                        [_vm._v(_vm._s(items.name))]
                                      )
                                    }),
                                    0
                                  )
                                ])
                          ])
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
        ],
        1
      )
    ]),
    _c("div", { staticClass: "order-footer" }, [
      _c("div"),
      _c(
        "div",
        { staticClass: "footer-ri" },
        [
          _c("el-button", { on: { click: _vm.returnList } }, [
            _vm._v(_vm._s(this.type !== "view" ? "取 消" : "返 回"))
          ]),
          this.type !== "view"
            ? _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.save }
                },
                [_vm._v("保 存")]
              )
            : _vm._e()
        ],
        1
      )
    ]),
    _c("div", { staticClass: "lines" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }