<template>
  <div class="model-wrap">
    <FunctionHeader
      v-model="functionHeader.searchVal"
      search-title="请输入区域编码/名称"
      :export-params="functionHeader.exportParams"
      :tags="functionHeader.tags"
      :export-name="functionHeader.exportName"
      @search="$refs.mTable.setTableData(true)"
    />
    <MTable
      ref="mTable"
      default-expand-all
      :is-tree-data="true"
      :columns="columns"
      :height="height"
      :show-page="false"
      :set-data-method="getTableData"
    >
      <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
      <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
        <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}" />
        <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
      </div>
      <div slot="type" slot-scope="{ row }">{{ areaTypeName[row.type] }}</div>
      <div slot="action" slot-scope="{ row }">
        <!-- <el-button v-if="![1,2].includes(row.type) && permission('AddChild')" type="text" @click="addChild(row)">新建下级</el-button>
        <el-button v-else type="text" disabled>新建下级</el-button>
        <el-divider v-if="permission('AddChild')" direction="vertical" />
        <el-button v-if="permission('Status')" type="text" @click="switchStatus(row, params.radio)">{{ row.isEnabled ? '停用' : '启用' }}</el-button>
        <el-divider v-if="permission('Status')" direction="vertical" />
        <el-dropdown v-if="!row.isSystem" @command="(command) => { more(row, command) }">
          <span class="el-dropdown-link">
            更多<i class="el-icon-arrow-down el-icon--right" />
          </span>
          <el-dropdown-menu slot="dropdown" class="td-dropdown-menu">
            <el-dropdown-item v-if="permission('Modify')" command="modify">修改</el-dropdown-item>
            <el-dropdown-item v-if="permission('Copy')" command="copy">复制</el-dropdown-item>
            <el-dropdown-item v-if="permission('Del')" command="del">删除</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown> -->

        <!-- 新的 ————————Start———————— -->
        <el-button v-if="![2].includes(row.type) && permission('AddChild')" type="text" @click="addChild(row)">新建下级</el-button>
        <el-button v-else type="text" disabled>新建下级</el-button>
        <el-divider v-if="permission('AddChild')" direction="vertical" />

        <el-button v-if="permission('Copy')" type="text" @click="modifyOrCopy(row, 'copy')">复制</el-button>
        <el-divider v-if="permission('Copy')" direction="vertical" />

        <el-button v-if="permission('Status')" type="text" @click="switchStatus(row, params.radio)">{{ row.isEnabled ? '停用' : '启用' }}</el-button>
        <el-divider v-if="permission('Status')" direction="vertical" />

        <el-button v-if="permission('Modify')" type="text" @click="modifyOrCopy(row, 'modify')">修改</el-button>
        <el-divider v-if="permission('Del')" direction="vertical" />

        <el-button v-if="permission('Del')" type="text" @click="del(row)">删除</el-button>
        <!-- 新的 ————————End———————— -->
      </div>
    </MTable>

    <MDialog v-model="visible" :title="messages[currentType]" @onOk="submitForm">
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList" />
    </MDialog>
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import conventionMixin from '@/mixin/conventional-page'
import api from '@/api/information/production/factory-model-refactor'
import { columns, getFunctionList } from './columns'
import formList from './form-list'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import { areaType } from '@/config/options.config'

export default {
  name: 'FactoryModel',
  components: { FunctionHeader },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      params: {
        radio: 'FactoryModel'
      },
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('Add'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.add
            }
          }
        ],
        exportName: 'exportFactoryModel',
        exportParams: {}
      },
      columns,
      visible: false,
      formList: formList,
      formData: {},
      currentType: '新增',
      formOptions: {
        areaOptions: []
      },
      areaTypeName: {}
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 192
    }
  },
  mounted() {
    getFunctionList({
      areaTypeChange: this.areaTypeChange
    })
    areaType.forEach(item => {
      this.areaTypeName[item.id] = item.name
    })
  },
  methods: {
    async getAreaList(type) {
      const res = await api.getFactoryModelList({
        searchVal: Encrypt(JSON.stringify([
          ...this.defaultSearch,
          {
            id: getUUid(),
            fieldName: 'type',
            fieldType: 'number',
            fieldValue: type - 1,
            operator: 'eq'
          }
        ]))
      })
      if (res) {
        this.formList[3].children[0].options = res
        this.formOptions.areaOptions = res
      }
    },
    async getTableData(condition, callback) {
      const { order, search } = condition
      const searchCondition = {
        sort: order,
        searchVal: Encrypt(JSON.stringify(search)),
        searchKeyword: this.functionHeader.searchVal
      }
      this.functionHeader.exportParams = searchCondition
      const res = await this.api[`get${this.params.radio}List`](searchCondition)
      if (res) {
        callback({
          total: res.length,
          content: res
        })
      }
      callback()
    },
    areaTypeChange(val) {
      if (val !== 0) {
        this.getAreaList(val)
      }
      if (this.currentType === 'add' || this.currentType === 'copy') {
        this.createAreaCode(val)
      }
      if (this.$refs.formBuild) {
        this.$refs.formBuild.setRuleRequired([{
          key: 'parentId',
          required: val !== 0
        }])
        this.$refs.formBuild.setForm({
          parentId: ''
        })
      }
      this.formList[3].hidden = val === 0
    },
    async createAreaCode(val) {
      if (sessionStorage.getItem('memberCode') !== 'mubai') return false
      const res = await api.createCode({
        areaSign: val,
        count: 1
      })
      if (res) {
        this.$refs.formBuild.setForm({
          code: res[0]
        })
      }
    },
    add() {
      this.formData = {}
      this.currentType = 'add'
      this.formList = this._.cloneDeep(formList)
      this.setFormListDisabled([0, 2, 3], false)
      this.visible = true
    },
    addChild(row) {
      this.setFormListDisabled([2, 3], true)
      this.setFormListDisabled([0], false)
      this.formData = { parentId: row.id, type: row.type + 1 }
      this.currentType = 'add'
      this.areaTypeChange(row.type + 1)
      this.visible = true
    },
    // 更多操作
    more(row, command) {
      const functions = {
        // addChild: () => { this.addChild(row) },
        modify: () => { this.modifyOrCopy(row, command) },
        copy: () => { this.modifyOrCopy(row, command) },
        del: () => { this.del(row) }
      }
      functions[command]()
    },
    modifyOrCopy(row, command) {
      this.setFormListDisabled([0, 2, 3], command === 'modify')
      this.formData = row
      this.currentType = command
      this.areaTypeChange(row.type)
      this.visible = true
    },
    setFormListDisabled(indexS, bol) {
      indexS.forEach(index => {
        if (!this.formList[index].props) {
          this.formList[index].props = {}
        }
        this.formList[index].props.disabled = bol
      })
    },
    // 打印仓库编码
    printCode(row) {
      this.$refs.printCode.print(row.code)
    },
    createSetData(raw) {
      return {
        code: raw.code,
        name: raw.name,
        type: raw.type,
        parentId: raw.parentId || -1,
        isEnabled: raw.isEnabled,
        remark: raw.remark
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    }
  }
}
</script>

<style scoped lang="scss">
.model-wrap {
  padding: 10px;
  .function-header-wrap {
    padding-top: 0;
  }
}
</style>
