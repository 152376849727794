
import { status } from '@/config/options.config'
import { formatColumns } from '@/utils'
export const Customer = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    sortable: false,
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'code',
    label: `客户编码`,
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input' }
  },
  {
    prop: 'name',
    label: `客户名称`,
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input' }
  },
  {
    prop: 'address',
    label: '收货地址',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input',
      required: false }
  },
  {
    prop: 'contact',
    label: '联系人',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input',
      required: false }
  },
  {
    prop: 'tel',
    label: '联系电话',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input',
      required: false }
  },
  {
    prop: 'mail',
    label: '邮箱',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input',
      required: false }
  },
  {
    prop: 'isEnabled',
    label: `客户状态`,
    slotName: 'isEnabled',
    sortable: false,
    filters: formatColumns(status),
    form: {
      tagName: 'el-select',
      defaultValue: 1,
      options: status,
      required: false
    }
  },
  {
    prop: 'business',
    label: '行业',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input',
      required: false }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input', required: false, props: { type: 'textarea' }}
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 145
  }
]

export const CustomerSite = [
  {
    prop: 'name',
    label: '现场名称',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input' }
  },
  {
    prop: 'address',
    label: '地址',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input', required: false }
  },
  {
    prop: 'principal',
    label: '负责人',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input',
      required: false }
  },
  {
    prop: 'phone',
    label: '联系方式',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input',
      required: false }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 145
  }
]
