<template>
  <div class="materials-list-wrapper" :style="{ width: open ? '359px' : 0}">
    <div class="operate">
      <div class="top">
        <div class="title">未绑定物料清单</div>
        <div class="page">
          <el-button type="text" icon="el-icon-arrow-left" :disabled="page.current === 1" @click="pageChange('-')" />
          <div>
            <input v-model.number="page.current" class="input-c" @keyup.enter="pageChange()" @blur="pageChange()"> / {{ page.total }}
          </div>
          <el-button type="text" icon="el-icon-arrow-right" :disabled="page.current === page.total" @click="pageChange('+')" />
        </div>
      </div>
      <div class="search flex-sbc">
        <el-input v-model="searchVal" size="small" placeholder="请输入物料名称/编码/规格" clearable @clear="getMaterialsList" />
        <el-button size="small" type="primary" icon="el-icon-search" class="search-btn" clearable @click="getMaterialsList">搜索</el-button>
      </div>
    </div>
    <div v-loading="loading" class="list">
      <div v-for="item in materialsList" :key="item.id" class="card-wrapper" :class="{ active: item.id === currentId }" @click="clickCard(item)">
        <div class="top">
          <span>物料名称：</span>
          <span>{{ item.name }}</span>
        </div>
        <div class="bottom">
          <div class="label-value">
            <span>物料编码：</span>
            <span>{{ item.code }}</span>
          </div>
          <div class="label-value">
            <span>规格：</span>
            <span>{{ item.specifications }}</span>
          </div>
        </div>
      </div>
    </div>
    <img :src="open ? closeImg : openImg" class="open-img" alt="" style="width: 19px" @click="open = !open">
  </div>
</template>

<script>
import openImg from '@/assets/information/open.png'
import closeImg from '@/assets/information/close.png'
import maApi from '@/api/information/materials'
import { Encrypt } from '@/utils/sercet'
export default {
  name: 'MaterialsList',
  props: {
    condition: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      openImg,
      closeImg,
      page: {
        current: 1,
        total: 10
      },
      searchVal: '',
      open: false,
      materialsList: [],
      loading: false,
      currentId: ''
    }
  },
  watch: {
    condition() {
      this.getMaterialsList()
    }
  },
  mounted() {
    this.getMaterialsList()
  },
  methods: {
    async getMaterialsList() {
      this.loading = true
      const res = await maApi.getMaterialsListPage({
        page: this.page.current,
        limit: 10,
        searchKeyword: this.searchVal,
        searchVal: Encrypt(JSON.stringify(this.condition))
      })
      if (res) {
        this.materialsList = res.records
        this.page.total = res.pages
      }
      this.loading = false
    },
    pageChange(type) {
      if (type) type === '+' ? this.page.current++ : this.page.current--
      if (!this.page.current) {
        this.page.current = 1
      }
      if (this.page.current > this.page.total) {
        this.page.current = this.page.total
      }
      this.getMaterialsList()
    },
    clickCard(card) {
      this.currentId = card.id
      this.$emit('cardClick', [card])
    }
  }
}
</script>

<style scoped lang="scss">
.materials-list-wrapper {
  width: 359px;
  background: #FFFFFF;
  z-index: 12;
  transition: all .5s;
  box-shadow: -5px 2px 14px 0px rgba(0, 0, 0, 0.05);
  .operate {
    width: 100%;
    background: linear-gradient(180deg, #C4DBEF, #FFFFFF);
    .top {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 0;
      .title {
        padding: 7px 20px;
        background: #0091FF;
        color: #FFFFFF;
        white-space:nowrap;
        border-radius: 0 4px 4px 0;
      }
      .page {
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 10px;
        .el-button {
          color: #B1B1B8;
        }
        .input-c {
          width: 37px;
          height: 22px;
          color: #3690FF;
          background: #FAFCFF;
          border-radius: 4px;
          outline:none;
          border: 1px solid #DEDEEE;
          text-align: center;
          &:focus-visible {
            outline:none;
            border: 1px solid #DEDEEE;
          }
        }
      }
    }
    .search {
      width: 100%;
      padding: 10px;
      .el-button {
        margin-left: 15px;
      }
    }
  }
  .list {
    padding: 0 10px;
    overflow-x: auto;
    height: calc(100% - 123px);
    .card-wrapper {
      width: 329px;
      background: #FFFFFF;
      box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      cursor: pointer;
      .top {
        padding: 10px;
        span {
          font-size: 14px;
          color: #475B7D;
          font-weight: 700;
          &:first-child {
            color: #B2B4C7;
            font-weight: normal;
          }
        }
      }
      .bottom {
        padding: 10px;
        background: #F6F7F9;
        >div {
          span {
            font-size: 14px;
            color: #475B7D;
            font-weight: 700;
            &:first-child {
              color: #B2B4C7;
              font-weight: normal;
            }
          }
          &:first-child {
            padding-bottom: 5px;
          }
        }
      }
      margin-bottom: 10px;
      &:first-child {
        margin-top: 10px;
      }
    }
  }
  .open-img {
    position: absolute;
    left: -18px;
    top: 50%;
    margin-top: -32px;
  }
  .active {
    .top {
      background: #F4F6FF;
    }
    .bottom {
      background: #E3E7FA !important;
    }
  }
}
</style>
