import { procedureType, status } from '@/config/options.config'
import _ from 'lodash'
import { columns, groupColumns } from './columns'
import { transformColumnsToForm } from '@/utils'
const newColumns = _.cloneDeep(columns)
const newGroupColumns = _.cloneDeep(groupColumns)

export const formList = transformColumnsToForm(newColumns)
export const groupFormList = transformColumnsToForm(newGroupColumns)

export const searchFormList = [
  {
    colSpan: 5,
    key: 'type',
    tagName: 'el-select',
    defaultValue: 1,
    props: {
      placeholder: '请选择工序类别'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: procedureType
      }
    ]
  },
  {
    colSpan: 5,
    key: 'isEnabled',
    tagName: 'el-select',
    defaultValue: 1,
    props: {
      placeholder: '请选择工序状态'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: status
      }
    ]
  }
]

