var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.isHiddenLogo ? "hiddenLogo login-container" : "login-container"
    },
    [
      _c("div", { staticClass: "login-left" }, [
        _c("div", { staticClass: "left-tit", on: { click: _vm.jumps } }, [
          _c("span", [_vm._v("www.mubyte.cn | 让订单交付更高效")]),
          _c("img", {
            staticClass: "arror-img",
            attrs: { src: "https://oss.mubyte.cn/static/login-arror.png" }
          })
        ]),
        _c(
          "div",
          { staticClass: "left-type" },
          [_c("vue-typer", { attrs: { text: _vm.typingTexts } })],
          1
        ),
        _c("div", { staticClass: "left-txt" }, [
          _vm._v("提升生产管控效率、质量追溯水平和设备管理")
        ]),
        _vm._m(0),
        _vm._m(1)
      ]),
      _c("div", { staticClass: "login-right" }, [
        _c("div", { staticClass: "center" }, [
          _c("div", { staticClass: "blank-placeholder" }),
          _c("div", { staticClass: "form-wrapper" }, [
            _vm._m(2),
            _c(
              "div",
              { staticClass: "login-tab" },
              [
                _c(
                  "el-tabs",
                  {
                    on: { "tab-click": _vm.handleClick },
                    model: {
                      value: _vm.activeName,
                      callback: function($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName"
                    }
                  },
                  [
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "手机号登录", name: "first" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "form",
                            staticStyle: { "border-top-right-radius": "8px" }
                          },
                          [
                            _c(
                              "el-form",
                              {
                                ref: "loginPhoneForm",
                                staticClass: "login-form",
                                attrs: {
                                  model: _vm.loginPhoneForm,
                                  rules: _vm.loginPhoneRules,
                                  "label-position": "left"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "formLayout" },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { prop: "phoneNumber" } },
                                      [
                                        _c("el-autocomplete", {
                                          ref: "phone",
                                          staticClass: "inline-input",
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            "fetch-suggestions":
                                              _vm.querySearch,
                                            "value-key": "phone",
                                            placeholder: "请输入手机号",
                                            tabindex: "1"
                                          },
                                          on: { select: _vm.handleSelect },
                                          nativeOn: {
                                            keyup: function($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "tab",
                                                  9,
                                                  $event.key,
                                                  "Tab"
                                                )
                                              ) {
                                                return null
                                              }
                                              return _vm.handlePass(2)
                                            }
                                          },
                                          model: {
                                            value: _vm.loginPhoneForm.phone,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.loginPhoneForm,
                                                "phone",
                                                $$v
                                              )
                                            },
                                            expression: "loginPhoneForm.phone"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "padding-bottom": "0" },
                                        attrs: { prop: "password" }
                                      },
                                      [
                                        _c("el-input", {
                                          key: _vm.passwordPhoneType,
                                          ref: "password",
                                          attrs: {
                                            type: _vm.passwordPhoneType,
                                            placeholder: "请输入密码",
                                            name: "password",
                                            tabindex: "2"
                                          },
                                          nativeOn: {
                                            keyup: function($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              ) {
                                                return null
                                              }
                                              return _vm.handlePhoneLogin(
                                                $event
                                              )
                                            }
                                          },
                                          model: {
                                            value: _vm.loginPhoneForm.password,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.loginPhoneForm,
                                                "password",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "loginPhoneForm.password"
                                          }
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "show-pwd",
                                            on: { click: _vm.showPwd }
                                          },
                                          [
                                            _c("svg-icon", {
                                              attrs: {
                                                "icon-class":
                                                  _vm.passwordPhoneType ===
                                                  "password"
                                                    ? "eye"
                                                    : "eye-open"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: {
                                      "text-align": "left",
                                      padding: "0"
                                    },
                                    attrs: { prop: "remainPhonePassword" }
                                  },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        model: {
                                          value:
                                            _vm.loginPhoneForm
                                              .remainPhonePassword,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.loginPhoneForm,
                                              "remainPhonePassword",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "loginPhoneForm.remainPhonePassword"
                                        }
                                      },
                                      [_vm._v("记住账号")]
                                    ),
                                    _c("DownCode")
                                  ],
                                  1
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      color: "#fff"
                                    },
                                    attrs: {
                                      loading: _vm.loading,
                                      type: "primary"
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.handlePhoneLogin($event)
                                      }
                                    }
                                  },
                                  [_vm._v("立即登录 ")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    ),
                    _c(
                      "el-tab-pane",
                      { attrs: { label: "工厂账号登录", name: "second" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "form",
                            staticStyle: { "border-top-left-radius": "8px" }
                          },
                          [
                            _c(
                              "el-form",
                              {
                                ref: "loginForm",
                                staticClass: "login-form",
                                attrs: {
                                  model: _vm.loginForm,
                                  rules: _vm.loginRules,
                                  "label-position": "left"
                                }
                              },
                              [
                                !_vm.isSign
                                  ? _c(
                                      "el-form-item",
                                      { attrs: { prop: "memberCode" } },
                                      [
                                        _c("el-autocomplete", {
                                          ref: "memberCode",
                                          staticClass: "inline-input",
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            "fetch-suggestions":
                                              _vm.querySearchM,
                                            "value-key": "memberCode",
                                            placeholder: "请输入工厂代码",
                                            tabindex: "1"
                                          },
                                          on: { select: _vm.handleSelect },
                                          nativeOn: {
                                            keyup: function($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "tab",
                                                  9,
                                                  $event.key,
                                                  "Tab"
                                                )
                                              ) {
                                                return null
                                              }
                                              return _vm.handlePass(1)
                                            }
                                          },
                                          model: {
                                            value: _vm.loginForm.memberCode,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.loginForm,
                                                "memberCode",
                                                $$v
                                              )
                                            },
                                            expression: "loginForm.memberCode"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { class: _vm.isSign ? "formLayout" : "" },
                                  [
                                    _c(
                                      "el-form-item",
                                      { attrs: { prop: "userName" } },
                                      [
                                        _c("el-autocomplete", {
                                          ref: "userName",
                                          staticClass: "inline-input",
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            "fetch-suggestions":
                                              _vm.querySearch,
                                            "value-key": "userName",
                                            placeholder: "请输入用户名",
                                            tabindex: "2"
                                          },
                                          on: { select: _vm.handleSelect },
                                          nativeOn: {
                                            keyup: function($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "tab",
                                                  9,
                                                  $event.key,
                                                  "Tab"
                                                )
                                              ) {
                                                return null
                                              }
                                              return _vm.handlePass(2)
                                            }
                                          },
                                          model: {
                                            value: _vm.loginForm.userName,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.loginForm,
                                                "userName",
                                                $$v
                                              )
                                            },
                                            expression: "loginForm.userName"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-form-item",
                                      {
                                        staticStyle: { "padding-bottom": "0" },
                                        attrs: { prop: "password" }
                                      },
                                      [
                                        _c("el-input", {
                                          key: _vm.passwordType,
                                          ref: "password",
                                          attrs: {
                                            type: _vm.passwordType,
                                            placeholder: "请输入密码",
                                            name: "password",
                                            tabindex: "3"
                                          },
                                          nativeOn: {
                                            keyup: function($event) {
                                              if (
                                                !$event.type.indexOf("key") &&
                                                _vm._k(
                                                  $event.keyCode,
                                                  "enter",
                                                  13,
                                                  $event.key,
                                                  "Enter"
                                                )
                                              ) {
                                                return null
                                              }
                                              return _vm.handleLogin($event)
                                            }
                                          },
                                          model: {
                                            value: _vm.loginForm.password,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.loginForm,
                                                "password",
                                                $$v
                                              )
                                            },
                                            expression: "loginForm.password"
                                          }
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "show-pwd",
                                            on: { click: _vm.showPwd }
                                          },
                                          [
                                            _c("svg-icon", {
                                              attrs: {
                                                "icon-class":
                                                  _vm.passwordType ===
                                                  "password"
                                                    ? "eye"
                                                    : "eye-open"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-form-item",
                                  {
                                    staticStyle: {
                                      "text-align": "left",
                                      padding: "0"
                                    },
                                    attrs: { prop: "remainPassword" }
                                  },
                                  [
                                    _c(
                                      "el-checkbox",
                                      {
                                        model: {
                                          value: _vm.loginForm.remainPassword,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.loginForm,
                                              "remainPassword",
                                              $$v
                                            )
                                          },
                                          expression: "loginForm.remainPassword"
                                        }
                                      },
                                      [_vm._v("记住账号")]
                                    ),
                                    !_vm.isSign ? _c("DownCode") : _vm._e()
                                  ],
                                  1
                                ),
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: {
                                      width: "100%",
                                      color: "#fff"
                                    },
                                    attrs: {
                                      loading: _vm.loading,
                                      type: "primary"
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.handleLogin($event)
                                      }
                                    }
                                  },
                                  [_vm._v("立即登录 ")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ]),
        _c("div", { staticClass: "bottom-txt" }, [
          _vm._v(
            "©2015-" +
              _vm._s(_vm.nowYear) +
              " 木白科技.保留所有权利 沪ICP备16027431号-5"
          )
        ])
      ]),
      _c("div", { staticClass: "owner" })
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left-ul" }, [
      _c("div", { staticClass: "left-ul-li" }, [_vm._v("灵活部署")]),
      _c("div", { staticClass: "left-ul-li" }, [_vm._v("触达产线")]),
      _c("div", { staticClass: "left-ul-li" }, [_vm._v("智能化")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left-bottom" }, [
      _c("img", {
        staticClass: "wechat-img",
        attrs: { src: "https://oss.mubyte.cn/static/logo-wechat.png" }
      }),
      _c("span", [_vm._v("扫码关注公众号")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "cac-logo" }, [
      _c("img", {
        attrs: { src: "https://oss.mubyte.cn/static/logo-new%402x.png" }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }