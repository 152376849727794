/** write by luomingzhi */
import { processStatus, status, procedureClass, reportType } from '@/config/options.config'
let functionList = {}
export const getFunctionList = val => {
  functionList = val
}
// 这里面的东西别改顺序
const base = [
  {
    prop: 'code',
    label: '工艺路线编码',
    sortable: false,
    hiddenSearch: true,
    slotName: 'code',
    form: {
      tagName: 'el-input',
      colSpan: 6
    }
  },
  {
    prop: 'name',
    label: '工艺路线名称',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-input',
      colSpan: 6
    }
  },
  {
    prop: 'areas',
    label: '适用产线',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-select', key: 'areaIdList',
      props: { multiple: false, collapseTags: true },
      options: [],
      colSpan: 7,
      required: false
    }
  },
  {
    prop: 'isEnabled',
    label: '工艺路线状态',
    sortable: false,
    slotName: 'isEnabled',
    hiddenSearch: true,
    form: {
      tagName: 'el-select',
      options: status,
      defaultValue: 1,
      colSpan: 5
    }
  },
  {
    prop: 'type',
    label: '工艺路线属性',
    sortable: false,
    slotName: 'type',
    hiddenSearch: true,
    form: {
      tagName: 'el-select',
      options: procedureClass,
      colSpan: 6
    }
  },
  {
    prop: 'processFlowClassName',
    label: '工艺路线类型',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'div',
      customSlots: ['processFlowClassName'],
      colSpan: 6
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' },
      colSpan: 7
    }
  },
  {
    prop: 'isPartialOutbound',
    label: '分批出站',
    sortable: false,
    slotName: 'isPartialOutbound',
    hiddenSearch: true,
    form: {
      tagName: 'el-select',
      options: processStatus,
      defaultValue: 0,
      colSpan: 5
    }
  },
  {
    prop: 'materialsFullName',
    label: '成品物料',
    sortable: false,
    hiddenSearch: true,
    directives: [
      {
        name: 'selectScrollEnd',
        value: () => {
          console.log('滚动触底了，可以请求下一页了')
        }
      }
    ],
    form: {
      tagName: 'el-select',
      key: 'materialsId',
      options: [],
      colSpan: 6,
      optionsProps: {
        label: 'fullLabel',
        value: 'id'
      },
      props: {
        remote: true,
        remoteMethod: (val) => {
          console.log('远程搜', val)
        }
      },
      on: { change: (val) => { functionList.searchChange(val) } },
    }
  },
  {
    prop: 'overProductionType',
    label: '超产报工',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-select',
      options: reportType,
      defaultValue: 0,
      colSpan: 6
    }
  }
]
// const memberCode = sessionStorage.getItem('memberCode')

// if (['reagold', 'bbelc'].includes(memberCode)) {
//   base.splice(base.length - 1, 0, {
//     prop: 'erpWarehouseName',
//     label: '工艺结束后产出物料对接ERP仓库',
//     sortable: false,
//     hiddenSearch: true,
//     form: {
//       tagName: 'el-select',
//       key: 'erpWarehouseId',
//       required: false,
//       options: [],
//       colSpan: 6
//     }
//   })
// }
export { base }
