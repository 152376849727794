<template>
    <div class="scrap-wrap">
      <div class="top">
        <SearchForm
          ref="searchForm"
          :form-list-extend="searchFormList"
          :form-data="searchFormData"
          :default-form="defaultForm"
          @search="searchTable"
          :searchType='1'
        />
      </div>
      <div class="bottom">
        <FunctionHeader
          ref="functionHeader"
          v-model="functionHeader.searchVal"
          search-title="请输入领退料单名称/编号"
          :tags="!selectionData.length ?  functionHeader.tags :[]"
          :radio="changeParam"
          :export-name="functionHeader.exportName"
          :columns-key="columnsKey"
          :show-export = "!selectionData.length ? true : false"
          :export-params="functionHeader.exportParams"
          @search="$refs.mTable.setTableData(true)"
        >
          <div slot="all" v-show="selectionData.length">
            <el-button v-if="permission('Export')" type="primary" size="small" @click="batchExport()">批量导出</el-button>
            <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
            已选
            <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
            / {{ total }}项
          </span>
            <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
          </div>
        </FunctionHeader>
        <MTable ref="mTable" :columns="columns" :height="height" :columns-key="columnsKey" :set-data-method="getTableData" @selection-change="selectionChange">
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <NewPageOpen slot="code" slot-scope="{ row }" :path="getDetailPath(row, 'view')" :text="row.code" />
          <div slot="receiptType" slot-scope="{ row }">{{row.receiptType | receiptType }}</div>
          <div slot="directionType" slot-scope="{ row }">{{row.directionType === 0 ? '工单领料' : row.directionType === 1 ? '工单退料': '工单挪料'}}</div>
          <div slot="receiptStatus" slot-scope="{ row }">{{row.receiptStatus | receiptStatus }}</div>
          <div slot="action" slot-scope="{ row }">
                <el-button
                  v-if="permission( 'BackOut')"
                  type="text"
                  @click="backOut(row.id)"
                  :disabled="row.receiptStatus === 0 ? false : true"
                  >撤销</el-button
                >
          </div>
        </MTable>
      </div>
    </div>
  </template>

  <script>
  import { columns } from './columns'
  import { searchFormList } from './form-list'
  import api from '@/api/information/materials-apply'
  import conventionMixin from '@/mixin/conventional-page'
  import batchExportFront from '@/mixin/batch-export-front'
  import FunctionHeader from '@/components/FunctionHeader/index'
  import SearchForm from '@/components/SearchForm/SearchForm'
  import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
  import { docType,materialsRequisitionStatus } from '@/config/options.config'
  import dayjs from 'dayjs'

  export default {
    name: 'materialsApplyList',
    components: { NewPageOpen, SearchForm, FunctionHeader },
    mixins: [conventionMixin, batchExportFront],
    filters: {
        receiptType(val) {
            if (val || val=== 0) return docType.find(item => item.id === val).name
        },
        receiptStatus(val) {
            return materialsRequisitionStatus.find(item => item.id === val).name
        }
    },
    props:{
    projectId:{
        type: Number,
         default: undefined
      }
    },
    data() {
      return {
        api,
        params: {
          radio: 'MaterialsApply'
        },
        changeParam: 'Common',
        columns,
        searchFormList,
        searchFormData: {},
        searchFormatData: [],
        selectionData:[],
        functionHeader: {
          searchVal: '',
          tags: [
          {
            hidden: !this.permission('AddApply'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '领料',
            on: {
              click: () => {
                this.add('addOrder')
              }
            }
          },
          {
            hidden: !this.permission('AddReturn'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '退料',
            on: {
              click: () => {
                this.addReturn('addOrder')
              }
            }
          },
          {
            hidden: !this.permission('Export'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF',marginRight:'10px'},
            innerText: '全部导出',
            on: {
              click: () => this.batchExport(true)
            }
          },
        ],
          exportName: '',
          exportParams: {}
        },
        formData: {},
        currentType: 'add',
        visible: false,
        stationVisible: false,
        stationList: [],
        searchKeyword: true,
        defaultForm: { receiptType: 0,directionType:0},
        selectionDataList:[],
        total:0
      }
    },
    beforeRouteLeave(to, from, next) {
      if (to.name !== 'refresh') {
        this.$store.dispatch('tagsView/addCachedView', from)
      }
      next()
    },
    created() {
      let typeFlag = localStorage.getItem("typeFlag")
      if(typeFlag){
        this.defaultForm.directionType = +typeFlag
        localStorage.removeItem("typeFlag")
      }
    },
    computed: {
      height() {
        return this.projectId ? this.$store.state.app.contentHeight - 235 - 25 - 44 - 42 : this.$store.state.app.contentHeight - 235 - 25 - 44
      },
      columnsKey() {
        return this.$route.name + this.changeParam
      },
      getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectionDataList.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
    },
    mounted() {
    },
    methods: {
      add(type) {
           localStorage.removeItem('applyMaterials')
           this.$router.push({ name: 'materialsApplyDetail', query: { type }})
      },
      async backOut(id) {
           this.$confirm('您确定要撤销吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async() => {
            const res = await api.backOutMaterialsRequisition(id)
            if (res) {
              this.$message.success('撤销成功')
              this.$refs.mTable.setTableData()
            }
          })
        },
      addReturn(type) {
           localStorage.removeItem('applyMaterials')
          //  const propertyValue = this.searchFormatData.map(item => {
          //   if (item.derect !== undefined) {
          //     return item.derect
          //   }
          //   }).find(value => value !== undefined)
          //  if( propertyValue ){
           this.$router.push({ name: 'materialsApplyReturnDetail', query: { type }})
          //  }
      },
        selectionChange(data) {
          this.selectionData = data.map(item => item.id)
          this.selectionDataList = data
        },
        resetSelectData() {
        this.selectionDataList.forEach(item => {
          if (!item.selectable) {
            this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
          }
        })
        this.selectionDataList = this.selectionDataList.filter((item) => item.selectable)
    },
        async getTableData(condition, callback) {
        const { page, search, order } = condition
        const data = Object.assign({}, ...this.searchFormatData)
        const searchCondition = {
          search: this.functionHeader.searchVal,
          ...data
        }
        this.functionHeader.exportParams = searchCondition
        const res = await this.api[`get${this.params.radio}ListPage`]({
          page: page.currentPage,
          size: page.pageSize,
          projectId:this.projectId,
          ...searchCondition
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
          this.total = res.total
        }
        callback()
      },
      getDetailPath(row, command) {
        if(row.directionType){
          return { name: 'materialsApplyReturnDetail', query: { type:'view',id: row.id}}
        }else{
           return { name: 'materialsApplyDetail', query: { type:'view',id: row.id}}
        }
      },
      searchTable(data) {
        this.searchFormatData = data
        this.$refs.mTable.setTableData(true)
      },
      downloadFile(urlP, name) {
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = urlP
          link.setAttribute('download', name)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        },
      async batchExport(isAll) {
          const params = isAll ? this.functionHeader.exportParams : { ids: this.selectionData,directionType:this.functionHeader.exportParams.directionType }
          const res = await api.exportMaterialsRequisitionList(params)
          if (res && res.data.byteLength) {
            const urlP = window.URL.createObjectURL(
              new Blob([res.data], {
                type: 'application/octet-stream;charset=ISO8859-1'
              })
            )
            const fileName = `${dayjs().format('YYYYMMDDHHmmss')}.xls`
            this.downloadFile(urlP, fileName)
          } else {
            this.$message.error('导出失败，内容为空')
          }
      },
      allMore(command) {
        const functions = {
          batchDel: () => this.onDel(this.selectionData),
          batchExport: this.batchExport
        }
        functions[command]()
      },
      // 重置
      resetData() {
        this.searchData = {}
        this.functionHeader.searchVal = ''
        this.$refs.mTable.setTableData(true)
      }
    }
}
  </script>

  <style scoped lang="scss">
  .function-header-wrap {
    padding-top: 0;
  }

  .scrap-wrap {
    width: 100%;
    padding-top: 10px;
    .bottom {
      padding: 10px;
    }
    .m-radio-group {
      margin-left: 10px;
    }
  }
  ::v-deep {
    .e-divider {
      margin: 0px 0 0 0;
    }
  }
  </style>
