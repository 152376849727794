/** write by luomingzhi */
import { formatColumns } from '@/utils'
const loopStatus = [
  {
    id: 0,
    name: '否'
  },
  {
    id: 1,
    name: '是'
  }
]
const status = [
  {
    id: 0,
    name: '停用'
  },
  {
    id: 1,
    name: '启用'
  }
]
export const columns = [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    slotName: 'index',
    hiddenSearch: true,
    minWidth: 80
  },
  // {
  //   prop: 'code',
  //   label: '包装容器编码',
  //   sortable: false,
  //   hiddenSearch: true,
  //   form: { tagName: 'el-input' }
  // },
  {
    prop: 'name',
    label: '容器名称',
    sortable: false,
    hiddenSearch: true,
    slotName: 'name',
    form: { tagName: 'el-input' }
  },
  {
    prop: 'specification',
    label: '规格',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input' }
  },
  {
    prop: 'containerUnitName',
    label: '单位',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-select',
      key: 'containerUnitId'
    }
  },
  {
    prop: 'isCycle',
    label: '循环使用',
    sortable: false,
    hiddenSearch: true,
    slotName: 'isCycle',
    filters: formatColumns(loopStatus),
    form: {
      tagName: 'el-select',
      options: loopStatus,
      defaultValue: 0
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' }
    }
  },
  {
    prop: 'isEnabled',
    label: '状态',
    sortable: false,
    hiddenSearch: true,
    slotName: 'isEnabled',
    options: status
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 145
  }
]
export const matertialColumns = [
  {
    prop: 'materialsCode',
    label: '物料编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsClassName',
    label: '物料分类',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsTypeName',
    label: '物料类型',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsMainUnitName',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'specifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'nominalCapacity',
    label: '标准容量',
    sortable: false,
    hiddenSearch: true,
    slotName: 'nominalCapacity',
    width: 160
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 145
  }
]
