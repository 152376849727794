/** write by luomingzhi */
import { status } from '@/config/options.config'
import { formatColumns } from '@/utils'

// 物料单位
const MaterialsUnit = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '单位名称',
    hiddenSearch: true,
    sortable: false,
    form: { tagName: 'el-input' }
  },
  {
    prop: 'isEnabled',
    label: '单位状态',
    slotName: 'isEnabled',
    sortable: false,
    filters: formatColumns(status),
    form: {
      tagName: 'el-select',
      defaultValue: 1,
      options: status
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input', required: false, props: { type: 'textarea' }}
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 145
  }
]
// 物料类型
const MaterialsType = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '类型名称',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input' }
  },
  {
    prop: 'isEnabled',
    label: '类型状态',
    slotName: 'isEnabled',
    sortable: false,
    filters: formatColumns(status),
    form: {
      tagName: 'el-select',
      options: status,
      defaultValue: 1
    }
  },
  {
    prop: 'isSystem',
    label: '物料类型来源',
    sortable: false,
    slotName: 'isSystem',
    filters: [
      {
        text: '内置',
        value: 1
      }, {
        text: '自建',
        value: 0
      }
    ]
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input', required: false, props: { type: 'textarea' }}
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 145
  }
]
// 物料材质
const MaterialsQuality = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '材质名称',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input' }
  },
  {
    prop: 'isEnabled',
    label: '材质状态',
    slotName: 'isEnabled',
    sortable: false,
    filters: formatColumns(status),
    form: {
      tagName: 'el-select',
      options: status,
      defaultValue: 1
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input', required: false, props: { type: 'textarea' }}
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 145
  }
]
// 物料属性
const MaterialsAttribute = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '属性名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'isMarketable',
    label: '允许销售',
    slotName: 'isMarketable',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'isProducible',
    label: '允许生产',
    slotName: 'isProducible',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'isPurchasable',
    label: '允许采购',
    slotName: 'isPurchasable',
    sortable: false,
    hiddenSearch: true
  }
]

export default {
  MaterialsUnit,
  MaterialsType,
  MaterialsQuality,
  MaterialsAttribute
}
