<template>
    <div class="model-wrap">
        <div style="background-color: #f6f6f6;padding: 10px;">
            <div>
                <div style="display:flex;justify-content:space-between;line-height: 20px;font-size: 14px;font-weight: bold;">
                    <div style="color: #616161;">Word模板功能介绍</div>
                    <div style="color:#2790ff;cursor:pointer" @click="help">使用帮助与常见问题</div>
                </div>

                <div class="text">您可以通过直接使用系统模板或创建自定义模板进行打印</div>
            </div>
            <div style="margin-top: 10px;">
                <div style="line-height: 20px;font-size: 14px;font-weight: bold;color: #616161;">如何制作自定义模板</div>
                <div class="text">1. 您可以下载已有的模板进行编辑，也可以使用空白Word文件来创建新模板；</div>
                <div class="text">2. 进入使用帮助获取可使用的所有字段，复制所需要的字段代码粘贴到Word文件当中；</div>
                <div class="text">3. 保存编辑好的Word文件；</div>
                <div class="text">4. 点击下方「新增」或「修改」上传模板（注：文件格式只支持docx格式）</div>
            </div>
        </div>
      <FunctionHeader
        :hidden-search="true"
        :tags="functionHeader.tags"
        @search="$refs.mTable.setTableData(true)"
      />
      <MTable
        ref="mTable"
        :columns="columns"
        :height="height"
        :set-data-method="getTableData"
      >
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <div slot="isSystem" slot-scope="{ row }">{{ row.isSystem ? '是':'否' }}</div>
        <div slot="fixedLine" slot-scope="{ row }">{{ row.fixedLine ? row.lineCount : '关闭' }}</div>
        <div slot="action" slot-scope="{ row }">
            <el-button v-if="permission('DownLoad')" type="text" @click="downloadTemplate(row)">下载</el-button>
          <el-divider v-if="permission('DownLoad')" direction="vertical" />
          <el-button v-if="permission('Modify')" type="text" :disabled="row.isSystem ? true : false"  @click="modify(row)">修改</el-button>
          <el-divider v-if="permission('Modify')" direction="vertical" />
          <el-button v-if="permission('Del')" type="text" @click="del(row)" :disabled="row.isSystem ? true : false">删除</el-button>
        </div>
      </MTable>
  
      <MDialog v-model="visible" width="600px" :title="messages[currentType]"  @onOk="submitForm">
        <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList" label-position="left">
                <el-upload slot="drawing" :file-list="attachments" v-bind="uploadProps">
                <el-button type="default" size="small"  icon="el-icon-upload2">上传文件</el-button>
                <div slot="tip" class="el-upload__tip">仅支持扩展名.docx</div>
                </el-upload>

                <div slot="fixedLine">
                  <el-switch v-model="isFixedLine" :active-value="1" :inactive-value="0" active-color="#607FFF" inactive-color="#DCDFE6" />
                  <el-input-number v-show="isFixedLine === 1" v-model="lineCount" controls-position="right" :min="1" :step="1" :step-strictly="true" style="margin-left:10px" />
                </div>
            </MFormBuilder>
      </MDialog>
  
    </div>
  </template>
  
  <script>
  import FunctionHeader from '@/components/FunctionHeader/index'
  import conventionMixin from '@/mixin/conventional-page'
  import api from '@/api/dataSetInfo/printingModule'
  import { columns } from './columns'
  import formList from './form-list'
  import { getToken } from '@/utils/auth'
  
  export default {
    name: 'PrintingModuleList',
    components: { FunctionHeader },
    mixins: [conventionMixin],
    data() {
      return {
        api,
        params: {
          radio: 'PrintingModule'
        },
        functionHeader: {
          searchVal: '',
          tags: [
            {
              hidden: !this.permission('Add'),
              tagName: 'el-button',
              props: { type: 'primary', size: 'small' },
              style: { width: 'auto', background: '#607FFF' },
              innerText: '新增',
              on: {
                click: this.add
              }
            }
          ]
        },
        columns,
        visible: false,
        formList: formList,
        formData: {},
        currentType: '新增',
        formOptions: {},
        searchKeyword: true,
        uploadProps: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        multiple: false,
        accept: '.docx',
        limit: 1,
        onSuccess: this.fileUploadChange,
        onError: this.fileUploadChange,
        onRemove: this.fileRemove,
        onExceed: this.fileExceed,
        onPreview: this.handlePreview
      },
        attachments: [],
        typeList:[],
        isFixedLine: 0,
        lineCount: 1
      }
    },
    computed: {
      height() {
        return this.$store.state.app.contentHeight - 192 - 180
      }
    },
    mounted() {
      this.getAllWordTemplateType()
    },
    methods: {
      help(){
        window.open('https://bdbov0xyty.feishu.cn/wiki/FsvWwLa4Mi1HMXktNHtcg9Yzn5e')
      },
      add() {
      this.formData = {}
      this.attachments = []
      this.isFixedLine = 0
      this.lineCount = 1
      this.formList[0].props.disabled = false
      this.currentType = 'add'
      this.visible = true
    },
    modify(row) {
      this.formData = row
      let file = [{name:row.templateName,url:row.filePath}]
      this.attachments = file
      this.isFixedLine = row.fixedLine
      this.lineCount = row.lineCount
      this.formData.attachments = this.attachments
      this.formList[0].props.disabled = true
      this.currentType = 'modify'
      this.visible = true
    },
      async getAllWordTemplateType(){
        const res = await api.getAllWordTemplateType()
        if(res){
          this.formList[0].children[0].options = res.map(item =>{
            return {
              ...item,
              id:item.type,
              name:item.typeName
            }
          })
          this.typeList = res
        }
      },
     downloadTemplate(row) {
        fetch(row.filePath)
        .then(response => response.blob())
        .then(blob => {
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          link.download = row.templateName
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        })
    },
    fileExceed() {
      this.$message.info('只能上传一个文件')
    },
    fileRemove() {
      this.attachments = []
      this.$refs.formBuild.setForm({
        attachments: []
      });
    },
    // 上传附件
    fileUploadChange(res, file) {
      console.log('ceshiimg', res)
      if (res.errorCode === 0) {
        this.attachments.push({
          id: file.uid,
          name: file.name,
          url: res.result
        })
        return this.$message.success('上传成功')
      }
      if (res.errorCode !== 0) {
        return this.$message.error(res.result)
      }
    },
      createSetData(raw) {
        return {
          type: raw.type,
          typeName: this.typeList.find(item => item.type === raw.type).typeName,
          templateName: raw.templateName,
          filePath:this.attachments.length ? this.attachments[0].url : '',
          fixedLine:this.isFixedLine,
          lineCount:this.isFixedLine ? this.lineCount : '',
          remark: raw.remark
        }
      },
      createResetData(raw) {
        const obj = {
          id: raw.id
        }
        return Object.assign(this.createSetData(raw), obj)
      }
    }
  }
  </script>
  
  <style scoped lang="scss">
  .model-wrap {
    padding: 10px;
    .text {
        color: #aaaaaa;
        font-size: 14px;
        line-height: 20px;
    }
  }
  ::v-deep {
  .el-form-item {
    margin-bottom: 20px !important;
  }
}
  .el-upload__tip {
    display: inline-block;
    margin-left: 7px;
    margin-top: 0;
  }
  </style>
  