var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "MDialog",
    {
      attrs: {
        title: _vm.title,
        width: _vm.width - 320 + "px",
        showFullScreen: true
      },
      on: { onOk: _vm.submitForm, fullScreen: _vm.fullScreenChange },
      model: {
        value: _vm.modalShow,
        callback: function($$v) {
          _vm.modalShow = $$v
        },
        expression: "modalShow"
      }
    },
    [
      _c(
        "div",
        { staticClass: "m-table" },
        [
          _c("FunctionHeader", {
            staticClass: "materials-head",
            attrs: {
              "search-title": "请输入物料编号/名称/型号",
              tags: _vm.functionHeader.tags,
              "columns-setting": false,
              isDialog: true
            },
            on: {
              search: function($event) {
                return _vm.getReportItemLog()
              }
            },
            model: {
              value: _vm.functionHeader.searchVal,
              callback: function($$v) {
                _vm.$set(_vm.functionHeader, "searchVal", $$v)
              },
              expression: "functionHeader.searchVal"
            }
          }),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading"
                }
              ],
              attrs: { data: _vm.dataProcess, height: _vm.tableHeight },
              on: { "expand-change": _vm.onExpand }
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _c(
                          "el-table",
                          {
                            ref:
                              "expandTable" + props.row.procedureLocationCode,
                            staticClass: "ellipsis-table",
                            attrs: {
                              data: props.row.children,
                              "row-key": "id"
                            },
                            on: {
                              "selection-change": function(data) {
                                return _vm.handleSelectionChange(
                                  data,
                                  props.$index
                                )
                              }
                            }
                          },
                          [
                            _c("el-table-column", {
                              attrs: {
                                type: "selection",
                                "reserve-selection": true
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "index",
                                label: "序号",
                                width: "60"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _vm._v(
                                          " " + _vm._s(scope.$index + 1) + " "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "materialsType",
                                label: "物料类型"
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(scope) {
                                      return [
                                        _vm._v(
                                          " " +
                                            _vm._s(scope.row.materialsType) +
                                            " "
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "materialsCode",
                                label: "物料编号"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "materialsName",
                                label: "物料名称"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "specifications",
                                label: "物料规格"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: { prop: "mainUnitName", label: "主单位" }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "receivedQuantity",
                                label: "已领数量"
                              }
                            }),
                            _c("el-table-column", {
                              attrs: {
                                prop: "appliedNotReturnedQuantity",
                                label: "申请未退数量"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { prop: "procedureName", label: "工序名称" }
              }),
              _c("el-table-column", {
                attrs: { prop: "procedureLocationCode", label: "工序定位码" }
              }),
              _c("el-table-column", {
                attrs: { prop: "receivedQuantity", label: "已领数量" }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.page.currentPage,
              "page-sizes": _vm.page.pageSizes,
              "page-size": _vm.page.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.page.total
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }