import { status } from '@/config/options.config'
import _ from 'lodash'
import { columns } from './columns'
import { transformColumnsToForm } from '@/utils'
const newColumns = _.cloneDeep(columns)

export const formList = transformColumnsToForm(newColumns)

// 这里面的东西别改顺序
export const searchFormList = [
  {
    colSpan: 5,
    key: 'isEnabled',
    tagName: 'el-select',
    defaultValue: 1,
    props: {
      placeholder: '请选择工艺路线状态'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: status
      }
    ]
  },
  {
    colSpan: 5,
    key: 'processFlowClassId',
    tagName: 'el-select',
    defaultValue: 1,
    props: {
      placeholder: '请选择工艺路线类型'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 5,
    key: 'procedureId',
    tagName: 'el-select',
    props: {
      placeholder: '请选择包含工序'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    slotName: 'finishedMaterial',
    colSpan: 5,
    key: 'materialsId',
    tagName: 'el-select',
    props: {
      placeholder: '请选择成品物料',
      configFilter: true,
      remote: true,
      remoteMethod: (val) => {
        console.log('远程搜', val)
      }
    },
    directives: [
      { name: 'selectScrollEnd',
        value: () => {
          console.log('滚动触底了，可以请求下一页了')
        }
      }
    ],
    children: [
      {
        tagName: 'el-option',
        props: {
          // configLabel:'id-name',// 自定义拼接label，以‘-’切割
          // separator:' - ',// 分隔符
          label: 'fullLabel', // 自定义label需要字段值与configLabel字段值一致
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 5,
    key: 'procedureId',
    tagName: 'el-select',
    props: {
      placeholder: '请选择物料分类'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    colSpan: 6,
    key: 'lastUpdateTime',
    tagName: 'el-date-picker',
    defaultValue: 1,
    props: {
      rangeSeparator: '至',
      startPlaceholder: '更新开始日期',
      endPlaceholder: '结束日期',
      align: 'center',
      type: 'daterange',
      pickerOptions: [],
      valueFormat: 'yyyy-MM-dd',
      format: 'yyyy-MM-dd'
    },
    children: []
  },
  {
    colSpan: 6,
    key: 'createTime',
    tagName: 'el-date-picker',
    defaultValue: 1,
    props: {
      rangeSeparator: '至',
      startPlaceholder: '创建开始日期',
      endPlaceholder: '结束日期',
      align: 'center',
      type: 'daterange',
      pickerOptions: [],
      valueFormat: 'yyyy-MM-dd',
      format: 'yyyy-MM-dd'
    },
    children: []
  }
]

