<template>
  <div>
    <el-upload v-if="!readOnly" class="b20" type="primary" v-bind="uploadProps">
      <el-button size="small" type="primary">上传附件</el-button>
    </el-upload>
    <MTable ref="mTable" :show-page="false" :height="300" :columns="columns" :data="baseList">
      <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
      <div slot="fileName" slot-scope="{ row }">
        <a
          :href="row.url"
          :download="row.name"
          target="_blank"
          style="color: #607FFF"
        >{{ row.name }}</a>
        <img src="@/assets/information/procedure/附件@2x.png" style="width: 11px;margin-left: 5px">
      </div>
      <el-input slot="remark" v-model="row.remark" slot-scope="{ row }" :disabled="readOnly" placeholder="请输入" />
      <div slot="action" slot-scope="{ $index }">
        <el-button type="text" :disabled="readOnly" @click="baseList.splice($index,1)">删除
        </el-button>
      </div>
    </MTable>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
export default {
  name: 'ProcessFile',
  props: {
    baseList: {
      type: Array,
      default: () => []
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const columns = [
      {
        prop: 'index',
        label: '序号',
        slotName: 'index',
        width: 80,
        hiddenSearch: true
      },
      {
        prop: 'name',
        label: '名称',
        slotName: 'fileName',
        sortable: false,
        hiddenSearch: true
      },
      {
        prop: 'remark',
        label: '备注',
        slotName: 'remark',
        sortable: false,
        hiddenSearch: true
      },
      {
        slotName: 'action',
        label: '操作',
        fixed: 'right',
        hiddenSearch: true,
        sortable: false
      }
    ]
    return {
      columns: Object.freeze(columns),
      // baseList: [],
      uploadProps: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        showFileList: false,
        beforeUpload: this.beforeUpload,
        onSuccess: this.fileUploadChange,
        onError: this.fileUploadChange
      }
    }
  },
  watch: {
    // baseList(val) {
    //   this.baseList = [...this.baseList, ...val]
    // }
  },
  // created() {
  //   // console.log('this.baseList=', this.baseList)
  //   // this.baseList = [...this.baseList, ...this.baseList]
  // },
  methods: {
    beforeUpload(file) {
      const isLt50M = file.size / 1024 / 1024 < 50
      if (!isLt50M) {
        this.$message.error('上传文件大小不能超过 50M!')
      }
      return isLt50M
    },
    // 上传工艺文件
    fileUploadChange(res, file) {
      if (res.errorCode === 0) {
        this.$message.success('上传成功')
        this.baseList.push({
          serialNo: this.baseList.length + 1,
          name: file.name,
          url: res.result,
          remark: ''
        })
      } else {
        this.$message.error(res.result)
      }
    }
  }
}
</script>
