import { formatColumns } from '@/utils'
import { status, bindStatus } from '@/config/options.config'

export const columns = [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    hiddenSearch: true,
    slotName: 'index',
    minWidth: 80
  },
  {
    prop: 'code',
    label: '托盘编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'unit',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'status',
    label: '绑定状态',
    slotName: 'status',
    filters: formatColumns(bindStatus),
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'isEnabled',
    label: '托盘状态',
    sortable: false,
    hiddenSearch: true,
    slotName: 'isEnabled',
    filters: formatColumns(status)
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: {
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' }
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 205
  }
]

export const addColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    sortable: true,
    hiddenSearch: true,
    minWidth: 80
  },
  {
    prop: 'code',
    label: '托盘编码',
    sortable: false,
    hiddenSearch: true,
    slotName: 'code'
  },
  {
    prop: 'status',
    label: '绑定状态',
    slotName: 'status',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'isEnabled',
    label: '托盘状态',
    sortable: false,
    hiddenSearch: true,
    slotName: 'isEnabled'
  }
]
