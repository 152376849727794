import { status } from '@/config/options.config'

const RepositoryModel = [
  {
    prop: 'name',
    label: '库位名称',
    sortable: false,
    hiddenSearch: true,
    minWidth: 220,
    form: {
      index: 1,
      tagName: 'el-input',
      required: true
    }
  },
  {
    prop: 'isEnabled',
    label: '状态',
    sortable: false,
    hiddenSearch: true,
    slotName: 'isEnabled',
    form: {
      index: 2,
      tagName: 'el-select',
      options: status,
      required: false,
      defaultValue: 1
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 3,
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' }
    }
  },
  {
    prop: 'createTime',
    label: '创建时间',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'creator',
    label: '创建人',
    sortable: false,
    hiddenSearch: true
  },

  {
    slotName: 'action',
    label: '操作',
    sortable: false,
    hiddenSearch: true,
    width: 192
  }
]

const RepositoryListModel = [
  {
    prop: 'name',
    label: '仓库名称',
    sortable: false,
    hiddenSearch: true,
    minWidth: 220,
    form: {
      index: 1,
      tagName: 'el-input',
      required: true
    }
  }
]

const WarehouseSafeStock = [
  {
    prop: 'index',
    label: '序号',
    hiddenSearch: true,
    sortable: false,
    width: 60,
    slotName: 'index'
  },
  {
    prop: 'materialCode',
    label: '物料编号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 160,
    form: {
      index: 3,
      tagName: 'el-input',
      required: false,
      props: { disabled: true }
    }
  },
  {
    prop: 'materialName',
    label: '物料名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 130,
    form: {
      index: 4,
      tagName: 'el-input',
      required: false,
      props: { disabled: true }
    }
  },
  {
    prop: 'materialsTypeName',
    label: '物料类型',
    hiddenSearch: true,
    sortable: false,
    minWidth: 130,
    form: {
      index: 5,
      tagName: 'el-input',
      required: false,
      props: { disabled: true }
    }
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    hiddenSearch: true,
    sortable: false,
    minWidth: 60,
    form: {
      index: 6,
      tagName: 'el-input',
      required: false,
      props: { disabled: true }
    }
  },
  {
    prop: 'lowerLimit',
    label: '库存下限',
    slotName: 'lowerLimit',
    hiddenSearch: true,
    sortable: false,
    minWidth: 200,
    form: {
      index: 1,
      tagName: 'el-input-number',
      props: { controlsPosition: 'right', min: 1, step: 1, placeholder: '请输入' },
      required: false
    }
  },
  {
    prop: 'upperLimit',
    label: '库存上限',
    slotName: 'upperLimit',
    hiddenSearch: true,
    sortable: false,
    minWidth: 200,
    form: {
      index: 2,
      tagName: 'el-input-number',
      props: { controlsPosition: 'right', min: 1, step: 1, placeholder: '请输入' },
      required: false
    }
  },
  {
    slotName: 'action',
    label: '操作',
    sortable: false,
    hiddenSearch: true,
    width: 120
  }
]

const WarehouseCustomer = [{
  prop: 'index',
  label: '序号',
  hiddenSearch: true,
  sortable: false,
  width: 60,
  slotName: 'index'
},
{
  prop: 'consumerCode',
  label: '客户编码',
  hiddenSearch: true,
  sortable: false,
  minWidth: 160
}, {
  prop: 'consumer',
  label: '客户名称',
  hiddenSearch: true,
  sortable: false,
  minWidth: 160,
  form: {
    tagName: 'el-select',
    options: [],
    required: true,
    key: 'consumerId'
  }
}, {
  slotName: 'action',
  label: '操作',
  sortable: false,
  hiddenSearch: true,
  width: 120
}]

export default {
  RepositoryModel,
  RepositoryListModel,
  WarehouseSafeStock,
  WarehouseCustomer
}

