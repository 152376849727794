var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "inspection-plan-detail-wrapper" },
    [
      _c("div", { staticClass: "inspection-plan-detail-header flex-sbc" }, [
        _c(
          "div",
          {
            staticClass: "left",
            on: {
              click: function($event) {
                return _vm.$router.push({ name: "packageInfo" })
              }
            }
          },
          [
            _c("img", {
              staticStyle: { width: "8px" },
              attrs: {
                src: require("@/assets/information/procedure/左滑@2x.png")
              }
            }),
            _c(
              "span",
              { staticStyle: { "padding-left": "10px", cursor: "pointer" } },
              [_vm._v("容器详情")]
            )
          ]
        )
      ]),
      _c("div", { staticClass: "inspection-plan-detail-body" }, [
        _c("div", { staticClass: "detail-card" }, [
          _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
          _c(
            "div",
            { staticClass: "card-bot" },
            [
              _c(
                "el-row",
                { staticClass: "view-list", attrs: { gutter: 24 } },
                [
                  _vm._l(_vm.getColumns, function(item, index) {
                    return [
                      item.label
                        ? _c(
                            "el-col",
                            {
                              key: item.prop,
                              style: { marginBottom: index !== 4 ? "20px" : 0 },
                              attrs: { span: 6 }
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { color: "#9597AE" } },
                                [_vm._v(_vm._s(item.label) + "：")]
                              ),
                              _c("span", { staticClass: "bot-tis" }, [
                                _vm._v(_vm._s(_vm.getValue(item)))
                              ])
                            ]
                          )
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "detail-card" }, [
          _c("div", { staticClass: "card-tit" }, [_vm._v("物料")]),
          _c(
            "div",
            { staticClass: "card-bot" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-bottom": "10px" },
                  attrs: { size: "small", type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.addCheckItem()
                    }
                  }
                },
                [_vm._v("添加物料")]
              ),
              _c("MTable", {
                ref: "pointCheckTable",
                attrs: {
                  height: 300,
                  columns: _vm.matertialColumns,
                  "columns-setting": false,
                  "show-page": false,
                  data: _vm.selectCheckCheckItem,
                  "only-key": "uuid"
                },
                scopedSlots: _vm._u([
                  {
                    key: "index",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                    }
                  },
                  {
                    key: "nominalCapacity",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          !row.isEdit
                            ? _c("span", [_vm._v(_vm._s(row.nominalCapacity))])
                            : [
                                _c("el-input-number", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    "controls-position": "right",
                                    step: 1
                                  },
                                  model: {
                                    value: row.nominalCapacity,
                                    callback: function($$v) {
                                      _vm.$set(row, "nominalCapacity", $$v)
                                    },
                                    expression: "row.nominalCapacity"
                                  }
                                })
                              ]
                        ],
                        2
                      )
                    }
                  },
                  {
                    key: "action",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return _c(
                        "div",
                        {},
                        [
                          !row.isEdit
                            ? [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.modifyOrCopy(row, $index)
                                      }
                                    }
                                  },
                                  [_vm._v("修改")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.del(row)
                                      }
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              ]
                            : [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.cancel(row, $index)
                                      }
                                    }
                                  },
                                  [_vm._v("取消")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.save(row)
                                      }
                                    }
                                  },
                                  [_vm._v("保存")]
                                )
                              ]
                        ],
                        2
                      )
                    }
                  }
                ])
              })
            ],
            1
          )
        ])
      ]),
      _c("select-materials", {
        ref: "selectMaterials",
        attrs: {
          visible: _vm.showMaterials,
          title: "添加物料",
          "container-class-id": +_vm.params.id
        },
        on: {
          "update:visible": function($event) {
            _vm.showMaterials = $event
          },
          submitForm: _vm.submitMaterials
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }