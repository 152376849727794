export const columns = [
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    hiddenSearch: true,
    width: 80,
    slotName: 'index'
  },
  {
    prop: 'code',
    label: '工时原因编码',
    sortable: false,
    hiddenSearch: true,
    form: { index: 1, tagName: 'el-input', attrs: { maxlength: 50}, props: { disabled: false } }
  },
  {
    prop: 'type',
    label: '工时类型',
    sortable: false,
    hiddenSearch: true,
    slotName: 'type',
    form: {
      index: 2,
      tagName: 'el-select',
      key: 'type',
      options: [
        {id:0,name:'停工'},
        {id:1,name:'返工'}
      ]
    }
  },
  {
    prop: 'name',
    label: '工时原因',
    sortable: false,
    hiddenSearch: true,
    form: { index: 3, tagName: 'el-input', attrs: { maxlength: 50} }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: { index: 4,
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' },
      attrs: { maxlength: 250}
    }
  },
  {
    prop: 'createTime',
    label: '创建时间',
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'creator',
    label: '创建人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'lastUpdateTime',
    label: '更新时间',
    hiddenSearch: true,
    minWidth: 180
  },
  {
    prop: 'updater',
    label: '更新人',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 165
  }
]
