var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.readOnly
        ? _c(
            "el-upload",
            _vm._b(
              { staticClass: "b20", attrs: { type: "primary" } },
              "el-upload",
              _vm.uploadProps,
              false
            ),
            [
              _c("el-button", { attrs: { size: "small", type: "primary" } }, [
                _vm._v("上传附件")
              ])
            ],
            1
          )
        : _vm._e(),
      _c("MTable", {
        ref: "mTable",
        attrs: {
          "show-page": false,
          height: 300,
          columns: _vm.columns,
          data: _vm.baseList
        },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function(ref) {
              var $index = ref.$index
              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
            }
          },
          {
            key: "fileName",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [
                _c(
                  "a",
                  {
                    staticStyle: { color: "#607FFF" },
                    attrs: {
                      href: row.url,
                      download: row.name,
                      target: "_blank"
                    }
                  },
                  [_vm._v(_vm._s(row.name))]
                ),
                _c("img", {
                  staticStyle: { width: "11px", "margin-left": "5px" },
                  attrs: {
                    src: require("@/assets/information/procedure/附件@2x.png")
                  }
                })
              ])
            }
          },
          {
            key: "remark",
            fn: function(ref) {
              var row = ref.row
              return _c("el-input", {
                attrs: { disabled: _vm.readOnly, placeholder: "请输入" },
                model: {
                  value: row.remark,
                  callback: function($$v) {
                    _vm.$set(row, "remark", $$v)
                  },
                  expression: "row.remark"
                }
              })
            }
          },
          {
            key: "action",
            fn: function(ref) {
              var $index = ref.$index
              return _c(
                "div",
                {},
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", disabled: _vm.readOnly },
                      on: {
                        click: function($event) {
                          return _vm.baseList.splice($index, 1)
                        }
                      }
                    },
                    [_vm._v("删除 ")]
                  )
                ],
                1
              )
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }