// 列表
export const columnsAll = [
  // {
  //   type: 'selection',
  //   minWidth: 50,
  //   fixed: 'left'
  // },
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    sortable: false,
    minWidth: 80
  },
  {
    prop: 'status',
    label: '处理状态',
    hiddenSearch: true,
    sortable: false,
    slotName: 'status',
    minWidth: 120
  },
  {
    prop: 'repairNumber',
    label: '维修单号',
    hiddenSearch: true,
    sortable: 'custom',
    minWidth: 160
  },
  {
    prop: 'equipmentName',
    label: '设备名称',
    hiddenSearch: true,
    sortable: false,
    minWidth: 160
  },
  {
    prop: 'equipmentCode',
    label: '设备编码',
    hiddenSearch: true,
    sortable: false,
    minWidth: 120
  },
  {
    prop: 'stationName',
    label: '关联工位',
    minWidth: 100,
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'equipmentClassName',
    label: '设备类',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'repairMan',
    label: '维修人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'repairBeginTime',
    label: '维修开始时间',
    hiddenSearch: true,
    sortable: 'custom',
    minWidth: 180
  },
  {
    prop: 'repairEndTime',
    label: '维修结束时间',
    hiddenSearch: true,
    sortable: 'custom',
    minWidth: 180
  },
  {
    prop: 'faultDesc',
    label: '故障描述',
    hiddenSearch: true,
    sortable: false,
    slotName: 'faultDesc',
    minWidth: 200
  },
  {
    prop: 'emergenceDegree',
    label: '紧急程度',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'faultPicture',
    label: '故障图片',
    hiddenSearch: true,
    sortable: false,
    slotName: 'faultPicture'
  },
  {
    prop: 'faultNumber',
    label: '报修单号',
    hiddenSearch: true,
    sortable: false,
    minWidth: 160
  },
  {
    prop: 'reporter',
    label: '报修人',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'createTime',
    label: '报修时间',
    hiddenSearch: true,
    sortable: 'custom',
    minWidth: 180
  },
  {
    prop: 'organizationName',
    label: '业务部门',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'repairContinueTime',
    label: '维修用时',
    hiddenSearch: true,
    sortable: false,
    slotName: 'repairContinueTime'
  },
  {
    prop: 'faultReason',
    label: '故障原因',
    hiddenSearch: true,
    sortable: false
  },
  {
    prop: 'repairDesc',
    label: '维修描述',
    hiddenSearch: true,
    sortable: false,
    slotName: 'repairDesc',
    minWidth: 200
  },
  {
    prop: 'repairSparePartList',
    label: '消耗备件',
    sortable: false,
    hiddenSearch: true,
    slotName: 'repairSparePartList'
  }
]
export const spareColumns = [
  {
    prop: 'index',
    label: '序号',
    sortable: false,
    hiddenSearch: true,
    minWidth: 50,
    slotName: 'index'
  },
  {
    prop: 'sparePartDrawing',
    label: '图片',
    sortable: false,
    hiddenSearch: true,
    slotName: 'sparePartDrawing'
  },
  {
    prop: 'sparePartCode',
    label: '备件编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'sparePartName',
    label: '备件名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'requestQuantity',
    label: '数量',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'sparePartUnit',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'status',
    label: '备件状态',
    sortable: false,
    fixed: 'right',
    hiddenSearch: true,
    minWidth: 100,
    slotName: 'spareStatus'
  }
]

