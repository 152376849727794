<template>
  <div class="model-wrap">
    <FunctionHeader
      v-model="functionHeader.searchVal"
      search-title="请输入线边仓名称/编码"
      @search="$refs.mTable.setTableData(true)"
    />
    <MTable
      ref="mTable"
      default-expand-all
      :is-tree-data="true"
      :columns="columns"
      :height="height"
      :set-data-method="getTableData"
    >
      <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
      <div slot="action" slot-scope="{ row }">
        <el-button v-if="permission('Modify')" type="text" @click="modifyOrCopy(row, 'modify')">修改</el-button>
      </div>
    </MTable>

    <MDialog v-model="visible" :title="messages[currentType]" @onOk="submitForm">
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList" />
    </MDialog>

  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import conventionMixin from '@/mixin/conventional-page'
import api from '@/api/information/production/sit-warehouse'
import formList from './form-list'
import columns from './columns'

export default {
  name: 'SitWarehouse',
  components: { FunctionHeader },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      params: {
        radio: 'SiteWarehouse'
      },
      functionHeader: {
        searchVal: ''
      },
      columns,
      visible: false,
      formData: {},
      formList: formList,
      currentType: '新增',
      formOptions: {},
      searchKeyword: true
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 192
    }
  },
  methods: {
    createSetData(raw) {
      return {
        code: raw.code,
        name: raw.name,
        areaCode: raw.areaCode,
        areaName: raw.areaName
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    }
  }
}
</script>

<style scoped lang="scss">
.model-wrap {
  padding: 10px;

  .function-header-wrap {
    padding-top: 0;
  }
}
</style>
