import columns from './columns'
import { transformColumnsToForm } from '@/utils'

const formList = {
  RepositoryModel: transformColumnsToForm(columns.RepositoryModel),
  RepositoryListModel: transformColumnsToForm(columns.RepositoryListModel),
  WarehouseSafeStock: transformColumnsToForm(columns.WarehouseSafeStock),
  WarehouseCustomer: transformColumnsToForm(columns.WarehouseCustomer)
}

export default formList
