var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "materials-setting-wrap" },
    [
      _c(
        "el-radio-group",
        {
          staticClass: "m-radio-group",
          on: { change: _vm.radioChange },
          model: {
            value: _vm.params.radio,
            callback: function($$v) {
              _vm.$set(_vm.params, "radio", $$v)
            },
            expression: "params.radio"
          }
        },
        [
          _vm.permission("bomList")
            ? _c("el-radio-button", { attrs: { label: "bom" } }, [
                _vm._v("BOM")
              ])
            : _vm._e(),
          _vm.permission("processList")
            ? _c("el-radio-button", { attrs: { label: "process" } }, [
                _vm._v("工艺")
              ])
            : _vm._e(),
          _vm.permission("drawAndFileList")
            ? _c("el-radio-button", { attrs: { label: "drawAndFile" } }, [
                _vm._v("图纸及附件")
              ])
            : _vm._e(),
          _vm.permission("inspectionProjectGroupProcessList")
            ? _c(
                "el-radio-button",
                { attrs: { label: "inspectionProjectGroupProcess" } },
                [_vm._v("过程检")]
              )
            : _vm._e(),
          _vm.permission("inspectionInComingList")
            ? _c(
                "el-radio-button",
                { attrs: { label: "inspectionInComing" } },
                [
                  _vm._v(
                    _vm._s(_vm.memberCode === "dmdz" ? "放行单" : "来料检") +
                      " "
                  )
                ]
              )
            : _vm._e(),
          _vm.permission("inspectionOutboundList")
            ? _c(
                "el-radio-button",
                { attrs: { label: "inspectionOutbound" } },
                [_vm._v("出货检")]
              )
            : _vm._e()
        ],
        1
      ),
      _vm.permission(_vm.params.radio + "List")
        ? _c(
            "div",
            { staticClass: "top" },
            [
              _c(
                "SearchForm",
                {
                  ref: "searchForm",
                  attrs: {
                    "immediately-query": true,
                    "default-form": _vm.searchFormData,
                    "form-list-extend":
                      _vm.params.radio === "bom"
                        ? _vm.searchFormList
                        : _vm.searchFormLists
                  }
                },
                [
                  _c("m-remote-select", {
                    staticClass: "input-prepend",
                    attrs: {
                      slot: "prepend",
                      placeholder: "请选择物料",
                      "interface-fun": _vm.materialsApi.getMaterialsListPage,
                      condition: _vm.baseCondition,
                      options: _vm.formOptions.materialsList,
                      clearable: false
                    },
                    on: {
                      "update:options": function($event) {
                        return _vm.$set(
                          _vm.formOptions,
                          "materialsList",
                          $event
                        )
                      },
                      "on-change": _vm.materialsChange
                    },
                    slot: "prepend",
                    model: {
                      value: _vm.params.materialsId,
                      callback: function($$v) {
                        _vm.$set(_vm.params, "materialsId", $$v)
                      },
                      expression: "params.materialsId"
                    }
                  }),
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-more" },
                    on: { click: _vm.selectMaterials },
                    slot: "append"
                  }),
                  _c("div", {
                    staticStyle: { height: "32px" },
                    attrs: { slot: "prepends" },
                    slot: "prepends"
                  }),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        slot: "prepends",
                        size: "small",
                        type: "primary"
                      },
                      on: { click: _vm.getBom },
                      slot: "prepends"
                    },
                    [_vm._v("确定")]
                  )
                ],
                1
              ),
              _vm.params.materialsId
                ? _c("div", { staticClass: "material-font" }, [
                    _c("div", { staticStyle: { "padding-right": "20px" } }, [
                      _vm._v(
                        "物料编码：" + _vm._s(_vm.currentMaterials("code"))
                      )
                    ]),
                    _c("div", { staticStyle: { "padding-right": "20px" } }, [
                      _vm._v(
                        "物料名称：" + _vm._s(_vm.currentMaterials("name"))
                      )
                    ]),
                    _c("div", { staticStyle: { "padding-right": "20px" } }, [
                      _vm._v(
                        "物料规格：" +
                          _vm._s(_vm.currentMaterials("specifications"))
                      )
                    ])
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "bottom", style: { maxHeight: _vm.height + "px" } },
        [
          _vm.params.radio === "bom" && _vm.params.materialsId
            ? _c(
                "div",
                [
                  _c(
                    "FunctionHeader",
                    {
                      attrs: {
                        "export-name": _vm.functionHeader.exportName,
                        "import-name": _vm.functionHeader.importName,
                        "hidden-search": true,
                        "export-params": _vm.searchData
                      }
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "all" }, slot: "all" },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { "margin-left": "15px" },
                              attrs: { size: "small", type: "primary" },
                              on: { click: _vm.getAll }
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.isFirst ? "折叠全部" : "展开全部")
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "MTable",
                    {
                      ref: "mTable",
                      attrs: {
                        "only-key": "uuid",
                        "is-tree-data": true,
                        columns: _vm.columnsAll,
                        height: _vm.heights,
                        "show-page": false,
                        data: _vm.list,
                        "columns-setting": false,
                        "tree-props": {
                          children: "childBomList",
                          hasChildren: "hasChildren"
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "index",
                            fn: function(ref) {
                              var row = ref.row
                              var column = ref.column
                              var $index = ref.$index
                              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                            }
                          },
                          {
                            key: "quantityStandard",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _vm._v(
                                  _vm._s(_vm.fixData(row.quantityStandard))
                                )
                              ])
                            }
                          },
                          {
                            key: "action",
                            fn: function(ref) {
                              var row = ref.row
                              return _c(
                                "div",
                                {},
                                [
                                  _vm.permission("Modify")
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function($event) {
                                              return _vm.modifyOrCopy(row)
                                            }
                                          }
                                        },
                                        [_vm._v("编辑")]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            }
                          }
                        ],
                        null,
                        false,
                        4064030433
                      )
                    },
                    [_vm._v(" > ")]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.params.radio === "process"
            ? _c(
                "div",
                [
                  _vm.permission("SetProcess")
                    ? _c(
                        "el-button",
                        {
                          staticClass: "b10",
                          attrs: {
                            type: "primary",
                            disabled: !!_vm.processFlowVersion
                          },
                          on: { click: _vm.selectProcessFlow }
                        },
                        [_vm._v(" 选择工艺路线 ")]
                      )
                    : _vm._e(),
                  _vm.processFlowVersion
                    ? _c(
                        "div",
                        { staticClass: "process-info" },
                        [
                          _vm._v(
                            " 工艺路线：" + _vm._s(_vm.baseFormData.name) + " "
                          ),
                          _c("el-tag", { attrs: { size: "small" } }, [
                            _vm._v(_vm._s(_vm.processFlowVersion.version))
                          ]),
                          _vm.permission("DelProcess")
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: { click: _vm.delProcess }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: require("@/assets/l_c_M_images/垃圾桶.png"),
                                      alt: ""
                                    }
                                  })
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.processFlowVersion
                    ? _c("div", { staticClass: "process-detail-body" }, [
                        _c("div", { staticClass: "detail-card" }, [
                          _c("div", { staticClass: "card-tit" }, [
                            _vm._v("基本信息")
                          ]),
                          _c(
                            "div",
                            { staticClass: "card-bot" },
                            [
                              _c(
                                "el-row",
                                {
                                  staticClass: "view-list",
                                  attrs: { gutter: 24 }
                                },
                                _vm._l(_vm.columns.base, function(item, index) {
                                  return _c(
                                    "el-col",
                                    {
                                      key: item.prop,
                                      style: {
                                        marginBottom: index !== 6 ? "20px" : 0,
                                        display: "inline-flex"
                                      },
                                      attrs: {
                                        title: _vm.getValue(item),
                                        span: 8
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "word-break": "keep-all"
                                          }
                                        },
                                        [_vm._v(_vm._s(item.label) + "：")]
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "word-break": "break-all"
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.getValue(item)))]
                                      )
                                    ]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ]),
                        _c("div", { staticClass: "detail-card" }, [
                          _c("div", { staticClass: "card-tit" }, [
                            _vm._v("工艺路线图")
                          ]),
                          _c(
                            "div",
                            { staticClass: "card-bot process-card" },
                            [
                              _c("ProcessFlow", {
                                ref: "processFlow",
                                attrs: {
                                  "read-only": true,
                                  "concise-process-flow": true,
                                  "process-flow-detail": _vm.processFlowVersion
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ])
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm.params.radio === "drawAndFile" && _vm.params.materialsId
            ? _c("div", { staticClass: "detail-card" }, [
                _c("div", { staticClass: "card-tit" }, [
                  _vm._v("工序图纸及附件")
                ]),
                _c(
                  "div",
                  { staticClass: "card-bot" },
                  [
                    _vm._m(0),
                    _c("br"),
                    _vm.permission("UploadDrawing")
                      ? _c(
                          "el-upload",
                          _vm._b(
                            { staticClass: "b20", attrs: { type: "primary" } },
                            "el-upload",
                            _vm.uploadProps,
                            false
                          ),
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "primary" },
                                on: {
                                  click: function($event) {
                                    _vm.uploadKey = "drawingsList"
                                  }
                                }
                              },
                              [_vm._v("上传图纸")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("MTable", {
                      ref: "mTable",
                      attrs: {
                        "show-page": false,
                        height: 300,
                        columns: _vm.columns.drawings,
                        data: _vm.drawingsList
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "index",
                            fn: function(ref) {
                              var row = ref.row
                              var $index = ref.$index
                              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                            }
                          },
                          {
                            key: "fileName",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { color: "#607FFF" },
                                    attrs: {
                                      href: row.url,
                                      download: row.name,
                                      target: "_blank"
                                    }
                                  },
                                  [_vm._v(_vm._s(row.name))]
                                ),
                                _c("img", {
                                  staticStyle: {
                                    width: "11px",
                                    "margin-left": "5px"
                                  },
                                  attrs: {
                                    src: require("@/assets/information/procedure/附件@2x.png")
                                  }
                                })
                              ])
                            }
                          },
                          {
                            key: "action",
                            fn: function(ref) {
                              var row = ref.row
                              return _c(
                                "div",
                                {},
                                [
                                  _vm.permission("DelDrawing")
                                    ? _c("DelPopover", {
                                        on: {
                                          onOk: function(callback) {
                                            return _vm.delFiles(
                                              "drawingsList",
                                              row,
                                              callback
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            }
                          }
                        ],
                        null,
                        false,
                        109253243
                      )
                    }),
                    _c("div", { staticClass: "bt20 flex-sbc" }, [
                      _c("img", {
                        staticStyle: { width: "18px" },
                        attrs: {
                          src: require("@/assets/information/procedure/其他附件@2x.png")
                        }
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "l10",
                          staticStyle: { position: "relative", top: "2px" },
                          on: {
                            click: function($event) {
                              _vm.uploadKey = "drawingsList"
                            }
                          }
                        },
                        [_vm._v("其他附件")]
                      )
                    ]),
                    _c("br"),
                    _vm.permission("UploadFile")
                      ? _c(
                          "el-upload",
                          _vm._b(
                            { staticClass: "b20", attrs: { type: "primary" } },
                            "el-upload",
                            _vm.uploadProps,
                            false
                          ),
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "primary" },
                                on: {
                                  click: function($event) {
                                    _vm.uploadKey = "filesList"
                                  }
                                }
                              },
                              [_vm._v("上传附件")]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c("MTable", {
                      ref: "mTable",
                      attrs: {
                        "show-page": false,
                        height: 300,
                        columns: _vm.columns.files,
                        data: _vm.filesList
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "index",
                            fn: function(ref) {
                              var row = ref.row
                              var $index = ref.$index
                              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                            }
                          },
                          {
                            key: "fileName",
                            fn: function(ref) {
                              var row = ref.row
                              return _c("div", {}, [
                                _c(
                                  "a",
                                  {
                                    staticStyle: { color: "#607FFF" },
                                    attrs: {
                                      href: row.url,
                                      download: row.name,
                                      target: "_blank"
                                    }
                                  },
                                  [_vm._v(_vm._s(row.name))]
                                ),
                                _c("img", {
                                  staticStyle: {
                                    width: "11px",
                                    "margin-left": "5px"
                                  },
                                  attrs: {
                                    src: require("@/assets/information/procedure/附件@2x.png")
                                  }
                                })
                              ])
                            }
                          },
                          {
                            key: "action",
                            fn: function(ref) {
                              var row = ref.row
                              return _c(
                                "div",
                                {},
                                [
                                  _vm.permission("DelFile")
                                    ? _c("DelPopover", {
                                        on: {
                                          onOk: function(callback) {
                                            return _vm.delFiles(
                                              "filesList",
                                              row,
                                              callback
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            }
                          }
                        ],
                        null,
                        false,
                        1538810171
                      )
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          [
            "inspectionProjectGroupProcess",
            "inspectionProjectGroupFirst"
          ].includes(_vm.params.radio)
            ? _c(
                "div",
                {
                  staticClass: "inspection-wrapper",
                  style: { top: _vm.params.materialsId ? "225px" : "203px" }
                },
                [
                  _vm.params.materialsId
                    ? _c("inspection-setting", {
                        attrs: {
                          "current-key": _vm.params.radio,
                          "materials-info": _vm.materialsInfo
                        }
                      })
                    : _c("MBlank", {
                        staticClass: "inspection-no-container",
                        attrs: { title: "暂无内容" }
                      })
                ],
                1
              )
            : _vm._e(),
          ["inspectionInComing", "inspectionOutbound"].includes(
            _vm.params.radio
          )
            ? _c(
                "div",
                [
                  _vm.params.materialsId
                    ? _c("inspection-setting2", {
                        attrs: {
                          "current-key": _vm.params.radio,
                          "materials-id": _vm.materialsInfo.materialsId
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c("materials-list", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: ["process", "drawAndFile"].includes(_vm.params.radio),
            expression: "['process', 'drawAndFile'].includes(params.radio)"
          }
        ],
        staticStyle: {
          position: "absolute",
          top: "12px",
          right: "0",
          bottom: "37px"
        },
        attrs: { condition: _vm.materialsListCondition },
        on: { cardClick: _vm.setMaterialId }
      }),
      _c("SelectMaterials", {
        attrs: {
          visible: _vm.materialVisible,
          conditions: _vm.baseCondition,
          single: true
        },
        on: {
          "update:visible": function($event) {
            _vm.materialVisible = $event
          },
          submitForm: _vm.setMaterialId
        }
      }),
      _c("SelectProcessFlow", {
        attrs: {
          visible: _vm.processFlowVisible,
          "select-process-and-version-ids": _vm.processIdAndVersionId,
          "is-add-process-flow": true
        },
        on: {
          "update:visible": function($event) {
            _vm.processFlowVisible = $event
          },
          submit: _vm.selectFlow
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "b20 flex-sbc" }, [
      _c("img", {
        staticStyle: { width: "18px" },
        attrs: { src: require("@/assets/information/procedure/图纸@2x.png") }
      }),
      _c(
        "span",
        {
          staticClass: "l10",
          staticStyle: { position: "relative", top: "2px" }
        },
        [_vm._v("图纸")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }