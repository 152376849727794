/** write by luomingzhi */
import { status, areaType } from '@/config/options.config'
import { formatColumns } from '@/utils'
let functionList = {}
export const getFunctionList = val => {
  functionList = val
}

export const columns = [
  {
    prop: 'id',
    label: 'ID',
    sortable: true,
    hiddenSearch: true
  },
  {
    prop: 'code',
    label: '区域编码',
    sortable: false,
    hiddenSearch: true,
    form: { index: 0, tagName: 'el-input', attrs: { placeholder: '选择区域类型可自动生成编码' }}
  },
  {
    prop: 'name',
    label: '区域名称',
    sortable: false,
    hiddenSearch: true,
    form: { index: 1, tagName: 'el-input' }
  },
  {
    prop: 'type',
    label: '区域类型',
    sortable: false,
    hiddenSearch: true,
    slotName: 'type',
    filters: formatColumns(areaType),
    form: {
      index: 2,
      tagName: 'el-select',
      key: 'type',
      options: areaType,
      on: {
        change: (val) => { functionList.areaTypeChange(val) }
      }
    }
  },
  {
    prop: 'isEnabled',
    label: '区域状态',
    sortable: false,
    hiddenSearch: true,
    slotName: 'isEnabled',
    filters: formatColumns(status),
    form: {
      index: 4,
      tagName: 'el-select',
      options: status,
      defaultValue: 1
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: { index: 5,
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' }
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 225
  }
]
