var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tray-wrap" },
    [
      _c("FunctionHeader", {
        attrs: {
          "search-title": "请输入托盘编码",
          tags: _vm.functionHeader.tags,
          "export-name": _vm.functionHeader.exportName,
          "export-params": _vm.functionHeader.exportParams
        },
        on: {
          search: function($event) {
            return _vm.$refs.mTable.setTableData(true)
          }
        },
        model: {
          value: _vm.functionHeader.searchVal,
          callback: function($$v) {
            _vm.$set(_vm.functionHeader, "searchVal", $$v)
          },
          expression: "functionHeader.searchVal"
        }
      }),
      _c("MTable", {
        ref: "mTable",
        attrs: {
          columns: _vm.columns,
          height: _vm.height,
          "set-data-method": _vm.getTableData
        },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function(ref) {
              var row = ref.row
              var $index = ref.$index
              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
            }
          },
          {
            key: "isEnabled",
            fn: function(ref) {
              var row = ref.row
              return _c("div", { staticClass: "is-enabled" }, [
                _c("div", {
                  style: { background: row.isEnabled ? "#24CAD7" : "#FFCC78" }
                }),
                _c("div", [_vm._v(_vm._s(row.isEnabled ? "启用" : "停用"))])
              ])
            }
          },
          {
            key: "status",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [
                _vm._v(_vm._s(row.status ? "已绑定" : "未绑定"))
              ])
            }
          },
          {
            key: "action",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                {},
                [
                  _vm.permission("PrintCode")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.printCode(row)
                            }
                          }
                        },
                        [_vm._v("打印托盘编码")]
                      )
                    : _vm._e(),
                  _vm.permission("PrintCode")
                    ? _c("el-divider", { attrs: { direction: "vertical" } })
                    : _vm._e(),
                  _vm.permission("Status")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.switchStatus(row, _vm.params.radio)
                            }
                          }
                        },
                        [_vm._v(_vm._s(row.isEnabled ? "停用" : "启用"))]
                      )
                    : _vm._e(),
                  _vm.permission("Status")
                    ? _c("el-divider", { attrs: { direction: "vertical" } })
                    : _vm._e(),
                  _vm.permission("Del")
                    ? _c("DelPopover", {
                        on: {
                          onOk: function(callback) {
                            return _vm.del(row, callback)
                          }
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            }
          }
        ])
      }),
      _c(
        "MDialog",
        {
          attrs: { title: "新增" },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "el-row",
            {
              staticStyle: { "padding-bottom": "10px" },
              attrs: { gutter: 20, type: "flex", align: "middle" }
            },
            [
              _c(
                "el-col",
                {
                  staticStyle: { "font-size": "16px", "font-weight": "700" },
                  attrs: { span: 5 }
                },
                [_vm._v("输入数量生成托盘:")]
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "100%" },
                    attrs: { "controls-position": "right", min: 1 },
                    model: {
                      value: _vm.form.number,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "number", $$v)
                      },
                      expression: "form.number"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("el-input", {
                    staticStyle: { width: "100%" },
                    attrs: { "controls-position": "right" },
                    model: {
                      value: _vm.form.unit,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "unit", $$v)
                      },
                      expression: "form.unit"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.createTray }
                    },
                    [_vm._v("生成托盘")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "MTable",
            {
              attrs: {
                data: _vm.trayAddData,
                "show-page": false,
                columns: _vm.addColumns,
                height: _vm.addHeight
              },
              scopedSlots: _vm._u([
                {
                  key: "index",
                  fn: function(ref) {
                    var $index = ref.$index
                    return _c("div", {}, [_vm._v(_vm._s($index))])
                  }
                },
                {
                  key: "code",
                  fn: function(ref) {
                    var row = ref.row
                    return _c("div", {}, [_vm._v(_vm._s(row))])
                  }
                }
              ])
            },
            [
              _c("div", { attrs: { slot: "status" }, slot: "status" }, [
                _vm._v("未绑定")
              ]),
              _c(
                "div",
                {
                  staticClass: "is-enabled",
                  attrs: { slot: "isEnabled" },
                  slot: "isEnabled"
                },
                [
                  _c("div", { staticStyle: { background: "#24CAD7" } }),
                  _c("div", [_vm._v("启用")])
                ]
              )
            ]
          )
        ],
        1
      ),
      _c("PrintCode", { ref: "printCode" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }