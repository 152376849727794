import { columns } from './columns'
import _ from 'lodash'
import { transformColumnsToForm } from '@/utils'

const newColumns = _.cloneDeep(columns)

newColumns.splice(3, 0, {
  prop: 'parentId',
  label: '所属区域',
  form: {
    hidden: true,
    index: 3,
    tagName: 'el-select',
    options: []
  }
})
const formList = transformColumnsToForm(newColumns)

export default formList
