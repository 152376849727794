var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "inspection-setting-container"
    },
    [
      _vm.materialsInfo.processId && _vm.currentProcedure.id
        ? [
            _vm.permission(_vm.currentKey + "Modify")
              ? _c(
                  "div",
                  { staticClass: "footer" },
                  [
                    !_vm.readOnly
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "default", size: "small" },
                            on: { click: _vm.cancel }
                          },
                          [_vm._v("取消")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          type: "primary",
                          size: "small",
                          loading: _vm.buttonLoading
                        },
                        on: { click: _vm.editInspection }
                      },
                      [_vm._v(_vm._s(!_vm.readOnly ? "保存" : "编辑"))]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "left" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v(
                  _vm._s(
                    _vm.currentKey === "inspectionProjectGroupProcess"
                      ? "过程检"
                      : "首检"
                  )
                )
              ]),
              _c("div", { staticClass: "procedure-list" }, [
                _vm._m(0),
                _c(
                  "div",
                  { staticClass: "procedure-li" },
                  _vm._l(_vm.procedureList, function(item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "procedure",
                        class: {
                          active:
                            _vm.currentProcedure.uuid === item.procedureUuid
                        },
                        on: {
                          click: function($event) {
                            return _vm.setCurrentProcedure(item)
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "ellipsis",
                            staticStyle: { "max-width": "200px" }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                index + 1 > 9 ? index + 1 : "0" + (index + 1)
                              ) +
                                " | " +
                                _vm._s(item.procedureName)
                            )
                          ]
                        ),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              item.procedureIndex > 9
                                ? item.procedureIndex
                                : "0" + item.procedureIndex
                            )
                          )
                        ])
                      ]
                    )
                  }),
                  0
                )
              ])
            ]),
            _vm.currentProcedure.id
              ? _c(
                  "div",
                  {
                    staticClass: "right",
                    style: {
                      paddingBottom: _vm.permission(_vm.currentKey + "Modify")
                        ? "48px"
                        : "0"
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticStyle: { background: "#FFFFFF" } },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "flex-sbc",
                            staticStyle: { width: "100%" }
                          },
                          [
                            _c("div", { staticClass: "flex-sbc" }, [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "padding-left": "40px",
                                    "padding-right": "20px"
                                  }
                                },
                                [
                                  _c("span", [_vm._v("是否需要质检：")]),
                                  _c(
                                    "el-radio-group",
                                    {
                                      attrs: {
                                        name: _vm.currentKey,
                                        disabled: _vm.readOnly
                                      },
                                      on: {
                                        change: function(val) {
                                          _vm.isNeedQualityInspectionChange(
                                            val,
                                            _vm.currentKey
                                          )
                                        }
                                      },
                                      model: {
                                        value:
                                          _vm.qualityInspectionPlan[
                                            _vm.currentKey
                                          ].isNeedInspect,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.qualityInspectionPlan[
                                              _vm.currentKey
                                            ],
                                            "isNeedInspect",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "qualityInspectionPlan[currentKey].isNeedInspect"
                                      }
                                    },
                                    [
                                      _c("el-radio", { attrs: { label: 1 } }, [
                                        _vm._v("是")
                                      ]),
                                      _c("el-radio", { attrs: { label: 0 } }, [
                                        _vm._v("否")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "padding-left": "40px",
                                    "padding-right": "20px"
                                  }
                                },
                                [
                                  _c("span", [_vm._v("质检完成才能出站：")]),
                                  _c(
                                    "el-radio-group",
                                    {
                                      attrs: {
                                        name: _vm.currentKey,
                                        disabled: _vm.readOnly
                                      },
                                      model: {
                                        value: _vm.currentProcedure.isMustCheck,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.currentProcedure,
                                            "isMustCheck",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "currentProcedure.isMustCheck"
                                      }
                                    },
                                    [
                                      _c("el-radio", { attrs: { label: 1 } }, [
                                        _vm._v("是")
                                      ]),
                                      _c("el-radio", { attrs: { label: 0 } }, [
                                        _vm._v("否")
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.qualityInspectionPlan[_vm.currentKey]
                                .isNeedInspect === 1
                                ? _c(
                                    "div",
                                    {
                                      staticStyle: { "padding-right": "20px" }
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "padding-left": "10px"
                                          }
                                        },
                                        [_vm._v("检验类型：")]
                                      ),
                                      _c(
                                        "el-select",
                                        {
                                          attrs: { disabled: _vm.readOnly },
                                          model: {
                                            value:
                                              _vm.currentProcedure
                                                .isFullInspection,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.currentProcedure,
                                                "isFullInspection",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "currentProcedure.isFullInspection"
                                          }
                                        },
                                        [
                                          _c(
                                            "el-option",
                                            {
                                              attrs: { label: "全检", value: 1 }
                                            },
                                            [_vm._v("全检")]
                                          ),
                                          _c(
                                            "el-option",
                                            {
                                              attrs: { label: "抽检", value: 0 }
                                            },
                                            [_vm._v("抽检")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.qualityInspectionPlan[_vm.currentKey]
                                .isNeedInspect === 1
                                ? _c(
                                    "div",
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "padding-left": "10px"
                                          }
                                        },
                                        [_vm._v("质检方案：")]
                                      ),
                                      _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "200px" },
                                          attrs: {
                                            size: "small",
                                            disabled: "",
                                            filterable: "",
                                            placeholder: "请选择质检方案"
                                          },
                                          on: {
                                            change: function(val) {
                                              return _vm.qualityInspectionChange(
                                                val,
                                                _vm.currentKey
                                              )
                                            }
                                          },
                                          model: {
                                            value:
                                              _vm.qualityInspectionPlan[
                                                _vm.currentKey
                                              ].inspectId,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.qualityInspectionPlan[
                                                  _vm.currentKey
                                                ],
                                                "inspectId",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "qualityInspectionPlan[currentKey].inspectId"
                                          }
                                        },
                                        _vm._l(
                                          _vm.formOptions.inspectionPlanList,
                                          function(is) {
                                            return _c("el-option", {
                                              key: is.id,
                                              attrs: {
                                                label: is.name,
                                                value: is.id
                                              }
                                            })
                                          }
                                        ),
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ]),
                            _vm.permission(_vm.currentKey + "ReDefault")
                              ? _c(
                                  "el-button",
                                  {
                                    staticStyle: { "padding-right": "20px" },
                                    attrs: { type: "text", size: "small" },
                                    on: { click: _vm.reDefault }
                                  },
                                  [_vm._v("恢复默认")]
                                )
                              : _vm._e()
                          ],
                          1
                        ),
                        _vm.qualityInspectionPlan[_vm.currentKey]
                          .isNeedInspect === 1 &&
                        _vm.qualityInspectionPlan[_vm.currentKey].inspectId
                          ? _c("quality-inspection-project", {
                              ref: "qualityInspectionProject",
                              staticStyle: { padding: "10px" },
                              attrs: {
                                "inspection-entry-map":
                                  _vm.qualityInspectionPlan[_vm.currentKey]
                                    .inspectEntryMap,
                                attachments:
                                  _vm.qualityInspectionPlan[_vm.currentKey]
                                    .inspectAttachments,
                                "user-list":
                                  _vm.qualityInspectionPlan[_vm.currentKey]
                                    .inspectorIdList,
                                "read-only": _vm.readOnly
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]
                )
              : _vm._e()
          ]
        : _vm.materialsInfo.processId
        ? _c("MBlank", {
            attrs: {
              title:
                _vm.currentKey === "inspectionProjectGroupProcess"
                  ? "物料所有工序均无需过程检"
                  : "物料所有工序均无需首检"
            }
          })
        : _c("MBlank", { attrs: { title: "物料未绑定工艺" } })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top-title" }, [
      _c("span", [_vm._v("序号 | 工序名称")]),
      _c("span", [_vm._v("工序顺序")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }