var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "model-wrap" },
    [
      _c("FunctionHeader", {
        attrs: {
          "search-title": "请输入区域编码/名称",
          "export-params": _vm.functionHeader.exportParams,
          tags: _vm.functionHeader.tags,
          "export-name": _vm.functionHeader.exportName
        },
        on: {
          search: function($event) {
            return _vm.$refs.mTable.setTableData(true)
          }
        },
        model: {
          value: _vm.functionHeader.searchVal,
          callback: function($$v) {
            _vm.$set(_vm.functionHeader, "searchVal", $$v)
          },
          expression: "functionHeader.searchVal"
        }
      }),
      _c("MTable", {
        ref: "mTable",
        attrs: {
          "default-expand-all": "",
          "is-tree-data": true,
          columns: _vm.columns,
          height: _vm.height,
          "show-page": false,
          "set-data-method": _vm.getTableData
        },
        scopedSlots: _vm._u([
          {
            key: "index",
            fn: function(ref) {
              var row = ref.row
              var $index = ref.$index
              return _c("div", {}, [_vm._v(_vm._s($index + 1))])
            }
          },
          {
            key: "isEnabled",
            fn: function(ref) {
              var row = ref.row
              return _c("div", { staticClass: "is-enabled" }, [
                _c("div", {
                  style: { background: row.isEnabled ? "#24CAD7" : "#FFCC78" }
                }),
                _c("div", [_vm._v(_vm._s(row.isEnabled ? "启用" : "停用"))])
              ])
            }
          },
          {
            key: "type",
            fn: function(ref) {
              var row = ref.row
              return _c("div", {}, [_vm._v(_vm._s(_vm.areaTypeName[row.type]))])
            }
          },
          {
            key: "action",
            fn: function(ref) {
              var row = ref.row
              return _c(
                "div",
                {},
                [
                  ![2].includes(row.type) && _vm.permission("AddChild")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.addChild(row)
                            }
                          }
                        },
                        [_vm._v("新建下级")]
                      )
                    : _c(
                        "el-button",
                        { attrs: { type: "text", disabled: "" } },
                        [_vm._v("新建下级")]
                      ),
                  _vm.permission("AddChild")
                    ? _c("el-divider", { attrs: { direction: "vertical" } })
                    : _vm._e(),
                  _vm.permission("Copy")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.modifyOrCopy(row, "copy")
                            }
                          }
                        },
                        [_vm._v("复制")]
                      )
                    : _vm._e(),
                  _vm.permission("Copy")
                    ? _c("el-divider", { attrs: { direction: "vertical" } })
                    : _vm._e(),
                  _vm.permission("Status")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.switchStatus(row, _vm.params.radio)
                            }
                          }
                        },
                        [_vm._v(_vm._s(row.isEnabled ? "停用" : "启用"))]
                      )
                    : _vm._e(),
                  _vm.permission("Status")
                    ? _c("el-divider", { attrs: { direction: "vertical" } })
                    : _vm._e(),
                  _vm.permission("Modify")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.modifyOrCopy(row, "modify")
                            }
                          }
                        },
                        [_vm._v("修改")]
                      )
                    : _vm._e(),
                  _vm.permission("Del")
                    ? _c("el-divider", { attrs: { direction: "vertical" } })
                    : _vm._e(),
                  _vm.permission("Del")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.del(row)
                            }
                          }
                        },
                        [_vm._v("删除")]
                      )
                    : _vm._e()
                ],
                1
              )
            }
          }
        ])
      }),
      _c(
        "MDialog",
        {
          attrs: { title: _vm.messages[_vm.currentType] },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c("MFormBuilder", {
            ref: "formBuild",
            attrs: { "form-data": _vm.formData, "form-list": _vm.formList }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }