<template>
  <div class="materials-wrap">
    <div class="bottom">
      <Split :offset.sync="split.offset" :height="splitHeight">
        <template slot="left">
          <MaterialsClass @nodeClick="setMaterialsClassId" />
        </template>
        <template slot="right">
          <div class="top">
            <SearchForm :form-list-extend="searchFormList" :form-data="searchFormData" @search="searchTable" />
          </div>
          <div class="materials-info">
            <FunctionHeader
              ref="functionHeader"
              v-model="functionHeader.searchVal"
              search-title="请输入名称/编号/规格"
              :tags="!exportSelectionIds.length ?  functionHeader.tags :[]"
              :import-name="!exportSelectionIds.length ? functionHeader.importName : ''"
              :export-name="functionHeader.exportName"
              :export-params="functionHeader.exportParams"
              :show-export="!exportSelectionIds.length ? true : false"
              @search="$refs.mTable.setTableData(true)"
            >
              <div slot="all" v-show="exportSelectionIds.length">
                <el-button v-if="permission('Export')" size="small" type="primary"  @click="allMore('batchExport')">批量导出</el-button>
                <el-button v-if="permission('Del')" type="primary" size="small" @click="allMore('batchDel')">批量删除</el-button>
                <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
                  已选
                  <span style="color:#607FFF">{{ exportSelectionIds.length }}</span>
                  / {{ total }}项
                </span>
                  <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
              </div>
            </FunctionHeader>
            <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData" @selection-change="selectionChange">
              <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
              <div slot="drawing" slot-scope="{ row }">
                <el-image
                  v-if="row.picture"
                  :src="row.picture"
                  class="drawing-img"
                  :preview-src-list="[row.picture]"
                />
              </div>
              <NewPageOpen v-if="isShowChild" slot="code" slot-scope="{ row }" :path="{ name: 'materialsDetail', query: { id: row.id, type: 'view' }}" :text="row.code" />
              <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
                <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}" />
                <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
              </div>
              <div slot="isKeyMaterials" slot-scope="{ row }" >{{ row.isKeyMaterials ? '是' : '否' }}</div>
              <div slot="action" slot-scope="{ row }">
                <NewPageOpen v-if="permission('Modify')" :path="{ name: 'materialsDetail', query: { id: row.id, type: 'modify' }}" text="编辑" />
                <el-divider v-if="permission('Detail')" direction="vertical" />
                <el-dropdown @command="(command) => { more(row, command) }">
                  <span class="el-dropdown-link">
                    更多<i class="el-icon-arrow-down el-icon--right" />
                  </span>
                  <el-dropdown-menu slot="dropdown" class="td-dropdown-menu">
                    <el-dropdown-item v-if="permission('PrintCode')" command="printCode">打印</el-dropdown-item>
                    <el-dropdown-item v-if="permission('Copy')" command="copy">复制</el-dropdown-item>
                    <el-dropdown-item v-if="permission('Status')" command="switch">{{ row.isEnabled ? '停用' : '启用' }}</el-dropdown-item>
                    <el-dropdown-item v-if="permission('Del')" command="del">删除</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </MTable>
          </div>
        </template>
      </Split>
    </div>
    <PrintCode ref="printCode" />
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader'
import SearchForm from '@/components/SearchForm/SearchForm'
import { columns, getFunctionList } from './columns'
import maApi from '@/api/information/materials-attribute'
import api from '@/api/information/materials'
import { searchFormList } from './form-list'
import { getUUid } from '@/utils'
import { Encrypt } from '@/utils/sercet'
import Split from '@/components/Split/Split'
import MaterialsClass from '@/components/MaterialsClass/MaterialsClass'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import batchExportFront from '@/mixin/batch-export-front'
import fixCommonPage from '@/mixin/fix-common-page'
import PrintCode from '@/components/PrintCode/printCode'

export default {
  name: 'MaterialsList',
  components: { NewPageOpen, MaterialsClass, Split, FunctionHeader, SearchForm, PrintCode },
  mixins: [batchExportFront, fixCommonPage],
  data() {
    return {
      functionHeader: {
        searchVal: '',
        tags: [
          {
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: this.add
            }
          }
        ],
        importName: 'materials',
        exportName: 'exportMaterials',
        exportParams: {}
      },
      columns,
      searchFormList,
      searchFormData: {},
      searchFormatData: [],
      split: {
        offset: 15
      },
      materialsClassId: '',
      defaultSearch: {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      },
      total:0
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 88
    },
    splitHeight() {
      return this.$store.state.app.contentHeight - 235 + 70
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== 'refresh') {
      this.$store.dispatch('tagsView/addCachedView', from)
    }
    next()
  },
  // beforeMount() {
  //   getFunctionList({
  //     allCodeChange: this.allCodeChange
  //   })
  // },
  mounted() {
    this.getMaterialsTypeList()
    this.getMaterialsAttributeList()
    // this.getSpecificationsList()
  },
  methods: {
    resetSelectData() {
      this.exportSelectionList.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.exportSelectionList = this.exportSelectionList.filter((item) => item.selectable)
    },
    setMaterialsClassId(id) {
      this.materialsClassId = id
      this.$refs.mTable.setTableData(true)
    },
    // 物料规格
    async getSpecificationsList() {
      const res = await api.getSpecifications()
      if (res) {
        this.searchFormList[3].children[0].options = res
      }
    },
    // 获取物料类型
    async getMaterialsTypeList() {
      const res = await maApi.getMaterialsTypeList(this.defaultSearch)
      if (res) {
        this.searchFormList[1].children[0].options = res
      }
    },
    // 获取物料属性
    async getMaterialsAttributeList() {
      const res = await maApi.getMaterialsAttributeList()
      if (res) {
        this.searchFormList[2].children[0].options = res
      }
    },
    // 获取物料数据
    async getTableData(condition, callback) {
      const { page, search, order } = condition
      const searchCondition = {
        sort: order,
        materialsClassId: this.materialsClassId,
        searchKeyword: this.functionHeader.searchVal,
        searchVal: Encrypt(JSON.stringify([...search, ...this.searchFormatData,
      {
              id: getUUid(),
              fieldName: 'isVirtualMaterials',
              fieldType: 'number',
              fieldValue: 0,
              operator: 'eq',
            }]))
      }
      this.functionHeader.exportParams = searchCondition
      const res = await api.getMaterialsListPage({
        page: page.currentPage,
        limit: page.pageSize,
        ...searchCondition
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
        this.total = res.total
      }
      callback()
    },
    // 查询物料
    searchTable(data) {
      this.searchFormatData = data
      this.$refs.mTable.setTableData(true)
    },
    add() {
      this.$router.push({ name: 'materialsDetail', query: { type: 'add' }})
    },
    // 更多操作
    more(row, command) {
      const functions = {
        modify: () => {
          this.modifyOrCopy(row, command)
        },
        copy: () => {
          this.modifyOrCopy(row, command)
        },
        del: () => {
          this.del(row)
        },
        switch: () => {
          this.switch(row)
        },
        printCode: () => {
          this.printCode(row)
        }
      }
      functions[command]()
    },
    modifyOrCopy(row, command) {
      this.$router.push({ name: 'materialsDetail', query: { id: row.id, type: command }})
    },
    async batchDel() {
      const res = await api.delBatchMaterials(this.exportSelectionIds)
      if (res) {
        this.$message.success('删除成功')
        this.$refs.mTable.tableRefs().clearSelection()
        this.$refs.mTable.setTableData(true)
      }
    },
    // 删除物料
    del(row) {
      this.currentType = 'del'
      this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        const res = await api.delMaterials({ id: row.id })
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.setTableData()
        }
      })
    },
    printCode(row) {
      this.$refs.printCode.print(row)
    },
    // 更改物料状态
    async switch(row) {
      const res = await api.switchStatus(row.isEnabled ? 'disable' : 'enable', 'materials', row.id)
      if (res) {
        this.$message.success(`${row.name}${row.isEnabled ? '停用' : '启用'}成功`)
        this.$refs.mTable.setTableData()
      }
    },
    viewDetail(row) {
      this.$message.info('敬请期待！')
    },
    onSuccess() {
      this.$refs.mTable.setTableData()
    }
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}

.materials-wrap {
  width: 100%;

  .bottom {
    width: 100%;
    height: 100%;
    padding: 10px;

    .materials-info {
      padding: 10px;
    }
  }
}
.drawing-img {
  width: 50px;
  height: 48px;
  display: block;
  cursor: pointer;
  color: #fff;
}
::v-deep {
  .params-card {
    .body {
      padding: 0 !important;
    }
    .params {
      width: 100% !important;
    }
    .el-form-item__label {
      padding: 0 12px 0 0 !important;
    }
  }
}
</style>
