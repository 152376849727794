<template>
  <div class="unit-wrap common-tab">
    <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange">
      <el-radio-button v-if="permission('CustomerList')" label="Customer">客户</el-radio-button>
      <el-radio-button v-if="permission('CustomerSiteList')" label="CustomerSite">现场</el-radio-button>
    </el-radio-group>
    <div v-if="params.radio === 'Customer'">
      <FunctionHeader
        v-model="functionHeader.searchVal"
        :tags="functionHeader.tags"
        :search-title="searchTitle"
        :radio="params.radio"
        :columns-key="columnsKey"
        :import-name="functionHeader.importName"
        :export-name="functionHeader.exportName"
        :export-params="functionHeader.exportParams"
        @search="$refs.mTable.setTableData(true)"
      />
      <MTable
        v-if="permission(params.radio + 'List')"
        ref="mTable"
        :columns="Customer"
        :height="height"
        :columns-key="columnsKey"
        :set-data-method="getTableData"
      >
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
          <div :style="{ background: row.isEnabled ? '#24CAD7' : '#FFCC78' }" />
          <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
        </div>
        <div slot="action" slot-scope="{ row }">
          <el-button v-if="permission(params.radio + 'Status')" type="text" @click="switchStatus(row, params.radio)">
            {{ row.isEnabled ? '停用' : '启用' }}
          </el-button>
          <el-divider v-if="permission(params.radio + 'Status')" direction="vertical" />
          <el-button v-if="permission(params.radio + 'Modify')" type="text" @click="modifyOrCopy(row, 'modify')">修改</el-button>
          <el-divider v-if="permission(params.radio + 'Modify')" direction="vertical" />
          <DelPopover v-if="permission(params.radio + 'Del')" @onOk="callback => del(row, callback)" />
        </div>
      </MTable>
    </div>
    <!-- 现场 -->
    <Split v-else :offset.sync="split.offset" :height="splitHeight" style="margin-top: 10px">
      <template slot="left">
        <div class="group">
          <!-- <div class="top"> -->
          <el-input v-model="filterText" placeholder="请输入客户名称" />
          <!-- </div> -->
          <div class="content">
            <div
              v-for="item in customerList"
              :key="item.id"
              class="Warehouse"
              :class="{ active: item.id === currentId }"
              @click="onChangeCustomer(item)"
            >
              <span class="ellipsis">{{ item.name }}</span>
            </div>
          </div>
        </div>
      </template>
      <template slot="right">
        <div class="model-wrap-right">
          <FunctionHeader
            v-model="functionHeaders.searchVal"
            :tags="functionHeaders.tags"
            :search-title="searchTitle"
            :radio="params.radio"
            :columns-key="columnsKey"
            @search="searchTable"
          />
          <MTable
            ref="mTable"
            default-expand-all
            :is-tree-data="true"
            :columns="CustomerSite"
            :height="height"
            :set-data-method="getTableData"
            :columns-key="columnsKey"
            :show-page="false"
            :immediate-get-data="false"
          >
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <div slot="action" slot-scope="{ row }">
              <el-button v-if="permission(params.radio + 'Modify')" type="text" @click="modifys(row)">修改</el-button>
              <el-divider v-if="permission(params.radio + 'Modify')" direction="vertical" />
              <DelPopover v-if="permission(params.radio + 'Del')" @onOk="callback => del(row, callback)" />
            </div>
          </MTable>
        </div>
      </template>
    </Split>
    <MDialog v-model="visible" :title="messages[currentType]" @onOk="submitForm">
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList[params.radio]" />
    </MDialog>
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import DelPopover from '@/components/DelPopover/index'
import { Customer, CustomerSite } from './columns'
import formList from './form-list'
import api from '@/api/information/unit'
import conventionMixin from '@/mixin/conventional-page'
import Split from '@/components/Split/Split'
import { getUUid } from '@/utils'
import { Encrypt } from '@/utils/sercet'

export default {
  name: 'CustomerInfo',
  components: { Split, FunctionHeader, DelPopover },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      split: {
        offset: 18
      },
      currentId: -1,
      Customer,
      CustomerSite,
      params: {
        radio: 'Customer'
      },
      functionHeaders: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('CustomerSiteAdd'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto' },
            innerText: '新增',
            on: {
              click: () => {
                this.addSite()
              }
            }
          }
        ]
      },
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('CustomerAdd'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: () => {
                this.add()
                this.createCode()
              }
            }
          }
        ],
        importName: 'customer',
        exportName: 'exportCustomer',
        exportParams: {}
      },
      visible: false,
      formList: formList,
      formData: {},
      currentType: 'add',
      searchTitle: '请输入客户名称',
      customerList: [],
      extraParams: [],
      searchFormatData: [],
      filterText: '',
      customerLists: []
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 65
    },
    splitHeight() {
      return this.$store.state.app.contentHeight - 225
    }
  },
  watch: {
    filterText(val) {
      if (val) {
        this.customerList = this.customerList.filter(item => item.name.includes(val))
      } else {
        this.customerList = this.customerLists
      }
    }
  },
  methods: {
    async addSite() {
      if (this.customerList.length === 0) {
        return this.$message.error('请先添加客户')
      }
      this.formData = {}
      this.currentType = 'add'
      await this.getSite()
      this.visible = true
    },
    async modifys(row) {
      this.formData = this._.cloneDeep(row)
      this.formData.parentId = this.formData.parentId === -1 ? '': this.formData.parentId
      this.currentType = 'modify'
      await this.getSite()
      this.visible = true
    },
    searchTable(data, val) {
      if (val !== 1 && this.functionHeaders.searchVal) {
        this.searchFormatData = [
          {
            id: getUUid(),
            fieldName: this.searchFieldName || 'name',
            fieldType: 'string',
            fieldValue: this.functionHeaders.searchVal,
            operator: 'like'
          }
        ]
      } else {
        this.searchFormatData = []
      }
      this.$refs.mTable.setTableData(true)
    },
    // 获取现场
    async getSite() {
      const res = await api.getCustomerSiteList({
        searchVal: Encrypt(
          JSON.stringify([
            {
              id: getUUid(),
              fieldName: 'customerId',
              fieldType: 'number',
              fieldValue: this.currentId,
              operator: 'eq'
            }
          ])
        )
      })
      if (res) {
        this.formList['CustomerSite'][1].children[0].options = [
          // {
          //   id: -1,
          //   name: '全部'
          // },
          ...res
        ]
      }
    },
    // 获取客户
    async getUser() {
      const res = await api.getCustomerList({
        searchVal: Encrypt(
          JSON.stringify([
            {
              id: getUUid(),
              fieldName: 'isEnabled',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq'
            }
          ])
        )
      })
      if (res) {
        this.customerList = res
        this.customerLists = res
        this.currentId = res && res.length > 0 ? res[0].id : -1
      }
    },
    onChangeCustomer(row) {
      this.currentId = row.id
      if (this.currentId !== -1) {
        this.extraParams = [
          {
            id: getUUid(),
            fieldName: 'customerId',
            fieldType: 'number',
            fieldValue: this.currentId,
            operator: 'eq'
          }
        ]
      } else {
        this.extraParams = []
      }

      this.$refs.mTable.setTableData(true)
    },
    async createCode() {
      if (sessionStorage.getItem('memberCode') !== 'mubai') return false
      const res = await api[`create${this.params.radio}Code`]({
        count: 1
      })
      if (res) {
        this.$refs.formBuild.setForm({
          code: res[0]
        })
      }
    },
    async radioChange(label) {
      this.params.radio = label
      const flag = label === 'CustomerSite'
      this.functionHeader.tags[0].hidden = !this.permission(label + 'Add')
      Object.assign(this.functionHeader, {
        importName: flag ? '' : 'customer',
        exportName: flag ? '' : 'exportCustomer'
      })
      switch (this.params.radio) {
        case 'CustomerSite':
          this.searchTitle = '请输入现场名称'
          break
        case 'Customer':
          this.searchTitle = '请输入客户名称'
          break
        default:
          break
      }
      if (label === 'CustomerSite') {
        await this.getUser()
        if (this.currentId !== -1) {
          this.extraParams = [
            {
              id: getUUid(),
              fieldName: 'customerId',
              fieldType: 'number',
              fieldValue: this.currentId,
              operator: 'eq'
            }
          ]
        }
      } else {
        this.extraParams = []
      }
      this.$refs.mTable.setTableData(true)
    },
    // textChange(val) {
    //   if (val) {
    //     this.customerList = this.customerList.filter(item => item.name.includes(val))
    //   } else {
    //     this.customerList = this.customerLists
    //   }
    // },
    createSetData(raw) {
      return {
        code: raw.code,
        name: raw.name,
        address: raw.address,
        tel: raw.tel,
        contact: raw.contact,
        mail: raw.mail,
        isEnabled: raw.isEnabled,
        remark: raw.remark,
        business: raw.business,
        parentId: raw.parentId || -1,
        principal: raw.principal,
        phone: raw.phone,
        customerId: this.params.radio === 'CustomerSite' && this.currentId !== -1 ? this.currentId : undefined
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .el-table__expand-icon {
    // vertical-align: middle;
    position: relative;
    top: 9px;
    top: 6px;
    &--expanded {
      top: 9px;
    }
  }
}
.unit-wrap {
  padding: 10px;
}
.model-wrap-right {
  margin-left: 10px;
}
.group {
  background: #ffffff;
  padding: 10px;
  width: 100%;
  height: 100%;
  overflow: auto;
  .top {
    width: 100%;
    padding: 10px;
    cursor: pointer;
    color: #fff;
    background: #8585f9;
    // border: 1px dashed #8585f9;

    .title {
      text-align: center;
    }
  }
  .content {
    .Warehouse {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 34px;
      padding: 10px;

      &.active {
        color: #8099ff;
        background: #f0f3ff;
      }

      &:hover {
        background-color: rgba(248, 248, 249, 1);
      }

      .ellipsis {
        display: inline-block;
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
      }

      .modify,
      .del {
        display: inline-block;
        width: 14px;
        height: 14px;
      }

      .modify {
        margin-right: 10px;
        background: url('../../../assets/quality/自定义标题@2x.png');
        background-size: contain;
        cursor: pointer;
        &:hover {
          background: url('../../../assets/quality/自定义标题备份@2x.png');
          background-size: contain;
        }
      }

      .del {
        background: url('../../../assets/quality/3.0-删除@2x.png');
        background-size: contain;
        cursor: pointer;
        &:hover {
          background: url('../../../assets/quality/3.0-删除备份@2x.png');
          background-size: contain;
        }
      }
    }
  }
}
</style>
