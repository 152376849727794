import { spotCheckCycleType } from '@/config/options.sales'

let functionList = {}
export const getFunctionList = val => {
  functionList = val
}
const FacilitySpotCheckPlan = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    hiddenSearch: true,
    slotName: 'index',
    width: 80
  },
  {
    prop: 'code',
    label: '计划编号',
    sortable: false,
    hiddenSearch: true,
    slotName: 'code',
    width: 140,
    form: { index: 1, tagName: 'el-input', colSpan: 8 }
  },
  {
    prop: 'name',
    label: '计划名称',
    sortable: false,
    hiddenSearch: true,
    width: 180,
    form: { index: 2, tagName: 'el-input', colSpan: 8 }
  },
  {
    prop: 'cycleType',
    label: '周期类型',
    sortable: false,
    hiddenSearch: true,
    width: 180,
    slotName: 'cycleType',
    form: { index: 3, tagName: 'el-select', colSpan: 8, options: spotCheckCycleType, defaultValue: 0 }
  },
  {
    prop: 'lifeCycle',
    label: '周期/天',
    sortable: false,
    hiddenSearch: true,
    slotName: 'lifeCycle',
    form: { index: 4, tagName: 'el-input-number', colSpan: 8, props: { controlsPosition: 'right', min: 0, placeholder: '请输入周期' }}
  },
  {
    prop: 'toleranceRange',
    label: '容差范围/天',
    sortable: false,
    hiddenSearch: true,
    slotName: 'toleranceRange',
    form: {
      index: 5,
      tagName: 'el-input-number',
      props: { controlsPosition: 'right', min: 0, placeholder: '请输入容差范围' },
      required: false,
      colSpan: 8
    }
  },
  {
    prop: 'startTime',
    label: '起始日期',
    sortable: false,
    hiddenSearch: true,
    form: {
      index: 6,
      tagName: 'el-date-picker',
      props: {
        valueFormat: 'yyyy-MM-dd',
        format: 'yyyy-MM-dd',
        pickerOptions: {
          disabledDate(time) {
            return time.getTime() < Date.now() - 8.64e7
          }
        }
      },
      colSpan: 8
    }
  },
  {
    prop: 'customerSiteIdList',
    label: '客户/现场',
    sortable: false,
    hiddenSearch: true,
    slotName: 'customerSiteIdList',
    width: 180,
    form: {
      index: 7, tagName: 'el-cascader',
      key: 'customerSiteIdList',
      colSpan: 8,
      props: {
        props: {
          label: 'name',
          value: 'id',
          checkStrictly: true
        },
        options: [],
        collapseTags: true,
        filterable: true
      },
      on: {
        change: (val) => { functionList.mulChange(val) }
      }
    }
  },
  {
    prop: 'isEnabled',
    label: '是否启用',
    sortable: false,
    hiddenSearch: true,
    slotName: 'isEnabled'
  },
  {
    prop: 'reminderTime',
    label: '提醒提前期',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'salesOrderNumber',
    label: '销售订单号',
    sortable: false,
    hiddenSearch: true
  },
  // {
  //   prop: 'lastEndTime',
  //   label: '上次完成时间',
  //   sortable: false,
  //   hiddenSearch: true
  // },
  // {
  //   prop: 'nextExecuteTime',
  //   label: '下次执行时间',
  //   sortable: false,
  //   hiddenSearch: true
  // },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 160
  }
]
const PlanSpotCheckTemplate = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    hiddenSearch: true,
    slotName: 'index',
    width: 80
  },
  {
    prop: 'name',
    label: '模板名称',
    sortable: false,
    hiddenSearch: true,
    slotName: 'name',
    width: 140,
    form: { index: 1, tagName: 'el-input', colSpan: 8 }
  },
  {
    prop: 'cycleType',
    label: '周期类型',
    sortable: false,
    hiddenSearch: true,
    width: 180,
    slotName: 'cycleType',
    form: { index: 2, tagName: 'el-select', colSpan: 8, options: spotCheckCycleType, required: false, defaultValue: 0 }
  },
  {
    prop: 'lifeCycle',
    label: '周期/天',
    sortable: false,
    hiddenSearch: true,
    slotName: 'lifeCycle',
    form: { index: 3, tagName: 'el-input-number', colSpan: 8, props: { controlsPosition: 'right', min: 0, placeholder: '请输入周期' }, required: false }
  },
  {
    prop: 'customerSiteIdList',
    label: '客户/现场',
    sortable: false,
    hiddenSearch: true,
    slotName: 'customerSiteIdList',
    width: 180,
    form: {
      index: 4,
      tagName: 'el-cascader',
      key: 'customerSiteIdList',
      colSpan: 8,
      props: {
        props: {
          label: 'name',
          value: 'id',
          checkStrictly: true
        },
        options: [],
        collapseTags: true,
        filterable: true
      },
      on: {
        change: (val) => { functionList.mulChange(val) }
      }
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    hiddenSearch: true,
    sortable: false,
    width: 150
  }
]
export default {
  FacilitySpotCheckPlan,
  PlanSpotCheckTemplate
}
