<template>
      <MDialog v-model="modalShow" :title="title" @onOk="submitForm" :width="width- 320 + 'px'" @fullScreen="fullScreenChange" :showFullScreen="true" >
        <div class="m-table">
          <FunctionHeader v-model="functionHeader.searchVal" search-title="请输入物料编号/名称/型号" class="materials-head"
                :tags="functionHeader.tags" :columns-setting="false" @search="getReportItemLog()"
                :isDialog=true />
                <el-table :data="dataProcess" :height="tableHeight" @expand-change="onExpand" v-loading="loading">
    <el-table-column type="expand">
      <template slot-scope="props">
        <el-table :data="props.row.children" :ref="'expandTable' + props.row.procedureLocationCode" row-key="id" class="ellipsis-table" @selection-change="(data) => handleSelectionChange(data, props.$index)">
            <el-table-column type="selection" :reserve-selection="true">
          </el-table-column>
          <el-table-column prop="index" label="序号" width="60">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
          </template>
          </el-table-column>
          <el-table-column prop="materialsType" label="物料类型">
            <template slot-scope="scope">
            {{ scope.row.materialsType }}
          </template>
          </el-table-column>
          <el-table-column prop="materialsCode" label="物料编号"></el-table-column>
          <el-table-column prop="materialsName" label="物料名称"></el-table-column>
          <el-table-column prop="specifications" label="物料规格"></el-table-column>
          <el-table-column prop="mainUnitName" label="主单位"></el-table-column>
          <el-table-column prop="receivedQuantity" label="已领数量"></el-table-column>
          <el-table-column prop="appliedNotReturnedQuantity" label="申请未退数量"></el-table-column>
        </el-table>
      </template>
    </el-table-column>
     <el-table-column prop="procedureName" label="工序名称"></el-table-column>
    <el-table-column prop="procedureLocationCode" label="工序定位码"></el-table-column>
    <el-table-column prop="receivedQuantity" label="已领数量"></el-table-column>
  </el-table>
 </div>
  <div class="page-container">
        <el-pagination
          :current-page="page.currentPage"
          :page-sizes="page.pageSizes"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
      </MDialog>
  </template>

  <script>
  import MaterialsClass from '@/components/MaterialsClass/MaterialsClass'
  import Split from '@/components/Split/Split'
  import SearchForm from '@/components/SearchForm/SearchForm'
  import FunctionHeader from '@/components/FunctionHeader'
  import mapi from '@/api/information/materials-require'

  export default {
    name: 'SelectMaterialsInventory',
    components: { MaterialsClass, Split, SearchForm, FunctionHeader },
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      selectProcessMaterials: {
        type: Array,
        default: () => []
      },
      receiptId: {
        type: Number,
        default: undefined
      },
      receiptType: {
        type: Number,
        default: undefined
      },
      title: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        modalShow: false,
        dataProcess:[],
        functionHeader: {
          searchVal: '',
          tags: []
        },
        page: {
      default: 20,
      pageSize: 20,
      pageSizes: [10, 20, 30, 50, 100, 150, 200],
      total: 0,
      currentPage: 1
    },
        selectionData: [],
        originProcessSelectionDatas:[],
        fullScreen: false,
        loading:false,
        isClassTableExpanded: false,
      }
    },
    computed: {
        width() {
          return this.$store.state.app.contentWidth
      },
      height() {
        return this.fullScreen ? this.$store.state.app.contentHeight - 160 - 83 - 57 : this.$store.state.app.contentHeight - 289 - 160 - 10
      },
      tableHeight() {
          return this.fullScreen ? this.$store.state.app.contentHeight - 160 - 10 - 44 -52: this.$store.state.app.contentHeight - 289 - 45 - 44 -52
      }
    },
    watch: {
      visible(val) {
        this.modalShow = val
        if (val) {
            this.getReportItemLog()
        }
      },
      modalShow(val) {
        if (!val) {
          this.functionHeader.searchVal = ''
          this.$emit('update:visible', false)
        }
      }
    },
    mounted() {
      this.modalShow = this.visible
      if (this.modalShow) {
      this.getReportItemLog()
      }
    },
    methods: {
        // 获取工序物料表
        async getReportItemLog() {
            this.loading = true
            const res = await mapi.getProcedureMaterialsListPage({
            page: this.page.currentPage,
            size:this.page.pageSize,
            search:this.functionHeader.searchVal,
            receiptId:this.receiptId,
            receiptType:this.receiptType,
            directionType:1})
            if (res) {
            this.loading = false
            this.dataProcess = res.records
            this.page.total = res.total
            }
            this.loading = false
        },
    onExpand(row,rows) {
      let _this = this
      _this.getExpandMaterialsData(row,rows)
    },
      // 获取工序展开物料
      async getExpandMaterialsData(row,rows) {
      let _this = this
      let procedureLocationCode = row.procedureLocationCode
      let expandTable = "expandTable" + procedureLocationCode
      // table展开时，根据之前选中的选项通过toggleRowSelection点击checkbox
      _this.$nextTick(function () {
        if (_this.$refs[expandTable]) {
          let hasSelections =
            _this.selectProcessMaterials.length > 0
              ? _this.selectProcessMaterials.length
              : undefined > 0
          if (hasSelections) {
            _this.isClassTableExpanded = true
            let selectedIds = _this.selectProcessMaterials.map(
              (mSelect) => mSelect.id
            )
            row.children.forEach((row) => {
              if (selectedIds.includes(row.id)) {
                _this.$refs[expandTable].toggleRowSelection(row, true)
              }
            })
          }
        }
        _this.isClassTableExpanded = false
      })
    },
    handleSelectionChange(index,data) {
      this.originProcessSelectionDatas[data] = index
      this.selectionData = this.originProcessSelectionDatas.flat()
    },
    handleSizeChange(val) {
      this.page.pageSize = val
      this.getReportItemLog()
    },
    // 当前页变化
    handleCurrentChange(val) {
      this.page.currentPage = val
      this.getReportItemLog()
    },
      fullScreenChange(val) {
        this.fullScreen = val
      },
      submitForm(callback) {
        this.$emit('submitForm', this.selectionData)
      }
    }
  }
  </script>

  <style lang="scss" scoped>
  .materials-info {
    padding: 10px;
  }
  .materials-head {
    padding: 0 0 10px 0;
  }
  .page-container {
      width: 100%;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 10px;
   }
   .m-table {
    position: relative;
    ::v-deep {
        .el-table .cell{
          padding-left:10px !important;
        }
    }
}
  </style>
