/** write by luomingzhi */
import { status } from '@/config/options.config'

export const columns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
  {
    prop: 'index',
    label: '序号',
    sortable: true,
    hiddenSearch: true,
    minWidth: 80,
    slotName: 'index'
  },
  {
    prop: 'code',
    label: '工位编码',
    sortable: false,
    hiddenSearch: true,
    minWidth: 160,
    form: { tagName: 'el-input' }
  },
  {
    prop: 'name',
    label: '工位名称',
    sortable: false,
    hiddenSearch: true,
    minWidth: 140,
    form: { tagName: 'el-input' }
  },
  {
    prop: 'areaName',
    label: '所属产线',
    sortable: false,
    hiddenSearch: true,
    minWidth: 150,
    form: { tagName: 'el-select', key: 'areaId', options: [] }
  },
  {
    prop: 'userAndUserGroup',
    label: '人员',
    slotName: 'userAndUserGroup',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'procedureList',
    label: '可执行工序',
    slotName: 'procedureList',
    sortable: false,
    hiddenSearch: true,
    minWidth: 150
  },
  // {
  //   prop: 'equipmentName',
  //   label: '工位设备',
  //   sortable: false,
  //   hiddenSearch: true,
  //   form: {
  //     tagName: 'el-cascader',
  //     key: 'equipmentId',
  //     required: false,
  //     props: {
  //       clearable: true,
  //       props: {
  //         label: 'name',
  //         value: 'id'
  //       },
  //       options: [],
  //       filterable: true
  //     }
  //   }
  // },
  {
    prop: 'isEnabled',
    label: '工位状态',
    sortable: false,
    slotName: 'isEnabled',
    hiddenSearch: true,
    form: {
      tagName: 'el-select',
      options: status,
      defaultValue: 1
    }
  },
  {
    prop: 'creator',
    label: '创建人',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'createTime',
    label: '创建时间',
    sortable: 'custom',
    hiddenSearch: true,
    minWidth: 170
  },
  {
    prop: 'lastUpdateTime',
    label: '最近更新时间',
    sortable: 'custom',
    hiddenSearch: true,
    minWidth: 170
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: { index: 5,
      tagName: 'el-input',
      required: false,
      props: { type: 'textarea' }
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 220
  }
]

export const userColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '用户/用户组名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'userName',
    label: '工号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'isEnabled',
    label: '状态',
    slotName: 'isEnabled',
    sortable: false,
    hiddenSearch: true
  }
]

export const procedureColumns = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    width: 80
  },
  {
    prop: 'code',
    label: '工序编码',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'name',
    label: '工序名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'procedureType',
    label: '工序类型',
    sortable: false,
    slotName: 'procedureType',
    hiddenSearch: true
  },
  {
    prop: 'groupName',
    label: '所属工序段',
    sortable: false,
    hiddenSearch: true
  }
]
