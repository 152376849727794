<template>
  <div class="model-wrap">
    <Split :offset.sync="split.offset" :height="splitHeight">
      <template slot="left">
        <div class="group">
          <div class="top">
            <div class="title" @click="onAndOrModify('RepositoryListModel')">新建仓库</div>
          </div>
          <div class="content">
            <div
              v-for="item in getLeftList"
              :key="item.id"
              class="Warehouse"
              :class="{ active: item.id === currentId }"
              @click="onChangeRepository(item)"
            >
              <span class="ellipsis">{{ item.name }}</span>
              <div v-if="item.id === currentId && item.isSystem !== 1">
                <i class="modify" @click.stop="onAndOrModify('RepositoryListModel', item)" />
                <i class="del" @click.stop="onDeleteHouse(item)" />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template slot="right">
        <div class="model-wrap-right">
          <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange">
            <el-radio-button label="RepositoryModel">库位设置</el-radio-button>
            <el-radio-button label="WarehouseSafeStock">安全库存</el-radio-button>
            <el-radio-button label="WarehouseCustomer">适用客户</el-radio-button>
          </el-radio-group>
          <div v-if="this.params.radio === 'RepositoryModel' && parentInfo.id" class="mtable-header">
            <div class="top">
              <span class="desc">是否启用库位管理</span>
              <el-switch :value="parentInfo.isLocation === 1" active-color="#13ce66" inactive-color="#eee" @change="onChangeLocation" />
            </div>
            <el-button :disabled="parentInfo.isLocation !== 1" type="primary" size="small" @click="onAddKw">新增库位</el-button>
          </div>
          <div v-if="isStock" class="mtable-header">
            <el-button type="primary" size="small" @click="onAddKc">新增</el-button>
          </div>
          <div v-if="isCustomer" class="mtable-header">
            <el-button type="primary" size="small" @click="onAndOrModifyCustomer()">添加客户</el-button>
          </div>
          <MTable
            ref="mTable"
            default-expand-all
            :is-tree-data="true"
            :columns="columns[params.radio]"
            :height="height"
            :show-page="isStock"
            :set-data-method="getTableData"
            :columns-key="columnsKey"
            :tree-data-parent-id="currentId"
            :columns-setting="false"
          >
            <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
            <div slot="isEnabled" slot-scope="{ row }" class="isEnabled">
              <el-switch :value="row.isEnabled === 1" active-color="#13ce66" inactive-color="#eee" @change="onSwitchStatus(row)" />
              <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
            </div>
            <div slot="lowerLimit" slot-scope="{ row }">
              {{ row.lowerLimit }}
            </div>
            <div slot="upperLimit" slot-scope="{ row }">
              {{ row.upperLimit }}
            </div>
            <div v-if="isCustomer" slot="action" slot-scope="{ row }">
              <el-button v-if="permission('Del')" type="text" @click="del(row)">移除</el-button>
            </div>
            <div v-else slot="action" slot-scope="{ row }">
              <el-button v-if="!isStock" type="text" @click="onRowAppendChild(row)">新建下级</el-button>
              <el-divider v-if="permission('Status') && !isStock" direction="vertical" />
              <el-button v-if="permission('Modify')" type="text" @click="onAndOrModify(isStock ? 'WarehouseSafeStock' : 'RepositoryModel', row)">
                修改
              </el-button>
              <el-divider v-if="permission('Modify')" direction="vertical" />
              <DelPopover v-if="permission('Del')" @onOk="callback => del(row, callback)" />
            </div>
          </MTable>

          <MDialog v-model="visible" :title="messages[currentType]" @onOk="submitForm">
            <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList[params.dialogType]" />
          </MDialog>
        </div>
      </template>
    </Split>
    <select-materials
      ref="selectMaterials"
      :visible.sync="showMaterials"
      :single="false"
      :is-add="true"
      :select-materials="selectMaterials"
      @submitForm="submitMaterials"
      @addMaterial="addMaterial"
    />
    <!-- 新增物料 -->
    <edit-materials ref="editMaterials" @onSuccess="onSuccess" />
    <MDialog v-model="showKc" width="1200px" title="新增安全库存" @onOk="onSubmitKc">
      <el-button class="mtable-header" type="primary" @click="() => (this.showMaterials = true)">选择物料</el-button>
      <MTable ref="kcTable" :height="325" :show-page="false" :data="materialList" :columns="columns.WarehouseSafeStock" :columns-setting="false">
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <div slot="lowerLimit" slot-scope="{ row }">
          <el-input-number v-model="row.lowerLimit" placeholder="请输入" :min="1" controls-position="right" :step="1" />
        </div>
        <div slot="upperLimit" slot-scope="{ row }">
          <el-input-number v-model="row.upperLimit" placeholder="请输入" :min="1" controls-position="right" :step="1" />
        </div>
        <div slot="action" slot-scope="{ $index }">
          <el-button type="text" class="li-btn" @click="onDelMaterial($index)">移除</el-button>
        </div>
      </MTable>
    </MDialog>
  </div>
</template>

<script>
import conventionMixin from '@/mixin/conventional-page'
import api from '@/api/information/warehouse/model'
import unitApi from '@/api/information/unit'
import columns from './columns'
import formList from './form-list'
import DelPopover from '@/components/DelPopover/index'
import { getUUid } from '@/utils'
import { Encrypt } from '@/utils/sercet'
import Split from '@/components/Split/Split'
import EditMaterials from '@/views/information/materials/components/edit-materials'
import SelectMaterials from '@/components/SelectMaterials/SelectMaterials.vue'

export default {
  name: 'Model',
  components: { DelPopover, Split, EditMaterials, SelectMaterials },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      params: {
        radio: 'RepositoryModel',
        dialogType: 'RepositoryModel'
      },
      split: {
        offset: 18
      },
      tableList: [],
      currentId: '',
      parentInfo: {},
      columns,
      formList: formList,
      formData: {},
      visible: false,
      currentType: 'add',
      code: '',
      showMaterials: false,
      showKc: false,
      selectMaterials: [],
      materialList: [],
      customerList: []
    }
  },
  computed: {
    getLeftList() {
      return this.tableList.filter(item => item.type === 0)
    },
    splitHeight() {
      return this.$store.state.app.contentHeight - 235 + 75
    },
    height() {
      const height = this.$store.state.app.contentHeight - 256 - 40 - 18
      if (this.isStock) {
        return height + 44
      } else if (this.isCustomer) {
        return height + 40
      } else {
        return height
      }
    },
    isKw() {
      return this.params.dialogType === 'RepositoryModel'
    },
    isStock() {
      return this.params.radio === 'WarehouseSafeStock'
    },
    isCustomer() {
      return this.params.radio === 'WarehouseCustomer'
    }
  },
  methods: {
    async getTableData(condition, callback) {
      const { page } = condition
      if ((this.isStock && this.params.dialogType !== 'RepositoryListModel') || this.isCustomer) {
        const res = await this.api[`get${this.params.radio}List`]({
          page: page.currentPage,
          limit: page.pageSize,
          searchVal: Encrypt(
            JSON.stringify([
              {
                id: getUUid(),
                fieldName: 'warehouseId',
                fieldType: 'number',
                fieldValue: this.currentId,
                operator: 'eq'
              }
            ])
          )
        })
        res.records = (res.records || res).map(item => {
          return {
            ...item,
            lowerLimit: item.lowerLimit ? item.lowerLimit : undefined,
            upperLimit: item.upperLimit ? item.upperLimit : undefined
          }
        })
        if (res) {
          callback({
            total: res.total || res.length,
            content: res.records
          })
        }
      } else if (this.isKw && this.currentId) {
        const data = await api.getAllNodesByWarehouseId(this.currentId)
        if (data) {
          const datas = data.filter(item => +item.type === 2)
          callback({
            total: data.length,
            content: datas
          })
        }
      } else {
        this.getRepositoryModelList()
      }

      callback()
    },

    async getRepositoryModelList() {
      const res = await api.getRepositoryModelList()
      if (res && res.length) {
        this.tableList = res
        if (!this.currentId) {
          this.currentId = this.getLeftList[0].id
          this.parentInfo = this.getLeftList[0]
          this.$refs.mTable.setTableData(true)
        } else {
          this.parentInfo = this.tableList.find(item => item.id === this.currentId)
        }
      } else {
        this.tableList = []
      }
    },

    radioChange(type) {
      this.params.radio = type
      this.params.dialogType = type
      this.$refs.mTable.setTableData(true)
    },

    createSetData(raw) {
      if (this.isCustomer) {
        const customerInfo = this.customerList.find(item => item.id === raw.consumerId)
        return {
          warehouseId: this.parentInfo.id,
          consumerCode: customerInfo.code,
          consumer: customerInfo.name,
          remark: customerInfo.remark,
          isDeleted: customerInfo.isDeleted,
          creator: customerInfo.creator,
          updater: customerInfo.updater,
          createTime: customerInfo.createTime,
          lastUpdateTime: customerInfo.lastUpdateTime,
          ...raw
        }
      } else {
        return {
          type: this.isKw ? 2 : 0,
          parentId: this.params.dialogType === 'RepositoryListModel' ? -1 : this.parentInfo.id,
          code: this.code,
          approverIdList: [],
          isApprove: 0,
          isEnabled: 1,
          isSystem: 0,
          ...raw
        }
      }
    },

    createResetData(raw) {
      if (this.isStock || this.isCustomer) {
        return {
          ...raw
        }
      } else {
        const obj = {
          id: raw.id,
          code: raw.code,
          name: raw.name
        }
        return Object.assign(this.createSetData(raw), obj)
      }
    },

    async createCode() {
      const data = {
        warehouseType: this.isKw ? 2 : 0,
        count: 1
      }
      if (this.isKw) {
        data['warehouseCode'] = this.parentInfo.code
      }
      const res = await api.createCode(data)
      this.code = res[0]
    },

    onChangeRepository(current) {
      this.currentId = current.id
      this.parentInfo = current
      this.params.dialogType = 'RepositoryModel'
      this.$refs.mTable.setTableData()
    },

    async onChangeLocation(v) {
      const res = await api.updateOneId({
        ...this.parentInfo,
        isLocation: v ? 1 : 0
      })
      if (res) {
        this.getRepositoryModelList()
        this.$refs.mTable.setTableData(true)
      }
    },

    onAddKw() {
      this.parentInfo = this.tableList.find(item => item.id === this.currentId)
      this.onAndOrModify('RepositoryModel')
    },

    onSwitchStatus(row) {
      this.params.dialogType = 'RepositoryModel'
      this.switchStatus(row, this.params.radio)
    },

    onAndOrModify(type, row) {
      this.params.dialogType = type
      if (row) {
        this.currentType = 'modify'
        this.modifyOrCopy(row, 'modify')
      } else {
        this.createCode()
        this.currentType = 'add'
        this.add()
      }
    },

    onAndOrModifyCustomer(row) {
      this.getCustomerList()
      this.params.dialogType = 'WarehouseCustomer'
      this.currentType = 'add'
      this.add()
    },

    async getCustomerList() {
      const res = await unitApi.getCustomerList()
      this.formList['WarehouseCustomer'][0].children[0].options = res
      this.customerList = res || []
    },

    // 删除仓库
    onDeleteHouse(row) {
      this.$confirm('您确定要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        this.params.dialogType = 'RepositoryModel'
        const res = await api.delRepositoryModel({ id: row.id })
        if (res) {
          this.$message.success('删除成功')
          this.currentId = ''
          this.getRepositoryModelList()
        }
      })
    },

    // 新增节点
    async onRowAppendChild(row) {
      const res = await api.isAppendChild(row.id)
      if (res) {
        this.parentInfo = {
          ...row,
          isLocation: this.parentInfo.isLocation
        }
        this.onAndOrModify('RepositoryModel')
      }
    },

    // 新增安全库存
    onAddKc() {
      this.materialList = []
      this.selectMaterials = []
      this.showMaterials = true
    },

    submitMaterials(values) {
      this.showMaterials = false
      this.showKc = true
      this.selectMaterials = values.map(item => {
        return {
          ...item,
          selectable: true
        }
      })

      values.forEach(item => {
        if (!this.materialList.find(t => t.materialId === item.id)) {
          this.materialList.push({
            warehouseId: this.currentId,
            materialId: item.id,
            materialCode: item.code,
            materialName: item.name,
            materialsTypeName: item.materialsTypeName,
            mainUnitName: item.mainUnitName,
            lowerLimit: undefined,
            upperLimit: undefined
          })
        }
      })
    },

    onDelMaterial(index) {
      this.materialList = this.materialList.filter((item, i) => i !== index)
      this.selectMaterials = this.selectMaterials.filter((item, i) => i !== index)
    },

    addMaterial() {
      this.$refs.editMaterials.add()
    },

    onSuccess() {
      this.$refs.selectMaterials.updateTable()
    },

    async onSubmitKc(callback) {
      const res = await api.addListWarehouseSafeStock(this.materialList)
      if (res) {
        this.showKc = false
        this.$message.success('新增成功')
        this.$refs.mTable.setTableData()
      } else {
        callback()
      }
    },

    async submitForm(callback) {
      const { form } = this.$refs.formBuild
      this.$refs.formBuild.formRefs().validate(async valid => {
        if (valid) {
          let type = this.params.radio
          if (this.params.dialogType === 'RepositoryListModel') {
            type = 'RepositoryModel'
          }
          const res = await this.api[`${this.currentType}${type}`](
            this.currentType !== 'modify' ? this.createSetData(form) : this.createResetData(form)
          )
          if (res) {
            this.$message.success(this.getSuccessMessage())
            if (this.continuousAdd) {
              this.formData = {}
            } else {
              this.visible = false
            }
            this.$refs.mTable.setTableData()
            if (this.getDeviceParametersRecord) this.getDeviceParametersRecord()
          }
          callback(true)
        } else {
          callback()
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .el-table__expand-icon {
    // vertical-align: middle;
    position: relative;
    top: 9px;
    top: 6px;
    &--expanded {
      top: 9px;
    }
  }
}

.model-wrap {
  font-size: 14px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  background: #f0f2f5;
  overflow-x: hidden;
  padding: 10px;

  .el-radio-group {
    margin-bottom: 10px;
  }

  &-right {
    flex: 1;
    padding: 10px;
    background: #fff;
  }

  .mtable-header {
    margin-bottom: 15px;

    .top {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 5px 0 15px 0;

      .desc {
        padding-right: 20px;
      }
    }
  }

  .group {
    background: #ffffff;
    padding: 10px;
    width: 100%;
    height: 100%;
    overflow: auto;

    .top {
      width: 100%;
      margin: 10px 0;
      padding: 10px;
      cursor: pointer;
      color: #8585f9;
      border: 1px dashed #8585f9;

      .title {
        text-align: center;
      }
    }

    .content {
      height: 360px;

      .Warehouse {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 34px;
        padding: 10px;

        &.active {
          color: #8099ff;
        }

        &:hover {
          background-color: rgba(248, 248, 249, 1);
        }

        .ellipsis {
          display: inline-block;
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
        }

        .modify,
        .del {
          display: inline-block;
          width: 14px;
          height: 14px;
        }

        .modify {
          margin-right: 10px;
          background: url('../../../assets/quality/自定义标题@2x.png');
          background-size: contain;
          cursor: pointer;
          &:hover {
            background: url('../../../assets/quality/自定义标题备份@2x.png');
            background-size: contain;
          }
        }

        .del {
          background: url('../../../assets/quality/3.0-删除@2x.png');
          background-size: contain;
          cursor: pointer;
          &:hover {
            background: url('../../../assets/quality/3.0-删除备份@2x.png');
            background-size: contain;
          }
        }
      }
    }
  }
  .el-tabs {
    width: calc(100% - 220px);
    padding: 0 10px;
    background: #ffffff;
    border-radius: 4px;
    padding-bottom: 7px;
    margin-left: 10px;
  }

  .function-header-wrap {
    padding-top: 0;
  }
  .isEnabled {
    display: flex;

    .el-switch {
      margin-right: 10px;
    }
  }
}
.role-tags {
  margin: 5px;
  border-color: #dde3eb;
  color: #9597ae;
}
</style>
