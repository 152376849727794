<template>
  <div style="font-size: 14px">
    <div style="padding: 15px 0">
      <span style="padding-left: 10px;">质检方案：</span>
      <el-select
        v-model="qualityInspectionPlan.inspectionProjectId"
        size="small"
        filterable
        :disabled="readOnly"
        style="width: 200px"
        placeholder="请选择质检方案"
        @change="(val) => qualityInspectionChange(val)"
      >
        <el-option
          v-for="item in inspectionPlanList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
      <el-button v-if="permission(currentKey + 'Modify')" size="small" type="primary" :loading="buttonLoading" style="margin-left: 10px" @click="editInspectionPlan">{{ readOnly ? '编辑' : '保存' }}</el-button>
      <el-button v-if="!readOnly" :loading="buttonLoading" size="small" style="margin-left: 10px" @click="cancel()">取消</el-button>
    </div>
    <quality-inspection-project
      v-if="qualityInspectionPlan.inspectionProjectId"
      ref="qualityInspectionProject"
      :inspection-entry-map="qualityInspectionPlan.inspectEntryMap"
      :attachments="qualityInspectionPlan.inspectAttachments"
      :user-list="qualityInspectionPlan.inspectorIdList"
      :read-only="readOnly"
      style="padding: 10px"
      :baseFormData="qualityInspectionPlan"
    />
  </div>
</template>

<script>
import QualityInspectionProject from '@/views/quality/inspection-plan/components/quality-inspection-project'
import QIP from '@/api/quality/inspection-plan'
import materialsApi from '@/api/information/materials'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
export default {
  name: 'InspectionSetting2',
  components: { QualityInspectionProject },
  props: {
    currentKey: {
      type: String,
      default: ''
    },
    materialsId: {
      required: true,
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      qualityInspectionPlan: {},
      readOnly: true,
      inspectionPlanList: [],
      buttonLoading: false
    }
  },
  watch: {
    currentKey: {
      handler: function() {
        this.readOnly = true
        this.getMaterialsDetail()
      },
      immediate: true
    },
    materialsId() {
      this.getMaterialsDetail()
    }
  },
  mounted() {
    this.getQualityInspectionPlanList()
  },
  methods: {
    async getMaterialsDetail(isFalse) {
      this.qualityInspectionPlan = {
        inspectionProjectId: null,
        inspectEntryMap: {},
        inspectAttachments: JSON.stringify([]),
        inspectorIdList: [],
        isCheck: null,  //0关闭  1开启审核
        checkedIDList: [],   //审核人员id
        isApproved: null,    ///0关闭  1开启审批
        approverIdList: []   //审批人员id
      }
      if(this.materialsId==0){
        return
      }
      const res = await materialsApi.getMaterialsInspectionInfoForIOQC(this.materialsId)
      if (res) {
        this.readOnly = true
        const key0 = this.currentKey !== 'inspectionInComing' ? 'oqcInspectId' : 'iqcInspectId'
        if (isFalse) return false
        if (!res[key0]) {
          this.readOnly = false
          return false
        }
        const key1 = this.currentKey !== 'inspectionInComing' ? 'oqcInspectEntryMap' : 'iqcInspectEntryMap'
        const key2 = this.currentKey !== 'inspectionInComing' ? 'oqcInspectorIdList' : 'iqcInspectorIdList'
        const key3 = this.currentKey !== 'inspectionInComing' ? 'oqcInspectAttachments' : 'iqcInspectAttachments'
        const key4 = this.currentKey !== 'inspectionInComing' ? 'oqcIsCheck' : 'iqcIsCheck'
      const key5 = this.currentKey !== 'inspectionInComing' ? 'oqcIsApproved' : 'iqcIsApproved'
      const key6 = this.currentKey !== 'inspectionInComing' ? 'oqcCheckedIDList' : 'iqcCheckedIDList'
      const key7 = this.currentKey !== 'inspectionInComing' ? 'oqcApproverIdList' : 'iqcApproverIdList'
        this.qualityInspectionPlan = {
          inspectionProjectId: res[key0],
          inspectEntryMap: res[key1],
          inspectAttachments: res[key3],
          inspectorIdList: res[key2],
          isCheck:res[key4],  //0关闭  1开启审核
        checkedIDList: res[key6],   //审核人员id
        isApproved: res[key5],    ///0关闭  1开启审批
        approverIdList:res[key7]    //审批人员id
        }
      }
    },
    async getQualityInspectionPlanList() {
      const res = await QIP.getInspectionPlanList({
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      })
      if (res) {
        this.inspectionPlanList = res
      }
    },
    async qualityInspectionChange(id) {
      const res = await QIP.getInspectionPlanDetailById({ id })
      if (res) {
        this.qualityInspectionPlan = {
          inspectionProjectId: id,
          inspectEntryMap: res.inspectionEntryMap,
          inspectAttachments: res.attachments,
          inspectorIdList: res.userList,
          isCheck: res.isCheck,  //0关闭  1开启审核
        checkedIDList: res.checkedIDList,   //审核人员id
        isApproved: res.isApproved,    ///0关闭  1开启审批
        approverIdList: res.approverIdList   //审批人员id
        }
      }
    },
    editInspectionPlan() {
      if (this.readOnly) {
        this.readOnly = false
      } else {
        this.saveInspectionPlan()
      }
    },
    async saveInspectionPlan() {
      this.buttonLoading = true
      if (!this.$refs.qualityInspectionProject) {
        this.buttonLoading = false
        this.$message.info('请选择质检方案')
        return false
      }
      const projectObject = this.$refs.qualityInspectionProject.transferDataToStandard()
      if (!projectObject) {
        this.buttonLoading = false
        return false
      }
      const key0 = this.currentKey !== 'inspectionInComing' ? 'oqcInspectId' : 'iqcInspectId'
      const key1 = this.currentKey !== 'inspectionInComing' ? 'oqcInspectEntryMap' : 'iqcInspectEntryMap'
      const key2 = this.currentKey !== 'inspectionInComing' ? 'oqcInspectorIdList' : 'iqcInspectorIdList'
      const key3 = this.currentKey !== 'inspectionInComing' ? 'oqcInspectAttachments' : 'iqcInspectAttachments'
      const key4 = this.currentKey !== 'inspectionInComing' ? 'oqcIsCheck' : 'iqcIsCheck'
      const key5 = this.currentKey !== 'inspectionInComing' ? 'oqcIsApproved' : 'iqcIsApproved'
      const key6 = this.currentKey !== 'inspectionInComing' ? 'oqcCheckedIDList' : 'iqcCheckedIDList'
      const key7 = this.currentKey !== 'inspectionInComing' ? 'oqcApproverIdList' : 'iqcApproverIdList'

      const res = await materialsApi.saveMaterialsInspectionSettingIOQC(this.currentKey === 'inspectionInComing' ? 2 : 3, {
        id: this.materialsId,
        [key0]: this.qualityInspectionPlan.inspectionProjectId,
        [key3]: projectObject.attachments,
        [key2]: projectObject.inspectorList,
        [key1]: projectObject.inspectionEntryMap,
        [key4]:projectObject.isCheck,
        [key5]:projectObject.isApproved,
        [key6]:projectObject.checkedIDList,
        [key7]:projectObject.approverIdList,
      })
      if (res) {
        this.readOnly = true
        this.getMaterialsDetail()
        this.$message.success('保存成功')
      }
      this.buttonLoading = false
    },
    cancel() {
      this.readOnly = true
      this.getMaterialsDetail(true)
    }
  }
}
</script>

<style scoped>

</style>
