var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "el-popover",
        {
          ref: "popover1",
          attrs: {
            "popper-class": "popover-class",
            placement: "top",
            title: "扫码下载",
            trigger: "hover"
          }
        },
        [
          _c("VueQr", {
            attrs: {
              "correct-level": 3,
              "auto-color": false,
              "color-dark": "#000000",
              text: _vm.version,
              size: 100,
              margin: 0
            }
          })
        ],
        1
      ),
      _c("img", {
        directives: [
          { name: "popover", rawName: "v-popover:popover1", arg: "popover1" }
        ],
        staticStyle: { float: "right" },
        attrs: {
          type: "text",
          src: require("@/assets/logo_images/download.png")
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }