const columnsAll = [
  {
    prop: 'bomLevel',
    label: '层级',
    sortable: false,
    hiddenSearch: true,
    width: 100
  },
  {
    prop: 'code',
    label: '物料编码',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'name',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsClassName',
    label: '物料分类',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsTypeName',
    label: '物料类型',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsAttributeName',
    label: '物料属性',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'specifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantityNumerator',
    label: '用量分子',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantityDenominator',
    label: '用量分母',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantityStandard',
    label: '标准用量',
    sortable: false,
    hiddenSearch: true,
    slotName: 'quantityStandard'
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 100
  }
]
// if (sessionStorage.getItem('memberCode') === 'mubai') {
//   columnsAll.push({
//     slotName: 'action',
//     label: '操作',
//     fixed: 'right',
//     sortable: false,
//     hiddenSearch: true,
//     width: 100
//   })
// }
export const columnsOrder = [
  {
    prop: 'bomLevel',
    label: '层级',
    sortable: false,
    hiddenSearch: true,
    width: 100,
    slotName: 'bomLevel'
  },
  {
    prop: 'code',
    label: '物料编码',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'name',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsClassName',
    label: '物料分类',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsTypeName',
    label: '物料类型',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'materialsAttributeName',
    label: '物料属性',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'specifications',
    label: '规格',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'quantityNumerator',
    label: '用量分子',
    sortable: false,
    hiddenSearch: true,
    slotName: 'quantityNumerator'
  },
  {
    prop: 'quantityDenominator',
    label: '用量分母',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'mainUnitName',
    label: '单位',
    sortable: false,
    hiddenSearch: true
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 100
  }
]

export default columnsAll
