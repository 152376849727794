let functionList = {}
export const getFunctionList = val => {
  functionList = val
}

export const formList = [
  {
    label: '生成数量：',
    key: 'quantity',
    tagName: 'el-input-number',
    colSpan: 24,
    props: {
      min: 1,
      controlsPosition: 'right',
      placeholder: '请输入数量'
    }
  }
]
export const searchFormList = [
  {
    label: '选择物料：',
    key: 'materialsId',
    tagName: 'el-input',
    colSpan: 6,
    attrs: {
      className: 'select-materials'
    },
    children: [
      {
        slots: ['prepend', 'append']
      }
    ]
  },
  {
    label: '输入批次码：',
    key: 'batchNumber',
    tagName: 'el-input',
    colSpan: 6,
    attrs: {
      placeholder: '请输入批次码'
    }
  },
  {
    colSpan: 3,
    tagName: 'div',
    style: { minWidth: '160px' },
    children: [
      {
        tagName: 'el-button',
        props: { type: 'primary', size: 'small' },
        style: { width: 'auto', background: '#607FFF' },
        innerText: '添加',
        on: {
          click: () => { functionList.addBatchNumber() }
        },
        children: []
      },
      {
        tagName: 'el-button',
        props: { size: 'small' },
        style: { width: 'auto' },
        innerText: '生成',
        on: {
          click: () => { functionList.generateBatchNumber() }
        },
        children: []
      }
    ]
  }
]
