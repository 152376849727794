<template>
  <div class="package-wrap">
    <FunctionHeader
      v-model="functionHeader.searchVal"
      search-title="请输入包装名称"
      :tags="functionHeader.tags"
      :export-name="functionHeader.exportName"
      :export-params="functionHeader.exportParams"
      :import-name="functionHeader.importName"
      @search="$refs.mTable.setTableData(true)"
    />
    <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData">
      <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
      <el-button slot="name" slot-scope="{ row }" style="white-space: pre-wrap" type="text" @click="viewDetail(row)">{{ row.name }}</el-button>
      <div slot="isEnabled" slot-scope="{ row }" class="isEnabled">
        <el-switch :value="row.isEnabled === 1" active-color="#13ce66" inactive-color="#eee" @change="switchStatus(row, params.radio)" />
        <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
      </div>
      <div slot="isCycle" slot-scope="{ row }">{{ row.isCycle === 1 ? '是':'否' }}</div>
      <!-- <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
        <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}" />
        <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
      </div> -->
      <div slot="action" slot-scope="{ row }">
        <el-button v-if="permission('Modify')" type="text" @click="modifyOrCopy(row, 'modify')">修改</el-button>
        <el-divider v-if="permission('Modify')" direction="vertical" />
        <!-- <el-button v-if="permission('Status')" type="text" @click="switchStatus(row, params.radio)">{{ row.isEnabled ? '停用' : '启用' }}</el-button>
        <el-divider v-if="permission('Status')" direction="vertical" /> -->
        <DelPopover v-if="permission('Del')" @onOk="(callback) => del(row, callback)" />
      </div>
    </MTable>

    <MDialog v-model="visible" :title="messages[currentType]" @onOk="submitForm">
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList" />
    </MDialog>
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import DelPopover from '@/components/DelPopover/index'
import conventionMixin from '@/mixin/conventional-page'
import api from '@/api/information/warehouse/package'
import matApi from '@/api/information/materials-attribute'
import { columns } from './columns'
import formList from './form-list'
import { Encrypt } from '@/utils/sercet'

export default {
  name: 'PackageInfo',
  components: { FunctionHeader, DelPopover },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      params: {
        radio: 'ContainerClass'
      },
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('Add'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: () => {
                this.add()
              }
            }
          }
        ],
        importName: 'package',
        exportName: 'exportPackage',
        exportParams: {}
      },
      columns,
      visible: false,
      formList: formList,
      formData: {},
      currentType: 'add',
      formOptions: {
        unitOptions: []
      }
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 192
    }
  },
  mounted() {
    this.getUnitList()
  },
  methods: {
    // 查看详情
    viewDetail(row) {
      this.$router.push({
        name: 'packageDetail',
        query: { id: row.id, type: 'view' }
      })
    },
    async getUnitList() {
      const res = await matApi.getMaterialsUnitList({
        searchVal: Encrypt(JSON.stringify(this.defaultSearch))
      })
      if (res) {
        this.formList[2].children[0].options = res
        this.formOptions.unitOptions = res
      }
    },
    async createPackageCode() {
      if (sessionStorage.getItem('memberCode') !== 'mubai') return false
      const res = await api.createCode({
        count: 1
      })
      if (res) {
        this.$refs.formBuild.setForm({
          code: res[0]
        })
      }
    },
    createSetData(raw) {
      const containerUnitName = this.formOptions.unitOptions
        .find(item => item.id === raw.containerUnitId).name
      return {
        // code: raw.code,
        name: raw.name,
        specification: raw.specification,
        containerUnitId: raw.containerUnitId,
        containerUnitName,
        isCycle: raw.isCycle,
        remark: raw.remark
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    }
  }
}
</script>

<style scoped lang="scss">
.package-wrap {
  padding: 10px;
  .function-header-wrap {
    padding-top: 0;
  }
  .isEnabled {
    display: flex;
    .el-switch {
      margin-right: 10px;
    }
  }
}
</style>
