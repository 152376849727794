<template>
  <span>
    <el-popover ref="popover1" popper-class="popover-class" placement="top" title="扫码下载" trigger="hover">
      <VueQr
        :correct-level="3"
        :auto-color="false"
        color-dark="#000000"
        :text="version"
        :size="100"
        :margin="0"
      />
    </el-popover>
    <img v-popover:popover1 type="text" style="float: right" src="@/assets/logo_images/download.png">
  </span>
</template>
<script>
import VueQr from 'vue-qr'
import api from '@/api/sets/base-setting'
export default {
  name: 'DownCode',
  components: { VueQr },
  data() {
    return {
      version: ''
    }
  },
  mounted() {
    this.getVersionConfig()
  },
  methods: {
    // 获取app
    async getVersionConfig() {
      const res = await api.getVersion('app_version_upload')
      if (res) {
        this.version = res.value ? res.value : ''
      }
    }
  }
}
</script>
