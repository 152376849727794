<template>
  <div class="unit-wrap">
    <div style="display:flex;justify-content:space-between">
      <div :style="{width:type=== 0 ? '100%':'50%'}">
        <FunctionHeader
          v-model="functionHeader.searchVal"
          :tags="functionHeader.tags"
          :search-title="searchTitle"
          :columns-key="columnsKey"
          :import-name="functionHeader.importName"
          :export-name="functionHeader.exportName"
          :export-params="functionHeader.exportParams"
          @search="$refs.mTable.setTableData(true)">
          <div slot="all">
            <span style="margin-left: 15px;font-size: 14px;color: #606266;
            font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;"> 需求范围 ： </span>
            <el-select v-model="allFlag" placeholder="请选择需求范围" @change="allSrc"
                       style="margin-left:5px">
              <el-option
                v-for="item in allOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>

            <el-date-picker
              style="margin-left: 15px"
              v-model="allTime"
              align="right"
              type="date"
              @change="allSrc"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              placeholder="选择日期"
              :picker-options="pickerOptions">
            </el-date-picker>
          </div>
        </FunctionHeader>
        <MTable ref="mTable" :columns="getColumns" :height="heightAll" :columns-key="columnsKey"
                :set-data-method="getTableData" @row-click="handleRowClick" :row-style="getRowStyle">
          <div slot="headerSlot">
            <div style="display:flex;justify-content: center;color:#000">
              <div @click="viewDetail(1)" title="物料需求" style="margin-right:15px;cursor: pointer;">
                <i class="el-icon-d-arrow-right"/>
              </div>
              <div @click="viewDetail(2)" title="物料供应" style="margin-right:15px;cursor: pointer;">
                <i class="el-icon-d-arrow-left"/>
              </div>
            </div>
          </div>
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>

          <div slot="materialsTypeName" slot-scope="{ row }">
            <div>{{ row.materialsTypeName }}</div>
          </div>
          <div slot="missMaterialsRate" slot-scope="{ row }">
            <div>{{ row.missMaterialsRate }}%</div>
          </div>
          <div slot="sitMissMaterialsRate" slot-scope="{ row }">
            <div>{{ row.sitMissMaterialsRate }}%</div>
          </div>
          <div slot="isKeyMaterials" slot-scope="{ row }">
            <div>{{ row.isKeyMaterials === 1 ? '是' : '否'}}</div>
          </div>
          <div slot="completeRate" slot-scope="{ row }">
            <div>{{ row.completeRate }}%</div>
          </div>
        </MTable>
      </div>
      <div v-if="type === 1" title="物料总览" style="cursor: pointer;margin:53px 8px 0">
        <i class="el-icon-d-arrow-left" @click="viewDetail(0)"/>
      </div>
      <div v-if="type=== 1" style="width:48%;margin-top:53px">
        <MTable ref="mTables" :columns="columnsRequirement" :show-page="false" :columns-setting="false" :height="height"
                :columns-key="columnsKey" :data="requireList" :is-tree-data="true" :default-expand-all="isExpand">
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <div slot="missMaterialsRate" slot-scope="{ row }">
            <div>{{ row.missMaterialsRate }}%</div>
          </div>
        </MTable>
      </div>
      <div v-if="type === 2" title="物料总览" style="margin:53px 8px 0;cursor: pointer;">
        <i class="el-icon-d-arrow-right" @click="viewDetail(0)"/>
      </div>
      <div v-if="type=== 2" style="width:48%;margin-top:53px">
        <MTable
          ref="mTabless"
          :is-tree-data="true"
          :tree-props="{children: 'children'}"
          :show-page="false"
          :columns="columnsSupply"
          :data="supplyList"
          :columns-setting="false"
          default-expand-all
          :row-style="getRowDelayStyle"
        >
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        </MTable>
      </div>
    </div>
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import DelPopover from '@/components/DelPopover/index'
import {columnsAll, columnsRight, columnsRequirement, columnsSupply} from './columns'
import api from '@/api/information/materials-balance'
import conventionMixin from '@/mixin/conventional-page'

export default {
  name: 'materialsBalance',
  components: {FunctionHeader, DelPopover},
  mixins: [conventionMixin],
  props: {
    projectId: {
      type: Number,
      default: undefined
    }
  },
  data() {
    return {
      allFlag: 1,
      allOptions: [
        {
          id: 1,
          name: '工单计划开始时间'
        },
        {
          id: 2,
          name: '工序计划开始时间'
        }
      ],
      allTime: '',
      pickerOptions: {
        shortcuts: [
          {
            text: '至本周',
            onClick(picker) {
              const date = new Date();
              const dayOfWeek = date.getDay();
              date.setDate(date.getDate() - dayOfWeek + 7);
              date.setHours(0, 0, 0, 0);
              picker.$emit('pick', date);
            }
          },
          {
            text: '至下一周',
            onClick(picker) {
              const date = new Date();
              const dayOfWeek = date.getDay();
              date.setDate(date.getDate() - dayOfWeek + 14);
              date.setHours(0, 0, 0, 0);
              picker.$emit('pick', date);
            }
          },
          {
            text: '至本月',
            onClick(picker) {
              const date = new Date();
              const dayOfWeek = date.getDate();
              date.setDate(date.getDate() - dayOfWeek + 1)
              date.setMonth(date.getMonth() + 1)
              date.setDate(0)
              date.setHours(0, 0, 0, 0);
              picker.$emit('pick', date);
            }
          },
          {
            text: '至下一月',
            onClick(picker) {
              const date = new Date();
              const dayOfWeek = date.getDate();
              date.setDate(date.getDate() - dayOfWeek + 1)
              date.setMonth(date.getMonth() + 2)
              date.setDate(0)
              date.setHours(0, 0, 0, 0);
              picker.$emit('pick', date);
            }
          },
        ]
      },
      api,
      params: {
        radio: 'MaterialsBalance'
      },
      functionHeader: {
        searchVal: '',
        tags: [],
        importName: '',
        exportName: 'exportMaterialsBalanceList',
        exportParams: {}
      },
      columnsAll,
      columnsRight,
      visible: false,
      formData: {},
      currentType: 'add',
      searchTitle: '请输入物料名称/编号/规格',
      type: 0,
      columnsRequirement,
      requireList: [],
      isExpand: true,
      selectedRow: '',
      columnsSupply,
      supplyList: []
    }
  },
  computed: {
    height() {
      return this.projectId ? this.$store.state.app.contentHeight - 235 - 10 - 42 : this.$store.state.app.contentHeight - 235 - 10
    },
    heightAll() {
      return this.projectId ? this.$store.state.app.contentHeight - 235 - 10 + 35 - 42 : this.$store.state.app.contentHeight - 235 - 10 + 35
    },
    columnsKey() {
      return this.$route.name + this.type
    },
    getColumns() {
      let columnRequire = this._.cloneDeep(this.columnsAll)
      if (this.type === 1) {
        columnRequire.splice(7)
      } else if (this.type === 2) {
        columnRequire = this.columnsRight
      }
      return columnRequire
    }
  },
  methods: {
    allSrc(){
      this.$refs.mTable.setTableData(true)
    },
    async getTableData(condition, callback) {
      const {page, search, order} = condition
      const data = Object.assign({}, ...this.searchFormatData)
      const searchCondition = {
        search: this.functionHeader.searchVal,
        type: this.allFlag,
        startTime:this.allTime ? this.allTime + ' 23:59:59' : this.allTime,
        ...data
      }
      if(this.allFlag && this.allTime){
        searchCondition.type = this.allFlag
        searchCondition.startTime = this.allTime + ' 23:59:59'
      }
      this.functionHeader.exportParams = searchCondition

      const obt = {
        page: page.currentPage,
        size: page.pageSize,
        projectId: this.projectId,
        ...searchCondition
      }

      const res = await this.api[`get${this.params.radio}ListPage`](obt)
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    viewDetail(type) {
      this.type = type
      if (type) {
        this.functionHeader.exportName = ''
      } else {
        this.functionHeader.exportName = 'exportMaterialsBalanceList'
      }
      this.$refs.mTable.setTableData(true)
    },
    //选中行
    async handleRowClick(row) {
      if (this.type === 1) {
        this.selectedRow = row
        const res = await api.getMaterialsBalanceRequireDetail({
          materialsId: row.materialsId,
          projectId: this.projectId,
          type: this.allFlag,
          startTime: this.allTime ? this.allTime + ' 23:59:59' : this.allTime
        })
        if (res) {
          this.requireList = res
        }
      } else if (this.type === 2) {
        this.selectedRow = row
        const res = await api.getMaterialsBalanceSupplyDetail({
          materialsId: row.materialsId,
          projectId: this.projectId,
          type: this.allFlag,
          startTime: this.allTime ? this.allTime + ' 23:59:59' : this.allTime
        })
        if (res) {
          this.supplyList = res
        }
      }
    },
    getRowStyle({row}) {
      if (row === this.selectedRow) {
        return {background: 'rgb(165,194,238)'}
      }
    },
    getRowDelayStyle({row}) {
      if (row.overdue) {
        return {background: 'rgba(216, 84, 84, 0.504)'}
      }
    }
  }
}
</script>

<style scoped lang="scss">
.unit-wrap {
  padding: 10px;
}
</style>
