<template>
    <div class="materials-wrap common-tab">

        <el-radio-group v-model="radio" class="m-radio-group" @change="radioChange">
      <el-radio-button :label="1">BOM</el-radio-button>
      <el-radio-button :label="2">MBOM</el-radio-button>
    </el-radio-group>
    <div class="bottom">
        <Split :offset.sync="split.offset" :height="splitHeight" v-if="radio == 1">
          <template slot="left">
            <BOMClass @nodeClick="setMaterialsClassId" @changeWidth="changeWidths"/>
          </template>
          <template slot="right">
            <div class="top">
              <SearchForm :form-list-extend="searchFormList" :form-data="searchFormData" @search="searchTable" :searchType='1'/>
            </div>
            <div class="materials-info">
              <FunctionHeader
                ref="functionHeader"
                v-model="functionHeader.searchVal"
                search-title="请输入名称/编号/规格"
                :tags="!selectionData.length ? functionHeader.tags : []"
                :import-name="!selectionData.length ? functionHeader.importName : ''"
                :exportName="!selectionData.length ? functionHeader.exportName : ''"
                @search="$refs.mTable.setTableData(true)"
              >
                <div slot="all" v-show="selectionData.length">
                  <el-button v-if="permission('Export')" size="small" type="primary"  @click="allMore('batchExport')">批量导出</el-button>
                  <el-button v-if="permission('Del')" size="small" type="primary"  @click="allMore('batchDel')">批量删除</el-button>
                  <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
                    已选
                    <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
                    / {{ total }}项
                  </span>
                    <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
                </div>
              </FunctionHeader>
              <MTable ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData" @selection-change="selectionChange">
                <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
                <div slot="drawing" slot-scope="{ row }">
                  <el-image
                    v-if="row.picture"
                    :src="row.picture"
                    class="drawing-img"
                    :preview-src-list="[row.picture]"
                  />
                </div>
                <NewPageOpen v-if="isShowChild" slot="code" slot-scope="{ row }" :path="{ name: 'bomDetail', query: { id: row.id, type: 'view' }}" :text="row.code" />
                <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
                  <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}" />
                  <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
                </div>
                <div slot="action" slot-scope="{ row }">
                  <el-button v-if="permission('Status')" type="text" @click="switchs(row)">{{ row.isEnabled ? '停用' : '启用' }}</el-button>
                  <el-divider v-if="permission('Status')" direction="vertical" />
                  <el-button v-if="permission('Del')" type="text" @click="del(row)">删除</el-button>
                  <!-- <NewPageOpen v-if="permission('Modify')" :path="{ name: 'materialsDetail', query: { id: row.id, type: 'modify' }}" text="编辑" /> -->
                  <!-- <el-divider v-if="permission('Del')" direction="vertical" /> -->
                  
                  <!-- <el-dropdown @command="(command) => { more(row, command) }">
                    <span class="el-dropdown-link">
                      更多<i class="el-icon-arrow-down el-icon--right" />
                    </span>
                    <el-dropdown-menu slot="dropdown" class="td-dropdown-menu">
                      <el-dropdown-item v-if="permission('Copy')" command="copy">复制</el-dropdown-item>
                      <el-dropdown-item v-if="permission('Del')" command="del">删除</el-dropdown-item>
                      <el-dropdown-item v-if="permission('Status')" command="switch">{{ row.isEnabled ? '停用' : '启用' }}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown> -->
                </div>
              </MTable>
            </div>
          </template>
        </Split>
        <div v-else>
          <div class="top">
              <SearchForm :form-list-extend="searchFormList" :form-data="searchFormData" @search="searchTable" :searchType='1'/>
            </div>
            <div class="materials-info">
              <FunctionHeader
                ref="functionHeader"
                v-model="functionHeader.searchVal"
                search-title="请输入名称/编号/规格"
                :tags="!selectionData.length ? functionHeader.tags : []"
                @search="$refs.mTable.setTableData(true)"
              >
                <div slot="all" v-show="selectionData.length">
                  <el-button v-if="permission('Export')" size="small" type="primary"  @click="allMore('batchExport')">批量导出</el-button>
                  <span style="margin: 0 10px;display: inline-block;font-size:12px;color: #393D60;">
                    已选
                    <span style="color:#607FFF">{{ getSelectionDataLength }}</span>
                    / {{ total }}项
                  </span>
                    <el-button type="text" size="small" @click="resetSelectData">清空</el-button>
                </div>
              </FunctionHeader>
              <MTable ref="mTable" :columns="getColumns" :height="height" :set-data-method="getTableData" @selection-change="selectionChange">
                <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
                <div slot="drawing" slot-scope="{ row }">
                  <el-image
                    v-if="row.picture"
                    :src="row.picture"
                    class="drawing-img"
                    :preview-src-list="[row.picture]"
                  />
                </div>
                <NewPageOpen v-if="isShowChild" slot="code" slot-scope="{ row }" :path="{ name: 'mbomDetail', query: { id: row.id, type: 'view' }}" :text="row.code" />
                <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
                  <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}" />
                  <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
                </div>
                <div slot="action" slot-scope="{ row }">
                  <NewPageOpen v-if="permission('Modify')" :path="{ name: 'materialsDetail', query: { id: row.id, type: 'modify' }}" text="编辑" />
                  <el-divider v-if="permission('Detail')" direction="vertical" />
                  <el-dropdown @command="(command) => { more(row, command) }">
                    <span class="el-dropdown-link">
                      更多<i class="el-icon-arrow-down el-icon--right" />
                    </span>
                    <el-dropdown-menu slot="dropdown" class="td-dropdown-menu">
                      <el-dropdown-item v-if="permission('Copy')" command="copy">复制</el-dropdown-item>
                      <el-dropdown-item v-if="permission('Del')" command="del">删除</el-dropdown-item>
                      <el-dropdown-item v-if="permission('Status111')" command="switch">{{ row.isEnabled ? '停用' : '启用' }}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </MTable>
            </div>
        </div>
      </div>
    </div>
  </template>

  <script>
  import FunctionHeader from '@/components/FunctionHeader'
  import SearchForm from '@/components/SearchForm/SearchForm'
  import {columns} from '@/views/information/bom/columns'
  import maApi from '@/api/information/materials-attribute'
  import api from '@/api/information/bom'
  import  {searchFormList}  from '@/views/information/bom/form-list'
  import { getUUid } from '@/utils'
  import { Encrypt } from '@/utils/sercet'
  import Split from '@/components/Split/Split'
  import BOMClass from '@/components/BOMClass/BOMClass'
  import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
  import fixCommonPage from '@/mixin/fix-common-page'
  import dayjs from 'dayjs'

  export default {
    name: 'bomList',
    components: { NewPageOpen, BOMClass, Split, FunctionHeader, SearchForm },
    mixins: [ fixCommonPage],
    data() {
      return {
        api,
        params: {
          radio: 'Bom'
        },
        functionHeader: {
          searchVal: '',
          exportName: 'exportBomList2',
          exportParams: {},
          tags: [
            // {
            //   hidden: !this.permission('Add'),
            //   tagName: 'el-button',
            //   props: { type: 'primary', size: 'small' },
            //   style: { width: 'auto', background: '#607FFF' },
            //   innerText: '新增',
            //   on: {
            //     click: this.add
            //   }
            // },

            //{
            //  hidden: !this.permission('Export'),
            //  tagName: 'el-button',
            //  props: { type: 'primary', size: 'small' },
            //  style: { width: 'auto', background: '#607FFF', marginRight: '0px'},
            //  innerText: '全部导出',
            //  on: {
            //    click: () => this.batchExport(true)
            //  }
            //}
          ],
          importName: 'materialsBomImport',
        //   exportName: 'exportBom',
          exportParams: {}
        },
        columns,
        searchFormList,
        searchFormData: {},
        searchFormatData: [],
        split: {
          offset: 15
        },
        selectionData:[],
        materialsClassId: '',
        defaultSearch: {
          searchVal: Encrypt(JSON.stringify(
            [{
              id: getUUid(),
              fieldName: 'isEnabled',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq'
            }]
          ))
        },
        radio:1,
        total:0,
        selectionDataList:[]
      }
    },
    computed: {
      height() {
        return this.$store.state.app.contentHeight - 235 - 88 - 48
      },
      splitHeight() {
        return this.$store.state.app.contentHeight - 235 + 70 - 48
      },
    getColumns() {
      const newColumn = this._.cloneDeep(this.columns)
      newColumn.pop()
      return newColumn
     },
     getSelectionDataLength() {
      const selectionDataNoDisabled = this.selectionDataList.filter((item) => !item.selectable)
      return selectionDataNoDisabled.length
    }
    },
    beforeRouteLeave(to, from, next) {
      if (to.name !== 'refresh') {
        this.$store.dispatch('tagsView/addCachedView', from)
      }
      next()
    },
    // beforeMount() {
    //   getFunctionList({
    //     allCodeChange: this.allCodeChange
    //   })
    // },
    mounted() {
    //   this.getMaterialsTypeList()
    //   this.getMaterialsAttributeList()
      // this.getSpecificationsList()
    },
    methods: {
      radioChange(val){
      this.searchFormatData = []
      this.functionHeader.searchVal = ''
        if(val === 1){
        this.params.radio = 'Bom'
        // this.functionHeader.tags[0].hidden = false
        }else{
          this.params.radio = 'MBom'
        //   this.functionHeader.tags[0].hidden = true
        }
        this.selectionData = []
      },
        changeWidths(val){
        this.split.offset = val ? 15 : 3
    },
        selectionChange(data) {
      this.selectionData = data.map(item => item.id)
      this.selectionDataList = data
    },
    resetSelectData() {
      this.selectionDataList.forEach(item => {
        if (!item.selectable) {
          this.$refs.mTable.tableRefs().toggleRowSelection(item, false)
        }
      })
      this.selectionDataList = this.selectionDataList.filter((item) => item.selectable)
    },
        allMore(command) {
      const functions = {
        batchDel: () => this.onDel(this.selectionData),
        batchExport: this.batchExport
      }
      functions[command]()
    },
    async batchExport(isAll) {
      const params = isAll ? this.functionHeader.exportParams : { ids: this.selectionData }
      const res = await this.api[`export${this.params.radio}List`](params)
      if (res && res.data.byteLength) {
        const urlP = window.URL.createObjectURL(
          new Blob([res.data], {
            type: 'application/octet-stream;charset=ISO8859-1'
          })
        )
        const fileName = `${dayjs().format('YYYYMMDDHHmmss')}.xls`
        this.downloadFile(urlP, fileName)
      }
    },
    downloadFile(urlP, name) {
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = urlP
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
      setMaterialsClassId(id) {
        this.materialsClassId = id
        this.$refs.mTable.setTableData(true)
      },
      // 物料规格
      async getSpecificationsList() {
        const res = await api.getSpecifications()
        if (res) {
          this.searchFormList[3].children[0].options = res
        }
      },
      // 获取物料类型
      async getMaterialsTypeList() {
        const res = await maApi.getMaterialsTypeList(this.defaultSearch)
        if (res) {
          this.searchFormList[1].children[0].options = res
        }
      },
      // 获取物料属性
      async getMaterialsAttributeList() {
        const res = await maApi.getMaterialsAttributeList()
        if (res) {
          this.searchFormList[2].children[0].options = res
        }
      },
      // 获取物料数据
      async getTableData(condition, callback) {
        const { page, search, order } = condition

        const data = Object.assign({}, ...this.searchFormatData)
        const searchCondition = {
          materialsBomClassId: this.materialsClassId,
          search: this.functionHeader.searchVal,
          ...data
        }
        this.functionHeader.exportParams = searchCondition
        const res = await this.api[`get${this.params.radio}ListPage`]({
          page: page.currentPage,
          size: page.pageSize,
          ...searchCondition
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
          this.total = res.total
        }
        callback()
      },
      // 查询物料
      searchTable(data) {
        this.searchFormatData = data
        this.$refs.mTable.setTableData(true)
      },
      add() {
        // this.$router.push({ name: 'materialsDetail', query: { type: 'add' }})
      },
      // 更多操作
      more(row, command) {
        const functions = {
          modify: () => {
            this.modifyOrCopy(row, command)
          },
          copy: () => {
            this.modifyOrCopy(row, command)
          },
          del: () => {
            this.del(row)
          },
          switch: () => {
            this.switch(row)
          }
        }
        functions[command]()
      },
      modifyOrCopy(row, command) {
        this.$router.push({ name: 'materialsDetail', query: { id: row.id, type: command }})
      },
      async onDel(ids) {
        const res = await api.batchDeleteBom({ids})
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.tableRefs().clearSelection()
          this.$refs.mTable.setTableData(true)
        }
      },
      // 删除物料
      del(row) {
        this.currentType = 'del'
        this.$confirm('您确定要删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          const res = await api.deleteBom({ id: row.id })
          if (res) {
            this.$message.success('删除成功')
            this.$refs.mTable.setTableData()
          }
        })
      },
      // 更改物料状态
      async switchs(row) {
        const type = row.isEnabled ? 'disable' : 'enable'
        const res = await api[`${type}Bom`](row.id)
        if (res) {
          this.$message.success(`${row.name}${row.isEnabled ? '停用' : '启用'}成功`)
          this.$refs.mTable.setTableData()
        }
      },
      viewDetail(row) {
        this.$message.info('敬请期待！')
      },
      onSuccess() {
        this.$refs.mTable.setTableData()
      }
    }
  }
  </script>

  <style scoped lang="scss">
  .m-radio-group{
    margin-bottom: 10px
  }
  .function-header-wrap {
    padding-top: 0;
  }

  .materials-wrap {
    width: 100%;

    .bottom {
      width: 100%;
      height: 100%;
      padding: 10px;

      .materials-info {
        padding: 10px;
      }
    }
  }
  .drawing-img {
    width: 50px;
    height: 48px;
    display: block;
    cursor: pointer;
    color: #fff;
  }
  ::v-deep {
    .params-card {
      .body {
        padding: 0 !important;
      }
      .params {
        width: 100% !important;
      }
      .el-form-item__label {
        padding: 0 12px 0 0 !important;
      }
    }
  }
  </style>
