// 详情基本信息字段
export const detailBaseFields = [
  {
    key: 'code',
    name: '领料单号：'
  },
  {
    key: 'name',
    name: '领料单名称：'
  },
  {
    key: 'receiptType',
    name: '单据类型：'
  },
  {
    key: 'receiptStatus',
    name: '领料单状态：'
  },
  {
    key: 'receiptNumber',
    name: '工单编号：'
  },
  {
    key: 'receiptName',
    name: '工单名称：'
  },
  {
    key: 'projectNumber',
    name: '项目编号：'
  },
  {
    key: 'projectName',
    name: '项目名称：'
  },
  {
    key: 'requireDate',
    name: '需求时间：'
  },
  {
    key: 'remark',
    name: '备注：'
  }
]
// 领料单新增物料字段
export const productionTaskColumn = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    width: 60
  },
  {
    prop: 'procedureLocationCode',
    label: '工序定位码',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    label: '工序名称',
    sortable: false,
    prop: 'procedureName',
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'materialsType',
    label: '物料类型',
    sortable: false,
    slotName: 'materialsType',
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'specifications',
    label: '物料规格',
    sortable: false,
    hiddenSearch: true,
    minWidth: 140
  },
  {
    prop: 'mainUnitName',
    label: '主单位',
    hiddenSearch: true,
    sortable: false,
    minWidth: 120
  },
  {
    prop: 'planOutputQuantity',
    slotName: 'planOutputQuantity',
    label: '申请数量',
    hiddenSearch: true,
    sortable: false,
    minWidth:140
  },
  {
    prop: 'notReceiveQuantity',
    label: '未领数量',
    sortable: false,
    hiddenSearch: true,
    minWidth:100
  },
  {
    prop: 'appliedUnissuedQuantity',
    label: '申请未发数量',
    sortable: false,
    hiddenSearch: true,
    minWidth:120
  },
  // {
  //   prop: 'availableStockQuantity',
  //   label: '占用库存',
  //   sortable: false,
  //   hiddenSearch: true,
  //   minWidth:100
  // },
  // {
  //   prop: 'availableQuantity',
  //   label: '自由库存',
  //   sortable: false,
  //   hiddenSearch: true,
  //   minWidth:100
  // },
  {
    prop: 'action',
    label: '操作',
    hiddenSearch: true,
    sortable: false,
    width: 120,
    slotName: 'action',
    fixed: 'right'
  }
]
// 详情物料字段
export const applyColumn = [
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      fixed: 'left',
      width: 60
    },
    {
      prop: 'procedureLocationCode',
      label: '工序定位码',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120
    },
    {
      label: '工序名称',
      sortable: false,
      prop: 'procedureName',
      hiddenSearch: true,
      minWidth: 120
    },
    {
      prop: 'materialsType',
      label: '物料类型',
      sortable: false,
      slotName: 'materialsType',
      hiddenSearch: true,
      minWidth: 120
    },
    {
      prop: 'materialsCode',
      label: '物料编号',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120
    },
    {
      prop: 'materialsName',
      label: '物料名称',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'specifications',
      label: '物料规格',
      sortable: false,
      hiddenSearch: true,
      minWidth: 140
    },
    {
      prop: 'mainUnitName',
      label: '主单位',
      hiddenSearch: true,
      sortable: false,
      minWidth: 120
    },
    {
      prop: 'applyQuantity',
      label: '申请数量',
      hiddenSearch: true,
      sortable: false,
      minWidth: 100
    },
    {
        prop: 'receivedQuantity',
        label: '已发数量',
        hiddenSearch: true,
        sortable: false,
        minWidth: 100
      },
    {
      prop: 'notReceiveQuantity',
      label: '未发数量',
      sortable: false,
      hiddenSearch: true,
      minWidth:100
    }
  ]
  // 退料单详情基本信息字段
export const detailReturnBaseFields = [
  {
    key: 'code',
    name: '退料单号：'
  },
  {
    key: 'name',
    name: '退料单名称：'
  },
  {
    key: 'receiptType',
    name: '单据类型：'
  },
  {
    key: 'receiptStatus',
    name: '退料单状态：'
  },
  {
    key: 'receiptNumber',
    name: '工单编号：'
  },
  {
    key: 'receiptName',
    name: '工单名称：'
  },
  {
    key: 'projectNumber',
    name: '项目编号：'
  },
  {
    key: 'projectName',
    name: '项目名称：'
  },
  {
    key: 'requireDate',
    name: '退料时间：'
  },
  {
    key: 'remark',
    name: '备注：'
  }
]
  // 退料单新增物料字段
export const returnAddColumn = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    width: 60
  },
  {
    prop: 'procedureLocationCode',
    label: '工序定位码',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    label: '工序名称',
    sortable: false,
    prop: 'procedureName',
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'materialsType',
    label: '物料类型',
    sortable: false,
    slotName: 'materialsType',
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    sortable: false,
    hiddenSearch: true,
    width: 120
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'specifications',
    label: '物料规格',
    sortable: false,
    hiddenSearch: true,
    minWidth: 140
  },
  {
    prop: 'mainUnitName',
    label: '主单位',
    hiddenSearch: true,
    sortable: false,
    minWidth: 120
  },
  {
    prop: 'planOutputQuantity',
    slotName: 'planOutputQuantity',
    label: '申请数量',
    hiddenSearch: true,
    sortable: false,
    minWidth:140
  },
  {
    prop: 'receivedQuantity',
    label: '已领数量',
    sortable: false,
    hiddenSearch: true,
    minWidth:100
  },
  {
    prop: 'appliedNotReturnedQuantity',
    label: '申请未退数量',
    sortable: false,
    hiddenSearch: true,
    minWidth:100
  },
  {
    prop: 'action',
    label: '操作',
    hiddenSearch: true,
    sortable: false,
    width: 120,
    slotName: 'action',
    fixed: 'right'
  }
]
// 退料单详情物料字段
export const applyReturnColumn = [
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true,
    fixed: 'left',
    width: 60
  },
  {
    prop: 'procedureLocationCode',
    label: '工序定位码',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    label: '工序名称',
    sortable: false,
    prop: 'procedureName',
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'materialsType',
    label: '物料类型',
    sortable: false,
    slotName: 'materialsType',
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'materialsCode',
    label: '物料编号',
    sortable: false,
    hiddenSearch: true,
    minWidth: 120
  },
  {
    prop: 'materialsName',
    label: '物料名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'specifications',
    label: '物料规格',
    sortable: false,
    hiddenSearch: true,
    minWidth: 140
  },
  {
    prop: 'mainUnitName',
    label: '主单位',
    hiddenSearch: true,
    sortable: false,
    minWidth: 120
  },
  {
    prop: 'applyQuantity',
    label: '申请数量',
    hiddenSearch: true,
    sortable: false,
    minWidth: 100
  },
  {
      prop: 'receivedQuantity',
      label: '已退数量',
      hiddenSearch: true,
      sortable: false,
      minWidth: 100
    },
  {
    prop: 'notReceiveQuantity',
    label: '未退数量',
    sortable: false,
    hiddenSearch: true,
    minWidth:100
  }
]
