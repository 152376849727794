
export const searchFormList = [
  {
    label: '选择物料：',
    key: 'materialsId',
    tagName: 'el-input',
    colSpan: 6,
    required: true,
    attrs: {
      className: 'select-materials'
    },
    children: [
      {
        slots: ['prepend', 'append']
      }
    ]
  },
  {
    label: '需求数量：',
    key: 'requiredQuantity',
    tagName: 'el-input',
    colSpan: 6,
    required: true,
    attrs: {
      clearable: true,
      placeholder: '请输入需求数量'
    }
  },
  {
    label: '',
    key: 'btn',
    tagName: 'div',
    colSpan: 6,
    domProps: { className: 'customer-content' },
    children: [
      {
        slots: ['prepends']
      }
    ]
  }
]
export const searchFormLists = [
  {
    label: '选择物料：',
    key: 'materialsId',
    tagName: 'el-input',
    required: true,
    colSpan: 8,
    attrs: {
      className: 'select-materials'
    },
    children: [
      {
        slots: ['prepend', 'append']
      }
    ]
  }
]
