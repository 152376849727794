<template>
  <div class="orderAdd">
    <div class="procedure-detail-header">
      <div class="left" @click="$router.push({ name: 'materialsSettingList' })">
        <img src="@/assets/information/procedure/左滑@2x.png">
        <span>编辑BOM</span>
      </div>
    </div>
    <div class="detail-card">
      <div class="card-tit">基本信息</div>
      <div class="card-bot">
        <el-col :span="6">
          <span class="bot-ti">物料名称：{{ detailData.name }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">物料编码：{{ detailData.code }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">规格：{{ detailData.specifications }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">物料属性：{{ detailData.materialsAttributeName }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">用量分母：<el-input-number v-model="detailData.quantityDenominator" :step="1" :min="0" controls-position="right" style="width: 150px;" /></span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">物料类型：{{ detailData.materialsTypeName }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">单位：{{ detailData.mainUnitName }}</span>
        </el-col>
      </div>
    </div>
    <div class="detail-card">
      <div class="card-bot">
        <el-button type="primary" class="e-btn" @click="selectadd">选择添加物料</el-button>
        <MTable ref="mUnitTable" :height="200" :show-page="false" :data="orderList" :columns="columnsOrder" :columns-setting="false">
          <div slot="bomLevel">1</div>
          <div slot="quantityNumerator" slot-scope="{ row }">
            <el-input-number v-model="row.quantityNumerator" :min="0" controls-position="right" :step="1" />
          </div>
          <div slot="action" slot-scope="{ row , $index}">
            <el-button type="text" class="li-btn" @click="delPlan($index)">删除</el-button>
          </div>
        </MTable>
      </div>
    </div>
    <div class="order-footer">
      <div class="footer-le" />
      <div class="footer-ri">
        <el-button @click="returnList">取消</el-button>
        <el-button type="primary" :loading="loading" @click="submitForms">确认</el-button>
      </div>
    </div>
    <div class="lines" />
    <select-materials
      :visible.sync="showMaterials"
      @submitForm="submitMaterials"
    />
  </div>
</template>
<script>
import { columnsOrder } from './columns'
import { orderSource } from '@/config/options.config'
import SelectMaterials from '@/components/SelectMaterials/SelectMaterials.vue'
import mApi from '@/api/information/materials-attribute'

export default {
  name: 'EditBom',
  components: {
    SelectMaterials
  },
  mixins: [],
  data() {
    return {
      columnsOrder,
      orderSource,
      showMaterials: false,
      orderList: [],
      selectData: [],
      searchFormData: {
        keyword: '',
        selectKeyword: ''
      },
      ruleForm: {
        produceOrderNumber: '',
        source: 0,
        deliveryTime: '',
        remark: '',
        priority: undefined
      },
      rules: {
        source: [
          { required: true, message: '请输入活动名称', trigger: 'blur' }
        ],
        produceOrderNumber: [
          { required: true, message: '请输入订单号', trigger: 'blur' }
        ]
      },
      id: '',
      selectMaterials: [],
      detailData: {
      },
      selectMaterialList: [],
      list: [],
      loading: false,
      materialsList: []
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 289 - 40 - 54 + 29 - 62 - 22
    },
    priorityStatus() {
      return this.$store.state.user.priorityList
    },
    getQuantityDenominator: function() {
      return this.detailData.quantityDenominator
    }
  },
  watch: {
    getQuantityDenominator(val) {
      this.orderList = this.orderList.map(item => {
        return {
          ...item,
          quantityDenominator: val
        }
      })
    }
  },
  mounted() {
    this.detailData = this.$route.query.detailData ? JSON.parse(this.$route.query.detailData) : {}
    this.materialsList = this.$route.query.materialsList ? JSON.parse(this.$route.query.materialsList) : []
    this.getDetail()
  },
  activated(){
    this.detailData = this.$route.query.detailData ? JSON.parse(this.$route.query.detailData) : {}
    this.materialsList = this.$route.query.materialsList ? JSON.parse(this.$route.query.materialsList) : []
    this.getDetail()
  },
  methods: {
    submitMaterials(val,callback) {
      const list = val.length > 0 ? val.map(item => {
        return {
          ...item,
          quantityNumerator: undefined,
          quantityDenominator: this.detailData.quantityDenominator
        }
      }) : []
      for (let i = 0; i < this.orderList.length; i++) {
        if (list.some(item => item.id === this.orderList[i].id)) {
          this.$message.error('选择的物料已存在')
          this.loading = false
          callback()
          return
        }
      }
      if (list.some(item => item.id === this.detailData.id)) {
        this.$message.error('不能选择当前物料')
        callback()

        return
      }
      console.log('list:',this.list)
      console.log('orderList:',this.orderList)

      this.orderList = [...this.orderList, ...list]
      this.showMaterials = false
      // this.selectMaterialList = val
    },
    selectadd() {
      // this.selectMaterials = this._.cloneDeep(this.orderList)
      this.showMaterials = true
    },
    submitForms() {
      if (this.detailData.quantityDenominator === undefined || this.detailData.quantityDenominator === '' || this.detailData.quantityDenominator === null) {
        this.$message.error('请输入用量分母')
        return
      }
      // if (this.orderList.length === 0) {
      //   this.$message.error('请选择物料')
      //   return
      // }
      this.loading = true
      this.getData()
    },
    // 修改
    async getData() {
      const data = this.orderList.map(item => {
        return {
          id: item.id,
          quantityNumerator: item.quantityNumerator
        }
      })
      const obj = {
        id: this.detailData.id,
        quantityDenominator: this.detailData.quantityDenominator
      }
      const res = await mApi.modifyMaterialsBom(Object.assign({}, obj, {
        bomList: data
      }))
      this.loading = false
      if (res) {
        this.returnList()
      }
    },
    // 详情
    async getDetail() {
      const res = await mApi.getMaterialsBomSingleList(this.detailData.id)
      if (res) {
        this.list = res
        this.orderList = res
        this.detailData.quantityDenominator = res.length > 0 ? res[0].quantityDenominator : 1
      }
    },
    returnList() {
      this.$router.push({ path: 'materials-setting-list', query: { materialsList: JSON.stringify(this.materialsList) }})
    },
    delPlan(index) {
      this.orderList.splice(index, 1)
      this.list = this.orderList
      // this.selectMaterialList = this.orderList
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep{
    .el-date-editor .el-range-separator,.el-date-editor .el-range__icon{
      line-height:26px;
    }
    .el-input-number{
      height:32px;
      line-height:32px;
      width:100%;
    }
    .el-input__inner{
      height:32px;
      line-height:32px;
    }
    .el-input-number.is-controls-right .el-input-number__increase, .el-input-number.is-controls-right .el-input-number__decrease{
      line-height:15px;
    }
  }
 .orderAdd{
   width:100%;
   height:100%;
  //  position:relative;
  .detail-card {
    margin-bottom: 20px;
  }
  .lines{
    width:100%;
    height:20px;
    background: #F0F2F5;
  }
   ::v-deep .el-select{
     width:100%;
   }
   .e-btn{
     margin-bottom:20px;
   }
   .order-footer{
     position: absolute;
     bottom:0;
     left:0;
     height:60px;
     width:100%;
     display:flex;
     justify-content: space-between;
     align-items: center;
     z-index:1;
     background:#fff;
     border-top:1px solid #DDE3EB;
     .footer-le{
      padding-left:30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393D60;
      span{
        margin-right:45px;
        display:inline-block;
      }
     }
     .footer-ri{
       margin-right:30px;
       display:flex;
       justify-content: center;
       align-items: center;
     }
   }
 }
 .procedure-detail-header{
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #41456B;
  line-height: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  .left{
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  img{
    width: 8px;
    height: 12px;
    display: block;
    margin-right: 10px;
  }
}
  .card-bot{

    .bot-ti{
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393D60;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 16px;
    }
  }
</style>
