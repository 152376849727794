var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "font-size": "14px" } },
    [
      _c(
        "div",
        { staticStyle: { padding: "15px 0" } },
        [
          _c("span", { staticStyle: { "padding-left": "10px" } }, [
            _vm._v("质检方案：")
          ]),
          _c(
            "el-select",
            {
              staticStyle: { width: "200px" },
              attrs: {
                size: "small",
                filterable: "",
                disabled: _vm.readOnly,
                placeholder: "请选择质检方案"
              },
              on: {
                change: function(val) {
                  return _vm.qualityInspectionChange(val)
                }
              },
              model: {
                value: _vm.qualityInspectionPlan.inspectionProjectId,
                callback: function($$v) {
                  _vm.$set(
                    _vm.qualityInspectionPlan,
                    "inspectionProjectId",
                    $$v
                  )
                },
                expression: "qualityInspectionPlan.inspectionProjectId"
              }
            },
            _vm._l(_vm.inspectionPlanList, function(item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id }
              })
            }),
            1
          ),
          _vm.permission(_vm.currentKey + "Modify")
            ? _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: {
                    size: "small",
                    type: "primary",
                    loading: _vm.buttonLoading
                  },
                  on: { click: _vm.editInspectionPlan }
                },
                [_vm._v(_vm._s(_vm.readOnly ? "编辑" : "保存"))]
              )
            : _vm._e(),
          !_vm.readOnly
            ? _c(
                "el-button",
                {
                  staticStyle: { "margin-left": "10px" },
                  attrs: { loading: _vm.buttonLoading, size: "small" },
                  on: {
                    click: function($event) {
                      return _vm.cancel()
                    }
                  }
                },
                [_vm._v("取消")]
              )
            : _vm._e()
        ],
        1
      ),
      _vm.qualityInspectionPlan.inspectionProjectId
        ? _c("quality-inspection-project", {
            ref: "qualityInspectionProject",
            staticStyle: { padding: "10px" },
            attrs: {
              "inspection-entry-map": _vm.qualityInspectionPlan.inspectEntryMap,
              attachments: _vm.qualityInspectionPlan.inspectAttachments,
              "user-list": _vm.qualityInspectionPlan.inspectorIdList,
              "read-only": _vm.readOnly,
              baseFormData: _vm.qualityInspectionPlan
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }