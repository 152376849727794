var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "detail-container" },
    [
      _c("div", { staticClass: "container-left" }, [
        _c(
          "div",
          { staticClass: "orderAdd" },
          [
            _c(
              "div",
              {
                staticClass: "detail-card",
                staticStyle: { "margin-bottom": "20px" }
              },
              [
                _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
                _c(
                  "div",
                  { staticClass: "card-bot" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "detail",
                        staticClass: "demo-form-inline",
                        attrs: {
                          "label-position": "right",
                          "label-width": "90px"
                        }
                      },
                      [
                        _c(
                          "el-row",
                          _vm._l(_vm.detailBaseFields, function(item) {
                            return _c(
                              "el-col",
                              { key: item.key, attrs: { span: 8 } },
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: item.name } },
                                  [
                                    _c("div", [
                                      _vm._v(_vm._s(_vm.getDetailVal(item.key)))
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            ),
            _vm.expTaskNeedHandleMsg.id
              ? _c(
                  "div",
                  {
                    staticClass: "detail-card",
                    staticStyle: { "margin-bottom": "20px" }
                  },
                  [
                    _c("div", { staticClass: "card-tit" }, [
                      _vm._v("异常判定")
                    ]),
                    _c(
                      "div",
                      { staticClass: "card-bot" },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "detail",
                            staticClass: "demo-form-inline",
                            attrs: {
                              "label-position": "right",
                              "label-width": "90px"
                            }
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.expTaskNeedHandleMsg.mastHaveValue.includes(
                                            "异常原因"
                                          )
                                            ? "*异常原因"
                                            : "异常原因"
                                        }
                                      },
                                      [
                                        _vm.expTaskNeedHandleMsg.editFieldList.includes(
                                          "异常原因"
                                        ) && !_vm.expTaskNeedHandleMsg.showFlag
                                          ? _c(
                                              "el-select",
                                              {
                                                attrs: {
                                                  placeholder: "请选择异常原因"
                                                },
                                                model: {
                                                  value: _vm.expReasonId,
                                                  callback: function($$v) {
                                                    _vm.expReasonId = $$v
                                                  },
                                                  expression: "expReasonId"
                                                }
                                              },
                                              _vm._l(
                                                _vm.expReasonList,
                                                function(item) {
                                                  return _c("el-option", {
                                                    key: item.id,
                                                    attrs: {
                                                      label: item.name,
                                                      value: item.id
                                                    }
                                                  })
                                                }
                                              ),
                                              1
                                            )
                                          : _c("el-input", {
                                              attrs: {
                                                disabled: true,
                                                clearable: ""
                                              },
                                              model: {
                                                value:
                                                  _vm.detail.abnormalReasonName,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.detail,
                                                    "abnormalReasonName",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "detail.abnormalReasonName"
                                              }
                                            })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.expTaskNeedHandleMsg.mastHaveValue.includes(
                                            "原因分析"
                                          )
                                            ? "*原因分析"
                                            : "原因分析"
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            disabled:
                                              !_vm.expTaskNeedHandleMsg.editFieldList.includes(
                                                "原因分析"
                                              ) ||
                                              _vm.expTaskNeedHandleMsg.showFlag,
                                            placeholder: _vm.expTaskNeedHandleMsg.editFieldList.includes(
                                              "原因分析"
                                            )
                                              ? "请输入原因分析"
                                              : "-",
                                            clearable: ""
                                          },
                                          model: {
                                            value: _vm.detail.reason,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.detail,
                                                "reason",
                                                $$v
                                              )
                                            },
                                            expression: "detail.reason"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.expTaskNeedHandleMsg.id
              ? _c(
                  "div",
                  {
                    staticClass: "detail-card",
                    staticStyle: { "margin-bottom": "20px" }
                  },
                  [
                    _c("div", { staticClass: "card-tit" }, [
                      _vm._v("异常处理")
                    ]),
                    _c(
                      "div",
                      { staticClass: "card-bot" },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "detail",
                            staticClass: "demo-form-inline",
                            attrs: {
                              "label-position": "right",
                              "label-width": "90px"
                            }
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 8 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: _vm.expTaskNeedHandleMsg.mastHaveValue.includes(
                                            "处理措施"
                                          )
                                            ? "*处理措施"
                                            : "处理措施"
                                        }
                                      },
                                      [
                                        _c("el-input", {
                                          attrs: {
                                            disabled:
                                              !_vm.expTaskNeedHandleMsg.editFieldList.includes(
                                                "处理措施"
                                              ) ||
                                              _vm.expTaskNeedHandleMsg.showFlag,
                                            placeholder: _vm.expTaskNeedHandleMsg.editFieldList.includes(
                                              "处理措施"
                                            )
                                              ? "请输入处理措施"
                                              : "-",
                                            clearable: ""
                                          },
                                          model: {
                                            value: _vm.detail.tempPolicy,
                                            callback: function($$v) {
                                              _vm.$set(
                                                _vm.detail,
                                                "tempPolicy",
                                                $$v
                                              )
                                            },
                                            expression: "detail.tempPolicy"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _vm.expTaskNeedHandleMsg.id
              ? _c(
                  "div",
                  {
                    staticClass: "detail-card",
                    staticStyle: { "margin-bottom": "20px" }
                  },
                  [
                    _c("div", { staticClass: "card-tit" }, [
                      _vm._v(
                        _vm._s(
                          _vm.expTaskNeedHandleMsg.mastHaveValue.includes(
                            "拍照上传"
                          )
                            ? "*拍照上传"
                            : "拍照上传"
                        ) + " "
                      )
                    ]),
                    _c(
                      "div",
                      { staticClass: "card-bot" },
                      [
                        _c(
                          "el-form",
                          {
                            ref: "detail",
                            staticClass: "demo-form-inline",
                            attrs: {
                              "label-position": "right",
                              "label-width": "90px"
                            }
                          },
                          [
                            _vm.expTaskNeedHandleMsg.editFieldList.includes(
                              "拍照上传"
                            )
                              ? _c(
                                  "el-row",
                                  [
                                    _c(
                                      "el-col",
                                      { attrs: { span: 8 } },
                                      [
                                        _c(
                                          "el-upload",
                                          _vm._b(
                                            {
                                              staticClass: "b20",
                                              attrs: { type: "primary" }
                                            },
                                            "el-upload",
                                            _vm.uploadProps,
                                            false
                                          ),
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                attrs: {
                                                  size: "small",
                                                  type: "primary"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    _vm.uploadKey =
                                                      "drawingsList"
                                                  }
                                                }
                                              },
                                              [_vm._v("上传图片 ")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "el-row",
                              { staticStyle: { "margin-top": "3px" } },
                              _vm._l(_vm.drawingsList, function(yp, index) {
                                return _c(
                                  "el-col",
                                  {
                                    staticStyle: {
                                      position: "relative",
                                      "margin-right": "20px"
                                    },
                                    attrs: { span: 2 }
                                  },
                                  [
                                    _vm.expTaskNeedHandleMsg.editFieldList.includes(
                                      "拍照上传"
                                    )
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "deleteA",
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteImg(index)
                                              }
                                            }
                                          },
                                          [_vm._v("× ")]
                                        )
                                      : _vm._e(),
                                    _c("img", {
                                      staticStyle: {
                                        width: "80px",
                                        height: "80px",
                                        cursor: "pointer"
                                      },
                                      attrs: { src: yp.url },
                                      on: {
                                        click: function($event) {
                                          return _vm.windowOpenImg(yp.url)
                                        }
                                      }
                                    })
                                  ]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _c(
              "el-tabs",
              {
                staticClass: "detail-card detail-tabs",
                attrs: { type: "border-card" },
                on: { "tab-click": _vm.tabClick },
                model: {
                  value: _vm.activeName,
                  callback: function($$v) {
                    _vm.activeName = $$v
                  },
                  expression: "activeName"
                }
              },
              [
                _c(
                  "el-tab-pane",
                  { attrs: { label: "详细信息", name: "1" } },
                  [
                    _c("TimeLine", {
                      attrs: { "base-data": _vm.moreMsgDetail }
                    })
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "涉及项目", name: "2" } },
                  [
                    _c("MTable", {
                      ref: "mTable",
                      attrs: {
                        columns: _vm.detailColumn_3,
                        height: 500,
                        data: _vm.detail.projectList,
                        "show-page": false
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "index",
                          fn: function(ref) {
                            var $index = ref.$index
                            return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                          }
                        },
                        {
                          key: "projectName",
                          fn: function(ref) {
                            var row = ref.row
                            return _c("div", {}, [
                              _vm._v(_vm._s(row.projectName.split("/")[1]))
                            ])
                          }
                        }
                      ])
                    })
                  ],
                  1
                ),
                _c(
                  "el-tab-pane",
                  { attrs: { label: "涉及物料", name: "3" } },
                  [
                    _c("MTable", {
                      ref: "mTable",
                      attrs: {
                        columns: _vm.detailColumn_2,
                        height: 500,
                        data: _vm.detail.materialsList,
                        "show-page": false
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "index",
                          fn: function(ref) {
                            var $index = ref.$index
                            return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                          }
                        }
                      ])
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "order-footer" }, [
              _c("div"),
              _c(
                "div",
                { staticClass: "footer-ri" },
                [
                  _c("el-button", { on: { click: _vm.returnList } }, [
                    _vm._v("返 回")
                  ]),
                  _vm._l(_vm.expTaskNeedHandleMsg.buttonNameList, function(
                    btn
                  ) {
                    return _vm.expTaskNeedHandleMsg.id &&
                      !_vm.expTaskNeedHandleMsg.showFlag
                      ? [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function($event) {
                                  return _vm.onChangeStatus(0)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(btn) + " ")]
                          )
                        ]
                      : _vm._e()
                  }),
                  _vm.expTaskNeedHandleMsg.id &&
                  !_vm.expTaskNeedHandleMsg.showFlag
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            loading: _vm.buttonLoading,
                            type: "primary"
                          },
                          on: {
                            click: function($event) {
                              return _vm.onChangeStatus(1)
                            }
                          }
                        },
                        [_vm._v("通 过 ")]
                      )
                    : _vm._e(),
                  _vm.expTaskNeedHandleMsg.id &&
                  _vm.expTaskNeedHandleMsg.showFlag
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            loading: _vm.buttonLoading,
                            type: "primary"
                          },
                          on: { click: _vm.callTaskCancel }
                        },
                        [_vm._v("撤 回 ")]
                      )
                    : _vm._e()
                ],
                2
              )
            ])
          ],
          1
        )
      ]),
      _c("div", { staticClass: "container-right" }, [
        _c(
          "div",
          {
            staticStyle: {
              height: "100vh",
              width: "94%",
              "box-sizing": "border-box",
              "margin-left": "6%",
              background: "#FFFFFF",
              padding: "20px"
            }
          },
          [
            _c("h3", [_vm._v("流程日志")]),
            _c(
              "div",
              {
                staticStyle: {
                  width: "100%",
                  "max-height": "80vh",
                  "overflow-y": "scroll"
                }
              },
              [
                _c(
                  "el-steps",
                  {
                    attrs: { direction: "vertical", active: 50, space: "140px" }
                  },
                  _vm._l(_vm.processList, function(itemP, index) {
                    return _c(
                      "el-step",
                      {
                        attrs: {
                          title: itemP.endTime,
                          icon: itemP.endTime
                            ? "el-icon-success"
                            : "el-icon-error"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            attrs: { slot: "description" },
                            slot: "description"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "description",
                                style: {
                                  paddingRight:
                                    index === _vm.processList.length - 1
                                      ? "10%"
                                      : "0"
                                }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      border: "none",
                                      "font-weight": "bold",
                                      color: "#000000"
                                    }
                                  },
                                  [_vm._v(_vm._s(itemP.taskName))]
                                ),
                                _c("div", [
                                  _vm._v(" " + _vm._s(itemP.assignee) + " "),
                                  itemP.comment
                                    ? _c("span", [
                                        _vm._v(" : " + _vm._s(itemP.comment))
                                      ])
                                    : _vm._e()
                                ])
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  }),
                  1
                )
              ],
              1
            )
          ]
        )
      ]),
      _c(
        "el-dialog",
        {
          attrs: { title: "评论", visible: _vm.dialogFormVisible },
          on: {
            "update:visible": function($event) {
              _vm.dialogFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            [
              _c(
                "el-form-item",
                { attrs: { "label-width": "300" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", placeholder: "请输入评论" },
                    model: {
                      value: _vm.comment,
                      callback: function($$v) {
                        _vm.comment = $$v
                      },
                      expression: "comment"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogFormVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.confirmFin } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }