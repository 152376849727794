<template>
  <div class="materials-detail-wrapper">
    <div class="materials-detail-header flex-sbc">
      <div class="left" @click="back()">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px;cursor: pointer">{{ title }}</span>
      </div>
      <div class="right">
        <el-button v-if="permission('materialsListModify', false)" type="primary" :loading="buttonLoading" @click="switchOrSubmit">
          {{ saveButton ? '保存' : '编辑' }}
        </el-button>
      </div>
    </div>

    <div class="materials-detail-body">
      <div class="detail-card">
        <div class="card-tit">物料基本信息</div>
        <div class="card-bot" style="padding: 15px">
          <MFormBuilder v-if="saveButton" ref="baseForm" :form-list="baseFormList" :form-data="baseFormData">
            <div slot="picture">
              <el-upload
                class="avatar-uploader"
                v-bind="options"
                accept=".png,.jpg"
                :before-upload="beforeAvatarUpload"
                :on-success="handleAvatarSuccess"
              >
                <img v-if="materialsImageUrl" :src="materialsImageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
            </div>
          </MFormBuilder>
          <div v-else style="display: inline-flex;justify-content: space-between;width: 100%">
            <div style="width: 200px;">
              <div v-if="!baseFormData.name" />
              <el-image
                v-else
                style="border-radius: 4px"
                fit="contain"
                :src="baseFormData.picture || empty"
                :preview-src-list="[baseFormData.picture || empty]"
              />
            </div>
            <el-row class="view-list" style="padding: 10px 15px;flex: 1;" :gutter="24">
              <!--            暂时这么处理距离问题-->
              <el-col
                v-for="(item, index) in columns"
                :key="item.prop"
                :span="6"
                :style="{marginBottom: index !== 5 ? '20px' : 0 }"
              >
                <span class="bot-tis">{{ item.label }}：</span>
                <span class="bot-tis">{{ getValue(item) }}</span>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <EditMaterialsCopy ref="editMaterialsCopy" />
    </div>
  </div>
</template>

<script>
const empty = 'https://oss.mubyte.cn/static/empty.png'
import conventionMixin from '@/mixin/conventional-page'
import { formList } from './form-list'
import { columns } from './columns'
import api from '@/api/information/materials'
import EditMaterialsCopy from '@/views/information/materials/components/edit-materials-copy'
import { setTreeData } from '@/utils'
import maApi from '@/api/information/materials-attribute'
import attributeApi from '@/api/information/materials-attribute'
import { getToken } from '@/utils/auth'

export default {
  // eslint-disable-next-line vue/name-property-casing
  name: 'materialsDetail',
  components: { EditMaterialsCopy },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      params: {
        radio: 'BaseInfo',
        type: ''
      },
      empty,
      columns,
      saveButton: false,
      baseFormList: formList,
      baseFormData: {},
      buttonLoading: false,
      formOptions: {
        materialsTypeList: [],
        materialsUnitList: [],
        materialsClassList: [],
        wareHouseList: [],
        materialsQualityList: [],
        containerClassList: [],
        materialsAttributeList: []
      },
      options: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        showFileList: false
      },
      materialsImageUrl: '',
      fromRouteName:''
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 256 - 40 - 22 - 100
    },
    title() {
      const type = this.$route.query.type
      const map = { view: '物料详情', add: '新增物料', modify: '编辑物料' }
      return map[type]
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name === 'materialsList') {
      this.$store.dispatch('tagsView/delCachedView', to)
    }
    next()
  },
  beforeRouteEnter(to, from, next) {
      next(vm => {
          vm.fromRouteName = from.name
      })
    },
  watch: {
  },
  mounted() {
    this.params.type = this.$route.query.type
    this.saveButton = this.params.type !== 'view'
    if (this.params.type === 'add') {
      this.$refs.editMaterialsCopy.add()
    } else {
      this.getMaterialsDetail()
    }
    this.getMaterialsClassList()
    this.getMaterialsTypeList()
    this.getMaterialsAttributeList()
  },
  methods: {
    async getMaterialsDetail() {
      const res = await api.getMaterialsDetailList(this.$route.query.id)
      if (res) {
        this.baseFormData = res
        this.materialsImageUrl = this.baseFormData.picture
        this.$refs.editMaterialsCopy.modifyOrCopy(res, this.params.type)
      }
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 20
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 20MB!')
      }
      return isLt2M
    },
    handleAvatarSuccess(res, file) {
      if (res.errorCode === 0) {
        this.$message.success('上传成功')
        this.$refs.baseForm.setForm({
          picture: res.result
        })

        this.materialsImageUrl = res.result
      } else {
        this.$message.error(res.result)
      }
    },
    getValue(row) {
      const result = this.baseFormData[row.prop]
      if (row.form && row.form.options) {
        const current = row.form.options.find(item => item.id === result)
        return current && current.name
      }
      if (row.prop === 'isCheck') return { 0: '否', 1: '是' }[this.baseFormData[row.prop]]
      if (row.prop === 'isKeyMaterials') return { 0: '否', 1: '是' }[this.baseFormData[row.prop]]
      return result
    },
    back() {
      if(this.fromRouteName === 'materialsList'){
        this.$router.push({ name: 'materialsList' })
      }else{
        this.$router.back()
      }
    },
    fixSinImg(img) {
      const imgs = img.split(',')
      return imgs[0]
    },
    switchOrSubmit() {
      if (this.saveButton) {
        const { form } = this.$refs.baseForm
        this.buttonLoading = true
        this.$refs.baseForm.formRefs().validate(async() => {
          this.$refs.editMaterialsCopy.submitForm(form, () => {
            this.buttonLoading = false
          })
        })
      } else {
        this.params.type = 'modify'
        this.materialsImageUrl = this.baseFormData.picture
        this.$refs.editMaterialsCopy.modifyOrCopy(this.baseFormData, 'modify')
        this.saveButton = true
      }
    },
    // 获取物料分类
    async getMaterialsClassList() {
      const res = await api.getMaterialsClassList()
      if (res) {
        this.setMaterialsClass(res)
      }
    },
    // 获取物料类型
    async getMaterialsTypeList() {
      const res = await maApi.getMaterialsTypeList(this.defaultSearch)
      if (res) {
        this.formOptions.materialsTypeList = res
        this.baseFormList[3].children[0].options = res
      }
    },
    // 获取物料分类
    setMaterialsClass(res) {
      this.formOptions.materialsClassList = res
      this.baseFormList[2].props.options = setTreeData(res, -1)
    },
    // 获取物料属性
    async getMaterialsAttributeList() {
      const res = await attributeApi.getMaterialsAttributeList()
      if (res) {
        // console.log(res)
        // if (this.params.type === 'add') {
        //   let id;
        //   res.forEach((item)=>{
        //     if(item.name==='自制或采购件'){
        //       id = item.id
        //     }
        //   })
        //   this.baseFormData = {...this.baseFormData,materialsAttributeId:id}
        // }
        this.formOptions.materialsAttributeList = res
        this.baseFormList[4].children[0].options = res

      }
    }
  }
}
</script>

<style scoped lang="scss">
.materials-detail-wrapper {
  height: 100%;
  .materials-detail-header {
    width: 100%;
    padding: 10px 0;
  }
  .materials-detail-body {
    height: calc(100% - 60px);
    overflow-y: auto;
    overflow-x: hidden;
    .card-bot {
      .info {
        padding: 20px;
        border-bottom: 1px solid #DDE3EB;
      }
      ::v-deep .el-tabs__nav {
        position: relative;
        left: 50%;
        transform: translateX(-50%) !important;
      }
    }
  }
}
.drawing-img {
  width: 50px;
  height: 48px;
  display: block;
  cursor: pointer;
  color: #fff;
}
::v-deep {
  .table-row-img {
    >img {
      height: 50px;
    }
  }
}
::v-deep {
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 150px;
  display: block;
}
.drawing-img {
  width: 50px;
  height: 48px;
  display: block;
  cursor: pointer;
  color: #fff;
}
</style>
