import { inspectionStatus } from '@/config/options.config'

const searchFormList = [
  {
    key: 'taskCheckType',
    tagName: 'el-select',
    colSpan: 6,
    props: {
      placeholder: '请选择巡检类别'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  },
  {
    key: 'status',
    tagName: 'el-select',
    colSpan: 6,
    props: {
      placeholder: '请选择巡检状态',
      multiple: true,
      filterable: true,
      collapseTags: true
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: inspectionStatus
      }
    ]
  },
  {
    key: 'equipmentClassIds',
    tagName: 'el-cascader',
    colSpan: 6,
    props: {
      placeholder: '请选择设备类',
      props: {
        label: 'name',
        value: 'id',
        checkStrictly: true,
        multiple: true
      },
      options: [],
      collapseTags: true,
      filterable: true
    }
  },
  {
    colSpan: 6,
    key: 'checkPlanTime',
    tagName: 'el-date-picker',
    dateType: 'date',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '计划开始日期',
      endPlaceholder: '结束日期',
      align: 'center',
      type: 'daterange',
      pickerOptions: []
    },
    children: []
  },
  {
    colSpan: 6,
    key: 'checkStartTime',
    tagName: 'el-date-picker',
    dateType: 'date',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '实际开始日期',
      endPlaceholder: '实际开始日期',
      align: 'center',
      type: 'datetimerange',
      pickerOptions: [],
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss',
      defaultTime: ['00:00:00', '23:59:59']
    },
    children: []
  },
  {
    colSpan: 6,
    key: 'checkEndTime',
    tagName: 'el-date-picker',
    dateType: 'date',
    props: {
      rangeSeparator: '至',
      startPlaceholder: '实际结束日期',
      endPlaceholder: '实际结束日期',
      align: 'center',
      type: 'datetimerange',
      pickerOptions: [],
      valueFormat: 'yyyy-MM-dd HH:mm:ss',
      format: 'yyyy-MM-dd HH:mm:ss',
      defaultTime: ['00:00:00', '23:59:59']
    },
    children: []
  },
  {
    key: 'result',
    tagName: 'el-select',
    colSpan: 4,
    props: {
      placeholder: '请选择巡检结论'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: [
          {
            id: 0,
            name: '异常'
          },
          {
            id: 1,
            name: '正常'
          }
        ]
      }
    ]
  },
  {
    key: 'organizationId',
    tagName: 'el-select',
    colSpan: 4,
    props: {
      placeholder: '请选择业务部门'
    },
    children: [
      {
        tagName: 'el-option',
        props: {
          label: 'name',
          value: 'id'
        },
        options: []
      }
    ]
  }
]

export default {
  searchFormList
}
