// 班次列表
export const columns = [
  {
    type: 'selection',
    minWidth: 40,
    fixed: 'left'
  },
    {
      prop: 'index',
      label: '序号',
      slotName: 'index',
      hiddenSearch: true,
      fixed: 'left',
      width: 50
    },
    {
      prop: 'projectNumber',
      label: '班次编号',
      sortable: false,
      hiddenSearch: true,
      slotName:'projectNumber',
      minWidth: 160
    },
    {
      prop: 'name',
      label: '班次名称',
      sortable: false,
      hiddenSearch: true,
      minWidth: 160
    },
    {
      prop: 'createTime',
      label: '创建时间',
      hiddenSearch: true,
      sortable: false,
      width: 160
    },
    {
      prop: 'isEnabled',
      label: '状态',
      hiddenSearch: true,
      sortable: false,
       minWidth: 100,
       slotName:'isEnabled'
    },
    {
      slotName: 'action',
      label: '操作',
      sortable: false,
      hiddenSearch: true,
      minWidth: 160,
      fixed: 'right'
    }
  ]