<template>
    <div class="scrap-wrap">
      <div class="top">
        <SearchForm
          ref="searchForm"
          :form-list-extend="searchFormList"
          :form-data="searchFormData"
          :default-form="defaultForm"
          @search="searchTable"
          :searchType='1'
        />
      </div>
      <div class="bottom">
        <FunctionHeader
          ref="functionHeader"
          v-model="functionHeader.searchVal"
          search-title="请输入单据名称/编号/项目名称/项目编号"
          :tags="functionHeader.tags"
          :export-name="functionHeader.exportName"
          :columns-key="columnsKey"
          :export-params="functionHeader.exportParams"
          @search="$refs.mTable.setTableData(true)"
        >
        </FunctionHeader>
        <MTable ref="mTable" :columns="columns" :height="height" :columns-key="columnsKey" :set-data-method="getTableData" :onlyKey="'receiptId'" @selection-change="selectionChange">
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <NewPageOpen slot="receiptNumber" slot-scope="{ row }" :path="getDetailPath(row, 'view')" :text="row.receiptNumber" />
          <div v-if="row.areaList" slot="areaIdList" slot-scope="{ row }">
            <el-tag v-for="item in row.areaList" :key="item.id" color="#F6F7F9" class="role-tags">{{ item.name }}</el-tag>
          </div>
          <div slot="receiptType" slot-scope="{ row }">{{row.receiptType | receiptType }}</div>
          <div slot="receiptStatus" slot-scope="{ row }">{{row.receiptStatus | receiptStatus }}</div>
          <div slot="workOrderCompleteRate" slot-scope="{ row }">{{row.workOrderCompleteRate}}%</div>
          <div slot="keyMaterialsCompleteRate" slot-scope="{ row }">{{row.keyMaterialsCompleteRate}}%</div>

          <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
            <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}" />
            <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
          </div>
          <div slot="isPartialOutbound" slot-scope="{ row }" class="is-enabled">
            <div :style="{background: row.isPartialOutbound ? '#24CAD7' : '#FFCC78'}" />
            <div>{{ row.isPartialOutbound ? '启用' : '禁用' }}</div>
          </div>
          <div slot="type" slot-scope="{ row }" class="is-enabled">
            <span>{{ procedureClass[row.type].name }}</span>
          </div>

        </MTable>
      </div>
    </div>
  </template>

  <script>
  import { columns } from './columns'
  import { formList, searchFormList,getFunctionList } from './form-list'
  import api from '@/api/information/materials-require'
  import conventionMixin from '@/mixin/conventional-page'
  import batchExportFront from '@/mixin/batch-export-front'
  import FunctionHeader from '@/components/FunctionHeader/index'
  import SearchForm from '@/components/SearchForm/SearchForm'
  import { procedureClass } from '@/config/options.config'
  import CURDSelect from '@/components/CURDSelect/CURDSelect'
  import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
  import { docType,docStatus } from '@/config/options.config'

  export default {
    name: 'materialsRequireList',
    components: { NewPageOpen, CURDSelect, SearchForm, FunctionHeader },
    mixins: [conventionMixin, batchExportFront],
    filters: {
        receiptType(val) {
            if (val || val=== 0) return docType.find(item => item.id === val).name
        },
        receiptStatus(val) {
            return docStatus.find(item => item.id === val).name
        },
    },
    data() {
      return {
        api,
        isProcess: false,
        procedureClass,
        params: {
          radio: 'MaterialsRequire'
        },
        changeParam: 'materialsRequireList',
        columns,
        searchFormList,
        searchFormData: {},
        defaultSearchFormData: {
          procedureId: ''
        },
        searchFormatData: [],
        functionHeader: {
          searchVal: '',
          tags: [
          {
            hidden: !this.permission('BatchApply'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small',disabled: true },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '批量领料',
            on: {
              click: () => {
                this.batchApply()
              }
            }
          }],
          exportName: '',
          exportParams: {}
        },
        formList,
        formData: {},
        messages: {
          add: '新增',
          modify: '修改',
          copy: '复制'
        },
        currentType: 'add',
        visible: false,
        stationVisible: false,
        stationList: [],
        searchKeyword: true,
        defaultForm: { receiptType: 0 },
        isPageLoaded:false,
        selectionData:[]
      }
    },
    beforeRouteLeave(to, from, next) {
      if (to.name !== 'refresh') {
        this.$store.dispatch('tagsView/addCachedView', from)
      }
      next()
    },
    computed: {
      height() {
        return this.$store.state.app.contentHeight - 235 - 25
      },
      columnsKey() {
        return this.$route.name + this.changeParam
      }
    },
    watch: {
      selectionData(val) {
      if (val && val.length > 0) {
        this.functionHeader.tags[0].props.disabled = false
      }else{
      this.functionHeader.tags[0].props.disabled = true
    }
  }
    },
    created() {
    },
    activated(){
    if(!this.isPageLoaded){
      this.$refs.mTable.setTableData()
    }
    this.isPageLoaded = false
    },
    beforeMount() {
    getFunctionList({
      applyMethodsChange: this.applyMethodsChange
    })
  },
    mounted() {
      this.searchFormList[1].attrs.placeholder = '领料方式搜索'
      this.isPageLoaded = true
    },
    methods: {
      applyMethodsChange(val){
        if(val === 0){
          this.searchFormList[1].attrs.placeholder = '请输入工序定位码/名称'
        }else if(val === 1){
          this.searchFormList[1].attrs.placeholder = '请输入模组编号/名称'
        }else if(val === 2){
          this.searchFormList[1].attrs.placeholder = '请输入物料编码/名称'
        }else{
          this.searchFormList[1].attrs.placeholder = '领料方式搜索'
        }
      },
      selectionChange(data) {
        this.selectionData = data
    },
      batchApply() {
        const data = Object.assign({}, ...this.searchFormatData)
        let workOrderIds = this.selectionData.map(item=>item.receiptId).join(',')
        this.$router.push({ name: 'materialsBatchApply', query:{selected:JSON.stringify({workOrderIds:workOrderIds,...data})}})
      },
        async getTableData(condition, callback) {
        const { page, search, order } = condition

        const data = Object.assign({}, ...this.searchFormatData)
        const searchCondition = {
          search: this.functionHeader.searchVal,
          ...data
        }
        this.functionHeader.exportParams = searchCondition
        const res = await this.api[`get${this.params.radio}ListPage`]({
          page: page.currentPage,
          size: page.pageSize,
          ...searchCondition
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
        }
        callback()
      },
      getDetailPath(row, command) {
        return { name: 'materialsRequireDetail', query: { id: row.receiptId,type:row.receiptType}}
      },
      searchTable(data) {
        this.searchFormatData = data
        this.$refs.mTable.setTableData(true)
      }
    }
}
  </script>

  <style scoped lang="scss">
  .function-header-wrap {
    padding-top: 0;
  }

  .scrap-wrap {
    width: 100%;
    padding-top: 10px;
    .bottom {
      padding: 10px;
    }
    .m-radio-group {
      margin-left: 10px;
    }
  }
  .role-tags {
    margin: 5px;
    border-color: #dde3eb;
    color: #9597ae;
  }
  ::v-deep {
    .e-divider {
      margin: 0px 0 0 0;
    }
  }
  </style>
