import { docStatus, docType, procedureClass } from '@/config/options.config'
let functionList = {}
export const getFunctionList = val => {
  functionList = val
}
export const base = [
  {
    prop: 'receiptNumber',
    label: '单据编码',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'receiptName',
    label: '单据名称',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'receiptType',
    label: '单据类型',
    sortable: false,
    hiddenSearch: true,
    slotName:'receiptType',
    form: {
        tagName: 'el-select',
        options: docType,
        colSpan: 6
      }
  },
  {
    prop: 'receiptStatus',
    label: '单据状态',
    sortable: false,
    slotName: 'isEnabled',
    hiddenSearch: true ,
    form: {
        tagName: 'el-select',
        options: docStatus,
        colSpan: 6
      }
  },
  {
    prop: 'projectNumber',
    label: '项目编号',
    sortable: false,
    hiddenSearch: true
  },
  {
    prop: 'projectName',
    label: '项目名称',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'requireDate',
    label: '需求时间',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'workOrderCompleteRate',
    label: '工单齐套率',
    slotName: 'workOrderCompleteRate',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'requirementQuantity',
    label: '需求总数',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'supplyQuantity',
    label: '供应总数',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'receivedQuantity',
    label: '已领数量',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'notReceiveQuantity',
    label: '未领数量',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'keyMaterialsRequirementQuantity',
    label: '关键料需求数',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'keyMaterialsSupplyQuantity',
    label: '关键料供应数',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'keyMaterialsReceivedQuantity',
    label: '关键料已领数',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'keyMaterialsNotReceivedQuantity',
    label: '关键料未领数',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'keyMaterialsCompleteRate',
    label: '关键料齐套率',
    sortable: false,
    slotName: 'keyMaterialsCompleteRate',
    hiddenSearch: true,
  },
  {
    prop: 'computingTime',
    label: '计算时间',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true
  }
]
export const columnsWork = [
    // {
    //     type: 'selection',
    //     width: 50,
    //     fixed: 'left',
    //     reserveSelection: true
    //   },
      {
        prop: 'index',
        label: '序号',
        sortable: true,
        minWidth: 80,
        hiddenSearch: true,
        slotName: 'index'
      },
      {
        prop: 'materialsType',
        label: '物料类型',
        sortable: false,
        hiddenSearch: true,
        minWidth:120
      },
    {
      prop: 'materialsCode',
      label: '物料编码',
      sortable: false,
      hiddenSearch: true,
      minWidth:150
    },
    {
      prop: 'materialsName',
      label: '物料名称',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'specifications',
      label: '物料规格',
      sortable: false,
      hiddenSearch: true
    },
    {
        prop: 'mainUnitName',
        label: '主单位',
        sortable: false,
        hiddenSearch: true,
        minWidth:100
      },
      {
        prop: 'requirementQuantity',
        label: '需求数量',
        sortable: false,
        hiddenSearch: true,
        minWidth:100
      },
      {
        prop: 'receivedQuantity',
        label: '已领数量',
        sortable: false,
        hiddenSearch: true,
        minWidth:100
      },
    {
      prop: 'notReceiveQuantity',
      label: '未领数量',
      sortable: false,
      hiddenSearch: true,
      minWidth:100
    },
    {
      prop: 'appliedUnissuedQuantity',
      label: '申请未发数量',
      sortable: false,
      hiddenSearch: true,
      minWidth:120
    },
  {
    prop: 'supplyQuantity',
    label: '供应数',
    sortable: false,
    hiddenSearch: true,
    minWidth:120
  },
  {
    prop: 'stockQuantity',
    label: '库存数',
    sortable: false,
    hiddenSearch: true,
    minWidth:120
  },
  {
    prop: 'allocatedStockQuantity',
    label: '分配数',
    sortable: false,
    hiddenSearch: true,
    minWidth:120
  },
  {
    prop: 'completeRate',
    label: '齐套率',
    sortable: false,
    hiddenSearch: true,
    slotName:'completeRate',
    minWidth:120
  },
  {
    prop: 'isKeyMaterials',
    label: '是否关键料',
    sortable: false,
    hiddenSearch: true,
    slotName:'isKeyMaterials',
    minWidth:120
  },
    {
      prop: 'requireDate',
      label: '需求日期',
      sortable: false,
      hiddenSearch: true,
      minWidth:120
    },
  // {
  //   slotName: 'action',
  //   label: '操作',
  //   fixed: 'right',
  //   sortable: false,
  //   hiddenSearch: true,
  //   width: 120
  // }
  ]
//物料锁库列表
export const columnsOccupyMaterials = [
      {
        prop: 'index',
        label: '序号',
        sortable: true,
        minWidth: 80,
        hiddenSearch: true,
        slotName: 'index'
      },
      {
        prop: 'materialsType',
        label: '物料类型',
        sortable: false,
        hiddenSearch: true,
        slotName:'materialsType',
        minWidth:120
      },
    {
      prop: 'materialsCode',
      label: '物料编码',
      sortable: false,
      hiddenSearch: true,
      minWidth:150
    },
    {
      prop: 'materialsName',
      label: '物料名称',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'specifications',
      label: '物料规格',
      sortable: false,
      hiddenSearch: true
    },
    {
        prop: 'mainUnitName',
        label: '主单位',
        sortable: false,
        hiddenSearch: true,
        minWidth:100
      },
    {
      prop: 'notReceiveQuantity',
      label: '未领数量',
      sortable: false,
      hiddenSearch: true,
      minWidth:100
    },
    // {
    //   prop: 'availableStockQuantity',
    //   label: '自由库存',
    //   sortable: false,
    //   hiddenSearch: true,
    //   minWidth:100
    // },
    // {
    //   prop: 'lockedQuantity',
    //   label: '锁库数量',
    //   sortable: false,
    //   hiddenSearch: true,
    //   slotName:'lockedQuantity'
    // }
  ]
