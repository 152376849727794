export const columns = [
  {
    type: 'selection',
    width: 50,
    fixed: 'left',
    reserveSelection: true
  },
    {
      prop: 'index',
      label: '序号',
      sortable: true,
      minWidth: 80,
      hiddenSearch: true,
      slotName: 'index'
    },
    {
      prop: 'code',
      label: '料单号',
      slotName: 'code',
      sortable: false,
      hiddenSearch: true,
      minWidth: 160
    },
    {
      prop: 'name',
      label: '料单名称',
      sortable: false,
      hiddenSearch: true,
      minWidth: 160
    },
    {
      prop: 'receiptType',
      label: '单据类型',
      sortable: false,
      slotName: 'receiptType',
      hiddenSearch: true
    },
    {
      prop: 'directionType',
      label: '方向',
      sortable: false,
      hiddenSearch: true,
      slotName: 'directionType',
      minWidth: 160
      },
    {
      prop: 'receiptStatus',
      label: '状态',
      sortable: false,
      slotName: 'receiptStatus',
      hiddenSearch: true,
      minWidth: 120
    },
    {
      prop: 'applyQuantity',
      label: '申请数量',
      sortable: false,
      hiddenSearch: true,
      minWidth: 100
    },
    {
      prop: 'receivedQuantity',
      label: '完成数量',
      sortable: false,
      hiddenSearch: true,
      minWidth: 100
    },
    {
      prop: 'notReceiveQuantity',
      label: '未完数量',
      sortable: false,
      hiddenSearch: true,
      minWidth: 100
    },
    {
      prop: 'requireDate',
      label: '时间',
      sortable: false,
      hiddenSearch: true,
      minWidth: 120
    },
    {
        prop: 'receiptNumber',
        label: '工单编号',
        sortable: false,
        hiddenSearch: true
      },
      {
        prop: 'receiptName',
        label: '工单名称',
        sortable: false,
        hiddenSearch: true
      },
    {
      prop: 'projectNumber',
      label: '项目编号',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'projectName',
      label: '项目名称',
      hiddenSearch: true,
      sortable: false
    },
    {
      prop: 'remark',
      label: '备注',
      sortable: false,
      hiddenSearch: true
    },
    {
      slotName: 'action',
      label: '操作',
      sortable: false,
      hiddenSearch: true,
      width: 80,
      fixed: 'right'
    }
  ]
