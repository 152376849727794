export const columns = [
    {
      prop: 'typeName',
      label: '对象',
      sortable: false,
      hiddenSearch: true,
      form: { index: 1, tagName: 'el-select', key: 'type', 
      options: [],
      colSpan: 24,
      props: {
        disabled: false,
      } 
    }
    },
    {
      prop: 'templateName',
      label: '模板名称',
      sortable: false,
      hiddenSearch: true,
      form: { index: 2, tagName: 'el-input',colSpan: 24}
    },
    {
      prop: 'isSystem',
      label: '是否系统模板',
      slotName:'isSystem',
      sortable: false,
      hiddenSearch: true
    },
    {
      prop: 'fixedLine',
      label: '每页固定行',
      slotName:'fixedLine',
      sortable: false,
      hiddenSearch: true,
      form: {
        index: 4,
        tagName: 'div',
        customSlots: ['fixedLine'],
        colSpan: 24,
        required: false
      }
    },
    {
      prop: 'remark',
      label: '备注',
      sortable: false,
      hiddenSearch: true,
      form: { index: 5,
        tagName: 'el-input',
        required: false,
        colSpan: 24,
        props: { type: 'textarea' }
      }
    },
    {
      slotName: 'action',
      label: '操作',
      fixed: 'right',
      sortable: false,
      hiddenSearch: true,
      width: 165
    }
  ]
  