<template>
  <div class="batch-number-wrap common-tab">
    <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange">
      <el-radio-button v-if="permission('fastPage')" label="fast">快速生成</el-radio-button>
      <el-radio-button v-if="permission('historyPage')" label="history">历史生成</el-radio-button>
    </el-radio-group>
    <div v-if="params.radio === 'fast' && permission('fastPage')" class="top">
      <SearchForm ref="searchForm" :immediately-query="true" :form-list-extend="searchFormList">
        <m-remote-select
          slot="prepend"
          v-model="params.materialsId"
          class="input-prepend"
          placeholder="请选择物料"
          :interface-fun="materialsApi.getMaterialsListPage"
          :options.sync="formOptions.materialsList"
          :condition="remoteSelectCondition"
          :clearable="false"
          :on-change="remoteMaterialsSelectChange"
        />
        <el-button slot="append" icon="el-icon-more" @click="selectMaterials" />
      </SearchForm>
    </div>
    <div v-else class="placeholder-div" />
    <div v-if="permission(params.radio + 'Page')" class="bottom">
      <FunctionHeader
        v-model="functionHeader.searchVal"
        search-title="请输入批次码"
        :tags="functionHeader.tags"
        :hidden-search="params.radio === 'fast'"
        @search="$refs.mTable.setTableData(true)"
      />
      <MTable
        ref="mTable"
        :data="dataSourceFront && batchNumberData"
        :columns="columns[params.radio]"
        :height="height"
        :columns-setting="false"
        :row-class-name="tableRowClassName"
        :set-data-method="getTableData"
        @selection-change="selectionChange"
      >
        <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
        <el-button slot="materialsCode" slot-scope="{ row }" type="text" @click="viewMaterialsDetail(row.materialsId)">{{ row.materialsCode }}</el-button>
        <div slot="batchNumber" slot-scope="{ row }" class="batch-number-columns">
          <span>{{ row.batchNumber }}</span>
          <img v-if="currentBatchNumberList.indexOf(row.batchNumber) !== -1" src="../../../../assets/information/新@2x.png">
        </div>
        <div slot="action" slot-scope="{ row }">
          <el-button type="text" @click="printCode(row)">打印</el-button>
          <el-divider v-if="permission('Del')" direction="vertical" />
          <DelPopover v-if="permission('Del')" @onOk="callback => del(row, callback)" />
        </div>
      </MTable>
    </div>

    <MDialog v-model="visible" width="400px" title="生成批次码" @onOk="submitForm">
      <MFormBuilder ref="formBuild" label-position="left" :form-list="formList" />
    </MDialog>

    <SelectMaterials :visible.sync="materialVisible" :conditions="materialsConditions" :single="true" @submitForm="setMaterialId" />
    <PrintCodes v-if="memberCode === 'kiki'" ref="printCode" :common-id="commonId" />

    <PrintCode v-else ref="printCode" :common-id="commonId" />

    <!-- 物料详情-->
    <edit-materials ref="editMaterials" />
  </div>
</template>

<script>
import FunctionHeader from '@/components/FunctionHeader/index'
import { fastColumns, historyColumns } from './columns'
import { formList, searchFormList } from './form-list'
import SearchForm from '@/components/SearchForm/SearchForm'
import DelPopover from '@/components/DelPopover/index'
import materialsApi from '@/api/information/materials'
import api from '@/api/information/print-label/batch-number'
import { Encrypt } from '@/utils/sercet'
import { getUUid } from '@/utils'
import SelectMaterials from '@/components/SelectMaterials/SelectMaterials'
import { getFunctionList } from './form-list'
import PrintCode from '@/components/PrintCode/printCode'
import EditMaterials from '@/views/information/materials/components/edit-materials'
import viewMaterialsDetail from '@/mixin/view-materials-detail'
import PrintCodes from '@/project-custom/kiki/print-code-info/print-code-info'

export default {
  name: 'batchNumber',
  components: { PrintCodes, EditMaterials, PrintCode, SelectMaterials, SearchForm, FunctionHeader, DelPopover },
  mixins: [viewMaterialsDetail],
  data() {
    return {
      commonId: 'print',
      materialsApi,
      select: '',
      params: {
        radio: 'fast',
        materialsId: null
      },
      columns: {
        fast: fastColumns,
        history: historyColumns
      },
      functionHeader: {
        searchVal: '',
        tags: [
          {
            tagName: 'el-button',
            props: { type: 'primary', size: 'small', disabled: true },
            style: { width: 'auto' },
            innerText: '批量打印',
            on: {
              click: this.batchPrint
            }
          }
        ]
      },
      formList,
      searchFormList,
      visible: false,
      formOptions: {
        materialsList: []
      },
      defaultSearch: {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      },
      materialVisible: false,
      currentBatchNumberList: [],
      printCodes: [],
      materialsConditions: [
        {
          id: getUUid(),
          fieldName: 'isBatchManager',
          fieldType: 'number',
          fieldValue: 1,
          operator: 'eq'
        }
      ],
      dataSourceFront: true,
      batchNumberData: [],
      remoteSelectCondition: [{
        id: getUUid(),
        fieldName: 'isBatchManager',
        fieldType: 'number',
        fieldValue: 1,
        operator: 'eq'
      }]
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 65
    },
    memberCode() {
      return sessionStorage.getItem('memberCode')
    }
  },
  beforeMount() {
    getFunctionList({
      generateBatchNumber: this.generateBatchNumber,
      addBatchNumber: this.addBatchNumber
    })
  },
  mounted() {
  },
  methods: {
    // 获取物料数据
    async getTableData(condition, callback) {
      const { page, search, order } = condition
      // 在快速生成下未选择物料不获取批次码
      if (this.params.radio === 'fast') {
        callback({
          total: 0,
          content: []
        })
        callback()
        return false
      }
      if (this.functionHeader.searchVal) {
        search.push({
          id: getUUid(),
          fieldName: 'batchNumber',
          fieldType: 'string',
          fieldValue: this.functionHeader.searchVal,
          operator: 'like'
        })
      }
      const res = await api.getBatchNumberListPage({
        page: page.currentPage,
        limit: page.pageSize,
        sort: order,
        searchVal: Encrypt(JSON.stringify([...search]))
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    remoteMaterialsSelectChange(id, list) {
      console.log(1)
      this.params.materialsId = id
      this.formOptions.materialsList = list
    },
    tableRowClassName({ row }) {
      if (this.currentBatchNumberList.indexOf(row.batchNumber) !== -1 && this.params.radio === 'history') {
        return 'new-columns'
      }
      return ''
    },
    selectMaterials() {
      this.materialVisible = true
    },
    setMaterialId(list) {
      this.params.materialsId = list[0].id
      this.formOptions.materialsList = list
      this.materialVisible = false
    },
    radioChange(radio) {
      this.functionHeader.searchVal = ''
      this.dataSourceFront = radio === 'fast'
      this.params.materialsId = null
      this.$refs.mTable.setTableData(true)
    },
    batchPrint() {
      this.commonId = `${this.printCodes[0]}print`
      this.$refs.printCode.batchPrint(this.printCodes)
    },
    printCode(row) {
      this.commonId = `${row.batchNumber}print`
      this.$refs.printCode.print(row)
    },
    async del(row) {
      const res = await api.delBatchNumber({ id: row.id })
      if (res) {
        this.$message.success('删除成功')
        this.$refs.mTable.setTableData()
      }
    },
    // 添加批次码
    async addBatchNumber() {
      if (!this.params.materialsId) {
        return this.$message.info('请选择物料')
      }
      const materials = await materialsApi.getMaterialsDetailList(this.params.materialsId)
      const res = await api.addBatchNumber({
        batchNumber: this.$refs.searchForm.form.batchNumber,
        materialsId: materials.id,
        materialsCode: materials.code
      })
      if (res) {
        this.$refs.searchForm.setForm({ batchNumber: '' })
        this.currentBatchNumberList.push(res.batchNumber)
        this.batchNumberData.push(res)
        this.$message.success('添加成功')
        this.$refs.mTable.setTableData()
      }
    },
    // 自动生成批次码
    generateBatchNumber() {
      if (!this.params.materialsId) {
        return this.$message.info('请选择物料')
      }
      this.visible = true
    },
    selectionChange(data) {
      this.printCodes = data
      this.functionHeader.tags[0].props.disabled = !this.printCodes.length
    },
    async submitForm(callback) {
      const { form } = this.$refs.formBuild
      if (!form.quantity) {
        callback()
        return this.$message.info('请输入数量')
      }
      const materials = await materialsApi.getMaterialsDetailList(this.params.materialsId)
      const res = await api.batchAddBatchNumber({
        materialsId: materials.id,
        materialsCode: materials.code,
        generateCount: form.quantity
      })
      if (res) {
        this.visible = false
        this.currentBatchNumberList.push(...res.map(item => item.batchNumber))
        this.batchNumberData.push(...res)
        this.$message.success('添加成功')
        this.$refs.mTable.setTableData()
        callback(false)
      }
      callback()
    }
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}

.m-radio-group {
  margin: 10px 10px 0 10px;
}

.batch-number-wrap {
  width: 100%;

  .top {
    ::v-deep {
      .search-form-wrap {
        form {
          .el-row {
            .el-col:last-child {
              .el-form-item__content {
                margin-left: 0 !important;
              }
            }
          }
        }
      }
    }
  }

  .bottom {
    padding: 10px;
  }
}

.placeholder-div {
  height: 57px;
  padding: 20px 20px 0 20px;
  border-bottom: 1px solid #DDE3EB;
}

::v-deep input[classname=select-materials] {
  display: none;
}

::v-deep .el-input-group--prepend {
  width: 100%;
  height: 32px;
}

::v-deep .el-input-group__prepend {
  background: #FFFFFF;
  width: calc(100% - 56px);

  > div {
    width: calc(100% + 40px);
  }

  .input-prepend {
    width: 100%;
    color: #495060;
  }
}

::v-deep .el-input-group__append {
  width: 56px;
}
::v-deep {
  .el-table .new-columns {
    background: #ECFAFF;
  }
}
.batch-number-columns {
  display: inline-flex;
  align-items: center;
  img {
    height: 11px;
    padding-left: 5px;
  }
}
</style>
